import { Modal, ModalClose,  Sheet } from '@mui/joy';
import { Fragment, useState } from 'react';
import {  Button, Switch } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DoneIcon from '@mui/icons-material/Done';

export default function AdminSuspendAccountModal(props) {
    let { isSusupended } = props;
    const [open, setOpen] = useState(false);
    const [isAcSusupended, setIsAcSusupended] = useState(isSusupended);

    const handleDeleteButton = () => {
        handleCancel();
    };

    const handleCancel = () => {
        setOpen(false);
        setIsAcSusupended(isAcSusupended);
    };


    const handleCheckCancel = (e, reason) => {
        if (reason === 'closeClick') handleCancel(false);
    }

    function SwitchThumb(props) {
        return (
            <div className={`switch ${props.isChecked ? 'checked' : 'unchecked'}`}>
                {props.isChecked ? <DoneIcon /> : <HighlightOffIcon />}
            </div>
        );
    }

    return (
        <Fragment>
            {isAcSusupended ?
                <Switch
                    checked={isAcSusupended}
                    icon={<SwitchThumb isChecked={false} />}
                    checkedIcon={<SwitchThumb isChecked={true} />}
                    disabled
                /> :
                <Switch
                    checked={isAcSusupended}
                    icon={<SwitchThumb isChecked={false} />}
                    disabled
                />
            }
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={handleCheckCancel}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                className="delete-model-popup"
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.body',
                        }}
                    />
                    <p>
                        Are you sure to make this account suspended?
                    </p>
                    <div className='button-alignment'>
                        <Button className='cancel-btn' onClick={handleCancel}>No</Button>
                        <Button className='success-btn' type="button" onClick={handleDeleteButton}>Yes</Button>

                    </div>
                </Sheet>
            </Modal>
        </Fragment>
    );
}
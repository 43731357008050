import { Button, Modal, ModalClose, Typography, Sheet } from '@mui/joy';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Grid, TextField, Checkbox } from '@mui/material';
import { handlingCatchErorr,handlingSuccessResponse } from '../../../utils/errorHandling';
import {api} from '../../../../services/api';
import { useNavigate } from "react-router-dom";
import { validationFunction } from '../../commonFunctionalities/AllValidation';

let textFieldVariables = { "First Name": "fName", "Last Name": "lName", "Company": "company", "Phone": "phone", "Email Address": "email" };
let widthValues = { "fName": 6, "lName": 6, "company": 12, "phone": 12, "email": 12 }
let notRequired = ["company", "phone"];

export default function AccountDetailsEditModal(props) {
  let { editData, handleLoader, userId } = props;
  const isFirstRender = useRef(true);
  const navigate = useNavigate();
  let initialFormData = {
    fName: editData.firstname ? editData.firstname : '',
    lName: editData.lastname ? editData.lastname : '',
    company: editData.company ? editData.company : '',
    phone: editData.phone ? editData.phone : '',
    email: editData.email ? editData.email : '',
    isChecked: editData.isreceiveemailalerts ? editData.isreceiveemailalerts : false,
  };
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({ ...initialFormData });
  const [isNextStep, setIsNextStep] = useState(false);
  const [errors, setErrors] = useState({});
  const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [isChanged, setIsChanged] = useState(false);



  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return
    }
    if (isNextStep) handleAccountPostSubmit();
  }, [errors])

  const handleChange = (e) => {
    if (isNextStep) setIsNextStep(!isNextStep);
    const { name } = e.target;
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const initialValue = initialFormData[name];
    const valueChanged = initialValue !== value;
    if (!valueChanged) {
      setIsChanged(false);
    } else if (isNextStep) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
    let returnedInfo = validationFunction(name, value);
    if (name === "phone") setFormData((prevData) => ({ ...prevData, [name]: returnedInfo?.phoneData }));
    else setFormData((prevData) => ({ ...prevData, [name]: value }));
    errorMessageFunc(name, returnedInfo);
  };
  const errorMessageFunc = (errorName, errorMessage) => {
    if (errorName === "phone") setErrors((prevData) => ({ ...prevData, [errorName]: errorMessage.errMsg }));
    else setErrors((prevData) => ({ ...prevData, [errorName]: errorMessage }));
  }

  const handleCheckCancel = (e, reason) => {
    if (reason === 'closeClick') handleCancel(false);
  }

  const handleCancel = (isSuccess) => {
    setOpen(false);
    setIsChanged(false)
    setErrors({});
    if (!isSuccess) setFormData(initialFormData);
    setIsNextStep(false);
  };

  const handleAccountPostSubmit = () => {
    let areThereErrorMsgOrNot = Object.entries(errors).length !== 0 ? Object.entries(errors).every(([key, val]) => val === "") ? true : false : true;
    if (areThereErrorMsgOrNot) {
      handleLoader(true, false);
      const userEditInfo = {
        "id": userId,
        "login": formData?.email,
        "firstName": formData?.fName,
        "lastName": formData?.lName,
        "company": formData?.company,
        "phone": formData?.phone === "" ? null : formData?.phone,
        "billingAddress": editData?.billingAddress,
        "billingAddress2": editData?.billingAddress2,
        "billingCity": editData?.billingCity,
        "billingState": editData?.billingStateId,
        "billingStateId": editData?.billingState,
        "billingZipCode": editData?.billingZipCode,
        "paymentCustomerId": editData?.paymentCustomerId,
        "paymentCustomerAddressId": editData?.paymentCustomerAddressId,
        "billingStoreName": editData?.billingStoreName,
        "billingCountryCode": editData?.billingCountryCode?.toString(),
        "couponCode": editData?.couponCode,
        "billingCountry": editData?.billingCountry,
        "uphone": formData?.phone === "" ? null : formData?.phone,
        "isreceiveemailalerts": formData?.isChecked,
      }
      api.put("account", userEditInfo)
        .then((res) => {
          if (storedUserInfo && storedUserInfo.usertypeId === editData.usertypeid) {
            // Update only the firstname and lastname fields  for update top right corner
            const updatedUserInfo = {
              ...storedUserInfo,
              firstname:  formData?.fName,
              lastname: formData?.lName,  
            };
            localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
    
          }
           
          handleLoader(true, true);
          handleCancel(true);
          handlingSuccessResponse(res, "Account Details Updated Successfully");
        })
        .catch((err) => {
          handleCancel(false);
          handlingCatchErorr(err, navigate);
          handleLoader(false, false);
        });
    }
  }

  const handleAccountPreSubmit = () => {
    setIsNextStep(true);
    setIsChanged(false);
    Object.entries(formData).map(([key, val]) => {
      let returnedErrors = validationFunction(key, val)
      errorMessageFunc(key, returnedErrors);
      return '';
    });
  }

  return (
    <Fragment>
      <Button className='edit-account' onClick={() => setOpen(true)}>
        Edit Account
      </Button>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={handleCheckCancel}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        className="custom-model-popup account-details-popup"
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: 'calc(-1/4 * var(--IconButton-size))',
              right: 'calc(-1/4 * var(--IconButton-size))',
              boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
              borderRadius: '50%',
              bgcolor: 'background.body',
            }}
          />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            Account Details
          </Typography>
          <form className='custom-form'>
            <Grid container spacing={3}>
              {Object.entries(textFieldVariables).map(([key, val]) => (
                <Grid key={key} item xs={widthValues[val]}>
                  <TextField
                    name={val}
                    label={key}
                    type={val === "email" ? "email" : "text"}
                    value={formData && formData[val]}
                    onChange={handleChange}
                    fullWidth
                    error={val !== "company" ? !!errors[val] : false}
                    helperText={val !== "company" ? errors[val] : false}
                    required={!notRequired.includes(val) ? true : false}
                    className={`custom-fields ${key === "Email Address" ? "email-disabled" : ""}`}
                    InputProps={{
                      readOnly: key === "Email Address" ? true : false,
                    }}
                  />
                </Grid>
              )
              )}
            </Grid>
            <Grid item xs={12} className='receive-alert'>
              <Checkbox name="isChecked" checked={formData?.isChecked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />Receive Email Alerts
            </Grid>
            <Grid item xs={12} className='button-alignment'>
              <Button variant="text" onClick={() => handleCancel(false)} className='cancel-btn'>Cancel</Button>
              <Button id={!isChanged ? "disabled-bg" : ""} variant="contained" type="button" onClick={handleAccountPreSubmit} className='success-btn'>Save</Button>
            </Grid>
          </form>
        </Sheet>
      </Modal>
    </Fragment>
  );
}
import React, { Fragment, memo, useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from "@mui/material";
import { Button as JoyButton } from '@mui/joy';
import SubscriptionCancelModal from "../../modals/SubscriptionCancelModal";
import { useNavigate } from "react-router-dom";
import { paymentMode, paymentTerm, productNames } from "../../../utils/enums";
import { formattedAmount } from "../../commonFunctionalities/commonFunction";
import EnhancedTableHead from "../../commonFunctionalities/CommonMuiSortingFunc";
import moment from "moment";

const RenderSubscriptionRows = (data, updateHandler, cancelPopup, isImpersonated) => {
  return (
    <>
      {data.map((item, index) => (
        <TableRow
          key={index}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell component="th" scope="row">
            {item && item.name ? item.name === productNames.USProductName ? "Explorer" : item.name : "-"}
          </TableCell>
          <TableCell align="right">
            {item && item.subscription ? item.subscription.packagename : "-"}
          </TableCell>
          <TableCell align="right">
            {item && item.subscription && item.subscription.userSubscribedStates ? item.subscription.userSubscribedStates?.toString()?.replaceAll(",", ", ")
              : item.subscription.userSubscribedCountries ? item.subscription.userSubscribedCountries?.toString()?.replaceAll(",", ", ") : "-"}
          </TableCell>
          <TableCell align="right">
            {item && item.subscription.price ? item && item.subscription.price === 0 || item.subscription.paymentMode === paymentMode.offline ? "-" :
             `${formattedAmount(item.subscription.price, item?.currency, item?.currencycode)}`
             : "-"}
          </TableCell>
          <TableCell align="right">
            {item && item.subscription ? item.subscription.frequency : "-"}
          </TableCell>
          <TableCell align="right">
            {item && item.subscription && item.subscription.validtill
              ? moment(item.subscription.validtill).format('DD-MMM-YYYY')
              : "-"}
          </TableCell>
          {// !isImpersonated ?
            item?.subscription.paymentTerm === paymentTerm.recurring
              && item?.subscription.paymentMode !== paymentMode.offline && !item?.subscription?.validtill  ? (
              <>
                {item?.hasNextLevelSubscription ? (
                  <TableCell align="right">
                    <JoyButton
                      className="view-update-btn"
                      style={{ minWidth: '80px' }}
                      onClick={() => {
                        if ( item.subscription?.subCategoryList){
                          item.subscription?.subCategoryList?.filter(id=>id.defaultCountryId === item.defaultCountryId )
                          ?.filter((val, priceIndex, self) =>
                          val.subscriptionPriceName !== "" &&
                          self.findIndex((v) => v.subscriptionPriceName === val.subscriptionPriceName) === priceIndex
                        )
                          .map((val) => {
                            updateHandler(
                              item.permalink,
                              item.subscription.subscriptionkey,
                              val.subscriptionkey // Accessing subscriptionkey directly from val
                            );
                          });
                        }else{
                          updateHandler(
                            item.permalink,
                            item.subscription.subscriptionkey,
                            null // Accessing subscriptionkey directly from val
                          );
                        }
                       
                            
                        
                      }}
                      
                    >
                      Update
                    </JoyButton>
                  </TableCell>
                ) : (
                  <>
                    <TableCell align="right">{"-"}</TableCell>

                  </>
                )}
                <TableCell align="right">
                  <JoyButton className="user-cancel-btn" onClick={() => cancelPopup(item)} style={{ marginLeft: '0px' }}>
                    Cancel
                  </JoyButton>
                </TableCell>
              </>
            ) : (
              <>
                <TableCell align="right">{"-"}</TableCell>
                <TableCell align="right">{"-"}</TableCell>
              </>
            )
            // : null
          }


        </TableRow>
      ))}
    </>
  );
};



const SubscriptionTab = (props) => {
  let { subTabData, productLoad, invoiceLoad } = props;
  const [isCancel, setIsCancel] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()
  const [isImpersonated, setImpersonated] = useState(false);


  const updateHandler = (permalink, key,subkey) => {
    navigate(`/pricing/${permalink}/?sub=${key}&key=${subkey}`);

    // });
  };


  const cancelPopup = (item) => {
    if (item && item.subscription && item.subscription.id && item.subscription.subscriptionPriceId) {
      setIsCancel(!isCancel);
      setSelectedSubscription({
        productId: item.productId,
        subscriptionId: item.subscription.id,
        subscriptionPriceId: item.subscription.subscriptionPriceId,
      });
    }
  };
  let headRows = [
    { id: 'name', label: 'Product Name ', numeric: false },
    { id: 'packagename', label: 'Subscription Name', numeric: false },
    { id: 'details', label: 'Details', numeric: false },
    { id: 'price', label: 'Price', numeric: false },
    { id: 'frequency', label: 'Frequency', numeric: false },
    { id: 'validtill', label: 'valid till', numeric: false },
    { id: 'modify', label: 'Modify', numeric: true },
    { id: 'cancel', label: 'Cancel', numeric: true }
    // isImpersonated  ? null : { id: 'modify', label: 'Modify', numeric: true },
    // isImpersonated  ? null : { id: 'cancel', label: 'Cancel', numeric: true }
  ].filter(Boolean);

  let disableTableRowsNames = ['details','modify', 'cancel'];
  const [orderBy, setOrderBy] = useState("desc");
  const [order, setOrder] = useState("asc");

  const handleSort = (property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };
  

  // Add a helper function to compare values
const compareValues = (valueA, valueB) => {
  const numericA = parseFloat(valueA);
  const numericB = parseFloat(valueB);

  if (!isNaN(numericA) && !isNaN(numericB)) {
    return numericA - numericB;
  } else {
    return String(valueA).localeCompare(String(valueB));
  }
};
const subscriptionData = subTabData?.slice().sort((a, b) => new Date(b.subscription.createdDate) - new Date(a.subscription.createdDate));

const sortedData = subscriptionData.sort((a, b) => {
  const valueA = (a.subscription && a.subscription[orderBy]) || "";
  const valueB = (b.subscription && b.subscription[orderBy]) || "";

  const comparisonResult = compareValues(valueA, valueB);

  return order === "asc" ? comparisonResult : -comparisonResult;
});


  useEffect(() => {
    const impersonationInfo = localStorage.getItem('impersonationInfo');
    const userDetails = JSON.parse(localStorage.getItem("userInfo"));
    if (impersonationInfo || userDetails.impersonated) {
      setImpersonated(true);
    }
    else{ 
      setImpersonated(false);
    }
  }, []);  

  return (
    <Fragment>
      {((props.load) || (isLoading)) && <div className="loader-wrap"><div className="loading"></div> : </div>}
      <TableContainer component={Paper} className="common-table-design">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">

          <EnhancedTableHead
            order={order}
            onRequestSort={handleSort}
            disableTableRowsNames={disableTableRowsNames}
            headRows={headRows}

          />
          <TableBody>
            {sortedData && sortedData.length !== 0 ?
              <>
                {RenderSubscriptionRows((sortedData || []), updateHandler, cancelPopup, isImpersonated)}
              </>
              : (!props.load &&
                <TableRow>
                  <TableCell colSpan={8} className="no-data">{'No Data Found'}</TableCell>
                </TableRow>)}
          </TableBody>
        </Table>
      </TableContainer>

      {isCancel && (
        <SubscriptionCancelModal
          open={isCancel}
          cancelDetail={selectedSubscription}
          close={(val) => setIsCancel(val)}
          productLoads={productLoad}
          invoiceLoad={invoiceLoad}
          loader={(val) => setIsLoading(val)}
        />
      )}
    </Fragment>
  );
};

export default memo(SubscriptionTab);
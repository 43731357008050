import { Box, Button, Card, Checkbox, FormGroup, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TermsofUse from "../Home/modals/CheckoutModals/termsofUse";
import { Types } from "../utils/enums";
import MUILink from "@mui/material/Link";



const OrderSummary = (props) => {
    const { handleAgree, subDetail, isChecked, usersSearchedData, errors,isNational, accountBlurData, details, isAuthenticated, checked, isPreSunbsCribedStateList, allTerms, stateCount, priceDetail, paymentLength,isUnlimitedUS } = props;
    const summaryDetail = details;
    let userData = JSON.parse(localStorage.getItem("userInfo"));
    const accountDatas = accountBlurData;
    const handleCheckout = () => {
        props.handleValidationSteps();
    };
    const [open, setOpen] = useState(false);
    const [id, setId] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);


    const handleCheckCancel = (e, reason) => {
        if (reason === 'closeClick') handleCancel(false);
    }


    const handleCancel = () => {
        setOpen(false);
    };
    useEffect(() => {
        if (userData && userData.impersonated) {
            setIsButtonDisabled(true);
        }
    }, [userData]);



    // Function to check if all required form fields are filled
    const allFieldsFilled = () => {
        const requiredFields = ['fName', 'lName', 'email', 'registerphone', 'NewPassword', 'ConfirmPassword'];
        const requiredFieldOneTime =  ['fName', 'lName', 'email', 'registerphone'];
        const checkField = summaryDetail.Subscription === Types.reports ? requiredFieldOneTime : requiredFields
        for (const field of checkField) {
            if (!summaryDetail.AccountInfo[field]) {
                return false; // If any required field is empty, return false
            }
        }
        return true; // All required fields are filled
    };

    const allPaymentFilled = () => {
        if (!summaryDetail.payment) {
            return false; // If any required field is empty, return false
        }

        return true;
    }

    const allStateFilled = () => {
        if (!Object.entries(checked).length > 0) {

            return false; // If any required field is empty, return false
        }

        return true;
    }
    const allNationalFilled = () => {
        if (!summaryDetail.national) {
            return false; // If any required field is empty, return false
        }

        return true;
    }

    const allErrorsEmpty = () => {
        let requiredFields = [];
        if (summaryDetail.Subscription === Types.free) {
            requiredFields = ['fName', 'lName', 'email', 'registerphone', 'NewPassword', 'ConfirmPassword'];
        }else if (summaryDetail.Subscription === Types.reports){
            requiredFields = ['fName', 'lName', 'email', 'registerphone'];
        }
         else {
            requiredFields = ['fName', 'lName', 'email', 'registerphone', 'NewPassword', 'ConfirmPassword', 'payment', 'national'];
        }

        for (const field of requiredFields) {
            if (errors[field]) {
                return false; // If any required field is empty, return false
            }
        }
        return true; // All required fields are filled   
    };

    useEffect(() => {
        let isFieldsFilled = false;
        let areErrorsEmpty = false;
        const { Subscription } = summaryDetail;


        if (Subscription) {
            isFieldsFilled = allFieldsFilled();
            areErrorsEmpty = allErrorsEmpty();
        }

        if (isAuthenticated) {
            if (((Subscription === Types.basic) || (Subscription === Types.reports)) && summaryDetail.payment && errors) {
                // check basic && reports
                isFieldsFilled = allPaymentFilled()
                areErrorsEmpty = allErrorsEmpty();
            } else if (Subscription === Types.state && summaryDetail.state && errors) {
                // check state  button diabled or enable
                isFieldsFilled = allPaymentFilled() && allStateFilled()
                areErrorsEmpty = allErrorsEmpty();

            } else if (Subscription === Types.national && errors) {
                // check national
                isFieldsFilled = allPaymentFilled()
                areErrorsEmpty = allErrorsEmpty();
            }
        } else {

            if (((Subscription === Types.basic) || (Subscription === Types.reports)) && errors) {
                // check basic && reports
                isFieldsFilled =
                    allFieldsFilled() && allPaymentFilled();
                areErrorsEmpty = allErrorsEmpty();
            } else if (Subscription === Types.state && errors) {
                // check state  button diabled or enable
                isFieldsFilled =
                    allFieldsFilled() && allPaymentFilled() && allStateFilled();
                areErrorsEmpty = allErrorsEmpty();

            } else if (Subscription === Types.national && errors) {
                // check national
                isFieldsFilled = allPaymentFilled() && allFieldsFilled() && allNationalFilled();
                areErrorsEmpty = allErrorsEmpty();
            }

        }
        setIsButtonDisabled(!(isFieldsFilled && areErrorsEmpty));
    }, [accountDatas, summaryDetail, errors, isChecked, isAuthenticated]);



    const handleChkBkChange = (e, val, isCheckOrAgrree) => {
        let isCheckedOrNot = e?.target?.checked;
        handleAgree(e, val, isCheckOrAgrree);
        if (isCheckedOrNot) handleClickOpen(val);
        else setOpen(false);
    }
    const handleClickOpen = (id) => {
        setOpen({ ...open, [id]: !open })
        setId(id)
    }


    const userDetails = {};

    if (accountDatas?.fName) userDetails["First Name"] = accountDatas.fName;
    if (accountDatas?.lName) userDetails["Last Name"] = accountDatas.lName;
    if (accountDatas?.email) userDetails.Email = accountDatas.email;
    if (accountDatas?.company) userDetails.Company = accountDatas.company;
    if (accountDatas?.registerphone) userDetails.Phone = accountDatas.registerphone;

    const getPackageName = subDetail?.subscriptions?.filter(name => name.subscriptionType === summaryDetail.Subscription)
        .map((val) => {
            return val.packagename
        })

    // const filteredTerms = isAuthenticated ? allTerms.filter(item => item.id !== "1") : allTerms;

    const filteredTerms = allTerms;



    return (
        <>
            {props.loading && <div className="loader-wrap"><div className="loading"></div></div>}

            <Box class="summary-box">
                <Card>
                    <Timeline
                        sx={{
                            "& .MuiTimelineItem-root:before": {
                                display: "none",
                            },
                        }}
                        className="summary-list"
                    >
                        {[
                            {
                                label: summaryDetail.Subscription === Types.reports ? "Purchase Options" : "Subscription",
                                show: true,
                                content: summaryDetail.Subscription ? getPackageName[0] : "No data Available"

                            },


                            //show only for state user
                            {
                                label: "Selected States",
                                show: isAuthenticated && summaryDetail.Subscription === Types.state,
                                content: (
                                    <Typography variant="caption" display="block" gutterBottom className="main-market-text">
                                       {isUnlimitedUS ? "Subscribed All states" : stateCount === 0  && isPreSunbsCribedStateList.length === 0 ? <span>No data Available</span> :
                                            isPreSunbsCribedStateList.length > 0 && !stateCount && !summaryDetail.payment ?
                                                <span>No data Available</span> :
                                                isPreSunbsCribedStateList.length > 0 && !stateCount && summaryDetail.payment ?
                                                    usersSearchedData?.filter(isSub => isSub.isSubscribed).map((list, index) => (
                                                        <div key={index} row className="market-text">
                                                            <div style={{ paddingRight: "3px" }}>{list.name}
                                                                <span className="commann">,</span></div>
                                                        </div>
                                                    ))


                                                    : ""}
                                        {checked?.map((item, index) => (
                                            <div key={index} row className="market-text">
                                                {!isPreSunbsCribedStateList.includes(item.id) ? 
                                                <div style={{ paddingRight: "3px" }}>{item.name}
                                                <span className="commann">,</span></div> : null}
                                            </div>
                                        ))}
                                        



                                    </Typography>
                                ),
                            },
                            //show only for national user
                            {
                                label: "Subscription Types",
                                show: isAuthenticated && summaryDetail.Subscription === Types.national,
                                content: (
                                    <Typography variant="caption" display="block" gutterBottom>
                                        {summaryDetail?.national  ?
                                            <div className="pay-info">{summaryDetail?.national} </div>
                                            : isNational?.length>0 && 
                                            !summaryDetail?.national && 
                                            summaryDetail.payment ? 
                                            <div className="pay-info">{isNational[0]?.subscriptionPriceName}</div>
                                            : <span>No data Available</span>
                                        }
                                    </Typography>
                                ),
                            },

                            // not show for free user
                            {
                                label: "Payment Information",
                                show: summaryDetail.Subscription !== Types.free && summaryDetail.Subscription !== "",
                                content: (
                                    <Typography variant="caption" display="block" gutterBottom>
                                        {summaryDetail.payment ?
                                            <div className="pay-info">{summaryDetail.payment}</div>
                                            : <span>No data Available</span>
                                        }
                                    </Typography>
                                ),
                            },
                            {
                                label: "Account Information",
                                show: !isAuthenticated,
                                content: (
                                    <Grid container >
                                        {Object.values(userDetails).length > 0 ?
                                            Object.keys(userDetails).map((key) => (
                                                <Grid item xs={12} key={key}>
                                                    <Typography variant="caption" display="block" gutterBottom>
                                                        <span className="summary-decs-account">{key}:</span>
                                                        <span className="summary-namedisplay">{userDetails[key]}</span>
                                                    </Typography>
                                                </Grid>
                                            ))
                                            : <span>No data Available</span>}
                                    </Grid>
                                ),
                            },
                        ].map((item, index) => {
                            if (item.show === true) {
                                return (
                                    <TimelineItem key={index}>
                                        <TimelineSeparator>
                                            <TimelineDot className="summary-circle" />
                                            <TimelineConnector className="summary-line" />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Typography variant="h6">{item.label}</Typography>
                                            <div className="summary-decs">{item.content}</div>
                                        </TimelineContent>
                                    </TimelineItem>
                                );
                            }
                            return null; // or any other fallback if show is false
                        })}
                    </Timeline>

                    <Grid container spacing={2} className="agree-text box-padding">
                        {filteredTerms.map((item) => {
                            const isDisabled = ((summaryDetail.Subscription === Types.free)
                                || ((summaryDetail.Subscription === "") && (!isAuthenticated)))
                                ? false
                                : summaryDetail.payment !== "" || paymentLength.length > 0 ? false : true

                            return(

                            <FormGroup key={item.id} row >
                                <Checkbox name={item.name}
                                    checked={isChecked[item.name]}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    onClick={(e) => !isDisabled && handleChkBkChange(e, item.name, "notAgree")}
                                    className={isDisabled ? 'disbled-check' : ''}
                                />
                                <MUILink 
                                 className={isDisabled ? 'disbled-check' : ''}
                                 onClick={() => !isDisabled && handleClickOpen(item.name)}>{item.title}</MUILink>
                                {!isAuthenticated && summaryDetail.payment !== "" &&
                                    item.id === "1" ? priceDetail?.filter(val => details.LookupKey ? (val.lookupKey === details.LookupKey) : val.selected).map((val, index) => {
                                        if (val.accountAvailability === null || val.accountAvailability === 1) {
                                            return <span key={index} className="personone">Account Type : Single use / One person (no account sharing)</span>;
                                        } else {
                                            return <div key={index} >
                                                <div className="personthree">Account Type: Multiple Account Use / Multiple People </div>
                                                <div className="personthree">1 primary account plus {val.accountAvailability} additional accounts </div>
                                                <div className="personthree">Contact sales to set up additional account credentials if needed </div>
                                            </div>;
                                        }
                                    }) : ""}

                            </FormGroup>
                        )}
                        )}

                        {Object.entries(errors).map(([key, value], index) => {
                            if (key === 'first') {
                                return (
                                    <div key={index} className="agree-errormsg">
                                        {`${value}`}
                                    </div>
                                );
                            }
                            return null; // If you want to skip other errors
                        })}

                    </Grid>
                    {/* )} */}
                    <div className="summary-btn-box">
                        <Button variant="contained"
                            onClick={handleCheckout}
                            disabled={isButtonDisabled}
                            className="summary-btn"
                        >
                            {((summaryDetail.Subscription === Types.free)
                                || ((summaryDetail.Subscription === "") && (!isAuthenticated)))
                                ? "Create Account"
                                : "Proceed to Checkout"}

                        </Button>
                    </div>
                </Card>
            </Box>
            {open && <TermsofUse
                open={open}
                handleChkBkChange={handleChkBkChange}
                handleCheckCancel={handleCheckCancel}
                handleClickOpen={handleClickOpen}
                id={id}
                productName = {subDetail.productName}
            />}
        </>
    );
};

export default OrderSummary;

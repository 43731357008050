import React, { Fragment } from 'react';
import { Button, Modal, ModalClose, Sheet, Typography } from '@mui/joy';
import { CONFIG } from '../../../utils/config';

export default function CommonForAll(props) {

    const { open, close, showText, showComponent,btnText,className ,heading} = props;
    return (
        <Fragment>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={close}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: "scroll", top: "0" }}

            >
                <Sheet
                    variant="outlined"
                    className={className}
                   
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.body',
                        }}
                    />
                    {heading &&
                        <Typography variant="h2" component="h2" className='terms-heading stata-popup'>
                            Explorer Unlimited - Individual State(s)
                        </Typography>
                    }
                    {showComponent ? showComponent :
                        showText === "yes" ?
                            <Typography>
                                You have selected more than 7 states.
                                To optimize your savings, we suggest upgrading to our Unlimited National subscription.
                                Not sure if you are ready to upgrade? Contact our sales team to learn more at <a href={`mailto:${CONFIG.SupportEmail}`}>{CONFIG.SupportEmail}</a> or {CONFIG.ContactNo}

                            </Typography>
                            :
                            showText === "convert" ?
                                <>
                                    <Typography>
                                        Please note that we are in the process of migrating our payment processing system to a new provider. During this transition, your subscription and payment information may be transferred to our new payment processor, Stripe. Rest assured, your subscription details and billing will remain secure and uninterrupted throughout this process.
                                    </Typography>
                                    <Typography>
                                        By continuing with your subscription, you consent to the transfer of your payment information to Stripe for billing purposes. Your subscription terms and pricing will remain unchanged.
                                    </Typography>
                                    <Typography>If you have any questions or concerns regarding this migration, please don't hesitate to contact our customer support team at <a href={`mailto:${CONFIG.SupportEmail}`}>{CONFIG.SupportEmail}</a> or {CONFIG.ContactNo}</Typography>
                                    <Typography>Thank you for your understanding and continued support.</Typography>
                                </>
                                :
                                <Typography> {showText}</Typography>
                    }
                    {btnText && 
                    <div className='single-common-box'> <Button className='success-btn' onClick={close}>{btnText}</Button> </div>
                    }
                </Sheet>
            </Modal>
        </Fragment>
    );
}

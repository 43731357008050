import { Card, Grid, Typography } from "@mui/material";
import React from "react";
import { Types, frequency, mrkPackages } from "../utils/enums";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { formattedAmount } from "../Home/commonFunctionalities/commonFunction";

const Payments = ({ handleSelectPayment, activeIndex, priceDetail, subscriptionTypes, loading }) => {
    const percentageValue = (val) => {
        return val.toFixed();
    }


    return (
        <>
            {loading && <div className="loader-wrap"><div className="loading"></div> : </div>}
            <Grid container spacing={2} className="box-padding" >

                {priceDetail && priceDetail?.length > 0 &&
                    priceDetail.map((val, key) => {
                        debugger;
                        const monthlyPrices = priceDetail.filter(item => item.frequency === frequency.monthly);
                        const offerPercentage = monthlyPrices.length>0? ((1 - (val?.price / (monthlyPrices[0]?.price * val.duration))) * 100):0;
                        const isMarketPrice = priceDetail.filter(item => item.minCount === frequency.minCount);
                        const isMarketoffer = isMarketPrice.length > 0 ?((1 - (val?.price / (isMarketPrice[0]?.price * val.minCount))) * 100):0;

                        let boxColor = (!val.showUpgrade && !val.selected && subscriptionTypes !== Types.reports ?
                            "showor-hide-box" : (val.selected && subscriptionTypes !== Types.reports && !activeIndex) ?
                                'active payment-box select-outline-box' : val.selected && subscriptionTypes !== Types.reports ? 'select-outline-box' : "")
                        let boxSecondColor = activeIndex === key ? "active payment-box" : "payment-box "
                        return (
                            <Grid item xs={12} md={4} xl={3} lg={4}>
                                <Card
                                    disabled={!val.showUpgrade}
                                    onClick={() => {
                                        if (subscriptionTypes !== Types.reports) {
                                            if (!val.selected && val.showUpgrade) {
                                                handleSelectPayment(
                                                    key,
                                                    `Subscription for ${formattedAmount(val.price, val.currency, val.currencyCode)}/${val.frequency}`,
                                                    val
                                                );
                                            }
                                        } else {
                                            handleSelectPayment(
                                                key,
                                                `${mrkPackages.mrkExp} ${formattedAmount(val.price, val.currency, val.currencyCode)} `,
                                                val
                                            );
                                        }

                                    }}
                                    className={boxColor + " " + boxSecondColor}


                                >
                                    {subscriptionTypes === Types.reports ?
                                        isMarketoffer !== 0 &&
                                        <div className="label-img">
                                            <div className="save-txt">Save {percentageValue(isMarketoffer) + "%"}</div>
                                        </div>



                                        : offerPercentage !== 0 &&
                                        <div className="label-img">
                                            <div className="save-txt">Save {percentageValue(offerPercentage) + "%"}</div>
                                        </div>
                                    }


                                    {(val.selected && subscriptionTypes !== Types.reports) || activeIndex === key &&
                                        <div className="box-tick"><CheckCircleOutlineIcon /> </div>
                                    }
                                    {val.selected === true && subscriptionTypes !== Types.reports && (
                                        <div className="selected-tick">
                                            <CheckCircleOutlineIcon />{' '}
                                            <span className="tick-text"> Already Subscribed</span>
                                        </div>
                                    )}

                                    <Typography variant="subtitle1" gutterBottom>
                                        <div className="payment-text">
                                            <span className="text-subscription">
                                                {subscriptionTypes === Types.reports ? (
                                                   
                                                    <>
                                                        <span className="text-yearly-num">   {val.minCount > 1 ? val.minCount : ""}</span>    Market{val.minCount > 1 ? "s" : ""} at
                                                        <br />  <b> {formattedAmount(val.price, val.currency, val.currencyCode)}</b>
                                                    </>
                                                ) : (
                                                    
                                                    <>
                                                        Subscription for <br />
                                                        <b>{formattedAmount(val.price, val.currency, val.currencyCode)}</b>/
                                                        <span className="text-yearly">{val.frequency}</span>
                                                    </>
                                                )}
                                            </span>

                                        </div>
                                    </Typography>
                                </Card>
                            </Grid>

                        )
                    })}
            </Grid>


        </>

    )
}

export default Payments;
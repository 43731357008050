import React, { Fragment } from 'react';
import { Button, Modal, Sheet } from '@mui/joy';
import { Box, Grid } from '@mui/material';
import { CONFIG } from '../../utils/config';

export default function PasswordConfirmation (props){
    const handleLogout = () =>{
        localStorage.clear();
        const url = CONFIG.Explorer_url
        window.location.replace(url);
    } 
    
    return (
        <Fragment>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={props.open}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                className="custom-model-popup change-pwd-popup"
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                   <p className='mt-0 text-center'>{props.text ? props.text : "Your password has been updated successfully!. Now you can login with your new password."}</p>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': '',
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12} className='button-alignment text-center'>
                                <Button  className='success-btn' onClick={handleLogout}>OK</Button>                               
                            </Grid>
                        </Grid>
                    </Box>
                   
                </Sheet>
              
            </Modal>
        </Fragment>
    );
}

export const length = {
    name: 30,
    email: 50,
    password : 20,
    zipCode : 10,
    creditCount :3,
    subsLength: 50
}
export const minimumLength = {
    minLength: 3,
}

export const marketReport = {
    onePrepaid: 1,
}

export const usersType = {
    user: "Enterprise",
}

export const productNames = {
    USProductName: "Self-Storage Explorer - US & Canada",
    USParkingProductName: "Vehicle, RV and Boat Storage Explorer",
    EUProductName: "Self-Storage Explorer - UK & Europe"
}

export const usersTypeId = {
    Admin: 1,
    Users: 2,
}

export const ultStaOrCou = {
    ultState: "Unlimited State",
    ultCountry: "Unlimited National",
}

export const mrkPackages = {
    mrkExp: "Market Report",
}

export const paymentTerm = {
    recurring: "Recurring",
    free: "Free"
}

export const paymentMode = {
    online: "Online",
    offline : "Offline",
    custom : "Custom"
}

export const paymentStatus = {
    pending: "Pending",
    inactive: "InActive"
}

export const packageName = {
    free: "Free",
    inactive: "InActive"
}

export const Types = {
    free: "Free",
    basic: "Basic",
    state: "Unlimited State",
    national: "Unlimited National",
    reports: "Limited",
}

export const frequency = {
    monthly: "Monthly",
    minCount:1
   
}

export const searchTypeId = {
    city : 3
   
}

export const countryId = {
   unlimitedUs : "2"
   
}

export const getCurrencyDetail = {
    currencyCode: "USD",
    currency: "$"
}

export const getCurrencyName = {
    Europe: "Euro",
    UK: "GBP",
    
}

export const regionCode = {
    us: "US",
    ca: "CA"
}

export const currencyCode = {
    Euro: "€"
}




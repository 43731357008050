import React, { Fragment, useEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableRow, TableContainer, Paper } from '@mui/material';
import EnhancedTableHead, { getSorting, handleAlphabets, handleRequestSort, stableSort } from '../../commonFunctionalities/CommonMuiSortingFunc';
import { Link } from 'react-router-dom';
import { productNames } from '../../../utils/enums';

const ProductDetails = (props) => {
    let headRows = [
        { id: 'ProductName', label: 'Product Name', numeric: false },
        { id: 'subsciptionCount', label: 'No.Subscriptions', numeric: false },
        { id: 'view', label: 'View Subscription', numeric: false },
    ];
    let disableTableRowsNames = ['view'];
    let textWithAlphabets = ['ProductName'];
    let { productData, userId } = props;
    const [state, setState] = useState({ order: 'asc', orderBy: "ProductName" });

    useEffect(() => {
        handleAlphabets(textWithAlphabets);
    }, [])

    const handleRequestSortPreCall = (property) => {
        const { order, orderBy } = state;
        let { sort, prop } = handleRequestSort(property, order, orderBy);
        setState({ ...state, order: sort, orderBy: prop });
    }
    return (
        <Fragment>
            <TableContainer component={Paper} className="common-table-design">
                <Table>
                    <EnhancedTableHead
                        order={state.order}
                        orderBy={state.orderBy}
                        onRequestSort={handleRequestSortPreCall}
                        headRows={headRows}
                        disableTableRowsNames={disableTableRowsNames}
                    />
                    <TableBody>
                        {productData?.products && productData?.products.length > 0 ?
                            stableSort(
                                productData.products,
                                getSorting(state.order, state.orderBy)
                            ).map((strdata) =>
                                <TableRow key={strdata.productId}>
                                    <TableCell>{strdata.name ? strdata.name === productNames.USProductName ? "Explorer" : strdata.name : "-"}</TableCell>
                                    <TableCell>{strdata.totalSubscriptions ? strdata.totalSubscriptions : "-"}</TableCell>
                                    <TableCell><Link to={`/viewSubscription/${strdata.permalink}/${strdata.productId}/${userId}`} className='view-update-btn'>View</Link></TableCell>
                                </TableRow>
                            ) :
                            <TableRow>
                                <TableCell colSpan={8} className="no-data">No Data Found</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment >
    )
}

export default ProductDetails;
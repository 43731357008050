import React, { useState } from 'react';
import { Grid, Typography, List, ListItem, ListItemText } from '@mui/material';
import { CONFIG } from '../utils/config';
import DoneIcon from '@mui/icons-material/Done';
import "./pricing.css"
import ContactUs from '../Home/modals/ContactUs';

const UkData = (props) => {
  const { from, currency} = props;
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const isAuthenticated = (userData?.secureId !== null && userData?.secureId !== undefined) && (userData?.token !== null && userData?.token !== undefined) ? true : false;
  const [contactOpen, setContactOpen] = useState(false)
  const listItems = [
    {
      section: "Stores in the Market",
      items: [
        { text: "Number of stores (name and address)", free: true, basic: true, report: false, unlimited: true },
        { text: "Stores for Sale", free: false, basic: true, report: true, unlimited: true },
        { text: "Store Type: REIT,  Mid Operator, Small Operator", free: false, basic: true, report: false, unlimited: true },
        { text: "New Developments", free: false, basic: true, report: false, unlimited: true },
        { text: "Stores opened within the last year", free: false, basic: true, report: true, unlimited: true },
        { text: "Operating Hours", free: false, basic: false, report: true, unlimited: true },
        { text: "Ownership & Management details", free: false, basic: false, report: true, unlimited: true },
        { text: "Store Square Footage (Total & Rentable)", free: false, basic: false, report: true, unlimited: true },
        { text: "Debt, Tax and Transaction history", free: false, basic: false, report: true, unlimited: true },
      ],

    },
    {
      section: "Demographics",
      items: [
        { text: "Population", free: false, basic: true, report: false, unlimited: true },
        { text: "Households", free: false, basic: true, report: false, unlimited: true },
        { text: "Rental Households", free: false, basic: true, report: false, unlimited: true },
        { text: "Rental Households (as a %)", free: false, basic: true, report: false, unlimited: true },
      ],
    },
    {
      section: "Supply",
      items: [
        { text: "Net Rentable Square Footage", free: false, basic: true, report: false, unlimited: true },
        { text: "Square Feet per Capita", free: true, basic: true, report: false, unlimited: true },
        { text: "Square Feet per Household", free: false, basic: true, report: false, unlimited: true },
        { text: "Square Feet per Rental Households", free: false, basic: false, report: false, unlimited: true },
      ],
    },
    {
      section: "Market Spending Power",
      items: [
        { text: "Median Household Income", free: false, basic: true, report: false, unlimited: true },
        { text: "Aggregate Household Income", free: false, basic: false, report: false, unlimited: true },
        { text: "Household Income per Store", free: false, basic: false, report: false, unlimited: true },
        { text: "Average Property Value", free: false, basic: false, report: false, unlimited: true },
        { text: "Average Rental Costs", free: false, basic: false, report: false, unlimited: true },
      ],
    },
    {
      section: "Market Rates",
      items: [
        { text: "Rate Trend (12 months)", free: true, basic: true, report: false, unlimited: true },
        { text: "Average Rate per Square Foot", free: false, basic: true, report: false, unlimited: true },
        { text: "Rate Summary for Standard Units", free: false, basic: true, report: false, unlimited: true },
        { text: "Average Rate per Sq Ft by Store Type", free: false, basic: false, report: false, unlimited: true },
        { text: "Average Rate per Sq Ft by Unit Type", free: false, basic: false, report: false, unlimited: true },
        { text: "Average Rate per Sq Ft Graph (12 months)", free: false, basic: false, report: false, unlimited: true },
        { text: "Average Rate per Sq Ft Graph (up to 3 years)", free: false, basic: false, report: true, unlimited: true },
        { text: "Current Average Rates by Unit Type", free: false, basic: false, report: false, unlimited: true },
        { text: "Average Rates History (12 months)", free: false, basic: false, report: false, unlimited: true },
        { text: "Average Rates History (up to 3 years)", free: false, basic: false, report: true, unlimited: true },
        { text: "Rate Volatility History (12 months)", free: false, basic: false, report: true, unlimited: true },
        { text: "12 Month Trailing Average by Store", free: false, basic: false, report: true, unlimited: true },
        { text: "12 Month Trailing Average by Unit Type", free: false, basic: false, report: true, unlimited: true },
        { text: "Rate History by Store Graph (up to 3 years)", free: false, basic: false, report: true, unlimited: true },
      ],
    },
    {
      section: "Unit Availability",
      items: [
        { text: "Units Not Advertised (%)", free: false, basic: true, report: false, unlimited: true },
        { text: "Unit Types Offered", free: false, basic: false, report: false, unlimited: true },
        { text: "Current Inventory Availability by Unit Type", free: false, basic: false, report: false, unlimited: true },
        { text: "Inventory Availability by Unit Type History (12 months)", free: false, basic: false, report: false, unlimited: true },
      ],
    },
    {
      section: "Marketing",
      items: [
        { text: "Marketing Activity by Store", free: false, basic: false, report: false, unlimited: true },
        { text: "Marketing Activity by Market", free: false, basic: false, report: true, unlimited: true },
      ],
    },
    {
      section: "Nearby Businesses",
      items: [
        { text: "Colleges & Universities", free: true, basic: true, report: true, unlimited: true },
        { text: "Military Bases", free: true, basic: true, report: true, unlimited: true },
        { text: "Senior Care Facilities", free: true, basic: true, report: true, unlimited: true },
        { text: "Retailers", free: true, basic: true, report: true, unlimited: true },
        { text: "Malls", free: true, basic: true, report: true, unlimited: true },
      ],
    },
    {
      section: "Others",
      items: [
        { text: "Access to Interactive Platform", free: false, basic: false, report: true, unlimited: true },
        { text: "Custom Report Builder", free: false, basic: false, report: true, unlimited: true },
        { text: "Downloadable data", free: false, basic: false, report: true, unlimited: true },
      ],
    },
  ];


  return (
    <>
   
    <div className='feature-matrix'> <Grid container spacing={2}>
        <Grid item md={4} sm={4} className='product-details-left' >
          <div className='price-pack'> <Typography variant="h3">Available Data</Typography></div>
          {listItems.map((section, index) => (
            <React.Fragment key={index}>
              <Typography variant="h4">{section.section}</Typography>
              <List>
                {section.items.map((item, i) => (
                  <ListItem key={i} >
                    <ListItemText primary={item.text === "Stores for Sale" || item.text === "Colleges & Universities" || item.text === "Military Bases" || item.text === "Senior Care Facilities" || item.text === "Retailers" || item.text === "Malls" ? (<>{item.text} <span className='plus-symbol'>&#10013;</span></>) : (item.text)} />

                  </ListItem>
                ))}
              </List>
            </React.Fragment>
          ))}

        </Grid>
        <Grid item md={2} sm={2} className='other-columns-right free-col' >
          <div className='price-pack'><Typography variant="h3">Free</Typography></div>
          {listItems.map((section, index) => (
            <React.Fragment key={index}>
              <Typography variant="h4" ></Typography>
              <List>
                {section.items.map((item, i) => (
                  <ListItem key={i}>
                    {/* <ListItemText variant="h4" style={{ visibility: "hidden" }} primary={item.text.split(' ').slice(0, 1).join(' ')} /> */}
                    {item.free && <DoneIcon />}
                  </ListItem>
                ))}
              </List>
            </React.Fragment>
          ))}
        </Grid>
        <Grid item md={2} sm={2} className='other-columns-right highlight' >
          <div className='price-pack'> <Typography variant="h3">Basic</Typography>
            <p>as low as <b><i>{currency}</i>59</b>/month
              <span className='billed-annually-txt'> (billed annually)</span></p>
          </div>
          {listItems.map((section, index) => (
            <React.Fragment key={index}>
              <Typography variant="h4" ></Typography>
              <List>
                {section.items.map((item, i) => (
                  <ListItem key={i}>
                    {item.basic && <DoneIcon />}
                  </ListItem>
                ))}
              </List>
            </React.Fragment>
          ))}
        </Grid>
        <Grid item md={2} sm={2} className='other-columns-right last-two-columns' >
          <div className='price-pack'>  <Typography variant="h3">MARKET REPORT</Typography>
            <p> <b><i>{currency}</i>79</b>/market</p>
          </div>
          {listItems.map((section, index) => (
            <React.Fragment key={index}>
              <Typography variant="h4" ></Typography>
              <List>
                {section.items.map((item, i) => (
                  <ListItem key={i} className='star-tick'>
                    <DoneIcon />{item.report && <span>*</span>}
                  </ListItem>
                ))}
              </List>
            </React.Fragment>
          ))}
        </Grid>
        <Grid item md={2} sm={2} className='other-columns-right last-two-columns' >
          <div className='price-pack'>  <Typography variant="h3">UNLIMITED</Typography>
            {from === "singel-checkout" && isAuthenticated ?
              <p>as low as <b><i>{currency}</i>416</b>/month</p>
              : from === "singel-checkout" && !isAuthenticated  ? <a className='enquiry-contact' onClick={() => setContactOpen(true)}>Contact Us</a> : ""
            }
            {from === "marketing-page" &&
              <a href={CONFIG.Contact_url} target="_top" className='enquiry-contact'>
                Contact Us</a>
            } </div>
          {listItems.map((section, index) => (
            <React.Fragment key={index}>
              <Typography variant="h4" ></Typography>
              <List>
                {section.items.map((item, i) => (
                  <ListItem key={i}>
                    {item.unlimited && <DoneIcon />}
                  </ListItem>
                ))}
              </List>
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
      {contactOpen && 
      <ContactUs title={"Request a quote for an Unlimited Explorer Subscription"} 
      handleCancel={()=>setContactOpen(!contactOpen)}  open={contactOpen} isFrom={"uk"}/>}
    </div>
    </>
  );
};

export default UkData;

import { Fragment, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../../services/api";
import { Typography, Box, Grid, Breadcrumbs } from "@mui/material";
import { handlingCatchErorr } from "../../utils/errorHandling";
import AccountDetailsEditModal from "../modals/commonModals/AccountDetailsEditModal";
import UpdatePassword from "../modals/PasswordUpdate";
import BillingDetailsEditModal from "../modals/commonModals/BillingDetailsEditModal";
import AdminProductsDetails from "../Suitelandingpage/adminTab/viewOrUpdatePage/AdminProductsDetails";
import AdminSubscriptionModal from "../modals/adminModules/AdminSubscriptionModal";
import { usersType, usersTypeId } from "../../utils/enums";
import ProductDetails from "../Suitelandingpage/EnterpriseUser/ProductDetails";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AdminResetPassword from "../modals/adminModules/createUserStepper/AdminResetPassword";
import AdminLoginAsModal from "../modals/adminModules/AdminLoginAsModal";

const accDetails = { "fullname": "Full Name", "company": "Company", "phone": "Phone Number", "email": "Email Address" };
const bilDetails = { "billingAddress": "Address Line 1", "billingAddress2": "Address Line 2", "billingCity": "City", "billingState": "State/Province", "billingZipCode": "Zip/Postal Code" };

const CommonAccountDetails = (props) => {
    let { isFrom, userId } = props;
    const navigate = useNavigate();
    const userData = JSON.parse(localStorage.getItem("userInfo"));

    const [loader, setLoader] = useState(true);
    const [loaderTwo, setLoaderTwo] = useState(true);
    const [productData, setProductData] = useState([]);
    const [initialData, setInitialData] = useState({});
    const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [disable, setDisable] = useState(false);

    let loadAccountDetails = (isFromEdit) => {
        if (!initialData?.length || isFromEdit) {
            const queryParams = new URLSearchParams({
                userId: userId,
            });
            api.get(isFrom === "viewOrUpdate" || isFrom === usersType.user ? (`account?${queryParams}`) : ("account"))
                .then((res) => {
                    setInitialData(res.data.info);
                    stateLookUpApi(res.data.info?.billingCountry);
                    setLoader(false);
                })
                .catch((err) => {
                    handlingCatchErorr(err, navigate);
                    setLoader(false);
                });
        }
    };
    let countryLookUpApi = () => {
        api.get("lookup/countries")
            .then((res) => {
                setCountryData(res.data);
                setLoaderTwo(false);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
                setLoaderTwo(false);
            });
    };

    let stateLookUpApi = (value) => {

        if (value) {
            setDisable(true);
            api.get(`lookup/states/${value}`)
                .then((res) => {
                    setStateData(res.data);
                    setDisable(false);
                })
                .catch((err) => {
                    setDisable(true);
                    handlingCatchErorr(err, navigate);
                });
        } else {
            setDisable(true);
            setStateData([])
        };
    };

    let productDetailsApi = () => {
        api.get(`Subscription/userproduct/?userId=${userId}`)
            .then((res) => {
                setProductData(res.data);
                setLoader(false);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
                setLoader(false);
            });
    };

    let handleLoader = (isLoaderItTrueOrFalse, makeApiCallAgain) => {
        setLoader(isLoaderItTrueOrFalse);
        if (makeApiCallAgain) setTimeout(() => loadAccountDetails(true), 200);
    }

    let handleBillingData = (bilKey) => {
        if (bilKey === "billingStateId") {
            let stateName = stateData.filter(ft => ft.id === initialData[bilKey]).length > 0 ? stateData.filter(ft => ft.id === initialData[bilKey])[0]?.name : null;
            return <p className="result-data">{initialData[bilKey] ? stateName : '-'}</p>
        } else return <p className="result-data">{initialData[bilKey] ? initialData[bilKey] : '-'}</p>
    }


    let navBackButton = userData?.usertypeId === usersTypeId.Admin ? "/admin" : "/user";
    useEffect(() => {
        loadAccountDetails(false);
        if (!countryData?.length) countryLookUpApi();
        if (isFrom === "viewOrUpdate" || isFrom === usersType.user) productDetailsApi();
    }, []);
    useEffect(() => {
        if (initialData && initialData.email) {
          localStorage.setItem('userEmail', initialData.email);
        }
      }, [initialData]);
    return (
        <Fragment>
            {loader && <div className="loader-wrap"><div className="loading"></div></div>}
            {loaderTwo && <div className="loader-wrap"><div className="loading"></div></div>}
            {(initialData && Object.entries(initialData).length !== 0) ?
                <>
                    {(isFrom === "viewOrUpdate" || isFrom === usersType.user) ?
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="breadcrumb-list">
                            <Link color="inherit" to={navBackButton}>Users</Link>
                            <Typography color="text.primary">Details</Typography>
                        </Breadcrumbs>
                        :
                        <h2 className="page-heading" style={{ display: 'inline-block' }}>{isFrom === "viewOrUpdate" ? "View/Update" : "My Account"}</h2>
                    }

                    <Box sx={{ flexGrow: 1 }} className="myaccount-columns">
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <div className="two-columns-account">
                                    <Typography
                                        component="h3"
                                        variant="headline"
                                        style={{ marginBottom: '10px' }}
                                    >
                                        Account Details
                                    </Typography>
                                    {isFrom === usersType.user || userData?.impersonated ? " " :
                                        <div className="edit-section">
                                            <AccountDetailsEditModal editData={initialData} handleLoader={handleLoader} userId={userId} />
                                        </div>
                                    }
                                    {(isFrom === "viewOrUpdate" || isFrom === usersTypeId.Admin) &&
                                        <>

                                            <AdminLoginAsModal userId={userId} />
                                        </>
                                    }
                                    {/* returns an array of arrays, where each inner array contains a key-value pair from the bilDetails object. */}
                                    {Object.entries(accDetails).map(([key, values]) =>
                                        <div key={key}>
                                            <p className="my-account-label">{values}</p>
                                            <p className="result-data">{initialData[key] ? initialData[key] : '-'}</p>
                                        </div>
                                    )}
                                    <p className="my-account-label">Password</p>
                                    <input
                                        type="password"
                                        disabled
                                        value={123456}
                                        className="password-value"
                                    />
                                    {isFrom === usersType.user || userData?.impersonated ? " " :
                                        <div>{(isFrom !== "viewOrUpdate" && isFrom !== usersType.user) ? <UpdatePassword handleLoader={handleLoader} /> :
                                            <AdminResetPassword userId={userId} handleLoader={handleLoader} />
                                        }
                                        </div>
                                    }
                                </div>
                            </Grid>
                            {(userData?.usertypeId !== usersTypeId.Admin || isFrom === "viewOrUpdate" || isFrom === usersType.user) &&
                                <Grid item md={6} xs={12}>
                                    <div className="two-columns-account">
                                        <Typography
                                            component="h3"
                                            variant="headline"
                                            style={{ marginBottom: '10px' }}
                                        >
                                            Billing Details
                                        </Typography>
                                        {isFrom === usersType.user || userData?.impersonated ? "" :
                                            <div className="edit-section"><BillingDetailsEditModal editData={initialData} handleLoader={handleLoader} countryData={countryData} stateLookUpApi={stateLookUpApi} stateData={stateData} disable={disable} userId={userId} /></div>
                                        }
                                        {/* returns an array of arrays, where each inner array contains a key-value pair from the bilDetails object. */}
                                        {Object.entries(bilDetails).map(([key, values]) =>
                                            <div key={key}>
                                                <p className="my-account-label">{values}</p>
                                                {handleBillingData(key)}
                                            </div>
                                        )}
                                    </div>
                                </Grid>}
                            {(userData?.usertypeId === usersTypeId.Admin && isFrom === "viewOrUpdate") || (isFrom === usersType.user) ?
                                <>
                                    <Grid item md={12} xs={12} mt={3}>
                                        <div className="two-columns-account">
                                            <Typography
                                                component="h3"
                                                variant="headline"
                                                style={{ marginBottom: '10px' }}
                                            >
                                                Product Details
                                            </Typography>
                                            {isFrom === usersType.user ? <ProductDetails productData={productData} userId={userId} /> :
                                                <>
                                                    <div className="edit-section"><AdminSubscriptionModal userId={userId} handleLoader={handleLoader} productDetailsApi={productDetailsApi} /></div>
                                                    <AdminProductsDetails initialData={initialData} productData={productData} userId={userId} />
                                                </>}
                                        </div>
                                    </Grid>
                                </> : ""
                            }
                        </Grid>
                    </Box>
                </> :

                ""
            }
        </Fragment>
    )
}

export default CommonAccountDetails;
import React, { useEffect, useState } from 'react'
import { api } from '../../services/api';
import { useNavigate, useParams } from 'react-router-dom';
import CommonMessages from '../Home/commonFunctionalities/CommonMessages';

const Authorize = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { secureId } = useParams();
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    useEffect(() => {
        const fetchData = () => {
          setLoading(true);
          const payload = {
            secureid: secureId,
          };
          const userInfo = JSON.parse(localStorage.getItem('userInfo'));
          const storedSecureId = userInfo?.secureId;
          if (storedSecureId === secureId) {
            setLoading(false);
            setMessage('Please wait while we process your request');
            setMessageType('success');
            window.location.href = '/products';
          } else {
            api.post(`/Account/secretlogin`, payload)
            .then((res) => {
              if (res.status === 200) {
                setLoading(false);
                localStorage.setItem("userInfo", JSON.stringify(res.data));  
                setMessage('Please wait while we processing your request');
                setMessageType('success');
                window.location.reload();
                window.location.href = '/products';
              }
            })
            .catch((err) => {
              setMessage(err);
              setMessageType('error');
              window.globalThis.REACT_SNACK_OPEN = true;
              window.globalThis.REACT_SNACK_MESSAGE = err
              document.getElementById('enable-error-snack').click();
              setLoading(false);
              navigate('/page-expired');
            });
          }
        };    
        fetchData();
    }, [navigate, secureId]); 
    return (
        <div>
          <CommonMessages loading={loading} message={message} type={loading ? 'success' : 'error'} />
      </div>
    );
  };
export default Authorize
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import { AppBar, Box, Toolbar, Typography, Container } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import LogoImg from "../../../src/assets/images/suite_logo.png";
import { CONFIG } from '../utils/config';

const drawerWidth = 240;

export default function TopBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const { pathname } = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };


  const container = window !== undefined ? () => window().document.body : undefined;





  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} className="navigation-menu">
      <Divider />

    </Box>
  );


  return (
    <Box sx={{ display: 'flex' }} className="sticky-header">
      <AppBar component="nav" className="header-section">
        <Container maxWidth="xl" className='header-container'>
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { lg: 'flex' } }}
              className="suite-logo"
            >
              <img src={LogoImg} alt='MIS' />
            </Typography>
            <Box sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }} className="navigation-menu">
            </Box>
            <div className='welcome-info'>
              {props?.islogin === "notfound" &&
                <Box sx={{ flexGrow: 0, display: { md: 'flex', flexDirection: "column", justifyContent: 'flex-end' } }} className="user-text">
                  <Link to={CONFIG.Explorer_url} className='back-btn-home' style={{padding: '7px 25px', fontSize: '13px'}}>Login</Link>
                </Box>}
              {pathname.startsWith('/pricing/') || pathname.startsWith('/purchase/')  ? <>
                <Box sx={{ flexGrow: 0, display: { md: 'flex', flexDirection: "column", justifyContent: 'flex-end' } }} className="user-text">
                  {props?.islogin?.token !== null && props?.islogin?.token !== undefined ? <Link to="/products" 
                  className='back-btn-home'> Back to Dashboard </Link> : 
                  <Link to={pathname.startsWith('/pricing/eu-') || pathname.startsWith('/purchase/eu-') ? CONFIG.Europe_Marketing_url : CONFIG.Explorer_Marketing_url  } className='back-btn-home'>Back to Home</Link>}

                </Box>
              </> : (pathname.startsWith('/payment-')) || (pathname.startsWith('/account-confirmation')) ? <></> :
                <></>}
            </div>
          </Toolbar>
        </Container>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'block', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

    </Box>
  );
}


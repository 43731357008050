import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { validationFunction } from "../Home/commonFunctionalities/AllValidation";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {api} from "../../services/api";
import { handlingCatchErorr } from "../utils/errorHandling";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CommonPasswordStrengthMeter from "../Home/commonFunctionalities/CommonPasswordStrengthMeter";
import ContactUs from "../Home/modals/ContactUs";
import { CONFIG } from "../utils/config";

let textFieldVariables = { "NewPassword": "NewPassword", "ConfirmPassword": "ConfirmPassword" };
let initialData = { NewPassword: "", ConfirmPassword: "" };

const UserActivation = (props) => {
    const isFirstRender = useRef(true);
    const [passworderror, setPassworderror] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [passwordData, setPaswordData] = useState(initialData);
    const [isSubmitOrNot, setIsSubmitOrNot] = useState(false);
    const [isAccountActivated, setIsAccountActivated] = React.useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const secureid = queryParams.get('secureid');
    const token = queryParams.get('token');
    const [isStrength, setStrength] = useState(null);
    const [open, setOpen] = useState(false);

    const handleCancel = () => {
        setOpen(false);
    };

    const clickHandler = (event) => {
        event.preventDefault();
        if (!open) setOpen(true);
    };

    const dataHandler = async (childData) => {
        setStrength(childData);
    }
    const handleChange = (e) => {
        if (isSubmitOrNot) setIsSubmitOrNot(!isSubmitOrNot);
        const { name, value } = e.target;
        setPaswordData((prevData) => {
            const updatedData = { ...prevData, [name]: value };
            const returnedErrors = validationFunction(name, value, updatedData);
            errorMessageFunc(name, returnedErrors);
            handlePasswordValidation(name, value, updatedData);

            return updatedData;
        });
    };
    const handlePasswordValidation = (name, value, updatedData) =>{
        
        if (name === "NewPassword" && updatedData.ConfirmPassword.length>0) {
            if (value !== updatedData.ConfirmPassword  ) {
                errorMessageFunc("ConfirmPassword", "Passwords does not match");
            } else{
                errorMessageFunc("ConfirmPassword", "");
  
            } 
            
            
        }  
           
    }

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        if (isSubmitOrNot) handleUpdatePostSubmit();
    }, [passworderror]);

    const errorMessageFunc = (errorName, errorMessage) => {
        setPassworderror((prevData) => ({ ...prevData, [errorName]: errorMessage }));
    }

    const handleUpdatePreSubmit = (e) => {
        setIsSubmitOrNot(true);
        e.preventDefault();
        let errors = {};
        Object.entries(passwordData).forEach(([key, val]) => {
            const returnedErrors = validationFunction(key, val, passwordData);
            errorMessageFunc(key, returnedErrors);
            handlePasswordValidation(key, val, passwordData)
           
        });
    }

    const handleUpdatePostSubmit = () => {
        localStorage.clear();
        let areThereErrorMsgOrNot = Object.entries(passworderror).length !== 0 ? Object.entries(passworderror).every(([key, val]) => val === "") : true;
        if (areThereErrorMsgOrNot) {
            setIsLoading(true);
            const activationData = {
                "secureToken": token.replace(/ /g, '+'),
                "secureId": secureid.replace(/ /g, '+'),
                "password": passwordData.NewPassword,
                "confirmPassword": passwordData.ConfirmPassword
            };
            api.post('/account/activateuser', activationData)
                .then((response) => {
                    if (response.status === 200) {
                        setIsLoading(false);
                        navigate("/account-confirmation", {
                            
                        })
                    }

                    if (response.status === 400) {
                        setIsLoading(false);
                        setIsAccountActivated(2);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    handlingCatchErorr(error, navigate);
                });
        }
    }

    useEffect(()=>{
        if(props.from === "free-user"){
            handleOk()   
        }
    },[])

    const handleOk = () => {
        setIsLoading(true);
        const activationDatas = {
            "secureToken": token.replace(/ /g, '+'),
            "secureId": secureid.replace(/ /g, '+'),
        };
        api.post('/account/activate/user', activationDatas)
            .then((res) => {
                if (res.status === 200) {
                setIsLoading(false);
                navigate("/account-confirmation", {
                    
                })

            }

            })
            .catch((error) => {
                setIsLoading(false);
                handlingCatchErorr(error, navigate);
            });

    }

    return (
        <>


            {props.from === "free-user" ?
                <div className="user-activation account-acivate" >
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        className='custom-form'
                    >
                       {isLoading &&
                       <p className='text-center'>Please wait while we processing your request... </p>
            }
                        
                    </Box>
                </div>
                : <div className={isAccountActivated !== 2 ? "user-activation account-acivate" : " account-acivate"}>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        className='custom-form'
                    >
                        {isAccountActivated === 2 ?
                            <>
                                <h2 className="text-center mt-0">Token Expired!</h2>
                                <p className="mt-0 create-desc text-center">Your activation key has may expired or already been activated. Please contact support for further assistance.</p>
                                <Typography className="have-trouble">Having trouble? <a onClick={clickHandler}>Contact Us</a></Typography>
                                <Link to={CONFIG.MIG_login_url} className="go-home"><HomeOutlinedIcon />Go to Home</Link>
                            </> :
                            <>
                                <h2 className="text-center mt-0">Thank You!</h2>
                                <p className="mt-0 create-desc">Your account was activated successfully! Please set up your password.</p>
                                {Object.entries(textFieldVariables).map(([key, val], index) => (
                                    <div key={index}>
                                        <TextField
                                            name={key}
                                            error={!!passworderror[key]}  // Set error state based on the presence of an error message
                                            helperText={passworderror[key]}  // Display the error message
                                            onChange={handleChange}
                                            type="password"
                                            onInput={handleChange}
                                            label={key}
                                            className={`custom-fields ${key === "NewPassword" ? "new-password-field" : ""} ${passwordData.NewPassword.length !== 0 && passwordData.NewPassword.length < 6 ? "new-password-error" : ""}`}
                                        />
                                        {key === "NewPassword" && (
                                            <div className="pwd-strength-meter">
                                                <CommonPasswordStrengthMeter password={passwordData.NewPassword} actions={dataHandler} />
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <div className="password-update-action">
                                    <Button type="submit" className="success-btn" onClick={handleUpdatePreSubmit}>Submit</Button>
                                    <Typography className="have-trouble">Having trouble? <a onClick={clickHandler}>Contact Us</a></Typography>
                                </div>
                            </>
                        }
                    </Box>
                    {isLoading ? <div className="loader-wrap"><div className="loading"></div></div> : ""}
                    <ContactUs title={"Contact"} open={open} handleCancel={handleCancel} />
                </div>
            }
        </>
    )
}

export default UserActivation;

import React from "react";
import { Typography } from '@mui/material';
import "./pricing.css"

const ExpSubscriptionText = () => {
    return (
        <div className="right-sidetxt">
            <div  className="access-info"> <Typography variant="caption"> <span className="right-sidetxt-star">* </span>Accessible through Explorer platform </Typography></div>
            <div  className="access-info"> <Typography variant="caption" > <span className="right-sidetxt-star">**</span>Available only for US stores </Typography> </div>
        </div>
    )
}

export default ExpSubscriptionText;
import React from "react";
import { Typography } from '@mui/material';
import "./pricing.css"

const AvailableTitleEurope = () => {
    return (
        <div className="right-sidetxt">
            <div  className="access-info"> <Typography variant="caption"> <span className="right-sidetxt-star"> <strong>&#10013;</strong> </span>Available only for the UK</Typography></div>
            <div  className="access-info"> <Typography variant="caption" > <span className="right-sidetxt-star">*</span>Available in the Explorer Dashboard </Typography> </div>
        </div>
    )
}

export default AvailableTitleEurope;
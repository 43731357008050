import { Fragment, memo, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import {api} from '../../../../services/api';
import { handlingCatchErorr } from '../../../utils/errorHandling';
import SubscribeOrAvailable from './SubscribeOrAvailable';
import { Typography } from '@mui/material';


const Products = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);
    const [productData, setProductData] = useState([]);
    const [subscribedProducts, setSubscribedProducts] = useState([]);
    const [availableProducts, setAvailableProducts] = useState([]);


    let initialProductLoad = () => {
        setLoader(true)
        api.get("subscription/products")
            .then((res) => {
                setProductData(res.data);
                setSubscribedProducts(res.data.subscribed);
                setAvailableProducts(res.data.available)
                setLoader(false);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
                setLoader(false);
            });

    };

    useEffect(() => {
        if (!productData.length) {
            initialProductLoad();
        }
    }, []);

    let isParentOrNot = availableProducts && availableProducts.length > 0 ? 
    availableProducts.filter(product => 
        !subscribedProducts.some(sub => sub.productId === product.parentProductId)) 
    : [];


    let isShowinSub = availableProducts && availableProducts.length > 0 ? 
    availableProducts.filter(product => 
        subscribedProducts.some(sub => sub.productId === product.parentProductId)) 
    : [];

    const categories = [
        {
            title: 'Subscribed',
            products: subscribedProducts,
            isId : "parent",
            showAvailable: subscribedProducts.length > 0 ? isShowinSub : [],
            
        },
        {
            title: 'Available' ,
            products: subscribedProducts?.length > 0 ? isParentOrNot : availableProducts ,
            isId : "notParentId",
            showAvailable : subscribedProducts?.length
        },
    ];


    return (
        <Fragment>
            {loader && <div className="loader-wrap"><div className="loading"></div></div>}
            {productData && productData.length !== 0 ?
                <div>
                    {categories.map((category) => (
                        <div key={category.title}>
                            <h2 style={{ marginBottom: '10px', marginTop: '5px' }} 
                            className={category.title === "Available" && category?.products?.length >0 ? "available-heading" : ""}>
                                {category.title === "Available" ? category?.products?.length >0 ? category.title : "" : category.title}
                                </h2>
                            <SubscribeOrAvailable 
                            productDatas={category.products} 
                            title={category.title} 
                            isId={category.isId}
                            listAvailable = {category?.showAvailable}
                            />
                        </div>
                    ))}
                </div> :
                (!loader &&
                    <Typography
                        component="h3"
                        variant="headline"
                        style={{ marginBottom: '10px' }}
                    >
                        No Data Found
                    </Typography>)
            }
        </Fragment>
    )
}

export default memo(Products)
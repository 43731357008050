import { Modal, ModalClose, Sheet } from '@mui/joy';
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, FormHelperText } from '@mui/material';
import { Fragment } from 'react';
import { Types, ultStaOrCou } from '../../../../utils/enums';

export default function UnlimitedStateOrCountryModal(props) {
    let { selectedTab, stateOrcountryerrorMsg, subscriptionData, handleCancel, checkBoxDisable, errors, selectedpackName, selectedpackNationalValue,  open,  unlimitedStateDataList, selectedStateListIds, handleBtnClick, handleProdSubCheckChange } = props;
    
    let filteredUnlNational = subscriptionData[selectedTab]?.subscriptions?.filter(gt => gt.subscriptionType === selectedpackName)[0];

    const handleCheckCancel = (e, reason) => {
        if (reason === 'closeClick') handleCancel("notSave");
    }
    return (
        <Fragment>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={handleCheckCancel}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'scroll' }}
                className="state-select-popup"
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 800,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.body',
                        }}
                    />
                    <div className={selectedpackName === Types.national ? "select-country-list state-us-canada-list" : 'state-us-canada-list'}>
                        {selectedpackName === Types.state ?
                            <>
                                {unlimitedStateDataList[selectedTab]?.length > 0 ?
                                    <div>
                                        <h3 className='select-us'>Select State</h3>
                                        <p className='state-info'>You have selected {selectedStateListIds[selectedTab]?.length} of 7 States.</p>
                                        <Grid container spacing={2} >
                                            {unlimitedStateDataList[selectedTab]?.map((lst) => (
                                                <Grid item xs={3} className='state-list' key={lst.id}>
                                                    <FormGroup row >
                                                        <FormControlLabel name="selStateList" className='receive-alert' control={<Checkbox checked={selectedStateListIds[selectedTab]?.includes(lst.id) ? true : false} />} value={lst.id} label={lst.name} onChange={(e) => handleProdSubCheckChange(e, lst.id, lst.name)} />
                                                    </FormGroup>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div> :
                                    checkBoxDisable ? "Loading..." : "Data not found"}
                                <FormHelperText className='state-error'>{errors[`${selectedTab}${ultStaOrCou.ultState}`] !== undefined ? errors[`${selectedTab}${ultStaOrCou.ultState}`] : null}</FormHelperText>
                            </>
                            : selectedpackName === Types.national ? (
                                <div className='select-package'>
                                  <h3 className='select-us display-block' style={{ paddingBottom: '15px' }}>Select Region</h3>
                                  {filteredUnlNational?.subCategoryList
                                    ? filteredUnlNational?.subCategoryList
                                      ?.filter((ft, index, self) =>
                                        ft.subscriptionPriceName !== "" &&
                                        self.findIndex((item) => item.subscriptionPriceName === ft.subscriptionPriceName) === index
                                      )
                                      .map((ft) => (
                                        <Button
                                          key={ft.subscriptionPriceName}
                                          disabled={checkBoxDisable}
                                          className={selectedpackNationalValue === ft.subscriptionPriceName ? 'package-btn package-selected' : 'package-btn package-unselected'}
                                          onClick={() => handleBtnClick(ft.subscriptionPriceName, ' ', '', ft.subscriptionkey, 'national',ft.defaultCountryId)}
                                          name="btnChooseSubs"
                                        >
                                          {ft.subscriptionPriceName}<br />
                                        </Button>
                                      ))
                                    : checkBoxDisable ? <p className='package-loading'>Loading...</p> : <p className='no-package'>Data not found</p>
                                  }
                                </div>
                              ) : null}
                       
                    </div>
                    <FormHelperText className='state-error'>{stateOrcountryerrorMsg}</FormHelperText>
                    <Grid item xs={12} className='button-alignment'>
                        <Button variant="text" className='cancel-btn' onClick={() => handleCancel("notSave")}>Cancel</Button>
                        <Button variant="contained" className='success-btn' onClick={() => handleCancel("save")} type="button" >Save</Button>
                    </Grid>
                </Sheet>
            </Modal>
        </Fragment>
    );
}
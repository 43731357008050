import React, { Fragment, useState } from 'react'
import { Table, TableBody, TableCell, TableRow,  TableContainer, Paper } from '@mui/material';
import EnhancedTableHead, { getSorting, handleRequestSort, stableSort } from '../../../commonFunctionalities/CommonMuiSortingFunc';
import { Link } from 'react-router-dom';
import AdminSuspendAccountModal from '../../../modals/adminModules/AdminSuspendAccountModal';
import { productNames } from '../../../../utils/enums';

const AdminProductsDetails = (props) => {
    let headRows = [
        { id: 'name', label: 'Product Name', numeric: false },
        { id: 'subscriptionName', label: 'Subscription Name', numeric: false },
        
        { id: 'creditCount', label: 'Credit Count', numeric: false },
        { id: 'view/edit', label: 'View/Edit Subscription', numeric: false },
        { id: 'suspend', label: 'Active', numeric: false },
    ];
    let disableTableRowsNames = ['view/edit', 'creditCount', 'suspend'];
    let { productData, userId, initialData } = props;
    const [state, setState] = useState({ order: 'asc', orderBy: "" });

    const handleRequestSortPreCall = (property) => {
        const { order, orderBy } = state;
        let { sort, prop } = handleRequestSort(property, order, orderBy);
        setState({ ...state, order: sort, orderBy: prop });
    }
    return (
        <Fragment>
            <TableContainer component={Paper} className="common-table-design">
                <Table>
                    <EnhancedTableHead
                        order={state.order}
                        orderBy={state.orderBy}
                        onRequestSort={handleRequestSortPreCall}
                        disableTableRowsNames={disableTableRowsNames}
                        headRows={headRows}
                    />
                    <TableBody>
                        {productData?.products && productData?.products.length > 0 ?
                            stableSort(
                                productData.products,
                                getSorting(state.order, state.orderBy)
                            ).map((strdata,index) =>
                                <TableRow key={index}>
                                    <TableCell>{strdata.name ? strdata.name === productNames.USProductName ? "Explorer" : strdata.name : "-"}</TableCell>
                                    <TableCell>{strdata.subscriptionName ? strdata.subscriptionName : "-"}</TableCell>
                                    <TableCell>{strdata.creditsCount ? strdata.creditsCount : "-"}</TableCell>
                                    {/* Previously Used <TableCell> <Link to={{pathname: `/updateSubscription/${strdata.permalink}/${strdata.productId}/${userId}` }} className='view-update-btn'>View/Edit</Link> </TableCell> */}
                                    <TableCell><Link to={`/updateSubscription/${strdata.permalink}/${strdata.productId}/${userId}`} className='view-update-btn'>View/Edit</Link></TableCell>
                                    <TableCell> <AdminSuspendAccountModal isSusupended={strdata.isactive} id={strdata.productId} /> </TableCell>
                                </TableRow>
                            ) :
                            <TableRow>
                                <TableCell colSpan={8} className="no-data">No Data Found</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment >
    )
}

export default AdminProductsDetails;
import { Modal, ModalClose, Sheet } from '@mui/joy';
import { Fragment } from 'react';
import {Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {handlingCatchErorr,handlingSuccessResponse} from "../../utils/errorHandling"
import {api} from '../../../services/api';

export default function SubscriptionCancelModal(props) {
    const {invoiceLoad,close,loader,productLoads,cancelDetail,open} = props;
    const navigate = useNavigate();
    const  details =cancelDetail;


    const handleCancelSubscription = () =>{
        close(false);
        loader(true);
        const cancelData ={
            "productId": details.productId,
            "productSubscriptionId":details.subscriptionId,
            "subscriptionPriceId": details.subscriptionPriceId
        }
        api.post('subscription/cancelsubscription', cancelData)
        .then((res) => {
           loader(false);
            handlingSuccessResponse(res, "Subscription Cancelled Successfully. Please check your email for further instructions.");
             productLoads();
             invoiceLoad();
        })
        .catch((error) => {
            loader(false);
            handlingCatchErorr(error, navigate);
        });
    
      }

    const handleCancel = () => { close(false); };

    const handleCheckCancel = (e, reason) => {
        if (reason === 'closeClick') handleCancel(false);
    }

    return (
        <Fragment>
           
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={handleCheckCancel}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                className="delete-model-popup"
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.body',
                        }}
                    />
                    <p>
                        Are you sure you want to cancel the subscription?
                    </p>
                    <div className='button-alignment'>
                        <Button className='cancel-btn' onClick={handleCancel}>No</Button>
                        <Button className='success-btn' type="button" onClick={handleCancelSubscription}>Yes</Button>
                    </div>
                </Sheet>
            </Modal>
        </Fragment>
    );
}
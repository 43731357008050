import { Fragment, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useNavigate, Link } from 'react-router-dom';
import {api} from '../../services/api';
import { validationFunction } from '../Home/commonFunctionalities/AllValidation';
import { CONFIG } from '../utils/config';
import { Typography } from '@mui/material';

let textFieldVariables = { "userName": "Username", "Password": "password" };
let initialData = { userName: "", Password: "" };
const Login = (props) => {
    let { handleLogin } = props;
    const navigate = useNavigate();
    const isFirstRender = useRef(true);
    const [sighinerror, setSighinerror] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [signData, setSigninData] = useState(initialData);
    const [isSubmitOrNot, setIsSubmitOrNot] = useState(false);

    const handleChange = (e) => {
        if (isSubmitOrNot) setIsSubmitOrNot(!isSubmitOrNot);
        const { name, value } = e.target;
        setSigninData((prevData) => ({ ...prevData, [name]: value }));
    };

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return
        }
        if (isSubmitOrNot) handleSignInPostSubmit();
    }, [sighinerror])

    const errorMessageFunc = (errorName, errorMessage) => {
        setSighinerror((prevData) => ({ ...prevData, [errorName]: errorMessage }));
    }

    const handleSignInPreSubmit = (e) => {
        setIsSubmitOrNot(true);
        e.preventDefault();
        Object.entries(signData).map(([key, val]) => {
            let returnedErrors = validationFunction(key, val)
            errorMessageFunc(key, returnedErrors);
            return '';
        });
    }

    const handleSignInPostSubmit = (e) => {
        localStorage.clear();
        let { userName, Password } = signData;
        let areThereErrorMsgOrNot = Object.entries(sighinerror).length !== 0 ? Object.entries(sighinerror).every(([key, val]) => val === "") ? true : false : true;
        if (areThereErrorMsgOrNot) {
            setIsLoading(true);
            const loginData = {
                username: userName,
                password: Password,
            };
            api.post('/account/login', loginData)
                .then((response) => {
                    localStorage.setItem("userInfo", JSON.stringify(response.data));  // store data in localstorage
                    handleLogin(JSON.stringify(response.data));
                    navigate('/products');  // move to products page
                    window.history.pushState(null, null, window.location.pathname);
                    window.onpopstate = function () {
                        window.history.pushState(null, null, window.location.pathname);
                    };
                })
                .catch((error) => {
                    setIsLoading(false);
                    if (error.response?.status === 400) {
                        setSighinerror({ userName: "  ", Password: error.response.data })
                    }
                });
        }
    }

    const handleResetAllValues = () => {
        setSigninData(initialData); //reset formdata
        setSighinerror([]) //reset error
        setIsSubmitOrNot(false);
    }

    return (
        <Fragment>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <h5>User Sign in</h5>
                {Object.entries(textFieldVariables).map(([key, val], index) => (
                    <div key={index}>
                        <TextField
                            name={key}
                            placeholder={val}
                            error={!!sighinerror[key]}  // Set error state based on the presence of error message
                            helperText={sighinerror[key]}  // display the error message
                            onChange={handleChange}
                            type={key === "userName" ? "email" : "password"}
                            onInput={handleChange}
                            onPaste={(event) => {
                                if (key !== "userName") {
                                    event.preventDefault();
                                }
                            }}                        
                        />
                        {key === "userName" && <><br /><br /></>}
                    </div>
                ))}
                <Stack spacing={2} direction="row">
                    <Button type="submit" variant="contained" onClick={handleSignInPreSubmit} >Sign In</Button>
                    <Button type="reset" variant="text" onClick={handleResetAllValues}>Reset</Button>
                </Stack>
            </Box>
            <Link to="/forgotpassword">Forgot Password?</Link>
            <Typography className="have-trouble">
                If you encounter any issues or have questions,<br />
                please contact us at <a href={`mailto:${CONFIG.SupportEmail}`}>{CONFIG.SupportEmail}</a> or {CONFIG.ContactNo}
            </Typography>
            {isLoading ? <div className="loader-wrap"><div className="loading"></div></div> : ""}
        </Fragment>
    );
}

export default Login
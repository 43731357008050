import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import ContactUs from '../modals/ContactUs';
import { CONFIG } from '../../utils/config';
import { Link } from 'react-router-dom';

const FreeAccountConfirmation = (props) => {
    const [open, setOpen] = useState(false);

    const handleCancel = () => {
        setOpen(false);
    };


    return (
        <div className="account-acivate payment-success payment-msg" style={{ maxWidth: '620px', width: '100%', textAlign: 'center' }}>
            <Box
                component="form"
                noValidate
                autoComplete="off"
                className='custom-form'
            >
                {props.from === "free-account-confirm" ?
                    <>
                        <Typography variant="h5" gutterBottom className="text-center" style={{ marginBottom: '20px', fontSize: '22px' }}>
                            Activate Your Account Now
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom className="payment-desc">
                            Thank you for creating your account. Please check your email’s inbox (and spam folder) for our email with an activation link.
                            Finish activating your account by opening the link in this email. Once you activate your account you can log in normally.
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom className="payment-desc"> Your journey with us is just starting, and we are here to assist you. If you have any questions or need help, reach out to our support team at <a href={`mailto:${CONFIG.SupportEmail}`}>{CONFIG.SupportEmail}</a>.
                        </Typography>

                    </>
                    :
                    <>
                        <Typography variant="h5" gutterBottom className="text-center" style={{ fontSize: '22px' }}>
                        Your Account is Confirmed and Active 
                        </Typography>
                        <Typography variant="h6" gutterBottom className="text-center" style={{ marginBottom: '20px', fontSize: '15px' }}>

                         Welcome to the  {CONFIG.Application_Name}
                        </Typography>

                        <Typography variant="subtitle" gutterBottom className="payment-desc">
                        Your account is now active. You're all set to explore our dashboard and access valuable insights. If you have any questions regarding your account, please reach out to our team at <a href={`mailto:${CONFIG.SupportEmail}`}>{CONFIG.SupportEmail}</a>. We're ready to assist you! </Typography>
                        <Link to={CONFIG.Explorer_url} className="black-btn">Continue to login</Link>
                    </>}
                {/* } */}
            </Box>
            <ContactUs title={"Contact"} open={open} handleCancel={handleCancel} />
        </div>
    );
}

export default FreeAccountConfirmation;
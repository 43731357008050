import React, { Fragment, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableRow, TableContainer, Paper, TablePagination } from '@mui/material';
import moment from "moment";
import EnhancedTableHead, { getSorting, handleAlphabets, handleRequestSort, stableSort } from '../../../commonFunctionalities/CommonMuiSortingFunc';
import { usersType } from '../../../../utils/enums';
import { api } from '../../../../../services/api';
import { handlingCatchErorr } from '../../../../utils/errorHandling';
import { useNavigate } from 'react-router-dom';
import BillingDetailsEditModal from '../../../modals/commonModals/BillingDetailsEditModal';



const AdminMarketDetails = (props) => {
    let { MarketData, load, loadMarket,apiFrom} = props;
    const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [loaderTwo, setLoaderTwo] = useState(true);
    const [disable, setDisable] = useState(false);
    const navigate = useNavigate();
    let textWithAlphabets = ['marketname', 'address1', 'city', 'state'];

    let headRows = [
        { id: 'createddate', label: 'Created', numeric: false },
        { id: 'marketexpiredate', label: 'Access Expires', numeric: false },
        { id: 'marketname', label: 'Name of Market', numeric: false },
        { id: 'address1', label: 'Street Address/coordinates', numeric: false },
        { id: 'city', label: 'City', numeric: false },
        { id: 'state', label: 'State', numeric: true },
        { id: 'zipcode', label: 'Zipcode', numeric: true },
        { id: 'noofstores', label: 'No.Stores', numeric: true },
        props.isFrom === usersType.user
            ? null // Empty header when the condition is true
            : { id: 'edit', label: 'Edit', numeric: true },
    ].filter(Boolean); // Remove null values    ];
    let disableTableRowsNames = ['edit'];
    const [state, setState] = useState({ order: 'asc', orderBy: "date" });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    


    const handleRequestSortPreCall = (property) => {
        const { order, orderBy } = state;
        let { sort, prop } = handleRequestSort(property, order, orderBy);
        setState({ ...state, order: sort, orderBy: prop });
    }

    let countryLookUpApi = () => {
        api.get("lookup/countries")
            .then((res) => {
                setCountryData(res.data);
                setLoaderTwo(false);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
                setLoaderTwo(false);
            });
    };

    let stateLookUpApi = (value) => {
        if (value) {
            setDisable(true);
            api.get(`lookup/states/${value}`)
                .then((res) => {
                    setStateData(res.data);
                    setDisable(false);
                })
                .catch((err) => {
                    setDisable(true);
                    handlingCatchErorr(err, navigate);
                });
        } else {
            setDisable(true);
            setStateData([])
        };
    };
    useEffect(() => {
        if (!countryData?.length) countryLookUpApi();
    }, []);
    useEffect(() => {
        handleAlphabets(textWithAlphabets);
    }, [])

    MarketData = MarketData.slice().sort((a, b) => new Date(b.createddate) - new Date(a.createddate));

    return (
        <Fragment>
            {(load || loaderTwo) && <div className="loader-wrap"><div className="loading"></div></div>}
            <TableContainer component={Paper} className="common-table-design market-list-table fixed-header-table">
                <Table>
                    <EnhancedTableHead
                        order={state.order}
                        orderBy={state.orderBy}
                        onRequestSort={handleRequestSortPreCall}
                        disableTableRowsNames={disableTableRowsNames}
                        headRows={headRows}
                    />
                    <TableBody>
                        {MarketData && MarketData.length > 0 ?
                            stableSort(
                                MarketData,
                                getSorting(state.order, state.orderBy)
                            )
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                                .map((strdata) =>
                                    <TableRow key={strdata.id}>
                                        <TableCell>{strdata.createddate ? moment(strdata.createddate).format('DD-MMM-YYYY') : "-"}</TableCell>
                                        <TableCell>{strdata.marketexpiredate ? moment(strdata.marketexpiredate).format('DD-MMM-YYYY') : "-"}</TableCell>
                                        <TableCell>{strdata.marketname ? strdata.marketname : "-"}</TableCell>
                                        <TableCell>{strdata.address1 ? strdata.address1 : "-"}</TableCell>
                                        <TableCell>{strdata.city ? strdata.city : "-"}</TableCell>
                                        <TableCell>{strdata.state ? strdata.state : "-"}</TableCell>
                                        <TableCell>{strdata.zipcode ? strdata.zipcode : "-"}</TableCell>
                                        <TableCell>{strdata.noofstores ? strdata.noofstores : "-"}</TableCell>
                                        {props.isFrom === usersType.user ? "" :
                                            <TableCell>
                                                <BillingDetailsEditModal
                                                    from={"adminMarketEdit"}
                                                    productDetailsData={MarketData}
                                                    userStoreID={strdata.id}
                                                    countryData={countryData}
                                                    stateLookUpApi={stateLookUpApi}
                                                    stateData={stateData}
                                                    disable={disable}
                                                    loadMarket={loadMarket}
                                                    apiFrom ={apiFrom}
                                                /></TableCell>
                                        } </TableRow>
                                ) :
                                (!load && !loaderTwo) && (
                            <TableRow>
                                <TableCell colSpan={8} className="no-data">{'No Data Found'}</TableCell>
                            </TableRow>
                                )
                                
                        }
                    </TableBody>
                </Table>
                {!load && !loaderTwo && MarketData.length > 0 &&
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={MarketData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                }
            </TableContainer>
        </Fragment>

    )
}

export default AdminMarketDetails
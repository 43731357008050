import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Avatar, Tooltip, MenuItem, Container } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { KeyboardArrowDown, WidgetsOutlined, PlayCircleOutlined, Call, AccountCircleRounded, Logout, AccountCircleOutlined, BuildCircleOutlined, Menu as MenuIcon, } from '@mui/icons-material';
import LogoImg from "../../../src/assets/images/suite_logo.png";
import ContactUs from '../Home/modals/ContactUs';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { usersType, usersTypeId } from '../utils/enums';
const commonNavPages = { "Products": "/products", "Subscription and Billing Management": "/subscription", "Account Management": "/account" };
const adminNavPages = { "Administration": "/admin" };
const enterPriseNav = { "User": "/user" }
const drawerWidth = 240;

export default function DrawerAppBar(props) {
  const { window, stoptImpersonation } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const navigateURL = useNavigate();
  const { pathname } = useLocation();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [open, setOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const allNavPages = (() => {
    let impersonationData = JSON.parse(localStorage.getItem("impersonationInfo"));
    if (userData !== null && pathname !== '/') {
      if (impersonationData && impersonationData.usertypeId) {
        if (impersonationData.usertypeId === usersTypeId.Admin) {
          return { ...commonNavPages, ...adminNavPages };
        } else if (impersonationData.usertype === usersType.user) {
          return { ...commonNavPages, ...enterPriseNav };
        } else {
          return { ...commonNavPages };
        }
      } else {
        if (userData.usertypeId === usersTypeId.Admin) {
          return { ...commonNavPages, ...adminNavPages };
        } else if (userData.usertype === usersType.user) {
          return { ...commonNavPages, ...enterPriseNav };
        } else {
          return { ...commonNavPages };
        }
      }
    } else {
      return {};
    }
  })();
  const impersonationData = JSON.parse(localStorage.getItem("impersonationInfo"));
  const renderUserName = () => {
    if (impersonationData && impersonationData.firstname && impersonationData.lastname) {
      return `${!impersonationData.firstname ? " ": impersonationData.firstname} ${!impersonationData.lastname ? " " : impersonationData.lastname}`;
    } else if (userData) {
      return `${!userData.firstname? " " : userData.firstname} ${!userData.lastname? " " : userData.lastname}`;
    }
    return '';
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const container = window !== undefined ? () => window().document.body : undefined;
 

  const impersonationInfo = JSON.parse(localStorage.getItem('impersonationInfo'));
  const handleMenuItemClick = (isItLogOrAcc) => {
    handleCloseUserMenu();
    if (impersonationInfo) {
      stoptImpersonation();
    } else {
      if (isItLogOrAcc === "LogOut") {
        localStorage.clear();
        navigateURL('/page-expired');
      }
    }
  };

  const handleNavImage = (iconName) => {
    if (iconName === "/products") return <WidgetsOutlined />
    if (iconName === "/subscription") return <PlayCircleOutlined />
    if (iconName === "/account") return <AccountCircleOutlined />
    if (iconName === "/admin") return <BuildCircleOutlined />
    if (iconName === "/user") return <ManageAccountsOutlinedIcon />
    else return null
  }

  const handleCancel = () => {
    setOpen(false);
  };

  const clickHandler = (event) => {
    event.preventDefault();
    if (!open) setOpen(true);
  };

  const handleTabRemoval = () => {
    localStorage.removeItem('lastVisitedTab');
  };

  const menuList = (
    < List className={mobileOpen ? 'mobile-view-enabled' : 'desktop-enabled'} >
      {
        Object.entries(allNavPages).map(([key, val]) => {
          let highlightForUserUpdatePath = "";
          let highlightPathNavName = (pathname === val) ? 'bg-active-nav-links nav-link' : 'nav-link';
          if (userData.usertypeId === usersTypeId.Admin) highlightForUserUpdatePath = (pathname.startsWith("/updateusers") || pathname.startsWith("/updateSubscription")) ? val === "/admin" ? "bg-active-nav-links nav-link" : "nav-link" : highlightPathNavName;
          else highlightForUserUpdatePath = (pathname.startsWith("/viewuser") || pathname.startsWith("/viewSubscription")) ? val === "/user" ? "bg-active-nav-links nav-link" : "nav-link" : highlightPathNavName;
          return (
            <span key={key}>
              <ListItem key={key} disablePadding className='nav-list'>
                <ListItem component={Link} to={val} onClick={handleTabRemoval} className={highlightForUserUpdatePath}>
                  <ListItemText secondary={key} primary={handleNavImage(val)} />
                </ListItem>
              </ListItem>
            </span>
          )
        })
      }  {userData?.usertypeId === usersTypeId.Users &&
        <ListItem disablePadding className='nav-list'>
          <ListItem component={Link} to="" className='nav-link contact-menu' onClick={clickHandler}>
            <Call />
            <ListItemText primary="Contact Us" />
          </ListItem>
        </ListItem>
      }
    </List >
  )

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} className="navigation-menu">
      <Divider />
      {menuList}
    </Box>
  );

  return (
    <>
      <Box sx={{ display: 'flex' }} className="sticky-header">
       {impersonationInfo ? (<div className='admin-stripe'><Container maxWidth="xl"><div className='admin-stripe_txt'><AccountCircleRounded />You are logged in as "{renderUserName()}". <a onClick={() => handleMenuItemClick("LogOut")} className='return-back'>Return to Admin</a></div></Container> </div>) : ('')}     
        <AppBar component="nav" className="header-section">          
          <Container maxWidth="xl" className='header-container'>
            <ContactUs title="Contact Us" handleCancel={handleCancel} open={open} />
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { lg: 'none' } }}
                className='mobile-menu-btn'
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, display: { lg: 'flex' } }}
                className="suite-logo"
              >
                <Link to="/products"><img src={LogoImg} alt='MIS' /></Link>
              </Typography>
              <Box sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }} className="navigation-menu">
                {menuList}
              </Box>
              <div className='welcome-info'>
                {(pathname !== "/" && userData !== null) && <>
                  <Box sx={{ flexGrow: 0, display: { md: 'flex', flexDirection: "column", justifyContent: 'flex-end' } }} className="user-text">
                    {impersonationInfo ? (<span className='welcome-txt'>Logged In As</span>) : (<span className='welcome-txt'>Welcome</span>)}
                    <span className='username-txt'>{renderUserName()}</span>
                  </Box>
                 
                  <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="Open Menu" className='icon-dropdown'>
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar><AccountCircleRounded /></Avatar>
                        <KeyboardArrowDown />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      sx={{ mt: '45px' }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                      className='welcome-dropdown'
                    >
                      <MenuItem sx={{ display: 'flex' }} onClick={() => handleMenuItemClick("LogOut")}><Logout />{impersonationInfo ? "Back to Admin Mode" : "Logout"}</MenuItem>
                    </Menu>
                  </Box>
                </>}
              </div>
            </Toolbar>
          </Container>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', md: 'block', lg: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
        </Box>

      </Box>
    </>
  );
}


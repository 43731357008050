import React, { useEffect } from 'react';

const GoogleAnalytics = ({ trackingId }) => {

    useEffect(() => {
        if (!window.gtag) {
            // Add Google Analytics script dynamically
            const script = document.createElement('script');
            script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
            script.async = true;
            document.body.appendChild(script);

            window.dataLayer = window.dataLayer || [];
            
            function gtag() {
                window.dataLayer.push(arguments);
              }
              gtag('js', new Date());
              gtag('config', trackingId);
            // Clean up function to remove the script when the component unmounts
            return () => {
                document.body.removeChild(script);
            };
        } 

    }, []);

    return null;
};

export default GoogleAnalytics;

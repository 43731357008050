import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {api} from '../../services/api';
import { handlingCatchErorr, handlingSuccessResponse } from '../utils/errorHandling';
import { validationFunction } from '../Home/commonFunctionalities/AllValidation';
import { useLocation, useNavigate } from 'react-router-dom';
import CommonPasswordStrengthMeter from '../Home/commonFunctionalities/CommonPasswordStrengthMeter';
import { CONFIG } from '../utils/config';
let textFieldVariables = { "NewPassword": "NewPassword", "ConfirmPassword": "ConfirmPassword" };
let initialData = { NewPassword: "", ConfirmPassword: "" };

const ResetPassword = () => {
    const isFirstRender = useRef(true);
    const [passworderror, setPassworderror] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [passwordData, setPaswordData] = useState(initialData);
    const [isSubmitOrNot, setIsSubmitOrNot] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const secureid = queryParams.get('secureid');
    const [isStrength, setStrength] = useState(null);

    // Callback function to handle password strength meter data
    const dataHandler = async (childData) => {
        setStrength(childData);
    }

    // Handle input field change
    const handleChange = (e) => {
        if (isSubmitOrNot) setIsSubmitOrNot(!isSubmitOrNot);
        const { name, value } = e.target;
        setPaswordData((prevData) => {
            const updatedData = { ...prevData, [name]: value };
            const returnedErrors = validationFunction(name, value, updatedData);
            errorMessageFunc(name, returnedErrors);
            handlePasswordValidation(name, value, updatedData);

            return updatedData;
        });
    };
    const handlePasswordValidation = (name, value, updatedData) =>{
        
        if (name === "NewPassword" && updatedData.ConfirmPassword.length>0) {
            if (value !== updatedData.ConfirmPassword  ) {
                errorMessageFunc("ConfirmPassword", "Passwords does not match");
            } else{
                errorMessageFunc("ConfirmPassword", "");
  
            } 
            
            
        }  
           
    }

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        if (isSubmitOrNot) handleResetPostSubmit();
    }, [passworderror]);

    const errorMessageFunc = (errorName, errorMessage) => {
        setPassworderror((prevData) => ({ ...prevData, [errorName]: errorMessage }));
    }

    const handleResetPreSubmit = (e) => {
        setIsSubmitOrNot(true);
        e.preventDefault();
        Object.entries(passwordData).forEach(([key, val]) => {
            const returnedErrors = validationFunction(key, val, passwordData);
            errorMessageFunc(key, returnedErrors);
            handlePasswordValidation(key, val, passwordData)
           
        });
    }

    const handleResetPostSubmit = () => {
        localStorage.clear();
        let areThereErrorMsgOrNot = Object.entries(passworderror).length !== 0 ? Object.entries(passworderror).every(([key, val]) => val === "") : true;
        if (areThereErrorMsgOrNot) {
            setIsLoading(true);
            const loginData = {
                password: passwordData.ConfirmPassword,
                secureID: secureid.replace(/ /g, '+'),
            };
            api.post('/account/updateuserpassword', loginData)
                .then((response) => {
                    handlingSuccessResponse(response, "Password Updated Successfully");
                    const url = CONFIG.Explorer_url
                    window.location.replace(url);                   
                     setIsLoading(true);
                })
                .catch((error) => {
                    setIsLoading(false);
                    handlingCatchErorr(error, navigate);
                });
        }
    }

    const handleResetAllValues = () => {
        setPaswordData(initialData); // reset form data
        setPassworderror([]); // reset error
        setIsSubmitOrNot(false);
    }

    return (
        <div className='user-password-reset'>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <h2>Reset Password</h2>
                {Object.entries(textFieldVariables).map(([key, val], index) => (
                    <div key={index}>
                        <TextField
                            name={key}
                            placeholder={val}
                            error={!!passworderror[key]}  // Set error state based on the presence of an error message
                            helperText={passworderror[key]}  // Display the error message
                            onChange={handleChange}
                            type="password"
                            onInput={handleChange}
                        />
                        {key === "NewPassword" && <div className='pwd-strength-meter'><CommonPasswordStrengthMeter password={passwordData.NewPassword} actions={dataHandler} /></div>}
                    </div>
                ))}
                <Stack spacing={2} direction="row" className='user-reset-btn'>
                    <Button type="submit" variant="contained" onClick={handleResetPreSubmit} className='submit-btn'>Submit</Button>
                    <Button type="reset" variant="text" onClick={handleResetAllValues} className='reset-btn'>Reset</Button>
                </Stack>
            </Box>
            <p className='text-center'>
                If you encounter any issues or have questions,<br />
                please contact us at <a href={`mailto:${CONFIG.SupportEmail}`}>{CONFIG.SupportEmail}</a> or {CONFIG.ContactNo}
            </p>
            {isLoading ? <div className="loader-wrap"><div className="loading"></div></div> : ""}
        </div>
    );
}

export default ResetPassword;

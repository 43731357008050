import { TableHead, TableCell, TableRow, TableSortLabel } from '@mui/material';

let textWithAlphabets = [];

const handleAlphabets = (txtWithAlpha) => {
    textWithAlphabets = txtWithAlpha === undefined ? [] : txtWithAlpha;
};

const desc = (a, b, orderBy, retOne, retTwo) => {
    if (textWithAlphabets.includes(orderBy)) {
        if (a[orderBy] === "" || a[orderBy] === null) return retOne;
        if (b[orderBy] === "" || b[orderBy] === null) return retTwo;
        if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) return -1;
        if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) return 1;
    } else {
        if (a[orderBy] === "" || a[orderBy] === null) return retOne;
        if (b[orderBy] === "" || b[orderBy] === null) return retTwo;
        if (b[orderBy] < a[orderBy]) return -1;
        if (b[orderBy] > a[orderBy]) return 1;
    }
    return 0;
};

const stableSort = (array, cmp) => {
    const stabilized = array.map((el, index) => [el, index]);
    stabilized.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilized.map((el) => el[0]);
};

const getSorting = (order, orderBy) => {
    return order === "desc" ? (a, b) => desc(a, b, orderBy, 1, -1) : (a, b) => -desc(a, b, orderBy, -1, 1);
};

const handleRequestSort = (prop, order, orderBy, orderAlternate) => {
    const isDesc = orderBy === prop && order === "desc";
    let sort = orderAlternate === undefined ? isDesc ? "asc" : "desc" : orderAlternate;
    return { sort, prop };
};


export default function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, disableTableRowsNames, headRows } = props;
    const createSortHandler = property => {
        onRequestSort(property);
    };

    return (
        <TableHead>
            <TableRow className="table-header">
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={() => createSortHandler(row.id)}
                            disabled={disableTableRowsNames?.includes(row?.id)}
                        >
                            {row.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export { stableSort, getSorting, handleRequestSort, handleAlphabets };
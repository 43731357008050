import React, { Fragment, useEffect, useState } from 'react';
import { Box, Grid, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {api} from '../../../../services/api';
import { handlingCatchErorr } from '../../../utils/errorHandling';
import { handleRequestSort } from '../../commonFunctionalities/CommonMuiSortingFunc';
import UserGrid from "./EnterpriseUserGrid"
import { CommonSearchFunction } from '../../commonFunctionalities/CommonSearchFunction';

const EnterpriseDashboard = () => {
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();
    const [usersData, setUsersData] = useState([]);
    const [state, setState] = useState({ order: 'asc', orderby: "createdDate" });
    const [searchedText, setSearchedText] = useState("");
    const [usersSearchedData, setUsersSearchedData] = useState([]);

    const handleChange = (e, val) => {
        let { name, value } = e.target;
        if (name === "searchField") setSearchedText(value);
    };
    useEffect(() => {
        const searchedFieldsString = [ "email","firstname","lastname"];
        let { data, isEmptyOrNot } = CommonSearchFunction(searchedText, usersSearchedData, searchedFieldsString);
        if (isEmptyOrNot === "notEmpty") setUsersData(data);
        else setUsersData(usersSearchedData);
       
    }, [searchedText]);

    let initialUserManagementLoad = () => {
        api.get("account/users")
            .then((res) => {
                setUsersData(res.data.userList);
                setUsersSearchedData(res.data.userList);
                setLoader(false);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
                setLoader(false);
            });
        // }
    };

    useEffect(() => {
        initialUserManagementLoad();
    }, []);

    const handleRequestSortPreCall = (property) => {
        const { order, orderBy } = state;
        let { sort, prop } = handleRequestSort(property, order, orderBy);
        setState({ ...state, order: sort, orderBy: prop });
    }

    return (
        <Fragment>
            <Box sx={{ width: '100%', typography: 'body1' }} className="tab-design">
                <Grid container sx={{ marginBottom: "10px" }}>
                    <Grid item xs={6}>
                        <h3 style={{ margin: '0' }}>Users</h3>
                    </Grid>
                    <Grid item xs={6} className='text-right'>
                        <TextField
                            name={"searchField"}
                            placeholder='Search'
                            type="text"
                            value={searchedText}
                            onChange={handleChange}
                            className='custom-fields user-search-field'
                        />
                    </Grid>
                </Grid>
                <UserGrid loader={loader} usersData={usersData} state={state} handleRequestSortPreCall={handleRequestSortPreCall} />
            </Box>
        </Fragment>
    )
}

export default EnterpriseDashboard
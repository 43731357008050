import { Modal, ModalClose, Sheet } from '@mui/joy';
import { Fragment, useState } from 'react';
import {  Button } from '@mui/material';
import DeleteOutlineOutlined from '@mui/icons-material/DeleteOutlineOutlined';
import {api} from '../../../../services/api';
import { handlingCatchErorr,handlingSuccessResponse } from '../../../utils/errorHandling';
import { useNavigate } from 'react-router-dom';

export default function AdminDeleteUserModal(props) {
    let { userId, initialUserManagementLoad, handleLoader } = props;
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleDeleteButton = () => {
        handleCancel();
        handleLoader(true);
        api.delete(`/Admin/delete/${userId}`)
            .then((res) => {
                if (res.status === 200) {
                    initialUserManagementLoad("callenter");
                    handlingSuccessResponse(res, "User Deleted Successfully.");
                };
                handleLoader(false);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
                handleLoader(false);
            });
    };

    const handleCancel = () => { setOpen(false); };

    const handleCheckCancel = (e, reason) => {
        if (reason === 'closeClick') handleCancel(false);
    }

    return (
        <Fragment>
            <Button className="del-ico-btn" onClick={() => setOpen(true)}>
                <DeleteOutlineOutlined />
            </Button>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={handleCheckCancel}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                className="delete-model-popup"
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.body',
                        }}
                    />
                    <p>
                        Are you sure you want to delete?
                    </p>
                    <div className='button-alignment'>
                        <Button className='cancel-btn' onClick={handleCancel}>Cancel</Button>
                        <Button className='success-btn' type="button" onClick={handleDeleteButton}>Delete</Button>
                    </div>
                </Sheet>
            </Modal>
        </Fragment>
    );
}
import React from 'react';

const NotFound = () => {
  return (
    <div className="account-acivate payment-success" style={{ borderRadius: '10px' }}>
      <div className='invalid-access'>
        <h4 className='text-center mt-0'>404 - File or directory not found.</h4>
        <p className='text-center'>The resource you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
      </div>
    </div>
  );
};

export default NotFound;

import { Fragment, memo, useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from "@mui/material";
import InvoiceModal from "../../modals/InvoiceModal";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { Button as JoyButton } from '@mui/joy';
import {api} from "../../../../services/api";
import { handlingCatchErorr } from "../../../utils/errorHandling";
import { packageName, paymentMode, paymentStatus, paymentTerm } from "../../../utils/enums";
import { formattedAmount } from "../../commonFunctionalities/commonFunction";
import EnhancedTableHead, { getSorting, handleAlphabets, handleRequestSort, stableSort } from "../../commonFunctionalities/CommonMuiSortingFunc";
import ContactUs from "../../modals/ContactUs";

const InvoiceTab = (props) => {
  let { invoiceTabData,load } = props;
   let textWithAlphabets = [ 'packageName'];
  useEffect(() => {
    handleAlphabets(textWithAlphabets);
}, [])
  let headRows = [
    { id: 'orderDate', label: 'Invoice Date', numeric: false },
    { id: 'packageName', label: 'Invoice For', numeric: false },
    { id: 'amount', label: 'Amount', numeric: true },
    { id: 'status', label: 'Status', numeric: false },
    { id: 'billingAddress', label: 'Billing Address', numeric: false },
    { id: 'details', label: 'Details', numeric: false },
  ];
  let disableTableRowsNames = ['details', 'billingAddress', 'status'];
  const [state, setState] = useState({ order: 'asc', orderBy: "" });
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  
  const handleStripeBilling = (userOrderId) => {
    return new Promise((resolve, reject) => {
      setLoader(true);
      api.get(`subscription/billinghistory/${userOrderId}`)
        .then((response) => {
          setLoader(false);
          if (response.headers['location'] !== "" && response.headers['location'] !== null && response.headers['location'] !== undefined) {
            // Redirect to the new location
            //  window.location.replace(response.headers['location']);
            const newTabUrl = response.headers['location'];
            window.open(newTabUrl, '_blank');

          }

          // Resolve the promise to signal success
          resolve();
        })
        .catch((error) => {
          // Handle API call error and reject the promise
          setLoader(false);
          handlingCatchErorr(error, navigate);
          reject(error);
        });
    });
  }

  const handleBraintreeBilling = (userOrderId) => {
      setLoader(true);
      api.get(`subscription/braintree/billinghistory/${userOrderId}`)
        .then((response) => {
          setLoader(false);
          navigate("/invoice/billing-history", { state: {billingdetail:response.data,load:loader} });
         
        })
        .catch((error) => {
          // Handle API call error and reject the promise
          setLoader(false);
          handlingCatchErorr(error, navigate);
        });
  }

  const handleBillingHistory = (invoiceData) => {
    if (invoiceData.isBraintree) {
      handleBraintreeBilling(invoiceData.userOrderId);
    } else {
      handleStripeBilling(invoiceData.userOrderId);
    }
  };
  
  const handleRequestSortPreCall = (property) => {
    const { order, orderBy } = state;
    let { sort, prop } = handleRequestSort(property, order, orderBy);
    setState({ ...state, order: sort, orderBy: prop });
}
const clickContact = (event) => {
  event.preventDefault();
  if (!open) setOpen(true);
};
const handleCancel = () => {
  setOpen(false);
};

invoiceTabData = invoiceTabData.slice().sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));

  return (
    <Fragment>
      {((load) || (loader) ) && <div className="loader-wrap"><div className="loading"></div> : </div>}
      <TableContainer component={Paper} className="common-table-design user-management-table fixed-header-table">
        <Table>
            <EnhancedTableHead
                order={state.order}
                orderBy={state.orderBy}
                onRequestSort={handleRequestSortPreCall}
                disableTableRowsNames={disableTableRowsNames}
                headRows={headRows}
            />
          <TableBody>
              {invoiceTabData && invoiceTabData.length > 0 ? (
                stableSort(
                    invoiceTabData,
                    getSorting(state.order, state.orderBy)
                )
                .map((invoiceData, index) => {
                return (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {moment(invoiceData.orderDate).format('DD-MMM-YYYY')}
                    </TableCell>
                    <TableCell align="right">{invoiceData?.packageName}</TableCell>
                    <TableCell align="right">
                      {invoiceData?.packageName === packageName.free || invoiceData?.paymentStatus === paymentStatus.pending ||
                       (!invoiceData?.amount && invoiceData.paymentMode !== paymentMode.offline)
                        ? "-"
                        : invoiceData.paymentMode === paymentMode.offline ? 
                        <JoyButton onClick={clickContact}>Contact Us</JoyButton>
                        :
                        `${formattedAmount(invoiceData.amount/100,invoiceData.currency,invoiceData.currencyCode)}`
                      
                      }
                    </TableCell>                
                    <TableCell align="right">{invoiceData?.packageName === packageName.free || !invoiceData?.paymentStatus ? "-" : (invoiceData?.paymentStatus === "InActive" ? "Cancelled" : `${invoiceData?.paymentStatus}`)}</TableCell>
                    <TableCell align="right">{invoiceData?.packageName === packageName.free ? "-" : `${invoiceData?.billingAddress === null || invoiceData?.billingAddress === "" ? "-" : invoiceData?.billingAddress}`}</TableCell>
                    <TableCell align="right">
                      {invoiceData?.paymentMode === paymentMode.online && 
                      invoiceData?.paymentTerm === paymentTerm.recurring && 
                      invoiceData?.paymentStatus !== paymentStatus.pending && 
                      invoiceData?.paymentStatus !== paymentStatus.inactive ? 
                      <JoyButton onClick={() =>handleBillingHistory(invoiceData)}>See billing detail</JoyButton>
                        : 
                        
                        (invoiceData?.paymentMode === paymentMode.online &&
                           invoiceData?.paymentTerm !== paymentTerm.recurring ) ||                          
                         ( invoiceData?.paymentMode === paymentMode.offline && 
                          invoiceData?.paymentTerm === paymentTerm.recurring ) &&
                         invoiceData?.paymentStatus !== paymentStatus.pending && 
                           invoiceData?.paymentStatus !== paymentStatus.inactive 
                           
                           ? <InvoiceModal title= "Print Invoice"  invoiceDetails={invoiceData} /> :
                           "-"}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (!load &&
              <TableRow>
                <TableCell colSpan={6} className="no-data">
                  {'No Data Found'}
                </TableCell>
              </TableRow>
            )}
          </TableBody>

        </Table>
      </TableContainer>
      <ContactUs title={"Contact"} open={open} handleCancel={handleCancel} />
    </Fragment>
  );
};

export default memo(InvoiceTab);
import {api} from "../../services/api";
import { handlingCatchErorr } from "../utils/errorHandling";

export const CommonUpgrade = async (statelist,countrylist,lookupKey,units,isChecked, navigate ) => {
    return new Promise((resolve, reject) => {
        if (lookupKey !== null && lookupKey !== undefined && lookupKey !== "") {
            const upgradeData = {
                "lookupKey": lookupKey,
                "units": units,
                "stateList": statelist,
                "countryList":countrylist,
                "IsTermUse": isChecked.first,
                "IsSubscriptionTerm": isChecked.third
            };

            api.post("Subscription/upgrade", upgradeData)
                .then((response) => {
                    if (response.headers['location'] !== "" && response.headers['location'] !== null && response.headers['location'] !== undefined) {
                        // Redirect to the new location
                       window.location.replace(response.headers['location']);
                        // const newTabUrl = response.headers['location'];
                        //     window.open(newTabUrl, '_blank');
                    }

                    // Resolve the promise to signal success
                    resolve();
                })
                .catch((error) => {
                    // Handle API call error and reject the promise
                    handlingCatchErorr(error, navigate);
                    reject(error);
                });
        }
    });
};

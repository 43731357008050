import React, { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import SessionTimeoutModal from './SessionTimeoutModal';
import { CONFIG } from '../../utils/config';
let timeout;
let countdownInterval;
const TimeoutModal = () => {
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);

  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  }

  //clear session interval time in popup
  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  //logout for idle timeout
  const handleLogout = async (isTimedOut = false) => {
    try {
      setTimeoutModalOpen(false);
      clearSessionInterval();
      clearSessionTimeout();
      localStorage.removeItem("userInfo");
      localStorage.clear();
      window.top.location.href = CONFIG.MIG_login_url;
    } catch (err) {
      console.error(err);
    }
  };
  //continue the session
  const handleContinue = () => {
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();
  };

  //call on active session
  const onActive = () => {
    if (!timeoutModalOpen) {
      clearSessionInterval();
      clearSessionTimeout();
    }
  };

  //call on idle session
  const onIdle = () => {
    let userData = JSON.parse(localStorage.getItem("userInfo"));
    const isAuthenticated = (userData?.secureId !== null && userData?.secureId !== undefined) && (userData?.token !== null && userData?.token !== undefined) ? true : false;
    const delay = 1000 * 1;
    if (isAuthenticated && !timeoutModalOpen) {
      timeout = setTimeout(() => {
        let countDown = 60;
        setTimeoutModalOpen(true);
        setTimeoutCountdown(countDown);
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            setTimeoutCountdown(--countDown);
          } else {
            handleLogout(true);
          }
        }, 1000);
      }, delay);
    }
  };

  // timeout expiry implementation code
  useIdleTimer({
    onActive: onActive,
    onIdle: onIdle,
    debounce: 250,
    timeout: 900000
  });


  return (
    <div>
      <SessionTimeoutModal
        countdown={timeoutCountdown}
        onContinue={handleContinue}
        onLogout={handleLogout}
        open={timeoutModalOpen}
      />
    </div>
  );
};

export default TimeoutModal;
import { useEffect, useState } from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { eussapi, napapi, nassapi } from '../../../../../services/api';
import { handlingCatchErorr } from '../../../../utils/errorHandling';
import { useNavigate } from 'react-router-dom';
import AdminMarketDetails from './AdminMarketDetails';
import { CommonTabPanel } from '../../../commonFunctionalities/CommonTabPanel';
import { productNames } from '../../../../utils/enums';

const AdminProductTab = (props) => {
    const { productList, userId, secureId, isFrom, loader, TabValue } = props;
    const [tabValues, setTabValues] = useState('');
    const [load, setLoad] = useState(false);
    const [parkingLoad, setParkingLoad] = useState(false);
    const [marketData, setMarketData] = useState([]);
    const [marketParkingData, setMarketParkingData] = useState([]);
    const [marketDataEU, setMarketDataEU] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Set the initial tab value to the first object's name in the productList array
        if (productList && productList.length > 0) {
            setTabValues(productList[0].name);
        }
    }, [productList]);

    const handleChange = (e, value) => {
        setTabValues(value);
    };
    const getMarketDetailsSelf = () => {
        setLoad(true)
        nassapi.get(`/Market/admin/${userId}/${secureId} `)
            .then((res) => {


                if (res.status === 200) {
                    setMarketData(res.data)
                }

                setLoad(false);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
                setLoad(false);
            });
    }
    const getMarketDetailsParking = () => {
        setParkingLoad(true)
        napapi.get(`/Market/admin/${userId}/${secureId} `)
            .then((res) => {


                if (res.status === 200) {
                    setMarketParkingData(res.data)
                }

                setParkingLoad(false);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
                setParkingLoad(false);
            });
    }
    const getMarketDetailsEu = () => {
        setLoad(true)
        eussapi.get(`/Market/admin/${userId}/${secureId} `)
            .then((res) => {
                if (res.status === 200) {
                    setMarketDataEU(res.data)
                }

                setLoad(false);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
                setLoad(false);
            });
    }

    useEffect(() => {
        if (!marketData.length && productNames.USProductName && TabValue === "2") {
            getMarketDetailsSelf();
        }

    }, [TabValue])

    useEffect(() => {
        if (!marketParkingData.length && tabValues === productNames.USParkingProductName && TabValue === "2") {
            getMarketDetailsParking();
        }
        if (!marketDataEU.length && tabValues === productNames.EUProductName && TabValue === "2") {
            getMarketDetailsEu();
        }
    }, [tabValues])


    return (
        <>
            {(loader || load) && <div className="loader-wrap"><div className="loading"></div></div>}

            <Box sx={{ width: '100%', typography: 'body1' }} className="subscriptions-invoice tab-design">
                <TabContext value={tabValues}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="sub-invoice-tab tab-box">
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            {productList.map((val, key) => (
                                <Tab label={val.name} value={val.name} key={val.name} />
                            ))}
                        </TabList>
                    </Box>
                    <CommonTabPanel value={productNames.EUProductName}>
                        <AdminMarketDetails
                            load={load}
                            isFrom={isFrom}
                            MarketData={marketDataEU}
                            loadMarket={getMarketDetailsEu}
                            apiFrom={eussapi}
                        />

                    </CommonTabPanel>
                    <CommonTabPanel value={productNames.USProductName}>
                        <AdminMarketDetails
                            load={load}
                            isFrom={isFrom}
                            MarketData={marketData}
                            loadMarket={getMarketDetailsSelf}
                            apiFrom={nassapi}
                        />

                    </CommonTabPanel>
                    <CommonTabPanel value={productNames.USParkingProductName}>
                        <AdminMarketDetails
                            load={parkingLoad}
                            isFrom={isFrom}
                            MarketData={marketParkingData}
                            loadMarket={getMarketDetailsParking}
                            apiFrom={napapi}
                        />

                    </CommonTabPanel>

                </TabContext>
            </Box>
        </>
    );
};

export default AdminProductTab;

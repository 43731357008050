import * as React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { TopBar, Footer, Header } from '../core';
import Box from '@mui/material/Box';
import { Container } from '@mui/joy';
import "../../components/SingleCheckout/singleCheckout.css"
import { api } from "../../services/api";
import { handlingCatchErorr, handlingSuccessResponse } from "../utils/errorHandling";
function SinglePageCheckoutLayout(props) {
  const navigate = useNavigate();
  const stoptImpersonation = () => {
    api.post('Impersonation/logout')
      .then((res) => {
        if (res.status === 200) {
          let userInfo = res.data;
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          props. handleImperLogout(JSON.stringify(res.data));
          localStorage.removeItem('impersonationInfo');
          handlingSuccessResponse(res, "You are Logged Out Successfully");
          navigate("/admin")
        }
      })
      .catch((err) => {
        handlingCatchErorr(err, navigate);
      });
  };  
  return (
    <div>
      {props.from?.token !== null && props.from?.token !== undefined ? <Header stoptImpersonation={stoptImpersonation} from={"single-chckout"}/> :
      <TopBar islogin={props.from}/> }
      <Box component="main" className="body-main">
        <Container maxWidth="xl">
          
            <Outlet />
          
        </Container>
      </Box>
      <Footer />
    </div>
  );
}

export default SinglePageCheckoutLayout;
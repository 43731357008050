import { Button, Typography } from '@mui/joy';
import { Grid, TextField, Box, InputLabel, FormControl, Select, MenuItem, FormHelperText } from '@mui/material';
import { formattedAmount } from '../../../commonFunctionalities/commonFunction';

let textFieldBasicBillInfoVariables = { "First Name": "fName", "Last Name": "lName", "Company": "company", "Phone": "stpOnePhone", "Email Address": "email", };
let textFieldAddBasicInfoVariables = { "Address Line 1": "thridStepbillingAddress", "Address Line 2": "thridStepbillingAddress2", "City": "thridStepbillingCity", "Country": "thridStepbillingCountry", "State/Province": "thridStepbillingState", "Zip/Postal Code": "thridStepbillingZipCode" };


const StepperThree = (props) => {
    let { handleChange, errors, thridStepData, countryId, stateId, unlimitedStateDataList,countryData,tabIndValues, stateData, priceDetails, disable, formData, handleAddBillingAddress, handleSkipBillingAddress, tabList, btnSelectedValue, isBillingAddressSkipped, isBillingAddressAdded,btnSelectedNationalValue,btnSelPacValue,selectedStateListIds } = props;
    const handlePricingDetails = (ft) => {
        try {
            let filterSelBtn = btnSelectedValue?.filter(hh => hh[ft?.name])[0];
            let selBtnVal = filterSelBtn[ft?.name];
            let filPriceDetails = priceDetails?.filter(hh => hh[ft?.name])[0];
            return filPriceDetails[ft?.name]?.filter(yt => yt.id === selBtnVal)[0];
        } catch (error) {
            return [];
        }
    }

    let selectedPackNameArr = btnSelPacValue.filter(dt => dt[tabIndValues]);
    let selectedpackName = selectedPackNameArr?.length > 0 ? selectedPackNameArr[0][tabIndValues] : "";
    
    let selectedPackNationalValArr = btnSelectedNationalValue?.filter(dt => dt[tabIndValues]);
    let selectedpackNationalValue = selectedPackNationalValArr?.length > 0 ? selectedPackNationalValArr[0][tabIndValues] : "";
    
    let finalList = [];
    let selectedState = unlimitedStateDataList[tabIndValues]?.map(hy => 
      selectedStateListIds[tabIndValues]?.map(gt => {
        if (gt.includes(hy.id)) {
          finalList.push(hy.name);
        }
        return "";
      })
    );
    
    return (
        <div className='stepper-fields step-three'>
            <form className='custom-form'>
                <Box sx={{ flexGrow: 1 }} >
                    <Typography level="h4"><span className='step-circle'>1</span>Basic Information</Typography>
                    <Grid container spacing={3} className='mt-0 basic-details' >
                    {Object.entries(textFieldBasicBillInfoVariables).map(([key, val]) => (formData[val] ? (
                          <Grid item xs={6} key={key}>
                            <div align="left" className='create-label-values'>
                              <div className='create-label'>{key + ": "}</div>
                              <div className='create-values'>{formData[val]}</div>
                            </div>
                          </Grid>
                        ) : null
                      ))}
                    </Grid>
                    <Typography level="h4"><span className='step-circle'>2</span>Product Details</Typography>
                    <Grid container spacing={1} className='mt-0 product-details' >
                        {tabList.length !== 0 ? tabList.filter(tab => tab.name === tabIndValues).map(ft => { 
                            let selPriceVal = handlePricingDetails(ft);
                            return (<>
                                <Grid item xs={4}>
                                    <div className='product-details-list' key={ft.productId}>
                                        <div align="left" className='create-label-values'> <div className='create-label'>Product Name:</div> <div className='create-values'>{ft.name}</div></div>
                                        <div align="left" className='create-label-values'>
                                             <div className='create-label'>Selected Subscription</div>
                                              <div className='create-values'> 
                                              {selectedpackName}
                                              </div></div>
                                              {!selectedpackNationalValue ? "" : 
                                              <div align="left" className='create-label-values'>
                                             <div className='create-label'>Selected Subscription Type</div>
                                              <div className='create-values'>{selectedpackNationalValue}</div></div>
                        }
                        {finalList.length > 0 &&  
                        <div align="left" className='create-label-values'>
                        <div className='create-label'>Selected State</div>
                         <div className='create-values'>{finalList?.toString()?.replaceAll(",", ", ")}</div></div>
                        }
                                        <div align="left" className='create-label-values'> <div className='create-label'>Selected Package Details:</div> <div className='create-values'>
                                            <span>
                                                Subscription for <br /> 
                                                <b>{formattedAmount(selPriceVal.price, selPriceVal.currency, selPriceVal.currencyCode)}</b>/
                                                <span>{selPriceVal?.frequency}</span>
                                            </span>
                                        </div></div>
                                    </div>
                                </Grid>
                            </>)
                        })
                            : <span></span>}
                    </Grid>
                    <Typography level="h4" style={{ marginTop: '15px' }} className="mb-5"><span className='step-circle'>3</span>{!isBillingAddressSkipped ? "Add Billing Address" : "Skipped Billing Address"}
                    {isBillingAddressSkipped && !isBillingAddressAdded && (
                        <Button className="skip-btn" onClick={() => handleAddBillingAddress()}>
                        Add Billing
                        </Button>
                    )}
                     {!isBillingAddressSkipped && !isBillingAddressAdded && (
                        <Button className="skip-btn" onClick={() => handleAddBillingAddress()}>
                        Add Billing
                        </Button>
                    )}
                    {!isBillingAddressSkipped && isBillingAddressAdded && (
                        <Button className="skip-btn" onClick={() => handleSkipBillingAddress()}>
                        Skip
                        </Button>
                    )}
                    {isBillingAddressSkipped && isBillingAddressAdded && (
                        <Button className="skip-btn" onClick={() => handleSkipBillingAddress()}>
                          Skip
                        </Button>
                    )}
                    </Typography>
                    {isBillingAddressAdded ? <Grid container spacing={3} className='mt-0' >
                        {Object.entries(textFieldAddBasicInfoVariables).map(([key, val]) => (
                            <Grid item xs={6}>
                                {val === "thridStepbillingCountry" ?
                                    <FormControl required className='custom-fields' sx={{ minWidth: 240 }}>
                                        <InputLabel id="demo-simple-select-required-label">{key}</InputLabel>
                                        <Select
                                            name={val}
                                            labelId="demo-simple-select-required-label"
                                            id="demo-simple-select-required"
                                            value={countryId}
                                            label={key}
                                            onChange={handleChange}
                                            required
                                            className='prd-text-fld'
                                        >
                                            <MenuItem value="">Select Country</MenuItem>
                                            {countryData && countryData.length > 0 && countryData.map((ft, index) =>
                                                <MenuItem key={index} value={ft.id}>{ft.name}</MenuItem>
                                            )}
                                        </Select>
                                        <FormHelperText className='Mui-error'>{errors[val]}</FormHelperText>
                                    </FormControl  >
                                    :
                                    val === "thridStepbillingState" ?
                                        <FormControl required className='custom-fields' sx={{ minWidth: 240 }}>
                                            <InputLabel id="demo-simple-select-required-label">{key}</InputLabel>
                                            <Select
                                                name={val}
                                                labelId="demo-simple-select-required-label"
                                                id="demo-simple-select-required"
                                                value={stateId}
                                                label={key}
                                                onChange={handleChange}
                                                disabled={disable}
                                                required
                                                className='prd-text-fld'
                                            >
                                                <MenuItem value="">Select State</MenuItem>
                                                {stateData && stateData.length > 0 && stateData.map((ft, index) =>
                                                    <MenuItem key={index} value={ft.id}>{ft.name}</MenuItem>
                                                )}
                                            </Select>
                                            <FormHelperText className='Mui-error'>{errors[val]}</FormHelperText>
                                        </FormControl  >
                                        :
                                        <TextField
                                            name={val}
                                            label={key}
                                            type="text"
                                            fullWidth
                                            value={thridStepData && thridStepData[val]}
                                            onChange={handleChange}
                                            error={!!errors[val]}
                                            helperText={errors[val]}
                                            required={val !== "thridStepbillingAddress2" ? true : false}
                                            className='custom-fields'
                                        />
                                }
                            </Grid>
                        ))}
                    </Grid> : null}
                </Box>
            </form>
        </div>
    )
}

export default StepperThree
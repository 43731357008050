import { useState, memo, useEffect } from "react";
import { Box, Tab } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import {api} from "../../../../services/api"
import Subscriptions from "./SubscriptionTab";
import Invoices from "./InvoiceTab"
import { CommonTabPanel } from "../../commonFunctionalities/CommonTabPanel";
import {  useNavigate } from "react-router-dom";
import { handlingCatchErorr } from '../../../utils/errorHandling';

const SubscriptionHome = () => {
  const navigate = useNavigate();


  const [tabValues, setTabValues] = useState('1');
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderTwo, setLoaderTwo] = useState(false);

  let initialProductLoad = () => {
    setLoader(true);
    api.get("account/subscriptions")
      .then((res) => {
        setSubscriptionData(res.data.subscribed);
        setLoader(false);
      })
      .catch((err) => {
        handlingCatchErorr(err, navigate);
        setLoader(false);
      });
  };
  let initialInvoiceLoad = () => {
    setLoaderTwo(true);
    api.get("subscription/invoice")
      .then((res) => {
        setInvoiceData(res.data);
        setLoaderTwo(false);
      })
      .catch((err) => {
        handlingCatchErorr(err, navigate);
        setLoaderTwo(false);
      });
  };

  useEffect(() => {
    if (!subscriptionData.length) initialProductLoad();
  }, []);

  useEffect(() => {
    if (!invoiceData.length && tabValues === "2") handleInvoice();
  }, [tabValues]);

  useEffect(() => {
    const storedTab = localStorage.getItem('lastVisitedTab');
    if (storedTab) {
      setTabValues(storedTab);
    } else {
      setTabValues('1');
    }
  }, []);


  useEffect(() => {
    localStorage.setItem('lastVisitedTab', tabValues);
  }, [tabValues]);

  const handleChange = (e, value) => {
    setTabValues(value);
  };

  const handleInvoice = () => {
    initialInvoiceLoad()
  }
  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }} className="subscriptions-invoice tab-design">
        <TabContext value={tabValues}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="sub-invoice-tab tab-box">
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="subscriptions" value="1" />
              <Tab label="invoices" value="2" />
              {/* <Tab label="invoices" value="2" onClick={() => handleInvoice()} /> */}
            </TabList>
          </Box>
          <CommonTabPanel value="1"><Subscriptions load={loader} subTabData={subscriptionData} productLoad={initialProductLoad} invoiceLoad={initialInvoiceLoad}/></CommonTabPanel>
          <CommonTabPanel value="2"><Invoices load={loaderTwo} invoiceTabData={invoiceData} /></CommonTabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default memo(SubscriptionHome);
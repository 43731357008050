import React, { Fragment, useEffect, useState } from 'react';
import {  Box, Tab, TextField } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import Reports from './Reports';
import UserManagement from './UserManagement';
import { CommonTabPanel } from '../../commonFunctionalities/CommonTabPanel';
import CreateUserModal from '../../modals/adminModules/CreateUserModal';
import { useNavigate } from 'react-router-dom';
import {api} from '../../../../services/api';
import { handlingCatchErorr } from '../../../utils/errorHandling';
import { handleRequestSort } from '../../commonFunctionalities/CommonMuiSortingFunc';
import { CommonSearchFunction } from '../../commonFunctionalities/CommonSearchFunction';

const AdministrationDashboard = () => {
    const [isEnterpriseDropDownData, setIsEnterpriseDropDownData] = useState([]);

    const [tabValues, setTabValues] = useState('1');
    const [loader, setLoader] = useState(false);
    const [loaderTwo, setLoaderTwo] = useState(false);
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [usersReports, setUsersReports] = useState([]);
    const [usersSearchedData, setUsersSearchedData] = useState([]);
    const [state, setState] = useState({ order: 'asc', orderby: "createdDate" });
    const [searchedText, setSearchedText] = useState("");
    const [emailError, setEmailError] = useState(false)
    const [dateError, setDateError] = useState(false)
    const [usersList, setUsersList] = useState("");
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [page, setPage] = useState(0);
    const [status,setStatus] = useState("")
    const handleChange = (e) => {
        let { name, value } = e.target;
        if (name === "searchField") {
            setSearchedText(value);
            setPage(0);
        }
    };
    const handleFromDateChange = (event) => {
        const selectedFromDate = event.target.value;
        setFromDate(selectedFromDate);        
        if (selectedFromDate && toDate) {
          const from = new Date(selectedFromDate);
          const to = new Date(toDate);        
          setDateError(to < from);
        }
      };

    const handleToDateChange = (event) => {
        const selectedToDate = event.target.value;
        setToDate(selectedToDate);      
        if (fromDate && selectedToDate) {
          const from = new Date(fromDate);
          const to = new Date(selectedToDate);      
          setDateError(to < from);
        }
      };
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const currentMonthStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
    const lastMonthStartDate = new Date(lastMonthEndDate.getFullYear(), lastMonthEndDate.getMonth(), 1);
    const lastMonthStartISO = lastMonthStartDate.toISOString().split('T')[0];
    const yesterdayISO = yesterday.toISOString().split('T')[0];
    const todayISO = today.toISOString().split('T')[0];

    const handleTabChange = (e, val) => {
        if (val !== undefined) setTabValues(val);
    };

    let handleLoader = (isTrueOrFalse) => {
        setLoaderTwo(isTrueOrFalse);
    }
    let initialUserManagementLoad = (enterpriseusers) => {
        setLoader(true);
        api.get("admin/users")
            .then((res) => {
                setStatus(res.status)
                setUsers(res.data)
                setUsersData(res.data?.userList);
                setUsersSearchedData(res.data.userList);
                if(enterpriseusers === "callenter") {
                    callEnterpriseLookupApi ();
                }
                
                setLoader(false);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
                setLoader(false);
            });
    };
    const fetchReportData = () => {
        setLoader(true);
        api.post("Admin/users/reports", {
            username: usersList !== '' ? usersList : null,
            fromDate: fromDate !== '' ? fromDate : null,
            toDate: toDate !== '' ? toDate : null
        })
          .then(response => {
            setUsersReports(response.data);
            setLoader(false);
          })
          .catch(error => {
            console.error('Error fetching report data:', error);
            setLoader(false);
          });
      };

    useEffect(() => {
        initialUserManagementLoad();
        fetchReportData()
    }, []);

    useEffect(() => {
        const searchedFieldsString = ["email","firstname","lastname"];
        let { data, isEmptyOrNot } = CommonSearchFunction(searchedText, usersSearchedData, searchedFieldsString);
        if (isEmptyOrNot === "notEmpty") setUsersData(data);
        else setUsersData(usersSearchedData);
        
    }, [searchedText]);

    const handleSortByCreatedDate = (load) => {
        if (load === "initialCreateUserLoad") handleRequestSortPreCall("firstname");
        else handleRequestSortPreCall("createdDate", "desc");
    }
    const handleRequestSortPreCall = (property, orderAlternate) => {
        const { order, orderBy } = state;
        let { sort, prop } = handleRequestSort(property, order, orderBy, orderAlternate);
        setState({ ...state, order: sort, orderBy: prop });
    };
    const handleTextFieldChange = (event) => {
        const userInput = event.target.value;
        if (userInput.trim().length > 50 || userInput.trim().length === 0) {
          setEmailError(true);
        } else {
          setEmailError(false);
          setUsersList(userInput.trim());
        }
      };
    useEffect(() => {
    if (usersList !== '' || fromDate !== '' || toDate !== '') {
        setButtonDisabled(false);
    } else {
        setButtonDisabled(true);
    }
    }, [usersList, fromDate, toDate]);

    const callEnterpriseLookupApi = () => {
        api.get("lookup/enterpriseusers")
            .then((res) => {
                const sortedData = res?.data.slice().sort((a, b) => {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();

                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                  });
                setIsEnterpriseDropDownData(sortedData);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
            });
    }

    return (
        <Fragment>
            {(loader || loaderTwo) && <div className="loader-wrap"><div className="loading"></div></div>}
            <Box sx={{ width: '100%', typography: 'body1' }} className="tab-design">
                <TabContext value={tabValues}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="tab-box">
                        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                            <Tab label="User Management" value="1" />
                            <Tab label="Reports" value="2" />
                            {tabValues === "1" &&
                                <div className='search_btn_align_right'>
                                    <TextField
                                        name={"searchField"}
                                        placeholder='Search'
                                        type="text"
                                        value={searchedText}
                                        onChange={handleChange}
                                        className='custom-fields user-search-field admin-searchbox'
                                    />
                                    <CreateUserModal callEnterpriseLookupApi={callEnterpriseLookupApi}  isEnterpriseDropDownData = {isEnterpriseDropDownData} initialUserManagementLoad={initialUserManagementLoad} handleSortByCreatedDate={handleSortByCreatedDate} />
                                </div>
                            }                        
                        </TabList>
                    </Box>                    
                    <CommonTabPanel value="1"><UserManagement users={users} status={status} loaderTwo ={loaderTwo} loader={loader} usersData={usersData} state={state} handleRequestSortPreCall={handleRequestSortPreCall} initialUserManagementLoad={initialUserManagementLoad} handleLoader={handleLoader} page={page} setPage={setPage} /></CommonTabPanel>
                    <CommonTabPanel value="2"><Reports state={state} emailError={emailError} dateError={dateError} usersReports={usersReports} usersData={usersData} handleTextFieldChange={handleTextFieldChange} handleRequestSortPreCall={handleRequestSortPreCall} usersList={usersList} handleFromDateChange={handleFromDateChange} handleToDateChange={handleToDateChange} fetchReportData={fetchReportData} todayISO={todayISO} yesterdayISO={yesterdayISO} buttonDisabled={buttonDisabled} lastMonthStartISO={lastMonthStartISO}  /></CommonTabPanel>
                </TabContext>
            </Box>
        </Fragment>
    )
}

export default AdministrationDashboard
import { Card, CardActions, CardContent, Chip, Typography } from '@mui/material';
import { Grid } from '@mui/material'
import React, { memo, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { paymentTerm, Types } from '../../../utils/enums';
import { api } from '../../../../services/api';
import { handlingCatchErorr } from '../../../utils/errorHandling';

const SubscribeOrAvailable = (props) => {
    let { productDatas, isId, listAvailable, showAvailable, title } = props;
    const [secureId, setSecureId] = useState()
    const userDetails = JSON.parse(localStorage.getItem("userInfo"));
    const [signupLoader,setSignupLoader] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userInfo"));
        setSecureId(userData?.secureId)
    }, [])
    const generateModifiedUrl = (url) => {
        // Replace #sessionid# with the secureId
        return url?.replace('#sessionid#', secureId);
    };

    const CreditsComponent = (data) => {
        const creditsJSX = data?.subscription
            .filter(ty => ty.subscriptionType === Types.reports)
            .map((va, index) => (
                <div key={index}>
                    <p>
                        <b>Prepaid Reports:</b>{data.availableUnits ? data.availableUnits : "N/A"}
                        {/* {!userDetails?.impersonated &&
                            <Link to={`/pricing/${data.permalink}/${va.subscriptionkey}`}
                                state={{ key: va.subscriptionkey, count: null, id: va.subscriptionTypeId }}>
                                Buy Prepaid Reports</Link>
                        } */}
                    </p>
                </div>
            ));

        return <>{creditsJSX}</>;
    };

    const SubscripedComponent = (data, index) => {
        return (
            <p>
                <b>Subscription: </b>
                {data?.subscription && (
                    (() => {

                        const filteredAndMappedArray = data?.subscription
                            .filter(sel => sel.selected)
                            .filter(term => term?.paymentTerm === paymentTerm.free || term?.paymentTerm === paymentTerm.recurring);

                        const maxObject = (filteredAndMappedArray?.length > 0)
                            ? filteredAndMappedArray.reduce((max, current) => (max.SortOrder > current.SortOrder) ? max : current)
                            : null;

                        const SubscriptionType = filteredAndMappedArray.map((val) => val.subscriptionType)
                        const noSelected = data?.subscription
                            .filter(sel => !sel.selected)
                            .filter(term => term?.paymentTerm !== paymentTerm.free && term?.paymentTerm === paymentTerm.recurring);

                        const minNoSelected = (noSelected?.length > 0)
                            ? noSelected.reduce((min, current) => (current.SortOrder < min.SortOrder) ? current : min)
                            : null;

                        const modifyButton = data?.subscription?.some(type =>
                            type.subscriptionType === Types.national &&
                            type.subCategoryList?.some(is => is.isPremium && is.isSubscribed)
                        )
                        const subCategoryLists = filteredAndMappedArray?.map((val) => {
                            // Check if val.subCategoryList exists before applying filter
                            if (val?.subCategoryList) {
                                return val.subCategoryList.filter((item, priceIndex, self) =>
                                    item.subscriptionPriceName !== "" &&
                                    item.isSubscribed &&
                                    self.findIndex((v) => v.subscriptionPriceName === item.subscriptionPriceName) === priceIndex
                                );
                            }
                            // If val.subCategoryList is undefined or null, return an empty array
                            return [];
                        });

                        // Flatten the array of arrays
                        const flattenedArray = subCategoryLists?.flat();

                        // Check if data is selected and there are no free or recurring subscriptions that are not selected
                        const hasNoUnselectedFreeOrRecurring = data.selected &&
                            data?.subscription.filter(term => term?.paymentTerm === paymentTerm.free || term?.paymentTerm === paymentTerm.recurring)
                                .every(sel => !sel.selected);

                        // Check if there are any selected One-Time subscriptions
                        const hasSelectedOneTime = data?.subscription.filter(term => term?.paymentTerm === "One-Time")
                            .some(sel => sel.selected);

                        // Combine the conditions
                        const reportOnly = hasNoUnselectedFreeOrRecurring && hasSelectedOneTime;

                        return (

                            <span className='package-list'>
                                {
                                    filteredAndMappedArray?.length > 0 ? (
                                        // Render if filteredAndMappedArray has elements
                                        filteredAndMappedArray?.map((val, key) => (
                                            <React.Fragment key={key}>
                                                {val.subscriptionName
                                                    ? flattenedArray.map((subVal) => subVal?.subscriptionPriceName).join(", ")
                                                    : val.packagename}
                                                {key !== filteredAndMappedArray.length - 1 ? ", " : ""}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <>
                                            {reportOnly && <React.Fragment>Free</React.Fragment>}
                                            {/* {!userDetails?.impersonated && ( */}
                                            <Link to={`/pricing/${data.permalink}/${minNoSelected?.subscriptionkey}`}>
                                                Upgrade
                                            </Link>
                                            {/* )} */}
                                        </>
                                    )}



                                {/* {maxObject && !data.isUnlimitedNational && (!userDetails?.impersonated && ( */}
                                {maxObject && !data.isUnlimitedNational && (
                                    <Link to={`/pricing/${data.permalink}/${SubscriptionType.includes(Types.free) ? minNoSelected?.subscriptionkey : maxObject.subscriptionkey}`}>
                                        {modifyButton ? "Modify" : "Upgrade"}

                                    </Link>

                                )
                                }

                            </span>


                        );
                    })()
                )} </p>
        )
    }

    const Credits = (data) => {
        return (
            data?.subscription?.some(type =>
                type.subscriptionType === Types.national &&
                type.subCategoryList?.some(is => is.isPremium && is.isSubscribed)
            )
            || !data.isUnlimitedNational && CreditsComponent(data)
        )
    }

    const LinkUrl = (data) => {
        return (
            data?.subscription?.some(type =>
                type.subscriptionType === Types.national &&
                type.subCategoryList?.some(is => is.isPremium && is.isSubscribed)
            )
            || !data.isUnlimitedNational && ReportText(data)
        )
    }


    const ReportText = (data) => {

        const creditsJSX = data?.subscription
            .filter(ty => ty.subscriptionType === Types.reports)
            .map((va, index) => (

                < >
                    <Link className='buy-prepaid-btn' to={`/pricing/${data.permalink}/${va.subscriptionkey}`}
                        state={{ key: va.subscriptionkey, count: null, id: va.subscriptionTypeId }}>
                        Buy Prepaid Reports</Link>


                </>
            ));

        return <>{creditsJSX}</>;

    }

    const handleFreeSignup = (id,url) =>{
        setSignupLoader(true)
        const productid = id ;
        api.post(`/subscription/subscribe/free/${productid}`)
        .then((res) => {
            if(res.data === "successs"){
                const modifiedUrl = generateModifiedUrl(url);
                window.location.replace(modifiedUrl);            
                setSignupLoader(false)
            }
             
        }).catch((err) => {
            handlingCatchErorr(err, navigate);
            setSignupLoader(false);
        });
    }

    const LinkComponent = (data, index) => {
        return (
            <>
                {title === "Subscribed" ?

                    <Link key={index} className='open-btn' to={
                        generateModifiedUrl(data.loginurl)}>
                        Open </Link>
                    :
                    (!userDetails?.impersonated &&
                        <CardActions className='subscribed-btn'>
                            <Link key={index} className='open-btn' to={`/pricing/${data.permalink}`}>
                                Sign Up </Link>
                                {data.allowFreeSignup && !data.parentProductId ? 
                                <Link key={index} className='buy-prepaid-btn' onClick = {()=>handleFreeSignup(data.productId,data.loginurl)}>
                                Try it for Free </Link>
                                : " " }
                        </CardActions>)
                }
            </>
        )
    }

    return (
        <>
        {signupLoader && <div className="loader-wrap"><div className="loading"></div> : </div>}
        <Grid container spacing={2} className={productDatas?.length === 0 && title !== "Available" ? "no-subscription" : 'subscribed-layout'}>
            {productDatas && productDatas?.length > 0 ?

                (isId === "notParentId" && showAvailable > 0 ? productDatas.filter(isId => isId.parentProductId === null) :
                    productDatas)
                    .map((data, index) => (
                        <Grid key={index} item xs={12} md={3} xl={3} className={data.asset === "Vehicle, RV & Boat Storage" ? "vehicle-grid" : ""}>
                            <Card className='card-style'>
                                <div className='logo-box-tagline'>
                                    <img src={(`/${data?.imageName}`)} alt="logo" />
                                    <div className="tagline-logo">{data.asset}</div>
                                </div>
                                <CardContent>
                                    <h4>
                                        {data.subtitle}
                                    </h4>
                                    <div className='data-description'>
                                        {data.description}
                                    </div>
                                    {title === "Available" && <Typography className='available_subscription_title'>Available Subscription: </Typography>}
                                    <div className={title === "Subscribed" ? "package-details" : "available-subscription"}>

                                        {title === 'Subscribed' && SubscripedComponent(data, index)}

                                        {title === "Available" &&
                                            (data?.subscription.map((detail, index) => {
                                                return (
                                                    <>
                                                        <span key={index}>
                                                            <Chip
                                                                sx={{
                                                                    m: 0.2,
                                                                }}
                                                                key={index}
                                                                label={detail.packagename}
                                                            />
                                                        </span>
                                                    </>

                                                );

                                            }

                                            ))
                                        }
                                        {title === 'Subscribed' && Credits(data)}


                                    </div>
                                    <CardActions className='subscribed-btn'>
                                        {LinkComponent(data, index)}
                                        {title === 'Subscribed' && LinkUrl(data)}
                                    </CardActions>
                                </CardContent>


                            </Card>
                        </Grid >
                    ))
                : title === "Subscribed" ? (
                    <p>
                        None
                    </p>
                ) : title === "Available" ? (
                    // <p>
                    //     None

                    // </p>
                    null
                ) : null // Handle other cases or provide a default case if needed
            }
            {title === "Subscribed" ?
                isId === "parent" && listAvailable && listAvailable.length > 0 &&
                listAvailable.map((datas, index) => (
                    <Grid key={index} item xs={12} md={3} xl={3} className={datas.asset === "Vehicle, RV & Boat Storage" ? "vehicle-grid" : ""}>
                        <Card className='card-style'>
                            {/* <div className='beta'>
                                <span>Beta</span>
                            </div> */}
                            <div className='logo-box-tagline'>
                                <img src={(`/${datas?.imageName}`)} alt="logo" />
                                <div className="tagline-logo">{datas.asset}</div>
                            </div>
                            <CardContent>
                                <h4>{datas.subtitle}</h4>
                                <div className='data-description'>{datas.description}</div>
                                {productDatas && productDatas?.length > 0 && productDatas
                                    .filter(id => id.productId === datas.parentProductId).map((data, index) => (
                                        <>
                                            <div className={title === "Subscribed" ? "package-details" : "available-subscription"}>
                                                {SubscripedComponent(data, index)}
                                                {Credits(data)}
                                            </div>
                                            <CardActions className='subscribed-btn'>
                                                {LinkComponent(datas, index)}
                                                {title === 'Subscribed' && LinkUrl(data)}
                                            </CardActions>
                                        </>
                                    ))}
                            </CardContent>
                        </Card>
                    </Grid>
                ))
                : ""}

        </Grid >
        </>
    )
}

export default memo(SubscribeOrAvailable)
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import { AppBar, Box, Toolbar, Typography, Container } from '@mui/material';
import { useState } from 'react';
import LogoImg from "../../../src/assets/images/suite_logo.png";

const drawerWidth = 240;

export default function LogoBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };


  const container = window !== undefined ? () => window().document.body : undefined;





  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} className="navigation-menu">
      <Divider />

    </Box>
  );


  return (
    <Box sx={{ display: 'flex' }} className="sticky-header">
      <AppBar component="nav" className="header-section">
        <Container maxWidth="xl" className='header-container'>
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { lg: 'flex' } }}
              className="suite-logo"
            >
              <img src={LogoImg} alt='MIS' />
            </Typography>
            <Box sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }} className="navigation-menu">
            </Box>
           
          </Toolbar>
        </Container>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'block', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

    </Box>
  );
}


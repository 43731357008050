import { Button, Modal, ModalClose, Sheet } from '@mui/joy';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Grid, Stepper, Step, StepLabel, Box } from '@mui/material';
import { AddCircleOutlineOutlined, ArrowForwardIosRounded, ArrowBackIosRounded } from '@mui/icons-material';
import StepperOne from './createUserStepper/StepperOne';
import StepperTwo from './createUserStepper/StepperTwo';
import StepperThree from './createUserStepper/StepperThree';
import { validationFunction } from '../../commonFunctionalities/AllValidation';
import {api} from '../../../../services/api';
import { handlingCatchErorr, handlingSuccessResponse } from '../../../utils/errorHandling';
import { useNavigate } from 'react-router-dom';
import { Types, mrkPackages, paymentMode} from '../../../utils/enums';
import CommonForAll from '../commonModals/CommonForAll';

const offlineSteps = ['Basic Info', 'User Details'];
const onlineSteps = ['Billing Details'];
const steps = [...offlineSteps, ...onlineSteps];
const chkSubsBtn = "checkSubscriptionBtn";
const selPac = "SelPacDet";
const MaxStateText ="yes"

let initialFormData = { fName: '', lName: '', company: '', stpOnePhone: '', email: '', isChecked: false, isEnterpriceChecked: false };
let initialSecondStepData = { subscriptionId: '' };
let initialThridStepData = { "thridStepbillingAddress": '', "thridStepbillingAddress2": '', "thridStepbillingCity": '', "thridStepbillingCountry": '', "thridStepbillingState": '', "thridStepbillingZipCode": '' };
export default function CreateUserModal(props) {
    let { initialUserManagementLoad, handleSortByCreatedDate,callEnterpriseLookupApi,isEnterpriseDropDownData } = props;
    const navigate = useNavigate();
    const isFirstRender = useRef(true);
    const [open, setOpen] = useState(false);
    const [isNextStep, setIsNextStep] = useState(false);
    const [formData, setFormData] = useState(initialFormData);
    const [paymentRadioButtons, setPaymentRadioButtons] = useState([]);
    const [secondStepData, setSecondStepData] = useState(initialSecondStepData);
    const [thridStepData, setThridStepData] = useState(initialThridStepData);
    const [productsDropDownData, setProductsDropDownData] = useState([]);
    const [productId, setProductId] = useState([]);
    const [selectedStateListIds, setSelectedStateListIds] = useState({});
    const [selectedCountryListIds, setSelectedCountryListIds] = useState({});
    const [countryIds,setCountryIds] = useState([]);  
    const [tabList, setTabList] = useState([]);
    const [isEnterpriseUserId, setIsEnterpriseUserId] = useState("");
    const [selectPackageMethod, setSelectPackageMethod] = useState("");
    const [errors, setErrors] = useState({});
    const [activeStepCount, setActiveStepCount] = useState(0);
    const [skip, setSkip] = useState(new Set());
    const [unlimitedStateDataList, setUnlimitedStateDataList] = useState([]);
    const [countryId, setCountryId] = useState("");
    const [stateId, setStateId] = useState("");
    const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [disable, setDisable] = useState(true);
    const [loader, setLoader] = useState(false);
    const [btnSelectedValue, setBtnSelectedValue] = useState([]);
    const [btnSelectedNationalValue, setBtnSelectedNationalValue] = useState([]);
    const [btnSelPacValue, setBtnSelPacValue] = useState([]);
    const [priceDetails, setPriceDetails] = useState([]);
    const [valOffOnRadioBtn, setValOffOnRadioBtn] = useState({});
    const [subscriptionData, setSubscriptionData] = useState({});
    const [tabIndValues, setIndTabValues] = useState("");
    const [checkBoxDisable, setCheckBoxDisable] = useState(false);
    const [isBillingAddressSkipped, setIsBillingAddressSkipped] = useState(false);
    const [isBillingAddressAdded, setIsBillingAddressAdded] = useState(true);
    const [subsIdNSubsName, setSubsIdNSubsName] = useState({});
    const [status, setStatus] = useState(false);
    const [id,setId] = useState("");
    const [maxState, setMaxState] = useState(false);
    const [maxStateHeading, setMaxStateHeading] = useState("");
    const [hasMadeSelection, setHasMadeSelection] = useState(false);
    const [newState,setNewState] = useState ([])
    const [pymtEntities, setPymtEntities] = useState([]);
    const [selectedPymtEntity,setSelectedPymtEntity]=useState([]);
    const [selectedPayId,setSelectedPayId]  = useState("");
    const [payDet,setPayDet] = useState({});
    const [selectedPaymentId,setSelectedPaymentId] = useState([])

    

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return
        }
        if (isNextStep) postValidateOnNext();
    }, [errors])
    useEffect(() => {
        allLookUpApi();
    }, [])

    const handlePrevDataOnCheckBoxChnages = (convertSelectedBtnValueToObj, filteredNames) => {
        let convertValueToObj = Object.assign({}, ...convertSelectedBtnValueToObj)
        let updatedSelectedVal = filteredNames.map(ft => {
            if (convertValueToObj[ft.name] === undefined) {
                return { [ft.name]: "" };
            } else {
                return { [ft.name]: convertValueToObj[ft.name] };
            }
        });
        return updatedSelectedVal;
    }
    let stripeApi = (permaLink, productName, isFirstCall, filteredNames) => {
        setCheckBoxDisable(true);
        api.get(`Payment/stripeentities/${permaLink}`)
            .then((res) => {
                debugger;
                let stripeEntities = res.data;
                setPymtEntities(res.data);
                setCheckBoxDisable(false);
                let newPayment = { [productName]: res.data };
                let allSubscriptionData = {...payDet, ...newPayment };
                if (stripeEntities.length == 1) {
                    setSelectedPymtEntity(stripeEntities[0]);
                    // alert(stripeEntities);
                    subscriptionApi(permaLink, productName, isFirstCall, filteredNames, stripeEntities[0].id);
                }
                else
                {
                    setSelectedPymtEntity(stripeEntities);
                    // subscriptionApi(permaLink, productName, isFirstCall, filteredNames);
                }
                setPayDet(allSubscriptionData)
            })
            .catch((err) => {
                setCheckBoxDisable(false);
                handlingCatchErorr(err, navigate);
            });
    }
    const handleProdSubCheckChange = (e, id, productName) => {
        if (e.target.name === "products") {
            let searchApiValues = productsDropDownData.filter(ft => ft.name === productName)[0];
            let resultArray = [];
            if (e.target.checked) {
                resultArray = productId?.filter(CheckedId =>
                    CheckedId !== id
                )
                resultArray.push(id)
            }
            else {
                if (isNextStep) setIsNextStep(!isNextStep);
                resultArray = productId?.filter(CheckedId => CheckedId !== id);
               
                let uncheckedProductName = productsDropDownData.filter(gt => gt.productId === id)[0]?.name;
                
                errorMessageFunc(`${uncheckedProductName}${chkSubsBtn}`, "")
                errorMessageFunc(`${uncheckedProductName}${selPac}`, "")
            }
            let filteredNames = productsDropDownData.filter(ft => resultArray.includes(ft.productId) && ft.name);
            let filteredOnlyNames = filteredNames.map(dt => dt.name);
            debugger;
            if (subscriptionData[productName] === undefined) 
                stripeApi(searchApiValues.permalink, searchApiValues.name, "no", filteredNames);
            else {
                
                setBtnSelectedValue(handlePrevDataOnCheckBoxChnages(btnSelectedValue, filteredNames));
                setBtnSelectedNationalValue(handlePrevDataOnCheckBoxChnages(btnSelectedNationalValue, filteredNames));
                setBtnSelPacValue(handlePrevDataOnCheckBoxChnages(btnSelPacValue, filteredNames));
                setPriceDetails(handlePrevDataOnCheckBoxChnages(priceDetails, filteredNames));
            }
            setProductId(resultArray)
            setTabList(filteredNames);
            if (!filteredOnlyNames.includes(tabIndValues)) setIndTabValues(filteredOnlyNames[0]);
            let initialValOffOnRadioBtn = filteredNames.map(ft => { return { [ft.name]: [{ "selectedPayment": paymentMode.offline }, { "subscriptionId": "" }] } });
            Object.keys(valOffOnRadioBtn).map(ft => {
                if (!filteredOnlyNames.includes(ft)) {
                    valOffOnRadioBtn[ft][0].selectedPayment = paymentMode.offline;
                    valOffOnRadioBtn[ft][1].subscriptionId = "";
                };
                return "";
            });
            let convertToObj = Object.assign({}, ...initialValOffOnRadioBtn);
            setValOffOnRadioBtn({ ...convertToObj, ...valOffOnRadioBtn });
        } else if (e.target.name === "selStateList") {
            let resultArray = [];
            if (e.target.checked && selectedStateListIds[tabIndValues]?.length <= 6) {
                resultArray = selectedStateListIds[tabIndValues]?.filter(CheckedId =>
                    CheckedId !== id
                )
                resultArray.push(id);
            }
            else {
                resultArray = selectedStateListIds[tabIndValues]?.filter(CheckedId =>
                    CheckedId !== id
                )
            };   
            setSelectedStateListIds({ ...selectedStateListIds, [tabIndValues]: resultArray });
            if (selectedStateListIds[tabIndValues]?.length  >= 7 && resultArray.length >=7) {
                setMaxState(true)
            }
           
        }
        
    };


    const handleStateOrCountryModalCLose = (steOrCnt, saveOrNot) => {
        // if (saveOrNot === "notSave") {
            
        //     // setPriceDetails(commonForBtnChanges([], priceDetails));
        //     // setBtnSelectedValue(commonForBtnChanges("", btnSelectedValue));
        //     if (saveOrNot === "notSave" &&  steOrCnt === "state" && selectedStateListIds.lengh<0 ) setSelectedStateListIds({ ...selectedStateListIds, [tabIndValues]: [] });
        //     else if (steOrCnt === "country") {
        //         setBtnSelectedNationalValue(commonForBtnChanges("", btnSelectedNationalValue));
        //         errorMessageFunc(`${tabIndValues}${chkSubsBtn}`, "");
        //     };
        // } else {
        //     if (steOrCnt === "national") loadPriceDetails(" ", "", "", "nationalCall");
        //     else loadPriceDetails();
        // }
        // if (saveOrNot !== "Save") {
        //     setBtnSelectedValue(commonForBtnChanges("", btnSelectedValue));
          

        // } 
        setBtnSelectedValue(commonForBtnChanges("", btnSelectedValue));
          
        if (saveOrNot === "notSave") {
           
            setBtnSelectedNationalValue(commonForBtnChanges("", btnSelectedNationalValue));
            if (steOrCnt === "national") {
                loadPriceDetails(" ", "", "", "nationalCall");
              
            }
            else  if (steOrCnt === "state" ) {
                setNewState(newState)
                setSelectedStateListIds({...newState})
            }
        }
        else if (saveOrNot === "Save") {
            setNewState({...selectedStateListIds})
            loadPriceDetails()
            
        }

   
    }

    const handleTabChange = (e, value) => {
        setSelectedPaymentId({...selectedPaymentId})
        setIndTabValues(value);
    };
    
    const handleSelectChange = (selectedOption) => {
        if (selectedOption === null || selectedOption === undefined) setIsEnterpriseUserId("");
        else setIsEnterpriseUserId(selectedOption);
    }
    const handleChange = (e) => {
        if (isNextStep) setIsNextStep(!isNextStep);
        const { name } = e.target;

        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
      
        if (name !== undefined) {
            if (name === "thridStepbillingCountry") {
                setCountryId(value)
                setStateId("")
                stateLookUpApi(value);
            }
            else if (name === "thridStepbillingState") setStateId(value);
            else if (name === "isEnterpriceChecked") {
                if (!value) setErrors((prevData) => ({ ...prevData, "isEnterpriseUserDropDown": "" }));
                setFormData((prevData) => ({ ...prevData, [name]: value }));
            }
           
            else if (name === "selectPayment") {
                valOffOnRadioBtn[tabIndValues][0].selectedPayment = value;
                if (valOffOnRadioBtn[tabIndValues][0].selectedPayment !==  paymentMode.custom) {
                    valOffOnRadioBtn[tabIndValues][1].subscriptionId = "";
                };
            }
            else if (name === "selectPackage") setSelectPackageMethod(value)
            else if (name === "subscriptionId") {
                valOffOnRadioBtn[tabIndValues][1].subscriptionId = value;
            }
            else if (name === "thridStepconPass") {
                setThridStepData((prevData) => ({ ...prevData, "thridStepconPass": value }));
                let errorsMsg = "";
                if (value.length === 0) errorsMsg = 'Confirm Password is required';
                else if (thridStepData.thridSteppass !== value) errorsMsg = 'Passwords do not match';
                errorMessageFunc("thridStepconPass", errorsMsg);
            }
            else if (name.startsWith("thridStep")) setThridStepData((prevData) => ({ ...prevData, [name]: value }));
            else if (name === "stpOnePhone") {
                let returnedInfo = validationFunction(name, value);
                setFormData((prevData) => ({ ...prevData, [name]: returnedInfo?.phoneData }));
                errorMessageFunc(name, returnedInfo?.errMsg);
            }
            else setFormData((prevData) => ({ ...prevData, [name]: value }));
            if (name !== "thridStepconPass") {
                let returnedInfo = validationFunction(name, value);
                errorMessageFunc(name, returnedInfo);
            }
        }
    };

    const commonForBtnChanges = (handledValue, arr) => {
        let arrPush = [];
        for (let i = 0; i < arr.length; i++) {
            Object.entries(arr[i]).map(([key, val]) => {
                if (key.toString() === tabIndValues) arrPush.push({ [key]: handledValue });
                else arrPush.push(arr[i]);
                return '';
            });
        };
        return arrPush;
    }
    let loadPriceDetails = (key, count, typeId, whatCall) => {
        setPriceDetails(commonForBtnChanges([], priceDetails));
        let getPrice = {};
        if (whatCall === "directCall") {
            getPrice = {
                "subscriptionKey": key,
                "minCount": count,
                "subscriptionTypeId": typeId,
                "StripeEntityId":  selectedPayId ? selectedPayId : 1
                
            };
        } else if (whatCall === "nationalCall") {
            getPrice = {
                "subscriptionKey": subsIdNSubsName.subsKey,
                "minCount": null,
                "subscriptionTypeId": subsIdNSubsName.subsId,
                "StripeEntityId":  selectedPayId ? selectedPayId: 1
            };

        } else {
            getPrice = {
                "subscriptionKey": subsIdNSubsName.subsKey,
                "minCount": selectedStateListIds[tabIndValues]?.length,
                "subscriptionTypeId": subsIdNSubsName.subsId,    
                "StripeEntityId":  selectedPayId ? selectedPayId : 1

            };
        }
        setCheckBoxDisable(true);
        api.post('/Product/subscription/prices', getPrice)
            .then((res) => {
                const sortedPriceDetails = res.data.sort((a, b) => a.sortOrder - b.sortOrder);
                setPriceDetails(commonForBtnChanges(sortedPriceDetails, priceDetails));
                setCheckBoxDisable(false);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
                setCheckBoxDisable(false);
            });
    }
    let handleSubscriptionBtnChange = (value, subsName, subsId, subsKey, pacDet,id) => {
        setMaxStateHeading(subsName)
        if (isNextStep) setIsNextStep(!isNextStep);
        setId(id)
        if (pacDet === "SelPacDet") {
            let returnedErrors = validationFunction(`${tabIndValues}${selPac}`, subsName);
            errorMessageFunc(`${tabIndValues}${selPac}`, returnedErrors);
            setBtnSelectedNationalValue(commonForBtnChanges("", btnSelectedNationalValue));
            setSelectedStateListIds({ ...selectedStateListIds, [tabIndValues]: [] });
            setNewState({ ...selectedStateListIds, [tabIndValues]: [] });
            if (subsName !== btnSelPacValue.map(gt => gt[tabIndValues])[0]) {
                setBtnSelPacValue(commonForBtnChanges(subsName, btnSelPacValue));
                if (subsName === Types.basic || subsName === Types.reports) {
                    loadPriceDetails(subsKey, null, subsId, "directCall");
                } else {
                    setSubsIdNSubsName({ subsKey, subsId });
                }
                setPriceDetails(commonForBtnChanges([], priceDetails));
                setBtnSelectedValue(commonForBtnChanges("", btnSelectedValue));
            }
        } else if (pacDet === "national") {
            let countryIntegerArray = [];
            if (id && typeof id === 'string') {
                if (id.includes(',')) {
                    let countryIds = id.split(',');
                    countryIds.forEach(id => {
                        let parsedCountryId = parseInt(id.trim());
                        if (!isNaN(parsedCountryId)) {
                            countryIntegerArray.push(parsedCountryId);
                        }
                    });
                } else {
                    let parsedCountryId = parseInt(id.trim());
                    if (!isNaN(parsedCountryId)) {
                        countryIntegerArray.push(parsedCountryId);
                    }
                }
            }
            setCountryIds(countryIntegerArray)
            setSubsIdNSubsName({ subsKey, subsId: subsIdNSubsName.subsId });
            setBtnSelectedNationalValue(commonForBtnChanges(value, btnSelectedNationalValue));
        } else {
            let returnedErrors = validationFunction(`${tabIndValues}${chkSubsBtn}`, value);
            errorMessageFunc(`${tabIndValues}${chkSubsBtn}`, returnedErrors);
            setBtnSelectedValue(commonForBtnChanges(value, btnSelectedValue));
        }
    };

    let allLookUpApi = () => {
        callEnterpriseLookupApi();
        api.get("lookup/countries")
            .then((res) => {
                setCountryData(res.data);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
            });
        api.get("Product/products")
            .then((res) => {
                setProductsDropDownData(res?.data?.products);
                let objectWithEmptyArray = {};
                res?.data.products?.map(gt => Object.assign(objectWithEmptyArray, { [gt?.name]: [] }));
                setSelectedStateListIds(objectWithEmptyArray);
                setSelectedCountryListIds(objectWithEmptyArray);
                const objectWithEmptyString = {};
                res?.data.products?.map(gt => Object.assign(objectWithEmptyString, { [gt?.name]: "" }));
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
            });
        api.get("Lookup/modeofpayment")
            .then((res) => {
                setPaymentRadioButtons(res.data);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
            });
        
    };

    const callStatesLookupApi = (productId, productName) => {
        if (productId) {
           
            setCheckBoxDisable(true);
            api.get(`Lookup/product/states/${productId}`)
                .then((res) => {
                    let newStateCall = { [productName]: res.data };
                    let allStateData = { ...unlimitedStateDataList, ...newStateCall };
                    setUnlimitedStateDataList(allStateData);
                    setCheckBoxDisable(false);
                   
                })
                .catch((err) => {
                    setCheckBoxDisable(false);
                    handlingCatchErorr(err, navigate);
                   
                });
        }
    }

    let subscriptionApi = (permaLink, productName, isFirstCall, filteredNames,pymtId) => {
        setCheckBoxDisable(true);
        setSelectedPayId(pymtId)
        api.get(`Product/productsubscriptions/${permaLink}?pymtId=${pymtId}`)
            .then((res) => {
                callStatesLookupApi(res.data?.products?.productId, productName);
                let newProductCall = { [productName]: res.data.products };
                let allSubscriptionData = { ...subscriptionData, ...newProductCall };
                
                setBtnSelectedValue(handlePrevDataOnCheckBoxChnages(btnSelectedValue, filteredNames));
                setBtnSelectedNationalValue(handlePrevDataOnCheckBoxChnages(btnSelectedNationalValue, filteredNames));
                setBtnSelPacValue(handlePrevDataOnCheckBoxChnages(btnSelPacValue, filteredNames));
                setPriceDetails(handlePrevDataOnCheckBoxChnages(priceDetails, filteredNames));
                setSubscriptionData(allSubscriptionData);
                setCheckBoxDisable(false);
            })
            .catch((err) => {
                setCheckBoxDisable(false);
                handlingCatchErorr(err, navigate);
            });
    }

    let stateLookUpApi = (value) => {
        if (value) {
            setDisable(true);
            api.get(`lookup/states/${value}`)
                .then((res) => {
                    setStateData(res.data);
                    setDisable(false);
                })
                .catch((err) => {
                    setDisable(true);
                    handlingCatchErorr(err, navigate);
                });
        } else {
            setDisable(true);
            setStateData([])
        };
    };

    const handleCancel = () => {
        let objectWithEmptyArray = {};
        productsDropDownData?.map(gt => Object.assign(objectWithEmptyArray, { [gt?.name]: [] }));
        setOpen(false);
        setIsBillingAddressSkipped(false);
        setErrors({});
        setFormData(initialFormData);
        setThridStepData(initialThridStepData);
        setSecondStepData(initialSecondStepData);
        setActiveStepCount(0);
        setIsEnterpriseUserId("");
        setIsNextStep(false);
        setCountryId("");
        setStateId("");
        setTabList([]);
        setIndTabValues("");
        setProductId([]);
        setSubscriptionData({});
        setPayDet({})
        setBtnSelectedValue([]);
        setBtnSelectedNationalValue([]);
        setBtnSelPacValue([]);
        setPriceDetails([]);
        setValOffOnRadioBtn({});
        setSelectedStateListIds(objectWithEmptyArray);
        setNewState(objectWithEmptyArray)
        setSelectedCountryListIds(objectWithEmptyArray);
        setSubsIdNSubsName({});
        setSelectedPaymentId({})
    };

    const skipStep = (step) => {
        return skip.has(step);
    };

    const errorMessageFunc = (errorName, errorMessage) => {
        if (errorName === "stpOnePhone") setErrors((prevData) => ({ ...prevData, [errorName]: errorMessage.errMsg }));
        else setErrors((prevData) => ({ ...prevData, [errorName]: errorMessage }));
    }

    const postValidateOnNext = () => {
        let areThereErrorMsgOrNot = Object.entries(errors).length !== 0 ? Object.entries(errors).every(([key, val]) => val === "") ? true : false : true;
        if (areThereErrorMsgOrNot && activeStepCount !== 2) handleStepNext();
        if (areThereErrorMsgOrNot && activeStepCount === 2) handleCreateUser();
    }
    const preValidateOnNext = (fromStepNo,existRes) => {
        const subId = "subscriptionId";
        const prodChkBxs = "productsCheckBoxes";
        if (fromStepNo === "firstStep") {
            let allFields = formData.isEnterpriceChecked ? { ...formData, "isEnterpriseUserDropDown": isEnterpriseUserId?.value } : { ...formData };
   
            Object.entries(allFields).forEach(([key, val]) => {
               
                let returnedErrors = validationFunction(key, val, existRes);
                errorMessageFunc(key, returnedErrors);
               
            });
        } else if (fromStepNo === "secondStep") {
            tabList.map(gt => {
                if (valOffOnRadioBtn[gt.name][0].selectedPayment === paymentMode.custom) {
                    let returnedErrors = validationFunction(`${gt.name}${subId}`, valOffOnRadioBtn[gt.name][1].subscriptionId);
                    errorMessageFunc(`${gt.name}${subId}`, returnedErrors);
                } else {
                    errorMessageFunc(`${gt.name}${subId}`, "");
                }
                return "";
            });
            btnSelectedValue.map(dt => 
                Object.entries(dt).map(([key, val]) => {
                    // Check if the key matches the tabvalue
                    if (key === tabIndValues) {
                        let returnedErrors = validationFunction(`${key}${chkSubsBtn}`, val);
                        errorMessageFunc(`${key}${chkSubsBtn}`, returnedErrors);
                    }
                    return ''; // Return empty string to maintain the structure
                })
            );
            btnSelPacValue.map(dt => Object.entries(dt).map(([key, val]) => {
                if (key === tabIndValues) {
                let returnedErrors = validationFunction(`${key}${selPac}`, val);
                errorMessageFunc(`${key}${selPac}`, returnedErrors);
                }
                return '';
            }))
            
            let returnedErrors = validationFunction(prodChkBxs, productId);
            errorMessageFunc(prodChkBxs, returnedErrors);
        } else if (fromStepNo === "thirdStep") {
            let allFileds = { ...thridStepData, "thridStepbillingState": stateId, "thridStepbillingCountry": countryId };
            Object.entries(allFileds).map(([key, val]) => {
                if (isBillingAddressSkipped) errorMessageFunc(key, "");
                else {
                    let returnedErrors = validationFunction(key, val)
                    errorMessageFunc(key, returnedErrors);
                }
                return "";
            });
        }
    }
    const handleSkipBillingAddress = () => {
        setIsBillingAddressSkipped(true);
        setIsBillingAddressAdded(false);
    }
    const handleAddBillingAddress = () => {
        setIsBillingAddressAdded(true);
        setIsBillingAddressSkipped(false);

    }

    const handleCreateUser = () => {
        handleSortByCreatedDate("initialCreateUserLoad");
        setLoader(true);
        let selectedCountryName = countryData.filter(ft => ft.id === countryId)[0]?.name;
        let userbillingDet = {}
        if (isBillingAddressSkipped) {
            userbillingDet = {
                Address1: null,
                Address2: null,
                City: null,
                ZipCode: null,
                State: null,
                Country: null,
                CountryId: null,
                Phone: null,
            }
        } else {
            userbillingDet = {
                Address1: thridStepData.thridStepbillingAddress,
                Address2: thridStepData.thridStepbillingAddress2 ? thridStepData.thridStepbillingAddress2 : null,
                City: thridStepData.thridStepbillingCity,
                ZipCode: thridStepData.thridStepbillingZipCode,
                State: stateId,
                Country: selectedCountryName,
                CountryId: countryId,
                Phone: formData?.stpOnePhone ? formData.stpOnePhone : null,
            }
        };
        let suscriptionDet = [];
        let memberId = formData.isEnterpriceChecked ? isEnterpriseUserId?.value !== "" ? isEnterpriseUserId?.value : null : null;
        for (let i = 0; i < tabList.length; i++) {
            let productId = tabList[i].productId;
            let productName = tabList[i].name;
            let selSubId = btnSelectedValue.filter(ft => ft[tabList[i]?.name])[0][tabList[i]?.name];
            let subscriptionId = selSubId?.length > 0 ? selSubId[0] : selSubId.toString();
            let subscriptionPriceId = selSubId ? selSubId : null;
            let selPayment = valOffOnRadioBtn[tabList[i]?.name][0]?.selectedPayment;
            let subscriptionTextBox = selPayment === paymentMode.custom ? valOffOnRadioBtn[tabList[i]?.name][1].subscriptionId : null;
            let transactionTextBox = null;
            let modeOfSubsId = paymentRadioButtons.filter(dt => dt.name === selPayment)[0]?.id;
            let stateList = selectedStateListIds[tabList[i].name]?.length > 0 
            // && (productId === 1 || productId === 3)
             ? selectedStateListIds[tabList[i].name] : [];
            let marketExplorerId = subscriptionData[productName]?.subscriptions?.find(ft => ft.packagename === mrkPackages.mrkExp)?.id;
            let units = null;
            if (Number(subscriptionId) === marketExplorerId) {
                units = subscriptionData[productName]?.subscriptions?.find(ft => ft.id === subscriptionData[productName]?.subscriptions?.find(ft => ft.packagename === mrkPackages.mrkExp)?.id)?.prices?.find(gt => gt.id === Number(subscriptionPriceId))?.minCount;
                transactionTextBox = selPayment ===  paymentMode.custom ? valOffOnRadioBtn[tabList[i]?.name][1].subscriptionId : null;
                subscriptionTextBox = null;
            };

            suscriptionDet.push({
                "productId": productId ? Number(productId) : null,
                "subscriptionId": id ? id : null,
                "subscriptionPriceId": subscriptionPriceId ? Number(subscriptionPriceId) : null,
                "units": units === undefined ? null : units,
                "credits": 0,
                "stateList": stateList,
                "countryList": countryIds,
                "modeOfSubscription": modeOfSubsId,
                "payment": {
                    "subscriptionId": subscriptionTextBox ? subscriptionTextBox : null,
                    "transactionId": transactionTextBox ? transactionTextBox : null
                }
            })
        }
        let userDeatails = {
            userDetails: {
                Login: formData?.email,
                Firstname: formData?.fName,
                Lastname: formData?.lName,
                Company: formData?.company ? formData.company : null,
                Phone: formData?.stpOnePhone ? formData.stpOnePhone : null,
                UserType: 2,
                IsEnterprise: formData.isEnterpriceChecked ? 1 : 0,
                MemberId: memberId,
                CreatedDate: null,
                emailAlerts: formData.isChecked ? 1 : 0
            },
            userBillings: { ...userbillingDet },
            subscription: [...suscriptionDet],
        };
        api.post('/admin/adduser', { ...userDeatails })
            .then((res) => {
                if (res.status === 200) {
                    handlingSuccessResponse(res, "User created successfully");
                    handleSortByCreatedDate();
                    handleCancel();
                    initialUserManagementLoad();
                    callEnterpriseLookupApi();
                    setIsBillingAddressAdded(true)
                };
                setLoader(false);
            })
            .catch((error) => {
                setLoader(false);
                handlingCatchErorr(error, navigate);
                setIsBillingAddressAdded(false)
            });
    }
    const validateStepNextButton =  (existRes) => {
        if (activeStepCount === 0 ) {
            setIsNextStep(true);
            preValidateOnNext("firstStep", existRes);
        } else if (activeStepCount === 1) {
            setIsNextStep(true);
            preValidateOnNext("secondStep");
        } else if (activeStepCount === 2) {
            setIsNextStep(true);
            preValidateOnNext("thirdStep");
        }
    };
    const validateStepNext = (name) => {
        if (activeStepCount === 0 && formData.email){
            handleExistingUserorNot();
        }
        else{
            validateStepNextButton(); 
        }
       
        // if (activeStepCount === 0 && val === "next-btn") {
        //     setIsNextStep(true);
        //     preValidateOnNext("firstStep", existRes);
        // } else if (activeStepCount === 1) {
        //     setIsNextStep(true);
        //     preValidateOnNext("secondStep");
        // } else if (activeStepCount === 2) {
        //     setIsNextStep(true);
        //     preValidateOnNext("thirdStep");
        // }
    };

    const handleStepNext = () => {
        let newSkipped = skip;
        setActiveStepCount((prevActiveStep) => prevActiveStep + 1);
        setSkip(newSkipped);
    }

    const handleStepBack = () => {
        setIsNextStep(false);
        setErrors({});
        setActiveStepCount((prevActiveStep) => prevActiveStep - 1);
    };

    const handleCheckCancel = (e, reason) => {
        if (reason === 'closeClick') handleCancel(false);
    }

    const handleOnclick = () => {
        setOpen(true);
    };
    const handleExistingUserorNot = (name, value) => {
        const email = value || formData.email;
        setLoader(true);
        if (!errors.email) {
            api.get(`/account/user/exist/${email}`)
                .then((res) => {
                    validateStepNextButton(res.data);
                    setStatus(res.data);
    
                })
                .catch((err) => {
                    handlingCatchErorr(err, navigate);
                    
                })
                .finally(() => {
                    setLoader(false);
                });
        } else {
            setLoader(false);
        }
    };
    
  
    return (
        <Fragment>
            {loader && <div className="loader-wrap"><div className="loading"></div></div>}
            <Button variant="outlined" color="neutral" onClick={handleOnclick} className='create-user'>
                <AddCircleOutlineOutlined />Create User
            </Button>
            <Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={open} onClose={handleCheckCancel}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet variant="outlined" className={activeStepCount === 1 ? "expand-user-details bg-sheet" : "bg-sheet"}
                    sx={{ borderRadius: 'md', p: 3, boxShadow: 'lg', }}
                >
                    <ModalClose variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))', right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)', borderRadius: '50%', bgcolor: 'background.body',
                        }}
                    />
                    <center>
                        <div className='cr-user'>
                            <h1 >Create User</h1>
                        </div>
                        <div >
                            <Grid container spacing={3}>
                                <Grid item xs={2}>
                                    <Stepper activeStep={activeStepCount} alternativeLabel className='stepper-design' orientation="vertical">
                                        {steps.map((label, index) => {
                                            const stepProps = {};
                                            const labelProps = {};
                                            if (skipStep(index)) {
                                                stepProps.completed = false;
                                            }
                                            return (
                                                <Step key={label} {...stepProps}>
                                                    <StepLabel {...labelProps}> {label}</StepLabel>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                </Grid>
                                <Grid item xs={10} style={{ paddingLeft: '0px' }}>
                                    {activeStepCount !== steps.length &&
                                        (<div>
                                            {
                                                activeStepCount === 0 ?
                                                    <StepperOne handleChange={handleChange} errors={errors} formData={formData}
                                                        productsDropDownData={productsDropDownData} productId={productId} isEnterpriseDropDownData={isEnterpriseDropDownData}
                                                        isEnterpriseUserId={isEnterpriseUserId}  handleSelectChange={handleSelectChange} emailStatus={status} />
                                                    :
                                                    activeStepCount === 1 ?
                                                        <StepperTwo handleChange={handleChange} handleStateOrCountryModalCLose={handleStateOrCountryModalCLose}
                                                            selectedStateListIds={selectedStateListIds} selectedCountryListIds={selectedCountryListIds}
                                                            handleTabChange={handleTabChange} tabIndValues={tabIndValues} btnSelectedValue={btnSelectedValue} btnSelectedNationalValue={btnSelectedNationalValue} btnSelPacValue={btnSelPacValue}
                                                            handleProdSubCheckChange={handleProdSubCheckChange} errors={errors} productsDropDownData={productsDropDownData} productId={productId}
                                                            paymentRadioButtons={paymentRadioButtons} valOffOnRadioBtn={valOffOnRadioBtn} secondStepData={secondStepData} selectPackageMethod={selectPackageMethod}
                                                            tabList={tabList} subscriptionData={subscriptionData} checkBoxDisable={checkBoxDisable}
                                                            handleSubscriptionBtnChange={handleSubscriptionBtnChange} unlimitedStateDataList={unlimitedStateDataList}
                                                            countryData={countryData}  priceDetails={priceDetails} hasMadeSelection={hasMadeSelection}
                                                            setHasMadeSelection={setHasMadeSelection} newState={newState} pymtEntities={pymtEntities} subscriptionApi={subscriptionApi}
                                                            payDet = {payDet} selectedPaymentId={selectedPaymentId} setSelectedPaymentId={setSelectedPaymentId}/>
                                                        :
                                                        <StepperThree handleChange={handleChange} errors={errors} thridStepData={thridStepData} countryId={countryId}
                                                            stateId={stateId} countryData={countryData} stateData={stateData} disable={disable} formData={formData}
                                                            handleSkipBillingAddress={handleSkipBillingAddress} handleAddBillingAddress={handleAddBillingAddress} tabList={tabList} subscriptionData={subscriptionData}
                                                            btnSelectedValue={btnSelectedValue} isBillingAddressSkipped={isBillingAddressSkipped} isBillingAddressAdded={isBillingAddressAdded} priceDetails={priceDetails}
                                                            btnSelectedNationalValue={btnSelectedNationalValue} btnSelPacValue={btnSelPacValue} 
                                                            tabIndValues={tabIndValues} unlimitedStateDataList={unlimitedStateDataList}
                                                            selectedStateListIds={selectedStateListIds}  />
                                            }
                                            <Box sx={{
                                                display: 'flex', flexDirection: 'row',
                                                pt: 2
                                            }} className="stepper-action">
                                                <Button
                                                    color="primary"
                                                    disabled={activeStepCount === 0}
                                                    onClick={handleStepBack}
                                                    sx={{ mr: 1 }}
                                                    className='border-btn'
                                                > <ArrowBackIosRounded />
                                                    Previous
                                                </Button>
                                                <Box sx={{ flex: '1 1 auto' }} />
                                                <Button onClick={() => validateStepNext()} disabled={checkBoxDisable} className='success-btn'>
                                                    {activeStepCount === steps.length - 1 ? 'Create' : <>Next <ArrowForwardIosRounded /></>}
                                                </Button>
                                            </Box>
                                        </div>
                                        )}
                                </Grid>
                            </Grid>
                        </div>
                        {maxState && <CommonForAll open={maxState} heading={maxStateHeading}  btnText={"OK"} showText={MaxStateText}
                        className={"box-popup-single-state"} close={() => setMaxState(false)} />}
                    </center>
                </Sheet>
            </Modal>
        </Fragment>
    );
}
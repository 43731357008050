import { Grid, TextField, FormControlLabel, RadioGroup, Radio, FormControl, FormLabel, Checkbox, FormGroup, Button, Box, Tab, FormHelperText } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { CommonTabPanel } from '../../../commonFunctionalities/CommonTabPanel';
import { Fragment, useEffect, useState } from 'react';
import UnlimitedStateOrCountryModal from './UnlimitedStateOrCountryModal';
import { Types, packageName, paymentMode } from '../../../../utils/enums';
import { formattedAmount } from '../../../commonFunctionalities/commonFunction';


const StepperTwo = (props) => {
    let { hasMadeSelection, newState, setHasMadeSelection, paymentRadioButtons, tabIndValues, handleTabChange, handleChange, errors, countryData, stateOrCountry,
        handleStateOrCountryModalCLose, selectedStateListIds, selectedCountryListIds, productId, productsDropDownData, secondStepData, handleProdSubCheckChange, tabList,
        btnSelectedValue, btnSelectedNationalValue, btnSelPacValue, valOffOnRadioBtn, subscriptionData, checkBoxDisable, handleSubscriptionBtnChange, unlimitedStateDataList,
        priceDetails, pymtEntities, subscriptionApi ,payDet,selectedPaymentId , setSelectedPaymentId} = props;

    return (
        <div className='stepper-fields step-two'>
            <h4 style={{ marginBottom: '5px' }}>
                Product Name:
            </h4>
            <FormControl
                required
                error={errors["productsCheckBoxes"]}
            >
                <FormGroup row>
                    {productsDropDownData && productsDropDownData?.length > 0 &&
                        productsDropDownData?.filter(isId => isId.parentProductId === null).map(ft =>
                            <span key={ft.productId}>
                                <FormControlLabel name="products" className='receive-alert' control={<Checkbox checked={productId.includes(ft.productId) ? true : false} />} disabled={checkBoxDisable} value={ft.productId} label={ft.name} onChange={(e) => handleProdSubCheckChange(e, ft.productId, ft.name)} />
                            </span>
                        )}
                </FormGroup>
                <FormHelperText>{errors["productsCheckBoxes"]}</FormHelperText>
            </FormControl>
            {productId.length > 0 &&
                <Box sx={{ width: '100%', typography: 'body1' }} className="tab-design">
                    <TabContext value={tabIndValues}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="tab-box">
                            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                {tabList && tabList?.map((tabValues, index) =>
                                    <Tab label={tabValues.name} value={tabValues.name} key={tabValues.name} disabled={checkBoxDisable} />
                                )}
                            </TabList>
                        </Box>
                        <CommonTabPanel value={tabIndValues}><StepperTwoCommonTabs newState={newState} hasMadeSelection={hasMadeSelection} setHasMadeSelection={setHasMadeSelection}
                            priceDetails={priceDetails} checkBoxDisable={checkBoxDisable} stateOrCountry={stateOrCountry} countryData={countryData}
                            handleProdSubCheckChange={handleProdSubCheckChange} selectedStateListIds={selectedStateListIds}
                            handleStateOrCountryModalCLose={handleStateOrCountryModalCLose} selectedCountryListIds={selectedCountryListIds}
                            unlimitedStateDataList={unlimitedStateDataList} selectedTab={tabIndValues} subscriptionData={subscriptionData}
                            productsDropDownData={productsDropDownData} paymentRadioButtons={paymentRadioButtons} secondStepData={secondStepData}
                            errors={errors} handleChange={handleChange} tabList={tabList} btnSelectedValue={btnSelectedValue} btnSelectedNationalValue={btnSelectedNationalValue}
                            btnSelPacValue={btnSelPacValue} valOffOnRadioBtn={valOffOnRadioBtn} handleSubscriptionBtnChange={handleSubscriptionBtnChange} pymtEntities={pymtEntities}
                            subscriptionApi={subscriptionApi} payDet= {payDet} selectedPaymentId = {selectedPaymentId }
                            setSelectedPaymentId = {setSelectedPaymentId} /> </CommonTabPanel>
                    </TabContext>
                </Box>}
        </div>
    )
}

export default StepperTwo

function StepperTwoCommonTabs(props) {
    let { selectedTab, newState, hasMadeSelection, setHasMadeSelection, countryData, stateOrCountry, priceDetails, checkBoxDisable, selectedStateListIds,
        handleStateOrCountryModalCLose, selectedCountryListIds, handleProdSubCheckChange, paymentRadioButtons, unlimitedStateDataList, errors, handleChange, btnSelectedValue,
        btnSelectedNationalValue, btnSelPacValue, valOffOnRadioBtn, subscriptionData, handleSubscriptionBtnChange,
         pymtEntities, subscriptionApi, productsDropDownData,payDet, selectedPaymentId ,setSelectedPaymentId } = props;
    const [updatedUnlimitedStateList, setUpdatedUnlimitedStateList] = useState([]);
    const [open, setOpen] = useState(false);
    const [stateOrcountryerrorMsg, setStateOrcountryerrorMsg] = useState("");
    const [selectedPymtEntity, setSelectedPymtEntity] = useState([]);
    let selectedPackNameArr = btnSelPacValue.filter(dt => dt[selectedTab]);
    let selectedpackName = selectedPackNameArr?.length > 0 ? selectedPackNameArr[0][selectedTab] : "";
    let selectedPackValArr = btnSelectedValue.filter(dt => dt[selectedTab]);
    let selectedpackValue = selectedPackValArr?.length > 0 ? selectedPackValArr[0][selectedTab] : "";
    let selectedPackNationalValArr = btnSelectedNationalValue?.filter(dt => dt[selectedTab]);
    let selectedpackNationalValue = selectedPackNationalValArr?.length > 0 ? selectedPackNationalValArr[0][selectedTab] : "";
    let selectedPriceArr = priceDetails?.filter(dt => dt[selectedTab]);
    let selectedPriceDetails = selectedPriceArr?.length > 0 ? selectedPriceArr[0][selectedTab] : [];
    let subscriptionId = subscriptionData[selectedTab]?.subscriptions?.filter(ft => ft.subscriptionType === selectedpackName)[0]?.id
    
    useEffect(() => {
        handleUpdateForUnlimitedStateBtnDetails();
        // loadPackages();
    }, [newState]);
    const handleUpdateForUnlimitedStateBtnDetails = () => {
        let finalList = [];
        unlimitedStateDataList[selectedTab]?.map(hy => newState[selectedTab]?.map(gt => {
            if (gt.includes(hy.id)) finalList.push(hy.name);
            return "";
        }))

        setUpdatedUnlimitedStateList(finalList);

    }

    let loadPackages = () => {
        debugger;
        if (pymtEntities.length == 1) {

            setSelectedPymtEntity(pymtEntities[0]);

            let searchApiValues = productsDropDownData.filter(ft => ft.name === selectedTab)[0];

            let filteredNames = productsDropDownData.filter(ft => searchApiValues.productId && ft.name);
            subscriptionApi(searchApiValues.permalink, searchApiValues.name, "no", filteredNames, pymtEntities[0].id);
        }
    }

    let handleBtnClick = (val, name, subsId, subsKey, pacDetOrPacName, subscriId) => {
        debugger;
        setHasMadeSelection(true);

        if (name === Types.state) setOpen(true);
        if (name === Types.national) setOpen(true);
        handleSubscriptionBtnChange(val, name, subsId, subsKey, pacDetOrPacName, subscriId);
    };

    let handlePymtEntityClick = (pymt) => {
        setHasMadeSelection(true);
        setSelectedPaymentId(pymt)
        setSelectedPymtEntity(pymt);
        let searchApiValues = productsDropDownData.filter(ft => ft.name === selectedTab)[0];

        let filteredNames = productsDropDownData.filter(ft => searchApiValues.productId && ft.name);
        subscriptionApi(searchApiValues.permalink, searchApiValues.name, "no", filteredNames, pymt.id);
    }

    const handleCancel = (isSave) => {
        setStateOrcountryerrorMsg("");
        if (isSave === "notSave") {
            if (selectedpackName === Types.state) handleStateOrCountryModalCLose("state", "notSave");
            else if (selectedpackName === Types.national) handleStateOrCountryModalCLose("country", "notSave");
            setStateOrcountryerrorMsg("");
            setOpen(false);
            setHasMadeSelection(false);
        } else {
            if (selectedpackName === Types.state) {
                if (selectedStateListIds[selectedTab].length > 0) {
                    handleStateOrCountryModalCLose("state", "Save");
                    setOpen(false)
                } else setStateOrcountryerrorMsg('Atleast one state needs to be selected');
            } else if (selectedpackName === Types.national) {
                if (selectedpackNationalValue !== "") {
                    handleStateOrCountryModalCLose("national", "Save");
                    setOpen(false)
                } else setStateOrcountryerrorMsg('Package details is required');
            }

            setHasMadeSelection(true);
        }
    };
    let handleShowPaymentTextBox = () => {
        let selSubscriptionType = subscriptionData[selectedTab]?.subscriptions?.filter(ft => ft.subscriptionType === selectedpackName)[0]?.subscriptionType;
        let selPaymentTerm = subscriptionData[selectedTab]?.subscriptions?.filter(ft => ft.subscriptionType === selectedpackName)[0]?.paymentTerm;
        if (selSubscriptionType !== Types.free && selSubscriptionType !== undefined) {
            return (
                <Grid container spacing={2} className="mt-0 select-payment-type">
                    <Grid item xs={12} className='text-left pt-0'>
                        <FormLabel className="radio-heading">Select Payments</FormLabel>
                        {valOffOnRadioBtn[selectedTab] !== undefined ? <RadioGroup defaultValue={valOffOnRadioBtn[selectedTab][0]?.selectedPayment ?? ""} name="radio-buttons-group" className='text-left radio-group'>
                            {paymentRadioButtons.map((label, index) => (
                                <FormControlLabel key={index} value={label.name} label={label.name} disabled={checkBoxDisable} className='custom-radio' onChange={handleChange} name="selectPayment" control={<Radio />} />
                            ))}
                        </RadioGroup> : <span></span>}
                    </Grid>
                    {valOffOnRadioBtn[selectedTab][0]?.selectedPayment === paymentMode.custom &&
                        <Grid item xs={6} style={{ paddingTop: '10px' }}>
                            <div className='selected-package-details'>
                                <div className='package-values'>
                                    <form className='custom-form'>
                                        <TextField
                                            name={"subscriptionId"}
                                            label={selPaymentTerm === "One-Time" ? "Transaction Id" : "Subscription Id"}
                                            type="text"
                                            value={valOffOnRadioBtn[selectedTab][1].subscriptionId}
                                            onChange={handleChange}
                                            fullWidth
                                            error={!!errors[`${selectedTab}subscriptionId`]}
                                            helperText={errors[`${selectedTab}subscriptionId`]}
                                            required={true}
                                            className='custom-fields'
                                        />
                                    </form>
                                </div>
                            </div>
                        </Grid>
                    }
                </Grid>
            )
        }
    }

    return (
        <Fragment>
            <div className='select-package'>
                {
                    payDet[selectedTab] && payDet[selectedTab].length > 1 && payDet[selectedTab]?
                        <>
                            <FormLabel className="radio-heading">Select Currency</FormLabel>
                            {payDet[selectedTab].map(e => {
                                return (<Button key={e.id} className={selectedPaymentId ? selectedPaymentId.id === e.id
                                    ? 'package-btn package-selected' : "package-btn package-unselected" : "package-btn package-unselected"} onClick={() => handlePymtEntityClick(e)} name="btnChooseSubs">
                                     <span className="currency-box-text"> 
                                    {e.name}<br />
                                    <b>{e.currencycode}</b>
                                    </span>
                                </Button>)
                            })}
                        </>
                        : ''
                }
                <FormLabel className="radio-heading">Select Package</FormLabel>
                {subscriptionData[selectedTab]?.subscriptions ? subscriptionData[selectedTab]?.subscriptions?.filter(ft => ft.packagename !== packageName.free).sort((a, b) => a.sortOrder - b.sortOrder)?.map(ft => {
                    return (<Button key={ft.id} disabled={checkBoxDisable} className={selectedpackName === ft.subscriptionType
                        ? 'package-btn package-selected' : "package-btn package-unselected"} onClick={() => handleBtnClick(ft.id, ft.subscriptionType, ft.subscriptionTypeId, ft.subscriptionkey, "SelPacDet", subscriptionId)} name="btnChooseSubs">
                        {ft.packagename}<br />
                    </Button>)
                }) : checkBoxDisable ? <p className='package-loading'>Loading...</p> : <p className='no-package'>Data not found</p>}
                {!checkBoxDisable && <FormHelperText className='check-subscription-error'>{errors[`${selectedTab}SelPacDet`]}</FormHelperText>}
                <UnlimitedStateOrCountryModal newState={newState} errors={errors} handleBtnClick={handleBtnClick} selectedpackNationalValue={selectedpackNationalValue} subscriptionData={subscriptionData} checkBoxDisable={checkBoxDisable} stateOrcountryerrorMsg={stateOrcountryerrorMsg} countryData={countryData} stateOrCountry={stateOrCountry} handleCancel={handleCancel} selectedStateListIds={selectedStateListIds} selectedCountryListIds={selectedCountryListIds} handleProdSubCheckChange={handleProdSubCheckChange} unlimitedStateDataList={unlimitedStateDataList} selectedTab={selectedTab} open={open} selectedpackName={selectedpackName} />
                {(
                    selectedpackName === Types.state ?
                        <>
                            <FormLabel className="radio-heading already-state-selected">Selected State <span onClick={() => !checkBoxDisable && setOpen(true)} className='edit-state-select'>Edit</span></FormLabel>
                            <div className='listed-selected-state'>
                                {updatedUnlimitedStateList?.length > 0 && <p> {updatedUnlimitedStateList?.toString()?.replaceAll(",", ", ")}</p>}
                            </div>
                        </>
                        : selectedpackName === Types.national ?
                            <>
                                <FormLabel className="radio-heading already-state-selected">Selected Region <span onClick={() => !checkBoxDisable && setOpen(true)} className='edit-state-select'>Edit</span></FormLabel>
                                <div className='listed-selected-state'>
                                    <p>{selectedpackNationalValue}</p>
                                </div>
                            </>
                            : null)}
                {(
                    selectedpackName === Types.state && (!updatedUnlimitedStateList || updatedUnlimitedStateList.length === 0) ?
                        <p className='no-package'>At least one state needs to be selected</p>
                        : selectedpackName === Types.national && !selectedpackNationalValue ?
                            <p className='no-package'>At least one country needs to be selected</p>
                            : null
                )}

                {selectedpackName && (

                    (selectedpackName === Types.state && updatedUnlimitedStateList.length > 0) ||
                        (selectedpackName === Types.basic) ||
                        (selectedpackName === Types.national && selectedpackNationalValue) ||
                        (selectedpackName === Types.reports) ? (
                        <>
                            <FormLabel className="radio-heading">Select Package Details</FormLabel>
                            {selectedPriceDetails?.length > 0 ? selectedPriceDetails.sort((a, b) => a.sortOrder - b.sortOrder)?.map(priceSel => (
                                <Button
                                    key={priceSel.id}
                                    disabled={checkBoxDisable}
                                    className={selectedpackValue === priceSel.id ? 'package-btn package-selected' : "package-btn package-unselected"}
                                    onClick={() => handleBtnClick(priceSel.id, priceSel.subscriptionType, priceSel.subscriptionTypeId, priceSel.subscriptionkey, "", subscriptionId)}
                                    name="btnChooseSubs"
                                >
                                    <span className='font-normal'>
                                        Subscription for <br />
                                        <b>{formattedAmount(priceSel.price, priceSel.currency, priceSel.currencyCode)}</b>/
                                        <span>{priceSel.frequency}</span>
                                    </span>
                                </Button>
                            )) : checkBoxDisable ? <p className='package-loading'>Loading...</p> : <p className='no-package'>Data not found</p>}
                            {!checkBoxDisable && <FormHelperText className='check-subscription-error'>{errors[`${selectedTab}checkSubscriptionBtn`]}</FormHelperText>}
                        </>
                    ) : null
                )}

            </div>

            {handleShowPaymentTextBox()}
        </Fragment>
    )
}
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import {api} from '../../services/api';
import { handlingCatchErorr } from '../utils/errorHandling';
import { CONFIG } from '../utils/config';

const PaymentCancels = (props) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const search = useLocation().search;
  const session_id = new URLSearchParams(search).get('session_id');
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const isAuthenticated = (userData?.secureId !== null && userData?.secureId !== undefined) && (userData?.token !== null && userData?.token !== undefined) ? true : false;

  useEffect(() => {
    handleSession();
  }, []);

  const handleSession = () => {
    setLoader(true);
    api.get(`/Subscription/validate/${session_id}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.value === true) {
            if(isAuthenticated){
              navigate('/products');
            }else{
              window.location.replace(CONFIG.Explorer_Marketing_url)

            }
            setLoader(false);
          } else if (res.data.value === false) {
            navigate("/UnauthorizedAccess");
            setLoader(false);
          }
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response.status === 400) {
          navigate("/invalid-access");
        } else {
          handlingCatchErorr(error, navigate);
        }
      });
  };


  return (
    <>
    {loader &&
    <div className="account-acivate payment-success" style={{ borderRadius: '10px' }}>
      <Typography className='txt-session' data-testid='id'>
       
          <p className='text-center'>
            Please wait while we processing your request... </p>

      
      </Typography>
    </div>
  }
  </>
  )
}

export default PaymentCancels;
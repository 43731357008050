import { Autocomplete, Button, Table, Paper, TableBody, TableCell, TableContainer, TablePagination, TableRow, TextField } from "@mui/material";
import EnhancedTableHead, { getSorting, stableSort } from "../../commonFunctionalities/CommonMuiSortingFunc";
import moment from "moment";
import { useState } from "react";

const Reports = (props) => {
    let { handleRequestSortPreCall, emailError, dateError, usersReports, state, loader, handleTextFieldChange, lastMonthStartISO, handleFromDateChange, handleToDateChange, fetchReportData, todayISO, yesterdayISO, buttonDisabled } = props;
    let headRows = [
        { id: 'userName', label: 'User Name', numeric: false },
        { id: 'email', label: 'Email', numeric: false },
        { id: 'lastLogin', label: 'Last Login Date', numeric: false },
        { id: 'expiredDate', label: 'Expired Date', numeric: false },
    ];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const [error, setError] = useState(false);
    // let optionForSelect = usersData && usersData.length > 0 ? usersData.map(ft => { return { "value": ft.firstname, "label": ft.firstname } }) : [];
    return (
        // <div className="user-reports-tab">
        //     <div className="custom-form">
        //         <TextField
        //             label="Enter the Username or Email"
        //             onChange={handleTextFieldChange}
        //             error={error}
        //             helperText={emailError ? 'Please enter a valid user within 50 characters' : null}
        //             onBlur={handleTextFieldChange}
        //             onFocus={() => setError(false)}
        //             className='custom-fields user-txt-field'
        //         />
        //         <TextField
        //             id="from-date"
        //             label="From Date"
        //             defaultValue={yesterdayISO}
        //             type="date"
        //             onChange={handleFromDateChange}
        //             InputLabelProps={{
        //                 shrink: true,
        //             }}
        //             inputProps={{
        //                 max: todayISO,
        //                 min: lastMonthStartISO
        //             }}
        //             className='custom-fields'
        //             error={dateError}
        //             helperText={dateError ? 'To Date cannot be before From Date' : null}
        //         />
        //         <TextField
        //             id="to-date"
        //             label="To Date"
        //             defaultValue={todayISO}
        //             type="date"
        //             onChange={handleToDateChange}
        //             InputLabelProps={{
        //                 shrink: true,
        //             }}
        //             inputProps={{
        //                 max: todayISO,
        //                 min: lastMonthStartISO,
        //             }}
        //             className='custom-fields'
        //             error={dateError} 
        //             helperText={dateError ? 'To Date cannot be before From Date' : null}
        //         />
        //         <Button variant="contained" onClick={fetchReportData} disabled={buttonDisabled || emailError || dateError} className="generate_btn">
        //             Generate Report
        //         </Button>
        //     </div>
        //     <TableContainer component={Paper} className="common-table-design user-management-table fixed-header-table">
        //         <Table>
        //             <EnhancedTableHead
        //                 order={state.order}
        //                 orderBy={state.orderBy}
        //                 onRequestSort={handleRequestSortPreCall}
        //                 headRows={headRows}
        //             />
        //             <TableBody>
        //                 {usersReports && usersReports.length > 0 ? (
        //                     stableSort(
        //                         usersReports,
        //                         getSorting(state.order, state.orderBy)
        //                     )
        //                         .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        //                         .map((strdata, index) => (
        //                             <TableRow key={index}>
        //                                 <TableCell>{strdata.userName ? strdata.userName : null}</TableCell>
        //                                 <TableCell>{strdata.emailId ? strdata.emailId : null}</TableCell>
        //                                 <TableCell>{strdata.lastLogin ? moment(strdata.lastLogin).format('DD-MMM-YYYY') : null}</TableCell>
        //                                 <TableCell>{strdata.expiredDate ? moment(strdata.expiredDate).format('DD-MMM-YYYY') : null}</TableCell>
        //                             </TableRow>
        //                         ))
        //                 ) : (
        //                     !loader && (
        //                         <TableRow>
        //                             <TableCell colSpan={4} className="no-data">{'No Data Found'}</TableCell>
        //                         </TableRow>
        //                     )
        //                 )}
        //             </TableBody>
        //         </Table>
        //     </TableContainer>
        //     <TablePagination
        //         rowsPerPageOptions={[25, 50, 100]}
        //         component="div"
        //         count={usersReports.length}
        //         rowsPerPage={rowsPerPage}
        //         page={page}
        //         onPageChange={handleChangePage}
        //         onRowsPerPageChange={handleChangeRowsPerPage}
        //     />
        // </div>
        <h4>Under Construction</h4>
    )
}

export default Reports
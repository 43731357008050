import * as React from "react";
import { Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import { Container } from "@mui/material";
import { LogoBar } from "../core";
function PriceWithoutHeaderLayout() {
    return (
        <div>
            <LogoBar />
            <Box component="main" className="body-main">
                <Container maxWidth="xl">

                    <Outlet />

                </Container>
            </Box>
        </div>
    );
}

export default PriceWithoutHeaderLayout;
import React, { Fragment, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableRow, TableContainer, Paper } from '@mui/material';
import moment from "moment";
import EnhancedTableHead, { getSorting, handleAlphabets, handleRequestSort, stableSort } from '../../../commonFunctionalities/CommonMuiSortingFunc';
import { paymentTerm, usersType,paymentMode, paymentStatus } from '../../../../utils/enums';
import AdminSubscriptionCancelModal from '../../../modals/adminModules/AdminSubscriptionCancelModal';
import { Link, useNavigate } from 'react-router-dom';
import SubscriptionInvoiceModal from '../../../modals/SubscriptionInvoiceModal';
import { Button as JoyButton } from '@mui/joy';
import { handlingCatchErorr } from '../../../../utils/errorHandling';
import { api } from '../../../../../services/api';
const AdminSubscriptionEdit = (props) => {
    const { userProductSubscriptions, userId, handleUserProductSubscriptions, handleLoader, initialData } = props;
    const [subscripbedcountry, setState] = useState({ order: 'asc', orderBy: "" });
    const navigate = useNavigate();
    const [invoiceData, setInvoiceData] = useState([]);
    const [loader, setLoader] = useState(false);
    const headRows = [
        { id: 'packagename', label: 'subscription name', numeric: false },
        { id: 'userSubscribedStates', label: 'subscribed state', numeric: false },
        { id: 'userSubscribedCountries', label: 'subscribed country', numeric: true },
        { id: 'createdDate', label: 'Created', numeric: false },
        { id: 'validtill', label: 'valid till', numeric: false },
        { id: 'details', label: 'Details', numeric: false },
        props.isFrom === usersType.user
            ? null // Empty header when the condition is true
            : { id: 'edit', label: 'Cancel', numeric: true },
    ].filter(Boolean); // Remove null values

    // Effects for handling alphabets
    useEffect(() => {
        handleAlphabets(['packagename', 'subscriptionType']);
    }, []);

    // Sorting function
    const handleRequestSortPreCall = (property) => {
        const { order, orderBy } = subscripbedcountry;
        const { sort, prop } = handleRequestSort(property, order, orderBy);
        setState({ ...subscripbedcountry, order: sort, orderBy: prop });
    }
      // Add a helper function to compare values
const compareValues = (valueA, valueB) => {
    const numericA = parseFloat(valueA);
    const numericB = parseFloat(valueB);
  
    if (!isNaN(numericA) && !isNaN(numericB)) {
      return numericA - numericB;
    } else {
      return String(valueA).localeCompare(String(valueB));
    }
  };

  
  const subscriptionData = props.userProductSubscriptions.products?.slice().sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));

  const sortedData = subscriptionData?.sort((a, b) => {
    const valueA = (a && a[subscripbedcountry.orderBy]) || "";
    const valueB = (b && b[subscripbedcountry.orderBy]) || "";
  
    const comparisonResult = compareValues(valueA, valueB);
  
    return subscripbedcountry.order === "asc" ? comparisonResult : -comparisonResult;
  });

    const handleBillingDetails = (strdata) => {
    const { productId, productSubscriptionId, subcriptionPriceId } = strdata;
    const payload = {
        productId,
        productSubscriptionId,
        subcriptionPriceId,
        userId
    };
    setLoader(true);
    api.post(`/subscription/billingdetails`, payload)
        .then((response) => {
            setLoader(false);
            navigate("/updateSubscription/userbillingdetails", { state: { billingdetail: response.data, load: loader, initialData: initialData, userId } });
        })
        .catch((error) => {
            setLoader(false);
            handlingCatchErorr(error, navigate);
        });
    };

    const handleInvoiceDownload = (productId, productSubscriptionId, subcriptionPriceId, userId) => {
    const payload = {
        productId,
        productSubscriptionId,
        subscriptionPriceId: subcriptionPriceId,
        userId
    }
    setLoader(true);
    api.post(`/subscription/transaction`, payload)
        .then((response) => {
            setLoader(false);
            setInvoiceData(response.data);
        })
        .catch((error) => {
            setLoader(false);
            handlingCatchErorr(error, navigate);
        });
    };
    return (
        <Fragment>
            {loader && <div className="loader-wrap"><div className="loading"></div></div>}

            <TableContainer component={Paper} className="common-table-design market-list-table subscription_table">
                <Table>
                    <EnhancedTableHead
                        order={subscripbedcountry.order}
                        orderBy={subscripbedcountry.orderBy}
                        onRequestSort={handleRequestSortPreCall}
                        disableTableRowsNames={['edit', 'userSubscribedStates', 'userSubscribedCountries']}
                        headRows={headRows}
                    />
                    <TableBody>
                        {sortedData && sortedData.length > 0 ?
                            stableSort(
                                sortedData,
                                getSorting(subscripbedcountry.order, subscripbedcountry.orderBy)
                            ).map((strdata, index) =>
                                <TableRow key={index}>
                                    <TableCell>{strdata?.packagename ? strdata.packagename : "-"}</TableCell>
                                    <TableCell>{strdata?.userSubscribedStates ? strdata.userSubscribedStates?.toString()?.replaceAll(",", ", ") : "-"}</TableCell>
                                    <TableCell>{strdata?.userSubscribedCountries ? strdata.userSubscribedCountries?.toString()?.replaceAll(",", ", ") : "-"}</TableCell>
                                    <TableCell>{strdata?.createdDate ? moment(strdata.createdDate).format('DD-MMM-YYYY') : "-"}</TableCell>
                                    <TableCell>{strdata?.validtill ? moment(strdata.validtill).format('DD-MMM-YYYY') : "-"}</TableCell>
                                    <TableCell align="right">
                                    {strdata?.paymentTerm !== paymentTerm.free && (
                                        <>
                                            {strdata.modeOfPayment === "Offline" ? (
                                                <span>Offline</span>
                                            ) : (
                                                <>
                                                    {strdata.paymentTerm === paymentTerm.recurring ? (
                                                        <JoyButton onClick={() => handleBillingDetails(strdata)} className='view-update-btn'>See Billing Detail</JoyButton>
                                                    ) : (
                                                        strdata.paymentTerm !== paymentTerm.recurring && strdata.paymenttermId !== 3 ? (
                                                            <SubscriptionInvoiceModal
                                                                title="Print Invoice"
                                                                onClick={() => handleInvoiceDownload(strdata.productId, strdata.productSubscriptionId, strdata.subcriptionPriceId, userId)}
                                                                invoiceDetails={invoiceData}
                                                                modeOfPayment={strdata.modeOfPayment}
                                                                packagename={strdata.packagename}
                                                            />
                                                        ) : (
                                                            "-"
                                                        )
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}                                                                                                         
                                    {strdata?.paymentTerm === paymentTerm.free && "-" }                              
                                    </TableCell>
                                    {props.isFrom === usersType.user ? "" :
                                        <TableCell>{((strdata?.paymentTerm === paymentTerm.recurring) && (strdata?.subscriptionStatus === "Active") && (!strdata?.validtill)) ? <AdminSubscriptionCancelModal data={strdata} userId={userId} handleUserProductSubscriptions={handleUserProductSubscriptions} handleLoader={handleLoader} /> : "-"}</TableCell>}                                    
                                </TableRow>
                            ) : !loader &&
                            <TableRow>
                                <TableCell colSpan={8} className="no-data">{'No Data Found'}</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    )
}

export default AdminSubscriptionEdit;

import { Modal, ModalClose, Sheet } from '@mui/joy';
import { Fragment, useState } from 'react';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { handlingCatchErorr,handlingSuccessResponse } from '../../../utils/errorHandling';
import {api} from '../../../../services/api';

export default function AdminSubscriptionCancelModal(props) {
    let { data, userId, handleUserProductSubscriptions, handleLoader } = props;
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleCancelSubscription = () => {
        handleCancel();
        handleLoader(true);
        const userEditInfo = {
            "productId": data?.productId,
            "productSubscriptionId": data?.productSubscriptionId,
            "subscriptionPriceId": data?.subcriptionPriceId,
            "userId": userId
        }
        api.post("/Subscription/cancelsubscription", userEditInfo)
            .then((res) => {
                if (res.status === 200) {
                    handleUserProductSubscriptions();
                    handlingSuccessResponse(res, "Subscription Cancelled Successfully.");
                };
                handleLoader(false);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
                handleLoader(false);
            });
    };

    const handleCancel = () => { setOpen(false); };

    const handleCheckCancel = (e, reason) => {
        if (reason === 'closeClick') handleCancel(false);
    }

    return (
        <Fragment>
            <Link onClick={() => setOpen(true)} className='user-cancel-btn ml-0'>Cancel</Link>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={handleCheckCancel}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                className="delete-model-popup cancel-immediatley-popup"
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.body',
                        }}
                    />
                    <p>
                    The subscription will be cancelled immediately. Do you want to continue?
                    </p>
                    <div className='button-alignment'>
                        <Button className='cancel-btn' onClick={handleCancel}>Cancel</Button>
                        <Button className='success-btn' type="button" onClick={handleCancelSubscription}>OK</Button>
                    </div>
                </Sheet>
            </Modal>
        </Fragment>
    );
}
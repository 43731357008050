import { Typography } from '@mui/joy';
import { useState } from 'react';
import {Grid, TextField, Checkbox,  Autocomplete } from '@mui/material';

let textFieldVariables = { "First Name": "fName", "Last Name": "lName", "Company": "company", "Phone": "stpOnePhone", "Email Address": "email" };
let widthValues = { "fName": 6, "lName": 6, "company": 6, "stpOnePhone": 6, "email": 12 };
let notRequired = ["company", "stpOnePhone"];
let errorTextFieds = ["company"];


const StepperOne = (props) => {
    let { handleChange, errors, formData, isEnterpriseUserId, isEnterpriseDropDownData, handleSelectChange } = props;

    const [error, setError] = useState(false);
    let optionForSelect = isEnterpriseDropDownData && isEnterpriseDropDownData.length > 0 ? isEnterpriseDropDownData.map(ft => { return { "value": ft.id, "label": ft.name } }) : [];
    const validateSelection = (event) => {
        const enteredEmail = event.target.value;
        const found = optionForSelect.find(option => option.email === enteredEmail);
        if (event.type === 'blur') {
            setError(false);
        } else {
            setError(!found && enteredEmail !== '');
            if (found) {
                handleSelectChange(found);
            }
        }
    };
   
    return (
        <div className="stepper-fields step-one">
            <Typography

                id="modal-title"
                level="h4"
                textColor="inherit"
                className="mb-5"
            >
                Account Details
            </Typography>
            <Typography id="modal-desc" textColor="text.tertiary">
                <form className='custom-form'>
                    <Grid container spacing={3} className="mt-0">
                        {Object.entries(textFieldVariables).map(([key, val]) => (
                            <Grid item xs={widthValues[val]} key={key}>
                                <TextField
                                    name={val}
                                    label={key}
                                    type={val === "email" ? "email" : "text"}
                                    value={formData[val]}
                                    onChange={handleChange}
                                    // onBlur={handleEmailBlur}
                                    fullWidth
                                    error={!errorTextFieds.includes(val) ? !!errors[val] : false}
                                    required={!notRequired.includes(val) ? true : false}
                                    className='custom-fields'
                                       
                                    helperText={
                                        val !== "company" && val !== "email"
                                          ? errors[val]
                                          : val === "email" && errors.email
                                            ? (
                
                                              <div>
                                                {errors.email}                                                 
                                                </div>
                                            )
                                            : null
                                      }                                 
                                />
                            </Grid>
                        )
                        )}
                    </Grid>
                    <Grid container spacing={3} className="mt-0">
                        <Grid item xs={6} className='receive-alert text-left enterprice-checkbox'>
                            <Checkbox name="isEnterpriceChecked" checked={formData.isEnterpriceChecked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />Is Enterprise User
                        </Grid>
                        {formData.isEnterpriceChecked &&
                            <Grid item xs={6} className='choose_master'>
                                <Autocomplete
                                    className='custom-fields custom-drop_down'
                                    disablePortal
                                    onChange={(event, value) => handleSelectChange(value)}
                                    value={isEnterpriseUserId}
                                    id="combo-box-demo"
                                    options={optionForSelect}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Choose the Master Enterprise User"
                                            error={error}
                                            helperText={error ? 'Kindly select an email listed in the box' : null}
                                            onBlur={validateSelection}
                                            onFocus={() => setError(false)} 
                                        />
                                    )}
                                />
                               
                            </Grid>
                        }
                    </Grid>
                    <Grid item xs={12} className='receive-alert text-left'>
                        <Checkbox name="isChecked" checked={formData.isChecked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />Receive Email Alerts
                    </Grid>
                </form>
            </Typography>
        </div>
    )
}

export default StepperOne
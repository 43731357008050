import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { NotFound, Login, InvalidAccess, Products, SubscriptionHome, AccountManagement, AdministrationDashboard, HomeLayout, ForgotPassword, ResetPassword, CheckIsAlredyLoggedIn, AdminProductsDetailsEdit, UserActivation, PaymentSuccess, AdminViewOrUpdate, BillingHistory, EnterpriseDashboard, AvailableDataLayout, AvailableData, Home, SinglePageCheckoutLayout, PaymentCancels, PriceWithoutHeaderLayout } from '../components';
import FreeAccountConfirmation from '../components/Home/commonFunctionalities/FreeAccountConfirmation';
import { useEffect, useState } from 'react';
import { usersType, usersTypeId } from '../components/utils/enums';
import Authorize from '../components/Authorization/Authorize';
import AdminSubscriptionBillingDetails from '../components/Home/Suitelandingpage/adminTab/viewOrUpdatePage/AdminSubscriptionBillingDetails';


const AppRouter = () => {
  const [loggedInDetails, setLoggedInDetails] = useState(null);
  let userData = JSON.parse(localStorage.getItem("userInfo"));
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    handleLogin();
    handleImperLogout();
  }, []);
  const handleLogin = (loginData) => {
    setIsLoading(true);
    if (loginData !== undefined) {
      setLoggedInDetails(JSON.parse(loginData) || null);
    } else {
      setLoggedInDetails(userData);
    }
    setIsLoading(false);
  };


  const handleImperLogout = (data) => {
    setIsLoading(true);
    if (data !== undefined) {
      setLoggedInDetails(JSON.parse(data) || null);
    } else {
      setLoggedInDetails(userData);
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <div>
    <div className="loader-wrap"><div className="loading"></div></div></div>;
  }
  return (
    <Router>
      <Routes>
        <Route element={<HomeLayout handleImperLogout={handleImperLogout}/>} >
          <Route element={<CheckIsAlredyLoggedIn />} >
            <Route exact  path='/' element={<Login handleLogin={handleLogin} />} />
          </Route>
          <Route path="/logins/:secureId" element={<Authorize />} />
          <Route exact  path="/products" element={<RequireAuth ><Products /></RequireAuth>} />
          <Route exact  path="/subscription" element={<RequireAuth ><SubscriptionHome /></RequireAuth>} />
          <Route exact  path="/invoice/billing-history" element={<RequireAuth ><BillingHistory /></RequireAuth>} />
          <Route path="/account" element={<RequireAuth ><AccountManagement /></RequireAuth>} />
          {(loggedInDetails !== null && loggedInDetails?.usertypeId === usersTypeId?.Admin) && <>
            <Route exact  path="/updateusers/:userId" element={<RequireAuth ><AdminViewOrUpdate /></RequireAuth>} />
            <Route exact  path="/updateSubscription/:permaLink/:productId/:userId" element={<RequireAuth ><AdminProductsDetailsEdit /></RequireAuth>} />
            <Route path="/updateSubscription/userbillingdetails" element={<RequireAuth ><AdminSubscriptionBillingDetails /></RequireAuth>} />
            <Route exact  path="/admin" element={<RequireAuth ><AdministrationDashboard /></RequireAuth>} />
          </>}
          {(loggedInDetails !== null && userData?.usertype === usersType.user) && <>
            <Route exact  path="/user" element={<RequireAuth ><EnterpriseDashboard /></RequireAuth>} />
            <Route exact path="/viewuser/:userId" element={<RequireAuth ><AdminViewOrUpdate from={usersType.user} /></RequireAuth>} />
            <Route exact  path="/viewSubscription/:permaLink/:productId/:userId" element={<RequireAuth ><AdminProductsDetailsEdit from={usersType.user} /></RequireAuth>} />

          </>}
          
        </Route>
       

        <Route element={<PriceWithoutHeaderLayout />} >
          <Route exact  path="/invalid-access" element={<InvalidAccess />} />
          <Route exact  path="/page-expired" element={<InvalidAccess from={"page-exp"}/>} />
          <Route exact  path="/Impersonation-logout" element={<InvalidAccess  from={"page-exp"} handleImperLogout={handleImperLogout}/>} />
          <Route exact  path="/UnauthorizedAccess" element={<InvalidAccess from={"un-auth"} />} />
          <Route exact  path="/invalid-product-access" element={<InvalidAccess from = {"invalid-product"}/>} />
        </Route>
        {// {!userData?.impersonated) &&
        <Route element={<SinglePageCheckoutLayout  handleImperLogout={handleImperLogout} from={loggedInDetails} />} >
          {/* <Route path="/pricing/:permalink" element={<Home />} /> */}
          <Route  
            path="/pricing/"
            element={
              <Home from ={"recurring-paymet"}/>
            }
          >
            <Route exact  path=":permalink/:isSubKey" element={<Home from ={"recurring-paymet"}/>} />
            <Route exact  path=":permalink/:isSubKey/:key" element={<Home from ={"recurring-paymet"}/>} />


            {/* Route when 'isSubKey' is not present */}
            <Route exact  path=":permalink" element={<Home from ={"recurring-paymet"}/>} />

          </Route>
          <Route  
            path="/purchase/"
            element={
              <Home from ={"oneTime-paymet"}/>
            }
          >
            <Route exact  path=":permalink/:isSubKey" element={<Home from ={"oneTime-paymet"}/>} />
          </Route>
          {/* payment-cancel */}
          <Route exact  path="/payment-cancel" element={<PaymentCancels />}>
            <Route exact  path=":session_id" element={<PaymentCancels/>} />
          </Route>
          {/* payment-success */}
          <Route exact  path="/payment-success" element={<PaymentSuccess />}>
            <Route exact  path=":session_id/:entity" element={<PaymentSuccess />} />
          </Route>
          {/* user- activation */}
          <Route exact  path="/activate-account" element={<UserActivation />}>
            <Route exact  path=":token/:secureid" element={<UserActivation />} />
          </Route>
          {/* freeuser- activation */}
          <Route exact  path="/user-activate-account" element={<UserActivation from={"free-user"}/>}>
            <Route exact  path=":token/:secureid" element={<UserActivation from={"free-user"}/>} />
          </Route>
          
          {/* account-confirm */}
          <Route exact  path="/account-confirmation" element={<FreeAccountConfirmation />} />
          <Route exact  path="/free-account-confirmation" element={<FreeAccountConfirmation from={"free-account-confirm"}/>} />
          <Route exact  path="/forgotpassword" element={<ForgotPassword />} />
          <Route exact  path="/reset-password" element={<ResetPassword />}>
            <Route exact  path=":secureid" element={<ResetPassword />} />
          </Route>
        </Route>
}
        <Route element={<AvailableDataLayout />} >
          {/* subscription */}
          <Route exact  path="/available-data" element={<AvailableData from ={"marketing-page"}/>} />
        </Route>


        {userData?.token !== null && userData?.token !== undefined ?
          <Route element={<HomeLayout />} >
            <Route path="*" element={<NotFound />} />
          </Route>
          :
          <Route element={<SinglePageCheckoutLayout from={"notfound"} />} >
            <Route path="*" element={<NotFound />} />
          </Route>
        }
      </Routes>
    </Router>
  );
};

function RequireAuth({ children }) {
  let userData = JSON.parse(localStorage.getItem("userInfo"));
  let isAuthenticated = (userData?.secureId !== null && userData?.secureId !== undefined) && (userData?.token !== null && userData?.token !== undefined) ? true : false;
  return isAuthenticated ? children : <Navigate to="/UnauthorizedAccess" />;
}

export default AppRouter;


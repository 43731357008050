import { Typography } from '@mui/joy';
import { Grid, TextField, useMediaQuery, } from '@mui/material';
import CommonPasswordStrengthMeter from '../Home/commonFunctionalities/CommonPasswordStrengthMeter';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { CONFIG } from '../utils/config';
import { Types } from '../utils/enums';




let widthValues = { "email": 6, "NewPassword": 6, "ConfirmPassword": 6, "fName": 6, "lName": 6, "company": 3, "registerphone": 3, }

const CreateAccount = ({ handleChange, errors, formData, loading, handleBlur, fullDetails, emailStatus,isAccountSecurity }) => {
  const textFieldVariables = {
    "Personal Information": {
      "First Name": "fName",
      "Last Name": "lName",
      "Email Address (Username)": "email",
      "Company": "company",
      "Phone": "registerphone"
    },
    ...((fullDetails.Subscription === Types.reports)  &&
      (isAccountSecurity && isAccountSecurity === "oneTime-paymet" ) || 
      (fullDetails.Subscription === Types.reports)  &&
      (isAccountSecurity && isAccountSecurity === "recurring-paymet" ) ? "" : {
      "Account Security": {
        "New Password": "NewPassword",
        "Confirm Password": "ConfirmPassword",
      }
    })
  };
  const [isStrength, setStrength] = useState(null);


  const dataHandler = async (childData) => {
    setStrength(childData);
  }

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      {loading && <div className="loader-wrap"><div className="loading"></div></div>}
      <div container className='box-padding'>

        {Object.entries(textFieldVariables).map(([sectionTitle, sectionFields]) => (
          <div key={sectionTitle} className='create-acc-fields'>
            <Typography
              component="h4"
              id="modal-title"
              level="h4"
              textColor="inherit"
              fontWeight="lg"
              mb={1}
              className='title-bg-inner-headtxt'
            >
              {sectionTitle}
            </Typography>
            <form className='custom-form'>
              <Grid container spacing={3}>
                {Object.entries(sectionFields).map(([key, val]) => (
                  <Grid key={key} item xs={matches ? widthValues[val] : 12}>
                    <TextField
                      disabled={fullDetails.Subscription === "" ? true : false}
                      name={val}
                      label={key}
                      type={val === "email" ? "email" : val === "NewPassword" || val === "ConfirmPassword" ? "password" : "text"}
                      value={formData && formData[val]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      error={val !== "company" ? !!errors[val] : false}
                      // helperText={val !== "company" ? errors[val] : false}
                      helperText={
                        val !== "company" && val !== "email"
                          ? errors[val]
                          : val === "email" && errors.email
                            ? (

                              <div>
                                {errors.email}
                                {emailStatus ? 
                              //   fullDetails.Subscription === Types.reports ?
                              //   <Link to={CONFIG.Explorer_url} target="_blank" rel="noopener noreferrer" className='error-txt'>Sign in</Link>
                                  
                              //  :
                                <Link to={CONFIG.ForgotLink} target="_blank" rel="noopener noreferrer" className='error-txt'>Would you like to reset your password?</Link> 
                             : "" } </div>
                            )
                            : null
                      }
                      required={val !== "company" ? true : false}
                      className={`custom-fields ${key === "New Password" ? "new-password-field" : ""} ${formData.NewPassword.length !== 0 && formData.NewPassword.length < 6 ? "new-password-error" : ""} 
                      ${val === "email" && emailStatus ? "existing-user" : ""}  `}
                      inputProps={{
                        autoComplete: 'new-password', // 'new-password' is a non-standard value to disable auto-suggestions
                      }}
                      onFocus={(e) => e.target.setAttribute('autocomplete', 'off')} // Add this onFocus event handler
                      onPaste={(event) => {
                      if (val === "NewPassword" || val === "ConfirmPassword") {
                            event.preventDefault();
                        }
                      }}
                    />
                    {key === "New Password" && (
                      <div className="pwd-strength-meter">
                        <CommonPasswordStrengthMeter password={formData.NewPassword} actions={dataHandler} />
                      </div>
                    )}
                  </Grid>
                ))}
              </Grid>
            </form>
          </div>
        ))}

      </div>
      {loading && <div className="loader-wrap"><div className="loading"></div></div>}

    </>
  );
}

export default CreateAccount;
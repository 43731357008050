import React, { Fragment, useEffect, useState } from 'react';
import { Typography } from '@mui/joy';
import { Box, Tab, Breadcrumbs } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { CommonTabPanel } from '../../../commonFunctionalities/CommonTabPanel';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {api} from '../../../../../services/api';
import { handlingCatchErorr, handlingSuccessResponse } from '../../../../utils/errorHandling';
import AdminSubscriptionEdit from './AdminSubscriptionEdit';
import AdminCreditsModal from './AdminCreditsModal';
import { usersType, usersTypeId } from '../../../../utils/enums';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AdminProductTab from './AdminProductTab';

const AdminProductsDetailsEdit = (props) => {
    let { productId, permaLink, userId } = useParams();
    const navigate = useNavigate();
    let userData = JSON.parse(localStorage.getItem("userInfo"));
    const userEmail = localStorage.getItem('userEmail');
    const [loader, setLoader] = useState(false);
    const [loaderTwo, setLoaderTwo] = useState(false);
    const [userProductSubscriptions, setUserProductSubscriptions] = useState([]);
    const [tabValues, setTabValues] = useState('1');
    const [product, setProduct] = useState([]);

    useEffect(() => {
        if (!userProductSubscriptions.length) {
            handleUserProductSubscriptions();
        }
    }, [])

    useEffect(() => {
        if (!product.length) {
            handleProductMarketList();
        }
    }, [])

    const handleTabChange = (e, val) => {
        setTabValues(val);
    };

    const handleLoader = (trueOrFalse) => {
        setLoaderTwo(trueOrFalse);
    }
    const handleUserProductSubscriptions = () => {
        setLoader(true);
        api.get(`subscription/userproductsubscriptions/${productId}/${userId}`)
            .then((res) => {
                setUserProductSubscriptions(res.data);
                setLoader(false);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
                setLoader(false);
            });
    };


    const handleUserCreditsChangedValue = (val, handleUserCredits) => {
        setLoader(true);
        api.put(`Product/updateuserlimits/${permaLink}/${userId}/${val?.units}`)
            .then((res) => {
                if (res.status === 200) {
                    handlingSuccessResponse(res, "Success! Credits have been updated accordingly.")
                }
                handleUserCredits();
                setLoader(false);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
                setLoader(false);
            });
    };

    const handleProductMarketList = () => {
        setLoader(true)
        api.get(`Admin/products`)
            .then((res) => {
                if (res.status === 200) {
                    setProduct(res.data)
                }

                setLoader(false);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
                setLoader(false);
            });
    }

    let userDetails = userData !== null && (userData?.secureId !== null && userData?.secureId !== undefined) ? userData?.secureId  : ""
    let navBackButton = userData?.usertypeId === usersTypeId.Admin ? "/admin" : "/user";
    return (
        <Fragment>
            {loader && <div className="loader-wrap"><div className="loading"></div></div>}
            {loaderTwo && <div className="loader-wrap"><div className="loading"></div></div>}
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="breadcrumb-list">
                <Link color="inherit" to={navBackButton}>Users</Link>
                <Link color="inherit" to={-1}>Details</Link>
                <Typography color="text.primary">Subscriptions</Typography>
                <Typography color="text.primary"><strong>{userEmail} </strong></Typography>
            </Breadcrumbs>
            <Box sx={{ width: '100%', typography: 'body1' }} className="tab-design">
                <TabContext value={tabValues}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="tab-box">
                        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                            <Tab label="Subscription" value="1" />
                            <Tab label="Market List" value="2" />
                            {
                                props.from === usersType.user ||
                                    userProductSubscriptions?.products &&
                                    userProductSubscriptions.products.length > 0 &&
                                    userProductSubscriptions.products.some(isPre => isPre.isPremium)
                                    ? ""
                                    : <AdminCreditsModal permaLink={permaLink} userId={userId} handleUserCreditsChangedValue={handleUserCreditsChangedValue} />
                            }

                        </TabList>
                    </Box>
                    <CommonTabPanel value="1"><AdminSubscriptionEdit loader={loader} isFrom={props.from} userProductSubscriptions={userProductSubscriptions}  userId={userId} handleUserProductSubscriptions={handleUserProductSubscriptions} handleLoader={handleLoader} /></CommonTabPanel>
                    <CommonTabPanel value="2">
                       
                        <AdminProductTab  
                        isFrom={props.from}
                            productList={product} 
                            userId={userId}
                            secureId = {userDetails}
                            loader={loader}
                            TabValue= {tabValues}
                            />
                    </CommonTabPanel>
                </TabContext>
            </Box>
        </Fragment>
    )
}

export default AdminProductsDetailsEdit
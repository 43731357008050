export const CommonSearchFunction = (searchedText, usersSearchedData, searchedFieldsString) => {
    let value = typeof (searchedText) !== "string" ? searchedText : searchedText.trim();
    let convertedToString = String(value);
    if (convertedToString !== "") {
        let returnedArray = usersSearchedData.filter(filteredData => {
            for (let i = 0; i < searchedFieldsString.length; i++) {
                if (String(filteredData[searchedFieldsString[i]]).toLowerCase().includes(convertedToString.toLowerCase())) return filteredData;
            }
      
            return "";
        });
        return { data: returnedArray, isEmptyOrNot: "notEmpty" };
    } else return { data: "", isEmptyOrNot: "empty" };
}
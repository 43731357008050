import { Fragment, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useNavigate, Link } from 'react-router-dom';
import {api} from '../../services/api';
import { errorMsg, handlingCatchErorr } from '../utils/errorHandling';
import { validationFunction } from '../Home/commonFunctionalities/AllValidation';
import PasswordConfirmation from '../Home/modals/PasswordConfirmation';
import { CONFIG } from '../utils/config';

let initialData = { email: "" };
const ForgotPassword = () => {
    const navigate = useNavigate();
    const isFirstRender = useRef(true);
    const [forgoterror, setforgoterror] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [emailData, setEmailData] = useState(initialData);
    const [isSubmitOrNot, setIsSubmitOrNot] = useState(false);
    const [isemail,setIsemail]  = useState(false);
    
    const handleChangeEmail = (e) => {
        if (isSubmitOrNot) setIsSubmitOrNot(false); // Reset isSubmitOrNot when email changes
        const { name, value } = e.target;
        setEmailData((prevData) => ({ ...prevData, [name]: value }));
        setforgoterror({}); // Reset forgoterror when email changes
    };

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return
        }
        if (isSubmitOrNot) handleSignInPostSubmit();
    }, [forgoterror])

    const errorMessageFunc = (errorName, errorMessage) => {
        setforgoterror((prevData) => ({ ...prevData, [errorName]: errorMessage }));
    }

    const handleSignInPreSubmit = (e) => {
        setIsSubmitOrNot(true);
        e.preventDefault();
        Object.entries(emailData).map(([key, val]) => {
            let returnedErrors = validationFunction(key, val)
            errorMessageFunc(key, returnedErrors);
            return '';
        });
    }

    const handleSignInPostSubmit = (e) => {
        localStorage.clear();
        let areThereErrorMsgOrNot = Object.entries(forgoterror).length !== 0 ? Object.entries(forgoterror).every(([key, val]) => val === "") ? true : false : true;
        if (areThereErrorMsgOrNot) {
            setIsLoading(true);
            api.post('/account/forgotpassword', emailData)
                .then((response) => {
                    setIsLoading(false);
                    setIsemail(true)
                })
                .catch((error) => {
                    setIsLoading(false);
                    
                    if (error.response?.status === 400) {
                        errorMsg("If this email address has an active account, you will receive a password reset link in a few minutes. If you need further assistance please contact support.")
                       
                        localStorage.clear();
                        setTimeout(()=>{
                            const url = CONFIG.Explorer_url
                            window.location.replace(url);
                        },3000)
                       
                    }else{
                        handlingCatchErorr(error, navigate);
                    }
                });
        }
    }
    return (
        <Fragment>
            
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                
                <h2>Forgot Password</h2>
                <p>Provide your email address and we will send you a link to update your password.</p>
                <div >
                    <TextField
                        name="email"
                        placeholder="Enter Your Email id"
                        error={Object.values(forgoterror).some((error) => !!error)}
                         helperText={Object.values(forgoterror).filter((error) => !!error)}
                        onChange={handleChangeEmail}
                        type="email"
                        onInput={handleChangeEmail}
                    />
                </div>
                <Stack spacing={2} direction="row">
                    <Button type="submit" variant="contained" onClick={handleSignInPreSubmit} >Send</Button>
                    <Link to={CONFIG.MIG_login_url}>Cancel</Link>
                </Stack>
            
            </Box>
            <PasswordConfirmation open={isemail} text="The password reset link has been sent to your email."/>
            {isLoading && <div className="loader-wrap"><div className="loading"></div></div>}
        </Fragment>
    );
}

export default ForgotPassword
import { Button, Modal, ModalClose, Sheet, Typography } from '@mui/joy';
import {  useEffect, useRef, useState } from 'react';
import { Grid, TextField} from '@mui/material';
import { validationFunction } from '../../../commonFunctionalities/AllValidation';
import {api} from '../../../../../services/api';
import { handlingCatchErorr } from '../../../../utils/errorHandling';
import { useNavigate } from 'react-router-dom';

let textFieldVariables = { "Number of market credits available": "units" };
let widthValues = { "units": 12 };
export default function AdminCreditsModal(props) {
    let { handleUserCreditsChangedValue, permaLink, userId } = props;
    const navigate = useNavigate();
    const isFirstRender = useRef(true);
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [userCredits, setUserCredits] = useState([]);
    const [credits, setCredits] = useState([]);
    const [errors, setErrors] = useState({});
    const [isNextStep, setIsNextStep] = useState(false);
    const [isDisabled,setIsDisabled]  = useState(false);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return
        }
        if (isNextStep) handleOKButton();
    }, [errors])

    useEffect(() => {
        handleUserCredits(); // This will run only once when the component mounts
    }, []);

    const handleOKButton = () => {
        let areThereErrorMsgOrNot = Object.entries(errors).length !== 0 ? Object.entries(errors).every(([key, val]) => val === "") ? true : false : true;
        if (areThereErrorMsgOrNot) {
            setIsDisabled(false)
            handleCancel();
            handleUserCreditsChangedValue(credits, handleUserCredits);
           
        }
    };

    const handleUserCredits = () => {
        setLoader(true);
        api.get(`Product/userproductlimits/${permaLink}/${userId}`)
            .then((res) => {
                setUserCredits(res.data.credits);
                setCredits(res.data.credits);
                setLoader(false);
            })
            .catch((err) => {
                setLoader(false);
                handlingCatchErorr(err, navigate);
            });
    };

    const handleChange = (e) => {
        if (isNextStep) setIsNextStep(!isNextStep);
        const { name, value } = e.target;
        setIsDisabled(true)
        setCredits((prevData) => ({ ...prevData, [name]: value }));
        let returnedErrors = validationFunction(name, value);
        errorMessageFunc(name, returnedErrors);
    };

    const errorMessageFunc = (errorName, errorMessage) => {
        setErrors((prevData) => ({ ...prevData, [errorName]: errorMessage }));
    }

    const handleCancel = () => {
        setOpen(false);
        setErrors({});
        setCredits(userCredits);
        setIsNextStep(false);
        setIsDisabled(false)
    };

    const handleCheckCancel = (e, reason) => {
        if (reason === 'closeClick') handleCancel(false);
    }

    const handleOKPreSubmit = () => {
        setIsNextStep(true);
        Object.entries(credits).map(([key, val]) => {
            let returnedErrors = validationFunction(key, val)
            errorMessageFunc(key, returnedErrors);
            return '';
        });
    }

    const handleOnclickOpen = () => {
        setOpen(true);
    }
    const handleKeyDown = (event) => {
        if (event?.key === '-' || event?.key === '+') event.preventDefault();
    }
    return (
        <div className='credit_right_align'>
            <div className='available_credits_value' >Available Credits: <span>{credits.units ? credits.units : "N/A"}</span></div>
            <Button variant="outlined" color="neutral" className='credit_link' onClick={handleOnclickOpen}>
                {credits.units >0 ? "Update" : "Add" }
            </Button>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={handleCheckCancel}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                className="delete-model-popup add-credits"
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 300,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.body',
                        }}
                    />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                       {userCredits.units >0 ? "Update" : "Add" } Credits
                    </Typography>
                    <form className='custom-form'>
                        <Grid container spacing={3}>
                            {Object.entries(textFieldVariables).map(([key, val]) => (
                                <Grid key={key} item xs={widthValues[val]}>
                                    {!loader ?
                                        <TextField
                                            name={val}
                                            label={key}
                                            type='number'
                                            value={credits?.[val] === null ? "" : credits[val]}
                                            onChange={handleChange}
                                            fullWidth
                                            error={!!errors[val]}
                                            helperText={errors[val]}
                                            required={true}
                                            InputProps={{
                                                inputProps: { min: 0 }
                                            }}
                                            onKeyDown={handleKeyDown}
                                            className='custom-fields'
                                        />
                                        : <Typography
                                            component="h5"
                                            id="modal-title"
                                            textColor="inherit"
                                            fontWeight="lg"
                                            mb={1}
                                        >
                                            Loading ...
                                        </Typography>}
                                </Grid>
                            )
                            )}
                            <Grid item xs={12} className='button-alignment'>
                                <Button className='cancel-btn' onClick={handleCancel}>Cancel</Button>
                                <Button className='success-btn' type="button" disabled={loader} id={!isDisabled ? "disabled-bg" : ""} onClick={handleOKPreSubmit}>OK</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Sheet>
            </Modal>
        </div>
    );
}
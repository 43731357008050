import { Typography } from '@mui/material';
import React from 'react';

const CommonMessages = ({ message, type, loading }) => {
  const getMessageStyle = () => {
    switch (type) {
      case 'success':
        return { color: 'green' };
      case 'error':
        return { color: 'red' };
      default:
        return { color: 'black' };
    }
  };

  return (
    <>
      {loading && (
        <div className="account-activate payment-success" style={{ borderRadius: '10px' }}>
          <Typography className='txt-session' data-testid='id'>
            <p className='text-center'>
              Please wait while we process your request...
            </p>
          </Typography>
        </div>
      )}
    </>
  );
};

export default CommonMessages;

import { Grid, Typography, Button, Card } from "@mui/material";
import React, { useEffect, useRef, useState } from "react"
import SubscriptionType from "./subscriptionType";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { api } from "../../services/api";
import { handlingCatchErorr, handlingSuccessResponse } from "../utils/errorHandling";
import OrderSummary from "./orderSummary";
import CreateAccount from "./createAccount"
import Payments from "./payment";
import StateSelect from "./stateSelect";
import National from "./national";
import { validationFunction } from "../Home/commonFunctionalities/AllValidation";
import { Types, getCurrencyName } from "../utils/enums";
import { CommonUpgrade } from "./CommonUpgrade";
import { CommonSearchFunction } from "../Home/commonFunctionalities/CommonSearchFunction";
import CommonForAll from "../Home/modals/commonModals/CommonForAll";
import { CONFIG } from "../utils/config";
import GoogleAnalytics from "./GoogleAnalytics";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AvailableTitleUK from "../Pricing/AvailableTitleUK"
import AvailableTitleEurope from "../Pricing/AvailableTitleEurope"
import EuropeData from "../Pricing/EuropeData";
import UkData from "../Pricing/UkData";


const initialTermsOfConditions = { "second": false };
// const initialTermsOfConditionsForFree = { "first": false };
// const initialTermsOfConditionsForReport = { "first": false,"second": false };

const terms = [
    // {
    //     id: "0",
    //     title: "Agreement to the terms of Use ",
    //     name: "first"

    // },
    {
        id: "1",
        title: "Agreement to the terms of Use ",
        name: "second",

        type: "Account Type : Single use / One person (no account sharing)",
    },
    // {
    //     id: "2",
    //     title: "Agreement to Recurring Subscription ",
    //     name: "third"

    // },
];


const MaxStateText = "yes"

let initialFormData = { fName: '', lName: '', company: '', registerphone: '', email: '', NewPassword: '', ConfirmPassword: '' };
const Home = (props) => {
    const parms = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [allTerms, setAllTerms] = useState(terms);
    const [subscriptionDetails, setSubscriptionDetails] = useState([]);
    const [loader, setLoader] = useState(false);
    const [des, setDes] = useState({ name: '', description: '' });
    const [summaryDetails, setSummaryDetails] = useState({
        Subscription: "",
        AccountInfo: { ...initialFormData },
        state: [], // Assuming 'state' needs to be an array
        payment: "",
        LookupKey: "",
        Units: "",
        subkey: "",
        subTypeId: "",
        packageName: "",
    });
    const [paymentLength, setPaymentLength] = useState([]);
    const [isPremium, setIsPremium] = useState();
    const [isNational, setIsNational] = useState();
    const searchParams = new URLSearchParams(location.search);
    const subParam = searchParams.get('sub');
    const keyParam = searchParams.get('key');
    const [accountBlurData, setAcountBlurData] = useState({ ...initialFormData })
    let userData = JSON.parse(localStorage.getItem("userInfo"));
    let isAuthenticated = (userData?.secureId !== null && userData?.secureId !== undefined) && (userData?.token !== null && userData?.token !== undefined) ? true : false;
    const [natKey, setNatKey] = useState();
    const [defaultCountryId, setDefaultCountryId] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);
    const [nationalIndex, setNationalIndex] = useState(null);
    const isFirstRender = useRef(true);
    const [checked, setChecked] = useState([]);
    const [errors, setErrors] = useState({});
    const [isNextStep, setIsNextStep] = useState(false);
    const [isTerm, setIsTerm] = useState(false);
    const [validation, setValidation] = useState(false);
    const [isChecked, setIsChecked] = useState(initialTermsOfConditions);
    const [searchedText, setSearchedText] = useState("");
    const [list, setList] = useState([]);
    const [usersSearchedData, setUsersSearchedData] = useState([]);
    const [stateSearchedData, setStateSearchedData] = useState([]);
    const [openData, setOPenData] = useState(false)

    const [maxState, setMaxState] = useState(false);
    const [priceDetails, setPriceDetails] = useState([]);
    const [priceLoader, setPriceLoader] = useState(false);
    const [status, setStatus] = useState(false);
    const [stateLoader, setStateLoader] = useState(false);
    const [isUsSubscription, setIsUsSubscription] = useState(false);
    const [pymtEntities, setPymtEntities] = useState([]);
    const [selectedPymtEntity, setSelectedPymtEntity] = useState([]);
    const [initialSub, setInitialSub] = useState([]);
    const [initialSubKey, setInitialSubKey] = useState([]);
    const [showPymtEntities, setShowPymtEntities] = useState(false);

    const handleChange = (e) => {
        if (isNextStep) setIsNextStep(!isNextStep);
        const { name } = e.target;
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

        let returnedInfo = validationFunction(name, value, summaryDetails.AccountInfo);
        if (name === "email") { setStatus(false) }

        if (name === "registerphone") {
            setSummaryDetails((prevData) => ({
                ...prevData, AccountInfo: {
                    ...prevData.AccountInfo, [name]: returnedInfo?.phoneData
                }
            }));
            ErrorMessageFunc(name, returnedInfo);
        } else {
            setSummaryDetails(prevData => ({
                ...prevData,
                AccountInfo: {
                    ...prevData.AccountInfo,
                    [name]: value
                }
            }));
            let returnederr = validationFunction(name, value, summaryDetails.AccountInfo);
            ErrorMessageFunc(name, returnederr);
            handlePasswordValidation(name, value, summaryDetails.AccountInfo);

        };
    }

    const handlePasswordValidation = (name, value, updatedData) => {
        if (name === "NewPassword" && updatedData.ConfirmPassword.length > 0) {
            if (value !== updatedData.ConfirmPassword) {
                ErrorMessageFunc("ConfirmPassword", "Passwords does not match");
            } else {
                ErrorMessageFunc("ConfirmPassword", "");
            }
        }
    }

    const handleBlur = (e) => {
        const { name } = e.target;
        if (name === "email" && summaryDetails.Subscription !== Types.reports) {
            handleExistingUserorNot(e.target.name, e.target.value);
        }

        setAcountBlurData(summaryDetails.AccountInfo)
    }

    const handleExistingUserorNot = (name, value) => {
        // setStatus(false)
        if (value) {
            const email = value;
            if (!errors.email) {
                api.get(`/account/user/exist/${email}`)
                    .then((res) => {
                        if (res.data === true) {
                            // if(summaryDetails.Subscription === Types.reports){
                            //     setErrors((prevErrors) => ({
                            //         ...prevErrors,
                            //         email: `Email Already linked to an Active Account. Please login to purchase a market report or report bundle`
                            //     }));
                            // }else{
                            setErrors((prevErrors) => ({
                                ...prevErrors,
                                email: `Email Already linked to an  ${CONFIG.Application_Name}. Please use a different email address or`
                            }));
                            // }

                            setStatus(true)
                        }
                    }).catch((err) => {
                        handlingCatchErorr(err, navigate)
                        setStatus(false)
                    })
            } else {
                setLoader(false);
            }
        }
    }

    const handleChangeSearch = (e) => {
        let { name, value } = e.target;
        if (name === "searchField") setSearchedText(value);
    };

    const loadStateList = (data) => {
        setStateLoader(true)
        const productId = data?.productId;
        api.get(`lookup/product/states/${productId}`)
            .then((res) => {
                setList(res.data);
                let isPreSunbsCribedStateList = res.data
                    .filter(dt => dt?.isSubscribed)
                    .map(ft => ({ id: ft?.id, name: ft?.name }));
                setChecked(isPreSunbsCribedStateList);
                setUsersSearchedData(res.data);
                setStateSearchedData(res.data)
                let isUnlimitedUs = res.data
                    .every(list => (list.hasCountrySubscription === true));
                setIsUsSubscription(isUnlimitedUs)
                setStateLoader(false);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
                setStateLoader(false);
            });
    }

    useEffect(() => {
        const searchedFieldsString = ["name"];
        let { data, isEmptyOrNot } = CommonSearchFunction(searchedText, list, searchedFieldsString, "");
        if (isEmptyOrNot === "notEmpty") setStateSearchedData(data);
        else if (isEmptyOrNot === "empty") setStateSearchedData(list);
    }, [searchedText]);

    const handleChangeState = (event, id, stateName) => {
        if (isNextStep) setIsNextStep(!isNextStep);
        const isChecked = event.target.checked;
        const { name } = event.target;
        let resultArray = [];
        if (isChecked && checked.length >= 7) setMaxState(true)
        if (isChecked && checked.length <= 6) {
            resultArray = checked?.filter(CheckedId =>
                CheckedId.id !== id
            )
            resultArray.push({ id: id, name: stateName });

        }
        else {
            resultArray = checked?.filter(CheckedId =>
                CheckedId.id !== id
            )
        };
        // Filter subscriptionDetails based on subscriptionType
        const filteredSubscriptions = subscriptionDetails?.subscriptions?.filter(
            name => name.subscriptionType === Types.state
        );

        // Initiate loadPriceDetails for each filtered subscription
        const loadPriceDetailsPromises = filteredSubscriptions.map(val =>
            loadPriceDetails(val.subscriptionkey, resultArray.length, val.subscriptionTypeId)
        );
        let returnedInfo = validationFunction("regState", resultArray);
        ErrorMessageFunc("regState", returnedInfo);
        setChecked(resultArray);

        setActiveIndex(null)
        setSummaryDetails(prev => ({ ...prev, payment: "" }));
    };

    const handleDelete = (id) => {
        if (isNextStep) setIsNextStep(!isNextStep);

        if (id) {
            let resultArray = checked?.filter(CheckedId => CheckedId.id !== id)
            let returnedInfo = validationFunction("regState", resultArray);
            ErrorMessageFunc("regState", returnedInfo);
            setChecked(resultArray);

            // Filter subscriptionDetails based on subscriptionType
            const filteredSubscriptions = subscriptionDetails?.subscriptions?.filter(
                name => name.subscriptionType === Types.state
            );


            // Initiate loadPriceDetails for each filtered subscription
            const loadPriceDetailsPromises = filteredSubscriptions.map(val =>
                loadPriceDetails(val.subscriptionkey, resultArray.length, val.subscriptionTypeId)
            );

            // }
        };


        setSummaryDetails(prev => ({ ...prev, payment: "" }));

    };

    let initialSubscriptionLoad = (link, isSubKey) => {
        let pymts = [];
        const isMarkets = (isSubKey === undefined || isSubKey === null) ? "" : isSubKey
        setLoader(true);
        api.get(`payment/stripeentities/${link}`)
            .then((res) => {
                pymts = res.data;
                setPymtEntities(pymts);
                if (pymts.length == 1) setSelectedPymtEntity(pymts[0]);
            }).catch((err) => {
                handlingCatchErorr(err, navigate);
                setLoader(false);
            });
        api.get(`product/productsubscriptions/${link}?lookupkey=${isMarkets}`)
            .then((res) => {
                const propductData = res.data.products;
                setSubscriptionDetails(propductData)
                if (isAuthenticated) loadStateList(propductData);

                const initialSelectedSubscriptions = propductData.subscriptions
                    .filter(takeName => takeName.subscriptionkey === isSubKey)
                    .map(val => val);

                const subscription = initialSelectedSubscriptions.length > 0
                    ? initialSelectedSubscriptions[0]?.subscriptionType // Accessing the first element directly
                    : "";
                const getKey = initialSelectedSubscriptions.length > 0
                    ? initialSelectedSubscriptions[0] : "";
                setInitialSub(getKey);

                const packageName = initialSelectedSubscriptions.length > 0
                    ? initialSelectedSubscriptions[0]?.packagename // Accessing the first element directly
                    : "";
                const checkSubOrNot = getKey?.subCategoryList && getKey?.subCategoryList?.length > 0 &&
                    getKey?.subCategoryList?.filter(isub => isub.isSubscribed).map((val) => val);

                const isPremiumOrNot = getKey?.subCategoryList && getKey?.subCategoryList?.length > 0 &&
                    getKey?.subCategoryList?.filter(isub => isub.isSubscribed && isub.isPremium).map((val) => val);

                const matchList = (keyParam !== null && checkSubOrNot && checkSubOrNot?.length > 0
                    ? checkSubOrNot.filter(val => val.subscriptionkey === keyParam)
                    : []);
                const getInitalSubKey =
                    matchList && matchList?.length > 0
                        ? matchList[0]
                        : checkSubOrNot?.length > 0 ?
                            checkSubOrNot[0]
                            : getKey;

                setInitialSubKey(getInitalSubKey);

                const checksubscription = subscription;
                // const subScriptionDefault = propductData?.subscriptions?.every(val => !val.selected) && !isMarkets ?
                //     "Unlimited National" : subscription  // only premalink is present in the url ,unlimited select by default
                const descriptions = propductData.subscriptions
                    .filter(key => key.subscriptionType === checksubscription)
                    .map(feat => feat.features);

                //     const setActiveNational = propductData?.subscriptions?.map((details) =>
                //         details.subCategoryList
                //             ?.filter((val, priceIndex, self) =>
                //                 val.subscriptionPriceName !== "" &&
                //                 self.findIndex((v) => v.subscriptionPriceName === val.subscriptionPriceName) === priceIndex
                //             )
                //             .map((data, index) => data)
                //     ).filter((array) => array !== undefined && array !== null);

                //     const nationalKeyDefault = setActiveNational?.length > 0 && 
                //     setActiveNational.map((val, index) =>
                //       val.filter((value) => value.defaultCountryId === "2")
                //   );



                //     let objectIndex = -1;

                //     setActiveNational.forEach((subArray) => {
                //         const index = subArray.findIndex(obj => obj.defaultCountryId === countryId.unlimitedUs);
                //         if (index !== -1) {
                //             objectIndex = index;
                //             return;
                //         }
                //     });
                //     const setNationlDefault = propductData?.subscriptions?.every(val => !val.selected) && !isMarkets ?
                //         objectIndex
                //         : null
                //     setNationalIndex(setNationlDefault)

                setDes({ name: subscription, description: descriptions })
                setSummaryDetails({
                    Subscription: checksubscription,
                    AccountInfo: { ...initialFormData },
                    state: [], // Assuming 'state' needs to be an array
                    payment: "",
                    LookupKey: "",
                    Units: "",
                    subkey: keyParam === "null" || keyParam === null ? getInitalSubKey.subscriptionkey : keyParam,
                    subTypeId: getKey.subscriptionTypeId,
                    packageName: packageName

                });

                // if (checksubscription === Types.free) {
                //     setAllTerms(FreeTerms);
                //     setIsChecked(initialTermsOfConditionsForFree);
                // }
                // else if (checksubscription === Types.reports){
                //     setAllTerms(reportTerms);
                //     setIsChecked(initialTermsOfConditionsForReport);   
                // }

                // else {
                setAllTerms(terms);
                setIsChecked(initialTermsOfConditions);
                // }
                setIsPremium(isPremiumOrNot?.length)
                setIsNational(checkSubOrNot)
                setLoader(false);

                // if (propductData?.subscriptions?.every(val => !val.selected) && !isMarkets) {

                //                 loadPriceDetails(
                //                     nationalKeyDefault[0][0].subscriptionkey,
                //                   0,
                //                     7
                //                 );
                //             }


                if (Object.keys(getInitalSubKey).length > 0) {
                    if (checksubscription === Types.national) {
                        if (subParam || isSubKey) {
                            if (checkSubOrNot.length > 0) {
                                loadPriceDetails(getInitalSubKey.subscriptionkey,
                                    getKey.statesCount,
                                    getKey.subscriptionTypeId,
                                    propductData?.productStripeEntityid
                                );
                            }
                        }
                    } else if (checksubscription === Types.state) {
                        if (subParam || isSubKey) {
                            if (getKey.statesCount) {
                                loadPriceDetails(
                                    getInitalSubKey.subscriptionkey,
                                    getKey.statesCount,
                                    getKey.subscriptionTypeId
                                );
                            }
                        }
                    } else {
                        const isSubscribed = checksubscription !== Types.free;
                        const hasMultipleEntities = pymts.length > 1;

                        if ((subParam || isSubKey) && isSubscribed) {
                            if (hasMultipleEntities) {
                                loadPriceDetails(
                                    getInitalSubKey.subscriptionkey,
                                    getKey.statesCount,
                                    getKey.subscriptionTypeId,
                                    propductData.productStripeEntityid
                                );
                            } else {
                                loadPriceDetails(
                                    getInitalSubKey.subscriptionkey,
                                    getKey.statesCount,
                                    getKey.subscriptionTypeId
                                );
                            }
                        }
                    }




                }
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
                setLoader(false);
            });

    };

    let loadPriceDetails = (key, count, typeId, id) => {
        setPriceLoader(true);
        const getPrice = {
            "subscriptionKey": key,
            "minCount": count,
            "subscriptionTypeId": typeId,
            "StripeEntityId": id ? id : selectedPymtEntity ? selectedPymtEntity.id : 1
        };
        api.post('/Product/subscription/prices', getPrice)
            .then((res) => {
                // Sort the response data in ascending order based on a property (e.g., price)
                const sortedPriceDetails = res.data.sort((a, b) => a.sortOrder - b.sortOrder);
                setPriceDetails(sortedPriceDetails)
                const selectPayment = sortedPriceDetails?.filter(val => val.selected)
                setPaymentLength(selectPayment)
                setPriceLoader(false);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
                setPriceLoader(false);
            });
    }

    useEffect(() => {
        if ((!subParam && parms?.permalink && parms?.isSubKey !== "") || (subParam && parms?.permalink)) {
            if (!subscriptionDetails.length) {
                initialSubscriptionLoad(parms.permalink, subParam || parms.isSubKey);
            }
        } else if (!parms?.permalink) {
            const url = CONFIG.Explorer_url
            window.location.replace(url)
        }

    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling behavior
        });
    };

    useEffect(() => {
        scrollToTop();
    }, [])

    const handleSelectPayment = (index, name, details) => {
        if (index === activeIndex) {
            setActiveIndex(null); // Deselect the card if it's already selected
            setSummaryDetails(prevState => ({
                ...prevState,
                payment: "",
                LookupKey: "",
                Units: "",
            }));
            let returnedInfo = validationFunction("payment", name);
            ErrorMessageFunc("payment", returnedInfo);

            let countryrIntegerArray = [];

            setDefaultCountryId(countryrIntegerArray);
        } else {
            setActiveIndex(index);
            setSummaryDetails(prevState => ({
                ...prevState,
                payment: name,
                LookupKey: details.lookupKey,
                Units: details.minCount,
            }));
            let returnedInfo = validationFunction("payment", name);
            ErrorMessageFunc("payment", returnedInfo);

            let countryIntegerArray = [];
            if (details.defaultCountryId && typeof details.defaultCountryId === 'string') {
                if (details.defaultCountryId.includes(',')) {
                    let countryIds = details.defaultCountryId.split(',');
                    countryIds.forEach(id => {
                        let parsedCountryId = parseInt(id.trim());
                        if (!isNaN(parsedCountryId)) {
                            countryIntegerArray.push(parsedCountryId);
                        }
                    });
                } else {
                    let parsedCountryId = parseInt(details.defaultCountryId.trim());
                    if (!isNaN(parsedCountryId)) {
                        countryIntegerArray.push(parsedCountryId);
                    }
                }
            }
            setDefaultCountryId(countryIntegerArray); // Select the clicked card
        }
        if (isNextStep) setIsNextStep(!isNextStep);

    };

    const handleSelectNational = (index, name, val, min, typeId) => {
        if (isNextStep) setIsNextStep(!isNextStep);
        setSummaryDetails(prevState => ({
            ...prevState,
            national: name,
        }));
        setNatKey(val.subscriptionkey)
        let countryIntegerArray = [];

        if (val.defaultCountryId && typeof val.defaultCountryId === 'string') {
            if (val.defaultCountryId.includes(',')) {
                let countryIds = val.defaultCountryId.split(',');
                countryIds.forEach(id => {
                    let parsedCountryId = parseInt(id.trim());
                    if (!isNaN(parsedCountryId)) {
                        countryIntegerArray.push(parsedCountryId);
                    }
                });
            } else {
                let parsedCountryId = parseInt(val.defaultCountryId.trim());
                if (!isNaN(parsedCountryId)) {
                    countryIntegerArray.push(parsedCountryId);
                }
            }
        }

        setDefaultCountryId(countryIntegerArray);
        let returnedInfo = validationFunction("national", name);
        ErrorMessageFunc("national", returnedInfo);
        setNationalIndex(index);
        setActiveIndex(null)
        setSummaryDetails(prev => ({ ...prev, payment: "" }));
        const stripeId = subscriptionDetails.productStripeEntityid ?
            subscriptionDetails.productStripeEntityid : selectedPymtEntity.id
        loadPriceDetails(val.subscriptionkey, min, typeId, stripeId)
    };

    const handleAgree = (e, name, isCheckorgree) => {
        let isCheckedOrNot = e?.target?.checked;
        if (name === "Reset") {
            setIsChecked(initialTermsOfConditions);
        } else {
            if (isCheckorgree === "notAgree") {
                if (!isCheckedOrNot) {
                    setIsChecked(prevState => ({
                        ...prevState,
                        [name]: isCheckedOrNot,
                    }));
                }
            } else if (isCheckorgree === "agreeBtn") {
                setIsChecked(prevState => ({
                    ...prevState,
                    [name]: true,
                }));
            }
            if (isCheckedOrNot !== undefined) {
                let returnedErrorsFirst = validationFunction("first", isCheckedOrNot, summaryDetails.Subscription);
                ErrorMessageFunc("first", returnedErrorsFirst);
            }
        }
    };

    const handleLoadDes = (name, key, count, typeId, packageName) => {
        if (des.name !== name) {
            handleAgree("Reset")
            setIsTerm(true)
            // change the des based on the subscription type
            const descriptions = subscriptionDetails.subscriptions
                .filter(key => key.subscriptionType === name)
                .map(feat => feat.features);
            setDes({ name: name, description: descriptions })

            const getIsSubNationl = subscriptionDetails.subscriptions
                ?.flatMap(va => va?.subCategoryList?.filter(val => val.isSubscribed) || [])
                .filter(Boolean); // Filter out any undefined elements


            setDes({ name: name, description: descriptions })

            const keys = getIsSubNationl.length > 0 && pymtEntities.length <= 1 ? getIsSubNationl[0].subscriptionkey : key

            setSummaryDetails({ Subscription: name, subkey: keys, subTypeId: typeId, AccountInfo: { ...initialFormData }, state: [], payment: "", national: "", lookupKey: "", Units: "", packageName: packageName })
            setErrors({})
            setActiveIndex(null)
            setNationalIndex(null)
            setIsNextStep(false)
            setPriceDetails([])
            setPaymentLength([])
            let isPreSunbsCribedStateList = usersSearchedData
                .filter(dt => dt?.isSubscribed)
                .map(ft => ({ id: ft?.id, name: ft?.name }));
            setChecked(isPreSunbsCribedStateList);
            setNatKey("")
            setAcountBlurData(initialFormData)
            // if (name === Types.free) {
            //     setAllTerms(FreeTerms);
            //     setIsChecked(initialTermsOfConditionsForFree);
            // } else if (name === Types.reports){
            //     setAllTerms(reportTerms);
            //     setIsChecked(initialTermsOfConditionsForReport);   
            // }
            // else {
            setAllTerms(terms);
            setIsChecked(initialTermsOfConditions);
            setShowPymtEntities(false)
            setSelectedPymtEntity([])
            // }
            if (name !== Types.national && name !== Types.state && name !== Types.free && pymtEntities.length <= 1) {
                loadPriceDetails(key, count, typeId);
                setShowPymtEntities(true);
            } else if (name === Types.state && isPreSunbsCribedStateList.length > 0) {
                loadPriceDetails(key, isPreSunbsCribedStateList.length, typeId);
            } else if (name === Types.national && getIsSubNationl.length > 0) {
                loadPriceDetails(getIsSubNationl[0].subscriptionkey, count, typeId);
            }
            else if (pymtEntities.length > 1 && name !== Types.free && name !== Types.national && name !== Types.state && (subscriptionDetails.productStripeEntityid)) {
                loadPriceDetails(key, count, typeId, subscriptionDetails.productStripeEntityid);
            }
        }

    };

    let handlePymtEntityClick = (e) => {
        setSelectedPymtEntity(e);
        if (summaryDetails.Subscription !== Types.national) {
            loadPriceDetails(summaryDetails.subkey, null, summaryDetails.subTypeId, e.id);
        }
        setSummaryDetails(prev => ({ ...prev, payment: "" }));
        setActiveIndex(null)


    }

    const ErrorMessageFunc = (errorName, errorMessage) => {
        if (errorName === "registerphone") setErrors((prevData) => ({ ...prevData, [errorName]: errorMessage.errMsg }));
        else setErrors((prevData) => ({ ...prevData, [errorName]: errorMessage }));
    }

    const handleValidationSteps = () => {
        setIsNextStep(true);
        if (!isAuthenticated) {
            // Check create account validation 
            Object.entries(summaryDetails.AccountInfo).map(([key, val]) => {
                // Skip validation for password fields if subscription type is 'report'
                if (summaryDetails.Subscription === Types.reports && (key === 'NewPassword' || key === 'ConfirmPassword')) {
                    return ''; // Skip validation for password fields
                }

                let returnedErrors = validationFunction(key, val, summaryDetails.AccountInfo);
                ErrorMessageFunc(key, returnedErrors);

                return '';
            });
        }

        const { second, ...otherValues } = isChecked;
        let checkAllIsCheckedOrNot = Object.values(isChecked).every(dt => dt)
        let returnedErrorsFirst = validationFunction("first", checkAllIsCheckedOrNot, summaryDetails.Subscription);
        ErrorMessageFunc("first", returnedErrorsFirst);

        if (summaryDetails.Subscription !== Types.free) {
            if (summaryDetails.payment === "") {
                let returnedErrors = validationFunction("payment", summaryDetails.payment)
                ErrorMessageFunc("payment", returnedErrors);
            } if (summaryDetails.Subscription === Types.state && (checked.length === 0)) {
                let returnedErrors = validationFunction("regState", checked)
                ErrorMessageFunc("regState", returnedErrors);
            }
        }

    }

    const handleSubmit = () => {
        let areThereErrorMsgOrNot = Object.entries(errors)?.length !== 0 ? Object.entries(errors).every(([key, val]) => val === "") ? true : false : true;
        setValidation(areThereErrorMsgOrNot)
        const { second, ...otherValues } = isChecked;
        let checkAllIsCheckedOrNot = Object.values(isChecked).every(dt => dt)

        if (areThereErrorMsgOrNot && checkAllIsCheckedOrNot) {
            setLoader(true)
            const userDetails = summaryDetails.AccountInfo;
            const subscription = summaryDetails.Subscription;
            const details = summaryDetails;
            const lookupKey = (subscription !== Types.free && details.LookupKey !== "") ? details.LookupKey : details.subkey && details.subkey !== "" ? details.subkey : "us-free-explorer";
            const units = (subscription !== Types.free && details.LookupKey !== "") ? details.Units : 0;
            const statelist = summaryDetails.Subscription === Types.state && checked.length > 0
                ? checked.map((state) => state.id)
                : null;
            const countrylist = defaultCountryId
            const userRegInfo = {
                "login": userDetails.email,
                "firstName": userDetails.fName,
                "lastName": userDetails.lName,
                "company": userDetails.company,
                "phone": userDetails.registerphone,
                "Password": userDetails.ConfirmPassword,
                "LookupKey": lookupKey,
                "Units": units,
                "Credits": 0,
                "stateList": statelist,
                "countryList": countrylist,
                "IsAccountTerm": isChecked.first,
                "IsTermUse": isChecked.second,
                "IsSubscriptionTerm": isChecked.third
            }
            const userpurchaseInfo = {
                "login": userDetails.email,
                "firstName": userDetails.fName,
                "lastName": userDetails.lName,
                "company": userDetails.company,
                "phone": userDetails.registerphone,
                "LookupKey": lookupKey,
                "Units": units,
                "Credits": 0,
                "ReferenceId": "",
                "ReferenceUrl": "",
                "SecureId": ""
            }
            if (isAuthenticated) {
                CommonUpgrade(statelist, countrylist, lookupKey, units, isChecked, navigate)
                    .then(() => {
                        // API call was successful, hide the loader
                        setLoader(false);
                    })
                    .catch((error) => {
                        // Handle the error and hide the loader
                        setLoader(false);
                    });
            } else if (summaryDetails.Subscription === Types.reports) {
                api.post("/purchase", userpurchaseInfo)
                    .then((res) => {
                        setLoader(false)
                        handlingSuccessResponse(res, "Account Created Successfully");
                        const url = res.data.url

                        if (url) {
                            window.location.replace(url);
                        }
                    })
                    .catch((err) => {
                        handlingCatchErorr(err, navigate);
                        setLoader(false)
                    });
            }
            else {
                api.post("/subscription/adduser", userRegInfo)
                    .then((res) => {
                        setLoader(false)
                        handlingSuccessResponse(res, "Account Created Successfully");
                        if (res.headers['location'] !== "" && res.headers['location'] !== null && res.headers['location'] !== undefined) {
                            window.location.replace(res.headers['location'])
                            // const newTabUrl = res.headers['location'];
                            // window.open(newTabUrl, '_blank');
                        } else {
                            navigate("/free-account-confirmation", {

                            });
                        }
                    })
                    .catch((err) => {
                        handlingCatchErorr(err, navigate);
                        setLoader(false)
                    });
            }
        }
    }

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return
        }
        if (isNextStep) handleSubmit();
    }, [errors])


    let isPreSunbsCribedStateList = usersSearchedData.filter(dt => dt?.isSubscribed).map(ft => ft?.id);
    let newlySelectedStates = checked.filter(ft => !isPreSunbsCribedStateList.includes(ft.id))

    const handleResetState = () => {
        if (isNextStep) setIsNextStep(!isNextStep);

        let isPreSunbsCribedStateLists = usersSearchedData
            .filter(dt => dt?.isSubscribed)
            .map(ft => ({ id: ft?.id, name: ft?.name }));
        setChecked(isPreSunbsCribedStateLists)
        setSearchedText("")
        const filteredSubscriptions = subscriptionDetails?.subscriptions?.filter(
            name => name.subscriptionType === Types.state
        );


        // Initiate loadPriceDetails for each filtered subscription
        const loadPriceDetailsPromises = filteredSubscriptions.map(val =>
            loadPriceDetails(val.subscriptionkey, isPreSunbsCribedStateLists.length, val.subscriptionTypeId)
        );
        // }
    };
    const stepNumber =
        (pymtEntities.length > 1 && summaryDetails.Subscription === Types.free) || (pymtEntities.length > 1 && !summaryDetails.Subscription) ? 2 :
            pymtEntities.length <= 1 && (!summaryDetails.Subscription || summaryDetails.Subscription === Types.free
                || (summaryDetails.Subscription === Types.national && !isAuthenticated)
                || (summaryDetails.Subscription === Types.state && !isAuthenticated))
                ? 2
                : (summaryDetails.Subscription === Types.basic && selectedPymtEntity.length <= 0) || newlySelectedStates.length <= 0
                    ? 3
                    : 4
    const paymentEntities = isAuthenticated && subscriptionDetails?.productStripeEntityid
        ? pymtEntities.filter(ele => ele.id === subscriptionDetails.productStripeEntityid)
        : pymtEntities;
    return (
        <>
            <GoogleAnalytics trackingId="G-RHYWKLQJR4" />
            <div className="singlepage-bg">
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} className="fixed-scroll">
                    {loader && <div className="loader-wrap"><div className="loading"></div> : </div>}


                    {!loader &&
                        <>
                            <Grid item xs={12} sm={12} md={9} className="top-title-bg">
                                <div className="title-bg">
                                    <Typography variant="h4">
                                        <span className="stepno-circle">1</span> {summaryDetails.Subscription === Types.reports ? "Select Your Purchase Option" : "Choose Your Subscription Type"}</Typography></div>

                                <SubscriptionType
                                    marketDetail={subscriptionDetails}
                                    summaryDetails={summaryDetails.Subscription}
                                    des={des}
                                    load={loader}
                                    handleLoadDes={handleLoadDes}
                                    isPremium={isPremium}
                                    IsUsSubscription={isUsSubscription}
                                    isAuthenticated={isAuthenticated}
                                    openPricePopup={openData}
                                    entityLength={pymtEntities}
                                    selectedPymtEntity={selectedPymtEntity}
                                />
                                {((summaryDetails.Subscription !== Types.free && summaryDetails.Subscription) && (pymtEntities.length > 1 && !loader)
                                    //  || (summaryDetails.Subscription === Types.reports && !loader && pymtEntities.length>1)
                                ) &&
                                    <React.Fragment>

                                        {/* {"notlogin?"} */}
                                        <div className="title-bg">

                                            <Typography variant="h4">
                                                <span className="stepno-circle">
                                                    {2}</span> {
                                                    paymentEntities.length == 1 ? "Currency" : "Choose a Currency"}</Typography></div>
                                        <Grid container spacing={2} className="box-padding currency-box">
                                            {paymentEntities.map(e => {
                                                debugger;
                                                return (
                                                    <Grid item xs={12} md={4} xl={3} lg={4}>
                                                        <Card key={e.id} className={selectedPymtEntity ? selectedPymtEntity.id === e.id ||
                                                            subscriptionDetails?.productStripeEntityid === e.id
                                                            ? 'payment-box active ' : "payment-box " : "payment-box"} onClick={() => handlePymtEntityClick(e)} name="btnChooseSubs">
                                                            {selectedPymtEntity ?
                                                                (selectedPymtEntity.id === e.id || subscriptionDetails.productStripeEntityid === e.id)
                                                                &&
                                                                <div className="box-tick"><CheckCircleOutlineIcon /> </div>
                                                                : ""}   <div className="payment-text">
                                                                <span className="currency-box-text text-subscription">
                                                                    {e.name}<br />
                                                                    <b>{e.currencycode}</b>
                                                                </span>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                )
                                            })
                                            }
                                            {(Object.keys(selectedPymtEntity).length > 1 || subscriptionDetails.productStripeEntityid) && des.description !== "" && des.name !== "" && (
                                                <p className="box-desc">
                                                    {/* {des.description} <br /> */}
                                                    <button className="clickhere " onClick={() => setOPenData(true)}>Click here</button> to compare subscription features.
                                                </p>
                                            )}
                                        </Grid>
                                    </React.Fragment>
                                }
                                <CommonForAll open={openData} showComponent={
                                    <div className="mig-pricing single-popup-design">

                                        {selectedPymtEntity.name === getCurrencyName.Europe ||
                                            (paymentEntities.find(id => id.currencyid === subscriptionDetails.productStripeEntityid)?.name === getCurrencyName.Europe) ? (
                                            <>  <AvailableTitleEurope />
                                                <EuropeData
                                                    from={"singel-checkout"}
                                                    currency={Object.keys(selectedPymtEntity).length > 0
                                                        ? selectedPymtEntity.currencycode
                                                        : paymentEntities.find(val => val.name === getCurrencyName.Europe)?.currencycode
                                                    }
                                                />
                                            </>
                                        ) : selectedPymtEntity.name === getCurrencyName.UK ||
                                            (paymentEntities.some(val => val.name === getCurrencyName.UK) && isAuthenticated) ? (
                                            <> <AvailableTitleUK />
                                                <UkData
                                                    from={"singel-checkout"}
                                                    currency={Object.keys(selectedPymtEntity).length > 0
                                                        ? selectedPymtEntity.currencycode
                                                        : paymentEntities.find(val => val.name === getCurrencyName.UK)?.currencycode
                                                    }
                                                />
                                            </>
                                        ) : null}
                                    </div>

                                }
                                    className={"box-popup-single-available"}
                                    close={() => {
                                        setOPenData(false)
                                    }}

                                />


                                {summaryDetails.Subscription === Types.state && isAuthenticated && (
                                    <React.Fragment>
                                        <div className="title-bg"><Typography variant="h4">
                                            <span className="stepno-circle">{pymtEntities.length <= 1 ? 2 : 3}</span> Select Your States</Typography></div>
                                        <StateSelect
                                            handleChangeState={handleChangeState}
                                            errors={errors}
                                            checked={checked}
                                            handleDelete={handleDelete}
                                            selectedState={newlySelectedStates}
                                            subscriptionDetails={subscriptionDetails}
                                            usersSearchedData={usersSearchedData}
                                            stateSearchedData={stateSearchedData}
                                            searchedText={searchedText}
                                            handleChangeSearch={handleChangeSearch}
                                            handleResetState={handleResetState}
                                            isPreSunbsCribedStateList={isPreSunbsCribedStateList}
                                            stateLoader={stateLoader}
                                            isUnlimitedUS={isUsSubscription}
                                        />
                                    </React.Fragment>
                                )
                                }
                                {isAuthenticated &&
                                    summaryDetails.Subscription && subscriptionDetails?.subscriptions?.length > 0 &&
                                    subscriptionDetails.subscriptions.filter(type => type.subscriptionType === summaryDetails.Subscription).every(
                                        subcatData => subcatData.subCategoryList?.length > 0
                                    ) && 
                                    (
                                        pymtEntities.length > 1 ?
                                            (Object.keys(selectedPymtEntity).length > 1 || (subscriptionDetails.productStripeEntityid))
                                            : true
                                    )                                   &&
                                    (
                                        <React.Fragment >
                                            {/* Show for "Unlimited National" */}
                                            <div className="title-bg"><Typography variant="h4">
                                                <span className="stepno-circle">{pymtEntities.length <= 1 ? 2 : 3}</span> Subscription Type</Typography></div>
                                            <National
                                                handleSelectNational={handleSelectNational}
                                                activeIndex={nationalIndex}
                                                errors={errors}
                                                loading={priceLoader}
                                                subscriptionDetails={subscriptionDetails}
                                                subscriptionType={summaryDetails.Subscription}
                                                isPremium={isPremium}
                                                subKey={summaryDetails.subkey}
                                                isNational={isNational}
                                            />
                                        </React.Fragment>
                                    )}
                                {priceLoader && <div className="loader-wrap"><div className="loading"></div> : </div>}
                                {priceDetails.length > 0 && priceDetails?.every(priceData => priceData.price > 0) && (
                                    <React.Fragment>
                                        <div className="title-bg">
                                            <Typography variant="h4">
                                                <span className="stepno-circle">
                                                    {(summaryDetails.Subscription === Types.basic && (Object.keys(selectedPymtEntity).length <= 0 && !subscriptionDetails.productStripeEntityid)) ||
                                                        (summaryDetails.Subscription === Types.reports && pymtEntities.length <= 0) ? 2 :
                                                        summaryDetails.Subscription === Types.national && pymtEntities.length > 1 ? 4 :

                                                            summaryDetails.Subscription === Types.state || summaryDetails.Subscription === Types.national ||
                                                                ((Object.keys(selectedPymtEntity).length > 1 || subscriptionDetails.productStripeEntityid) && pymtEntities.length > 1)
                                                                ? 3 : 2}</span> Select Payments
                                            </Typography>
                                        </div>
                                        <Payments
                                            handleSelectPayment={(index, name, details) =>
                                                handleSelectPayment(index, name, details)
                                            }
                                            activeIndex={activeIndex}
                                            errors={errors}
                                            priceDetail={priceDetails}
                                            subscriptionTypes={summaryDetails.Subscription}
                                            loading={priceLoader}
                                            stateCount={newlySelectedStates.length}
                                            lookup={natKey}
                                        />
                                    </React.Fragment>
                                )
                                }
                                {!isAuthenticated && !loader &&
                                    <React.Fragment>

                                        {/* {"notlogin?"} */}
                                        <div className="title-bg">
                                            <Typography variant="h4">
                                                <span className="stepno-circle">
                                                    {natKey || (pymtEntities.length > 1 && Object.entries(selectedPymtEntity).length > 1) ? 4 : stepNumber}</span> Create a new Account</Typography></div>
                                        <CreateAccount
                                            handleChange={handleChange}
                                            errors={errors}
                                            formData={summaryDetails.AccountInfo}
                                            loading={loader}
                                            handleBlur={handleBlur}
                                            fullDetails={summaryDetails}
                                            emailStatus={status}
                                            isAccountSecurity={props.from}

                                        />
                                    </React.Fragment>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} className="summary-rightbox" >
                                <div className="summary-title">
                                    <Typography variant="h4">Order Summary</Typography></div>
                                {/* passed select details */}
                                <OrderSummary
                                    details={summaryDetails}
                                    isAuthenticated={isAuthenticated}
                                    errors={errors}
                                    loading={loader}
                                    usersSearchedData={usersSearchedData}
                                    handleValidationSteps={handleValidationSteps}
                                    accountBlurData={accountBlurData}
                                    validationError={validation}
                                    isChecked={isChecked}
                                    handleAgree={handleAgree}
                                    subDetail={subscriptionDetails}
                                    checked={checked}
                                    isPreSunbsCribedStateList={isPreSunbsCribedStateList}
                                    allTerms={allTerms}
                                    stateCount={newlySelectedStates.length}
                                    lookup={natKey}
                                    priceDetail={priceDetails}
                                    paymentLength={paymentLength}
                                    isNational={isNational}
                                    isUnlimitedUS={isUsSubscription}

                                />

                            </Grid>
                        </>
                    }


                </Grid>
                {maxState && <CommonForAll open={maxState}
                    heading={summaryDetails.Subscription} btnText={"OK"} showText={MaxStateText}
                    className={"box-popup-single-state"} close={() => setMaxState(false)} />}  {/* state greater than 7 open popup */}

            </div>
        </>
    )
}

export default Home;


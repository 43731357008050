import { Modal, ModalClose, Typography, ModalDialog, Button } from '@mui/joy';
import { Fragment } from 'react';
import { DialogContent } from '@mui/material';
import { CONFIG } from '../../../utils/config';
import { productNames } from '../../../utils/enums';

export default function TermsofUse(props) {
    let { handleChkBkChange, handleCheckCancel, open, id ,productName } = props;

    const handleClick = () => {
        // Construct the URL with query string parameter
        const queryString = "showPrivacy=true"; // Replace "param=value" with your actual query string
        const isFromUrl = (productName === productNames.USProductName || productName === productNames.USParkingProductName )
         ? CONFIG.Explorer_Marketing_url : productName === productNames.EUProductName ? CONFIG.Europe_Marketing_url : "" 
        const url = `${isFromUrl}?${queryString}`;

        // Open a new window with the URL containing the query string
        window.open(url, "_blank");
    };

    return (
        <Fragment>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={handleCheckCancel}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: "scroll" }}
                className="terms-popup"
            >
                <ModalDialog
                    aria-labelledby="nested-modal-title"
                    aria-describedby="nested-modal-description"
                    className="mb-popup"
                    sx={(theme) => ({
                        [theme.breakpoints.only('xs')]: {
                            top: 'unset',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            borderRadius: 0,
                            transform: 'none',
                            maxWidth: 'unset',
                        },
                    })}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.body',
                        }}
                    />
                    <Typography variant="h2" component="h2" className='terms-heading'>Terms of Service
                    </Typography>
                    <DialogContent dividers="true" className="terms-bt-border">
                        <Typography
                            variant="h5"
                            component="h5"
                            gutterBottom
                            className="st-online"
                        >
                            Effective and Last Updated: April 1, 2024

                        </Typography>
                        <Typography gutterBottom>
                            This website is owned and operated by Aggregate Intelligence, Inc. (the producer of the StorTrack services) referred hereto as ‘StorTrack’. This webpage contains the Terms of Service governing your access to and use of StorTrack (the “Site”). When used in this Agreement, "Service" means those premium content areas of the Site which you may have been authorized to access.
                            By submitting your completed Registration Form, and using any part of the service, you are representing that you are 18 years old or older, and that you agree to be bound by all of the terms in this Subscription Agreement. You may print and keep a copy of this Agreement. Aggregate Intelligence, Inc. may change the terms in this Subscription Agreement. When the terms are changed, we will notify you by e-mail and/or online postings. The changes will also appear in this document, which you can access at any time by selecting "Terms of Service" in the footer section of the site’s home page. After notification that a change in this Subscription Agreement has been posted, you agree to be bound by all of the changes.
                            The “Effective Date” of this Agreement is the date that the person placing the order accepts the Terms by clicking ‘Accept Terms of Subscription’ during the subscription account set-up process on the Site.
                            If you are entering into this Agreement on behalf of a company or other legal entity, you represent that you have the authority to bind such entity to this Agreement in which case the term “Client” shall refer to such entity. If you do not have such authority, or if you do not agree to the terms of this Agreement, do not accept this Agreement or use the Services. If you receive free access or a trial account for evaluation you are deemed a “Client” under this Agreement and are subject to its terms
                            You may not use or access the Services if you are a direct competitor of StorTrack or if you are accessing or using the Services for the benefit of a direct competitor of StorTrack.

                        </Typography>
                        <Typography
                            variant="h5"
                            component="h5"
                            gutterBottom
                            className="st-online"
                        >
                            PRIVACY OF INFORMATION

                        </Typography>
                        <Typography gutterBottom>
                            By clicking the "I Accept" button, you also are agreeing that Aggregate Intelligence, Inc. may use information you provide as part of the subscription process and usage information, and provide aggregate statistical information about subscribers to advertisers, potential advertisers, and content licensors. Aggregate Intelligence, Inc. also may use the information to inform you about other products and services, unless you notify Aggregate Intelligence, Inc. that you do not wish to receive this information. Aggregate Intelligence, Inc. also may deliver additional messages about the Service to you by e-mail.

                        </Typography>
                        <Typography gutterBottom>
                            As a general policy, Aggregate Intelligence, Inc. does not disclose to third parties
                            any specific personal identifying information about you, such as your name, address,
                            phone number, or e-mail address ("Personal Identifying Information").
                            Aggregate Intelligence, Inc. will not disclose any of your Personal
                            Identifying Information to a third party without first notifying you and giving
                            you an opportunity to elect not to have your Personal Identifying Information
                            disclosed. Also, please consult our <a className='privacy-link' onClick={handleClick}> Privacy Policy</a>.

                        </Typography>
                        <Typography
                            variant="h5"
                            component="h5"
                            gutterBottom
                            className="st-online"
                        >
                            ENTERPRISE CUSTOMERS

                        </Typography>
                        <Typography gutterBottom>
                            If Aggregate Intelligence, Inc. and you or your employer have manually signed a paper version of a contract governing your use of the Service, or if you or your employer subscribe to the Service pursuant to an Enterprise Subscription Agreement, the terms in that manually signed, paper document or Enterprise Subscription Agreement are not amended by, and supersede, terms in this Subscription Agreement.

                        </Typography>
                        <Typography
                            variant="h5"
                            component="h5"
                            gutterBottom
                            className="st-online"
                        >
                            FEES AND PAYMENTS

                        </Typography>
                        <Typography gutterBottom>
                            You can always find the current Subscription Fees and charges posted with the product information. Your subscription will continue for the term specified and will renew automatically until terminated by Aggregate Intelligence, Inc. or until you notify Aggregate Intelligence, Inc. by electronic mail of your decision to terminate your subscription or cancel the subscription from the “My Account” section after Signing In. Your default payment method will be charged for the total amount for any subscription package selected during the Checkout process. All fees and charges are non-refundable.
                        </Typography>
                        <Typography gutterBottom>
                            All fees and charges incurred in connection with your username and/or password will be billed to the default payment method you designate during the registration process for the Service or the electronic information service, which provides you access to the Service. If there are any annual, or similar periodic fees for your Service subscription, these fees will be billed automatically to your default payment method at the start of the period, and at the start of each renewal period, unless you terminate your subscription before the relevant period begins. If there is a problem charging your selected payment method, we may charge any other valid payment method associated with your account. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account, and you agree to accept responsibility for all activities that occur under your account or password. If you want to update your payment method, or if you believe someone has accessed the Service using your user name or password without your authorization, you must contact Aggregate Intelligence, Inc. Customer Service at the telephone numbers posted on the website or fill out the form available on the “Contact Us” page. You may also update your payment method from the “My Account” section after Signing In. You also are responsible for any fees or charges incurred to access the Service through an Internet access provider or other third-party service. YOU, AND NOT AGGREGATE INTELLIGENCE, INC., ARE RESPONSIBLE FOR PAYING ANY AMOUNTS BILLED TO YOUR PAYMENT METHOD BY A THIRD PARTY, WHICH WERE NOT AUTHORIZED BY YOU.

                        </Typography>
                        <Typography gutterBottom>
                            If Aggregate Intelligence, Inc. and you or your employer has manually signed a paper version of a contract governing your use of the Service, or if you or your employer subscribe to the Service pursuant to an Enterprise Subscription Agreement, some or all of these "Fees and Payments" terms may not apply to you. Please contact your employer for details.

                        </Typography>
                        <Typography
                            variant="h5"
                            component="h5"
                            gutterBottom
                            className="st-online"
                        >
                            COPYRIGHT AND LIMITATIONS ON USE

                        </Typography>
                        <Typography gutterBottom>
                            The content available as part of the Service, other than content accessible through the Product area, is defined as "Licensed Information". The Licensed Information is the property of Aggregate Intelligence, Inc. or its licensors and is protected by copyright and other intellectual property laws. Licensed Information received through the Service may be displayed, reformatted and printed for your personal, non-commercial use only. You agree not to reproduce, transmit, distribute, display, sell, location, archive or publish any Licensed Information to anyone, including but not limited to others in the same company or organization, without the express prior consent of Aggregate Intelligence, Inc. with these two exceptions:

                        </Typography>
                        <Typography gutterBottom>
                            <b>1)</b>  You may, on an occasional and irregular basis, disseminate an insubstantial portion of content retrieved from the Service, for a non-commercial purpose, without charge, and in non-electronic form, to a limited number of individuals, provided you include all copyright and other proprietary notices with such portion of the content in the same form in which the notices appear in the Service, and the phrase "Used with permission from Aggregate Intelligence, Inc. " To request consent for other matters, please send an e-mail to support@stortrack.com

                        </Typography>
                        <Typography gutterBottom>
                            <b>2)</b>  Except where indicated otherwise you may e-mail your Service search results to a single e-mail address and a single individual within your company or organization The content accessible through hyperlinks within company capsules in the database is not made available to you through any contractual arrangement between Aggregate Intelligence, Inc. and third parties, but instead is made available from publicly available sources on the World Wide Web. FOR THIS REASON, AGGREGATE INTELLIGENCE, INC. DOES NOT GRANT YOU ANY RIGHT OR LICENSE WHATSOEVER WITH RESPECT TO CONTENT ACCESSIBLE THROUGH THE GUIDE. You are solely responsible for determining the extent to which the content accessible through the application may be used or distributed. Aggregate Intelligence, Inc. reserves the right to monitor the Subscriber's use of all premium content, services, and tools to ensure the Subscriber's compliance with the Terms and Conditions. If it is determined that the Subscriber is not in compliance with these Terms and Conditions, Aggregate Intelligence, Inc. reserves the right to take such action as is deemed necessary, including, but not limited to, suspension or termination of the Subscriber's account. The Subscriber acknowledges that such monitoring of use may include determining whether or not the Service is accessed under the account from IP addresses, monitoring the log files to determine if any computer programs are being run in order to access data from the site, as well as noting excessive use from Subscription accounts. Only one individual may access the Service at the same time using the same user name or password, unless agreed otherwise by Aggregate Intelligence, Inc.

                        </Typography>
                        <Typography gutterBottom>
                            Aggregate Intelligence, Inc. and its content licensors do not give investment advice, or advocate the purchase or sale of any security or investment.

                        </Typography>
                        <Typography
                            variant="h5"
                            component="h5"
                            gutterBottom
                            className="st-online"
                        >
                            DISCLAIMER OF WARRANTIES AND LIABILITY
                        </Typography>
                        <Typography gutterBottom>
                            DUE TO THE NUMBER OF SOURCES FROM WHICH CONTENT ON THE SERVICE IS OBTAINED, AND THE INHERENT HAZARDS OF ELECTRONIC DISTRIBUTION, THERE MAY BE DELAYS, OMISSIONS OR INACCURACIES IN SUCH CONTENT AND THE SERVICE. AGGREGATE INTELLIGENCE, INC. AND ITS AFFILIATES, AGENTS AND LICENSORS CANNOT AND DO NOT WARRANT THE ACCURACY, COMPLETENESS, CURRENTNESS, NONINFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OF THE CONTENT AVAILABLE THROUGH THE SERVICE OR THE SERVICE ITSELF. AGGREGATE INTELLIGENCE, INC. AND ITS AFFILIATES, AGENTS OR LICENSORS SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSS OR DAMAGE, OTHER THAN DEATH OR PERSONAL INJURY RESULTING DIRECTLY FROM USE OF THE SERVICE, CAUSED IN WHOLE OR PART BY CONTINGENCIES BEYOND THEIR CONTROL OR NEGLIGENCE IN PROCURING, COMPILING, INTERPRETING, REPORTING OR DELIVERING THE SERVICE AND ANY CONTENT THROUGH THE SERVICE. IN NO EVENT WILL AGGREGATE INTELLIGENCE, INC., ITS AFFILIATES, AGENTS OR LICENSORS BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE ON SUCH CONTENT. AGGREGATE INTELLIGENCE, INC. AND ITS AFFILIATES, AGENTS AND LICENSORS SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DAMAGES OTHER THAN DIRECT DAMAGES (INCLUDING, WITHOUT LIMITATION, CONSEQUENTIAL, SPECIAL, INCIDENTAL, INDIRECT, OR SIMILAR DAMAGES) EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU AGREE THAT THE LIABILITY OF AGGREGATE INTELLIGENCE, INC., ITS AFFILIATES, AGENTS AND LICENSORS, IF ANY, ARISING OUT OF ANY KIND OF LEGAL CLAIM (WHETHER IN CONTRACT, TORT OR OTHERWISE) IN ANY WAY CONNECTED WITH THE SERVICE OR THE CONTENT IN THE SERVICE SHALL NOT EXCEED THE AMOUNT YOU PAID TO AGGREGATE INTELLIGENCE, INC. FOR THE USE OF THE SERVICE IN THE 12 MONTHS IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH CLAIM.

                        </Typography>
                        <Typography
                            variant="h5"
                            component="h5"
                            gutterBottom
                            className="st-online"
                        >
                            ADDITIONAL LEGAL TERMS AND CONDITIONS

                        </Typography>
                        <Typography gutterBottom>
                            You are responsible for all statements made and acts or omissions that occur while your password is being used. You may not use the Service for any unlawful purpose. Aggregate Intelligence, Inc. may refuse to grant you a user name that impersonates someone else, is protected by trademark or other proprietary right law, or is vulgar or otherwise offensive, as determined by Aggregate Intelligence, Inc.

                        </Typography>
                        <Typography gutterBottom>
                            Aggregate Intelligence, Inc. may discontinue or change the Service, certain Licensed Information, or certain content within the Service or its availability to you, at any time, and you may always terminate your subscription at any time. This Agreement is personal to you, and you may not assign your rights or obligations to anyone. If any provision in this Agreement is invalid or unenforceable under applicable law, the remaining provisions will continue in full force and effect. This Agreement, your rights and obligations, and all actions contemplated by this Agreement shall be governed by the laws of the State of Michigan USA, as if the Agreement was a contract wholly entered into and wholly performed within the State of Michigan. This Agreement will not be governed by the United Nations Convention on Contracts for the International Sale of Goods.
                        </Typography>
                    </DialogContent>
                    <Button variant="contained" onClick={(e) => handleChkBkChange(e, id, "agreeBtn")} className='modal-btn'>Agree</Button>
                </ModalDialog>
            </Modal>
        </Fragment>
    );
}
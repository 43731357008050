import { Breadcrumbs, Table, Typography } from "@mui/material";
import { Paper, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import React, { Fragment, useState } from 'react'
import EnhancedTableHead, { getSorting, handleRequestSort, stableSort } from '../../../commonFunctionalities/CommonMuiSortingFunc';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link, useLocation } from "react-router-dom";
import { getCurrencyDetail, usersTypeId } from '../../../../utils/enums';
import moment from 'moment';
export default function AdminSubscriptionBillingDetails(props) {
    const { loader } = props;
    const location = useLocation();
    const billingData = location.state.billingdetail;
    const userid = location.state.userId;
    const userEmail = localStorage.getItem('userEmail');
    const [subscripbedcountry, setState] = useState({ order: 'asc', orderBy: "" });
    const headRows = [
        { id: 'invoicenumber', label: 'Invoice Number', numeric: false },
        { id: 'paymentdate	', label: 'Payment Date', numeric: false },
        { id: 'term', label: 'Term', numeric: true },
        { id: 'currency', label: 'Currency', numeric: true },
        { id: 'amount', label: 'amount', numeric: false },
        { id: 'validfrom', label: 'Valid From', numeric: false },
        { id: 'validtill', label: 'Valid Till', numeric: false },
        { id: 'view', label: 'view', numeric: false }
    ]
    let disableTableRowsNames = ['term', 'currency', 'amount', 'view'];
    let userData = JSON.parse(localStorage.getItem("userInfo"));
    let userDetails = userData !== null && (userData?.secureId !== null && userData?.secureId !== undefined) ? userData?.secureId  : ""
    let navBackButton = userData?.usertypeId === usersTypeId.Admin ? "/admin" : "/user";
    let navBackButtonDetails = userData?.usertypeId === usersTypeId.Admin ? `/updateusers/${userid}` : `/user`;    
    const handleRequestSortPreCall = (property, orderAlternate) => {
        const { order, orderBy } = subscripbedcountry;
        let { sort, prop } = handleRequestSort(property, order, orderBy, orderAlternate);
        setState({ ...subscripbedcountry, order: sort, orderBy: prop });
    };
  return (
    <Fragment>
            {loader && <div className="loader-wrap"><div className="loading"></div></div>}
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="breadcrumb-list">
                <Link color="inherit" to={navBackButton}>Users</Link>
                <Link color="inherit" to={navBackButtonDetails}>Details</Link>
                <Link color="inherit" to={-1}>Subscriptions</Link>
                <Typography color="text.primary">User Billing Details</Typography>
                <Typography color="text.primary"><strong>{userEmail} </strong></Typography>
            </Breadcrumbs>
            <div className="tab-design">
                <h3 className="mt-0">User Billing Details</h3>
            <TableContainer component={Paper} className="common-table-design">
                <Table>
                    <EnhancedTableHead
                        order={subscripbedcountry.order}
                        orderBy={subscripbedcountry.orderBy}
                        onRequestSort={handleRequestSortPreCall}
                        disableTableRowsNames={disableTableRowsNames}                        
                        headRows={headRows}
                    />
                    <TableBody>
                        {billingData && billingData.length > 0 ?
                            stableSort(
                                billingData,
                                getSorting(subscripbedcountry.order, subscripbedcountry.orderBy)
                            ).map((strdata, index) =>
                                <TableRow key={index}>
                                    <TableCell>{strdata.invoiceNumber }</TableCell>
                                    <TableCell>{strdata && strdata.invoiceDate ? moment( strdata.invoiceDate).format('DD-MMM-YYYY'): "-"}</TableCell>
                                    <TableCell>{strdata.interval ? strdata.interval.charAt(0).toUpperCase() + strdata.interval.slice(1) : ''}</TableCell>
                                    <TableCell>{strdata.currency ? strdata.currency.toUpperCase() : ''}</TableCell>                
                                    <TableCell>{strdata.amount.toLocaleString()}</TableCell>
                                    <TableCell>{strdata && strdata.periodFrom ? moment( strdata.periodFrom).format('DD-MMM-YYYY'): "-"}</TableCell>
                                    <TableCell>{strdata && strdata.periodTo ? moment( strdata.periodTo).format('DD-MMM-YYYY'): "-"}</TableCell>
                                    <TableCell><Link to={strdata.invoiceUrl} target="_blank" className="button-as-link">View Invoice</Link></TableCell>
                                </TableRow>
                            ) : !loader &&
                            <TableRow>
                                <TableCell colSpan={8} className="no-data">{'No Data Found'}</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            </div>
        </Fragment>
  )
}

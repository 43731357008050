import { Modal, ModalClose, Sheet } from '@mui/joy';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Grid, Button } from '@mui/material';
import StepperTwo from './createUserStepper/StepperTwo';
import { api } from '../../../../services/api';
import { handlingCatchErorr, handlingSuccessResponse } from '../../../utils/errorHandling';
import { useNavigate } from 'react-router-dom';
import { validationFunction } from '../../commonFunctionalities/AllValidation';
import { Types, mrkPackages, paymentMode } from '../../../utils/enums';
import CommonForAll from '../commonModals/CommonForAll';

const chkSubsBtn = "checkSubscriptionBtn";
const selPac = "SelPacDet";
const MaxStateText = "yes"

export default function AdminSubscriptionModal(props) {
    let { handleLoader, userId, productDetailsApi } = props;
    const isFirstRender = useRef(true);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [selectedStateListIds, setSelectedStateListIds] = useState({});
    const [selectedCountryListIds, setSelectedCountryListIds] = useState({});
    const [countryIds, setCountryIds] = useState([]);
    const [tabIndValues, setIndTabValues] = useState("");
    const [btnSelectedValue, setBtnSelectedValue] = useState([]);
    const [btnSelPacValue, setBtnSelPacValue] = useState([]);
    const [productsDropDownData, setProductsDropDownData] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [paymentRadioButtons, setPaymentRadioButtons] = useState([]);
    const [unlimitedStateDataList, setUnlimitedStateDataList] = useState([]);
    const [productId, setProductId] = useState([]);
    const [errors, setErrors] = useState({});
    const [priceDetails, setPriceDetails] = useState([]);
    const [subscriptionData, setSubscriptionData] = useState({});
    const [checkBoxDisable, setCheckBoxDisable] = useState(false);
    const [tabList, setTabList] = useState([]);
    const [valOffOnRadioBtn, setValOffOnRadioBtn] = useState({});
    const [isNextStep, setIsNextStep] = useState(false);
    const [btnSelectedNationalValue, setBtnSelectedNationalValue] = useState([]);
    const [subsIdNSubsName, setSubsIdNSubsName] = useState({});
    const [id, setId] = useState("");
    const [hasMadeSelection, setHasMadeSelection] = useState(false);
    const [maxState, setMaxState] = useState(false);
    const [maxStateHeading, setMaxStateHeading] = useState("");
    const [newState, setNewState] = useState([]);
    const [pymtEntities, setPymtEntities] = useState([]);
    const [selectedPymtEntity,setSelectedPymtEntity]=useState([]);
    const [selectedPayId,setSelectedPayId]  = useState("");
    const [payDet,setPayDet] = useState({});
    const [selectedPaymentId,setSelectedPaymentId] = useState([])

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return
        }
        if (isNextStep) handleSubsPostSubmit();
    }, [errors])

    useEffect(() => {
        allLookUpApi();
    }, [])

    const handleCancel = () => {
        let objectWithEmptyArray = {};
        productsDropDownData?.map(gt => Object.assign(objectWithEmptyArray, { [gt?.name]: [] }));
        setIsNextStep(false);
        setOpen(false);
        setErrors({});
        setTabList([]);
        setIndTabValues("");
        setProductId([]);
        setSubscriptionData({});
        setBtnSelectedValue([]);
        setBtnSelPacValue([]);
        setValOffOnRadioBtn({});
        setBtnSelectedNationalValue([]);
        setPriceDetails([]);
        setSelectedStateListIds(objectWithEmptyArray)
        setSelectedCountryListIds(objectWithEmptyArray);
        setSubsIdNSubsName({});
        setNewState(objectWithEmptyArray)
        setPayDet({})
setSelectedPaymentId({})
    };

    const handleCheckCancel = (e, reason) => {
        if (reason === 'closeClick') handleCancel(false);
    }

    let allLookUpApi = () => {
        api.get("lookup/countries")
            .then((res) => {
                setCountryData(res.data);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
            });
        api.get("Product/products")
            .then((res) => {
                setProductsDropDownData(res?.data?.products);
                let objectWithEmptyArray = {};
                res?.data.products?.map(gt => Object.assign(objectWithEmptyArray, { [gt?.name]: [] }));
                setSelectedStateListIds(objectWithEmptyArray);
                setSelectedCountryListIds(objectWithEmptyArray);
                const objectWithEmptyString = {};
                res?.data.products?.map(gt => Object.assign(objectWithEmptyString, { [gt?.name]: "" }));
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
            });
        api.get("Lookup/modeofpayment")
            .then((res) => {
                setPaymentRadioButtons(res.data);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
            });

    };

    const handleTabChange = (e, value) => {
        setSelectedPaymentId({...selectedPaymentId})
        setIndTabValues(value);
    };

    const errorMessageFunc = (errorName, errorMessage) => {
        setErrors((prevData) => ({ ...prevData, [errorName]: errorMessage }));
    }
    const callStatesLookupApi = (productId, productName) => {
        if (productId) {

            setCheckBoxDisable(true);
            api.get(`Lookup/product/states/${productId}`)
                .then((res) => {
                    let newStateCall = { [productName]: res.data };
                    let allStateData = { ...unlimitedStateDataList, ...newStateCall };
                    setUnlimitedStateDataList(allStateData);
                    setCheckBoxDisable(false);

                })
                .catch((err) => {
                    setCheckBoxDisable(false);
                    handlingCatchErorr(err, navigate);
                });
        }
    }

    let subscriptionApi = (permaLink, productName, isFirstCall, filteredNames, pymtId) => {
        setCheckBoxDisable(true);
        setSelectedPayId(pymtId)
        api.get(`Product/productsubscriptions/${permaLink}?pymtId=${pymtId}`)
            .then((res) => {
                callStatesLookupApi(res.data?.products?.productId, productName);
                let newProductCall = { [productName]: res.data.products };
                let allSubscriptionData = { ...subscriptionData, ...newProductCall };
                setBtnSelectedValue(handlePrevDataOnCheckBoxChnages(btnSelectedValue, filteredNames));
                setBtnSelectedNationalValue(handlePrevDataOnCheckBoxChnages(btnSelectedNationalValue, filteredNames));
                setBtnSelPacValue(handlePrevDataOnCheckBoxChnages(btnSelPacValue, filteredNames));
                setPriceDetails(handlePrevDataOnCheckBoxChnages(priceDetails, filteredNames));
                setSubscriptionData(allSubscriptionData);
                setCheckBoxDisable(false);

            })
            .catch((err) => {
                setCheckBoxDisable(false);
                handlingCatchErorr(err, navigate);
            });
    }

    let stripeApi = (permaLink, productName, isFirstCall, filteredNames) => {
        setCheckBoxDisable(true);
        api.get(`Payment/stripeentities/${permaLink}`)
            .then((res) => {
                debugger;
                let stripeEntities = res.data;
                setPymtEntities(res.data);
                setCheckBoxDisable(false);
                let newPayment = { [productName]: res.data };
                let allSubscriptionData = {...payDet, ...newPayment };
                if (stripeEntities.length == 1) {
                    setSelectedPymtEntity(stripeEntities[0]);
                    // alert(stripeEntities);
                    subscriptionApi(permaLink, productName, isFirstCall, filteredNames, stripeEntities[0].id);
                }
                else
                {
                    setSelectedPymtEntity(stripeEntities);

                    // subscriptionApi(permaLink, productName, isFirstCall, filteredNames);
                }
                setPayDet(allSubscriptionData)

            })
            .catch((err) => {
                setCheckBoxDisable(false);
                handlingCatchErorr(err, navigate);
            });
    }

    const commonForBtnChanges = (handledValue, arr) => {
        let arrPush = [];
        for (let i = 0; i < arr.length; i++) {
            Object.entries(arr[i]).map(([key, val]) => {
                if (key.toString() === tabIndValues) arrPush.push({ [key]: handledValue });
                else arrPush.push(arr[i]);
                return '';
            });
        };
        return arrPush;
    }

    let handleSubscriptionBtnChange = (value, subsName, subsId, subsKey, pacDet, id) => {
        setMaxStateHeading(subsName)
        if (isNextStep) setIsNextStep(!isNextStep);
        setId(id)
debugger;
        if (pacDet === "SelPacDet") {
            let returnedErrors = validationFunction(`${tabIndValues}${selPac}`, subsName);
            errorMessageFunc(`${tabIndValues}${selPac}`, returnedErrors);
            setBtnSelectedNationalValue(commonForBtnChanges("", btnSelectedNationalValue));
            setSelectedStateListIds({ ...selectedStateListIds, [tabIndValues]: [] });
            setNewState({ ...selectedStateListIds, [tabIndValues]: [] });

            if (subsName !== btnSelPacValue.map(gt => gt[tabIndValues])[0]) {
                setBtnSelPacValue(commonForBtnChanges(subsName, btnSelPacValue));
                if (subsName === Types.basic || subsName === Types.reports) {
                    loadPriceDetails(subsKey, null, subsId, "directCall");
                } else {
                    setSubsIdNSubsName({ subsKey, subsId });
                }
                setPriceDetails(commonForBtnChanges([], priceDetails));
                setBtnSelectedValue(commonForBtnChanges("", btnSelectedValue));
            }
        } else if (pacDet === "national") {
            let countryIntegerArray = [];
            if (id && typeof id === 'string') {
                if (id.includes(',')) {
                    let countryIds = id.split(',');
                    countryIds.forEach(id => {
                        let parsedCountryId = parseInt(id.trim());
                        if (!isNaN(parsedCountryId)) {
                            countryIntegerArray.push(parsedCountryId);
                        }
                    });
                } else {
                    let parsedCountryId = parseInt(id.trim());
                    if (!isNaN(parsedCountryId)) {
                        countryIntegerArray.push(parsedCountryId);
                    }
                }
            }
            setCountryIds(countryIntegerArray)
            setSubsIdNSubsName({ subsKey, subsId: subsIdNSubsName.subsId });
            setBtnSelectedNationalValue(commonForBtnChanges(value, btnSelectedNationalValue));
        } else {
            let returnedErrors = validationFunction(`${tabIndValues}${chkSubsBtn}`, value);
            errorMessageFunc(`${tabIndValues}${chkSubsBtn}`, returnedErrors);
            setBtnSelectedValue(commonForBtnChanges(value, btnSelectedValue));
        }
    };
    const handlePrevDataOnCheckBoxChnages = (convertSelectedBtnValueToObj, filteredNames) => {
        let convertValueToObj = Object.assign({}, ...convertSelectedBtnValueToObj)
        let updatedSelectedVal = filteredNames.map(ft => {
            if (convertValueToObj[ft.name] === undefined) {
                return { [ft.name]: "" };
            } else {
                return { [ft.name]: convertValueToObj[ft.name] };
            }
        });
        return updatedSelectedVal;
    }
    const handleProdSubCheckChange = (e, id, productName) => {
        if (e.target.name === "products") {
            let searchApiValues = productsDropDownData.filter(ft => ft.name === productName)[0];
            let resultArray = [];
            if (e.target.checked) {
                resultArray = productId?.filter(CheckedId =>
                    CheckedId !== id
                )
                resultArray.push(id)
            }
            else {
                if (isNextStep) setIsNextStep(!isNextStep);
                resultArray = productId?.filter(CheckedId => CheckedId !== id);
                let uncheckedProductName = productsDropDownData.filter(gt => gt.productId === id)[0]?.name;
                errorMessageFunc(`${uncheckedProductName}${chkSubsBtn}`, "")
                errorMessageFunc(`${uncheckedProductName}${selPac}`, "")
            }
            let filteredNames = productsDropDownData.filter(ft => resultArray.includes(ft.productId) && ft.name);
            let filteredOnlyNames = filteredNames.map(dt => dt.name);
            debugger;
            if (subscriptionData[productName] === undefined) {
                
                stripeApi(searchApiValues.permalink, searchApiValues.name, "no", filteredNames);
            }
            else {

                setBtnSelectedValue(handlePrevDataOnCheckBoxChnages(btnSelectedValue, filteredNames));
                setBtnSelectedNationalValue(handlePrevDataOnCheckBoxChnages(btnSelectedNationalValue, filteredNames));
                setBtnSelPacValue(handlePrevDataOnCheckBoxChnages(btnSelPacValue, filteredNames));
                setPriceDetails(handlePrevDataOnCheckBoxChnages(priceDetails, filteredNames));
            }
            setProductId(resultArray)
            setTabList(filteredNames);
            if (!filteredOnlyNames.includes(tabIndValues)) setIndTabValues(filteredOnlyNames[0]);
            let initialValOffOnRadioBtn = filteredNames.map(ft => { return { [ft.name]: [{ "selectedPayment": paymentMode.offline }, { "subscriptionId": "" }] } });
            Object.keys(valOffOnRadioBtn).map(ft => {
                if (!filteredOnlyNames.includes(ft)) {
                    valOffOnRadioBtn[ft][0].selectedPayment = paymentMode.offline;
                    valOffOnRadioBtn[ft][1].subscriptionId = "";
                };
                return "";
            });
            let convertToObj = Object.assign({}, ...initialValOffOnRadioBtn);
            setValOffOnRadioBtn({ ...convertToObj, ...valOffOnRadioBtn });
        } else if (e.target.name === "selStateList") {
            let resultArray = [];
            if (e.target.checked && selectedStateListIds[tabIndValues]?.length <= 6) {
                resultArray = selectedStateListIds[tabIndValues]?.filter(CheckedId =>
                    CheckedId !== id
                )
                resultArray.push(id);
            }
            else {
                resultArray = selectedStateListIds[tabIndValues]?.filter(CheckedId =>
                    CheckedId !== id
                )
            };
            setSelectedStateListIds({ ...selectedStateListIds, [tabIndValues]: resultArray });
            if (selectedStateListIds[tabIndValues]?.length >= 7 && resultArray.length >= 7) {
                setMaxState(true)
            }

        }

    };


    const handleChange = (e) => {
        if (isNextStep) setIsNextStep(!isNextStep);
        const { name } = e.target;
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        if (name !== undefined) {
            if (name === "selectPayment") {
                valOffOnRadioBtn[tabIndValues][0].selectedPayment = value;
                if (valOffOnRadioBtn[tabIndValues][0].selectedPayment !== paymentMode.custom) {
                    valOffOnRadioBtn[tabIndValues][1].subscriptionId = "";
                }
                setValOffOnRadioBtn({ ...valOffOnRadioBtn });
            } else if (name === "subscriptionId") {
                valOffOnRadioBtn[tabIndValues][1].subscriptionId = value;
                setValOffOnRadioBtn({ ...valOffOnRadioBtn });
            }
        }
    }
    console.log(tabIndValues,"tabIndValues")

    const handleSubsPreSubmit = () => {
        debugger;
        setIsNextStep(true);
        const subId = "subscriptionId";
        tabList.map(gt => {
            if (valOffOnRadioBtn[gt.name][0].selectedPayment === paymentMode.custom) {
                let returnedErrors = validationFunction(`${gt.name}${subId}`, valOffOnRadioBtn[gt.name][1].subscriptionId);
                errorMessageFunc(`${gt.name}${subId}`, returnedErrors);
            } else {
                errorMessageFunc(`${gt.name}${subId}`, "");
            }
            return "";
        })
        btnSelectedValue.map(dt => Object.entries(dt).map(([key, val]) => {
            if (key === tabIndValues) {
            let returnedErrors = validationFunction(`${key}${chkSubsBtn}`, val);
            errorMessageFunc(`${key}${chkSubsBtn}`, returnedErrors);
            }
            return '';
        }))
        btnSelPacValue.map(dt => Object.entries(dt).map(([key, val]) => {
            if (key === tabIndValues) {
            let returnedErrors = validationFunction(`${key}${selPac}`, val);
            errorMessageFunc(`${key}${selPac}`, returnedErrors);
            }
            return '';
        }))

        let returnedErrors = validationFunction("productsCheckBoxes", productId);
        errorMessageFunc("productsCheckBoxes", returnedErrors);
    }

    const handleSubsPostSubmit = () => {
        debugger;
        console.log(errors,"errors")
        let areThereErrorMsgOrNot = Object.entries(errors).length !== 0 ? Object.entries(errors).every(([key, val]) => val === "") ? true : false : true;
        if (areThereErrorMsgOrNot) handleSubmit();
    }

    const handleSubmit = () => {
        debugger;
        handleLoader(true, false);
        let suscriptionDet = [];
        for (let i = 0; i < tabList.length; i++) {
            let productId = tabList[i].productId;
            let productName = tabList[i].name;
            let selSubId = btnSelectedValue.filter(ft => ft[tabList[i]?.name])[0][tabList[i]?.name];
            let subscriptionId = selSubId?.length > 0 ? selSubId[0] : selSubId.toString();
            let subscriptionPriceId = selSubId ? selSubId : null;
            let selPayment = valOffOnRadioBtn[tabList[i]?.name][0]?.selectedPayment;
            let subscriptionTextBox = selPayment === paymentMode.custom ? valOffOnRadioBtn[tabList[i]?.name][1].subscriptionId : null;
            let transactionTextBox = null;
            let modeOfSubsId = paymentRadioButtons.filter(dt => dt.name === selPayment)[0]?.id;
            let stateList = selectedStateListIds[tabList[i].name]?.length > 0
                // && (productId === 1 || productId === 3) 
                ? selectedStateListIds[tabList[i].name] : [];
            let marketExplorerId = subscriptionData[productName]?.subscriptions?.find(ft => ft.packagename === mrkPackages.mrkExp)?.id;
            let units = 0;
            if (Number(subscriptionId) === marketExplorerId) {
                units = subscriptionData[productName]?.subscriptions?.find(ft => ft.id === subscriptionData[productName]?.subscriptions?.find(ft => ft.packagename === mrkPackages.mrkExp)?.id)?.prices?.find(gt => gt.id === Number(subscriptionPriceId))?.minCount
                transactionTextBox = selPayment === paymentMode.custom ? valOffOnRadioBtn[tabList[i]?.name][1].subscriptionId : null
                subscriptionTextBox = null;
            };
            suscriptionDet.push({
                "productId": productId ? Number(productId) : null,
                "subscriptionId": id ? id : null,
                "subscriptionPriceId": subscriptionPriceId ? Number(subscriptionPriceId) : null,
                "units": units === undefined ? 0 : units,
                "credits": 0,
                "stateList": stateList,
                "countryList": countryIds,
                "modeOfSubscription": modeOfSubsId,
                "payment": {
                    "subscriptionId": subscriptionTextBox ? subscriptionTextBox : null,
                    "transactionId": transactionTextBox ? transactionTextBox : null
                }
            })
        }
        let userDeatails = {
            "userID": userId,
            subscription: [...suscriptionDet],
        };
        api.post('/admin/addusersubscription', { ...userDeatails })
            .then((res) => {
                if (res.status === 200) {
                    handlingSuccessResponse(res, "Subscription added successfully");
                    productDetailsApi();
                    handleCancel();
                };
                handleLoader(false, false);
            })
            .catch((error) => {
                handleLoader(false, false);
                handlingCatchErorr(error, navigate);
            });
    }
    let loadPriceDetails = (key, count, typeId, whatCall) => {
        setPriceDetails(commonForBtnChanges([], priceDetails));
        debugger;
        let getPrice = {};
        if (whatCall === "directCall") {
            getPrice = {
                "subscriptionKey": key,
                "minCount": count,
                "subscriptionTypeId": typeId,
                "StripeEntityId":  selectedPayId ? selectedPayId : 1
            };
        } else if (whatCall === "nationalCall") {
            getPrice = {
                "subscriptionKey": subsIdNSubsName.subsKey,
                "minCount": null,
                "subscriptionTypeId": subsIdNSubsName.subsId,
                "StripeEntityId":  selectedPayId ? selectedPayId : 1
            };

        } else {
            getPrice = {
                "subscriptionKey": subsIdNSubsName.subsKey,
                "minCount": selectedStateListIds[tabIndValues]?.length,
                "subscriptionTypeId": subsIdNSubsName.subsId,
                "StripeEntityId":selectedPayId  ? selectedPayId : 1
            };
        }
        setCheckBoxDisable(true);
        api.post('/Product/subscription/prices', getPrice)
            .then((res) => {
                const sortedPriceDetails = res.data.sort((a, b) => a.sortOrder - b.sortOrder);
                setPriceDetails(commonForBtnChanges(sortedPriceDetails, priceDetails));
                setCheckBoxDisable(false);
            })
            .catch((err) => {
                handlingCatchErorr(err, navigate);
                setCheckBoxDisable(false);
            });
    }

    const handleStateOrCountryModalCLose = (steOrCnt, saveOrNot) => {
        // if (saveOrNot === "notSave") {
        //     setPriceDetails(commonForBtnChanges([], priceDetails));
        //     setBtnSelectedValue(commonForBtnChanges("", btnSelectedValue));
        //     if (steOrCnt === "state") setSelectedStateListIds({ ...selectedStateListIds, [tabIndValues]: [] });
        //     else if (steOrCnt === "national") {
        //         setBtnSelectedNationalValue({ ...btnSelectedNationalValue, [tabIndValues]: "" })
        //         errorMessageFunc(`${tabIndValues}${chkSubsBtn}`, "");
        //     };
        // } else {
        //     if (steOrCnt === "national") loadPriceDetails(" ", "", "", "nationalCall");
        //     else loadPriceDetails();
        // }
        // if (saveOrNot === "Save") {
        //     setBtnSelectedValue(commonForBtnChanges("", btnSelectedValue));
        // }
        setBtnSelectedValue(commonForBtnChanges("", btnSelectedValue));

        if (saveOrNot === "notSave") {

            setBtnSelectedNationalValue(commonForBtnChanges("", btnSelectedNationalValue));
            if (steOrCnt === "national") {
                loadPriceDetails(" ", "", "", "nationalCall");

            }
            else if (steOrCnt === "state") {
                setNewState(newState)
                setSelectedStateListIds({ ...newState })
            }
        }
        else if (saveOrNot === "Save") {
            setNewState({ ...selectedStateListIds })
            loadPriceDetails()

        }


    }



    return (
        <Fragment>
            <Button className="edit-account edit-add-btn" onClick={() => setOpen(true)}>
                Add
            </Button>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={handleCheckCancel}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                className="add-subscription-popup"
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.body',
                        }}
                    />
                    <Grid container spacing={3}>
                        <StepperTwo handleChange={handleChange}
                            handleStateOrCountryModalCLose={handleStateOrCountryModalCLose}
                            selectedStateListIds={selectedStateListIds} selectedCountryListIds={selectedCountryListIds}
                            handleTabChange={handleTabChange} tabIndValues={tabIndValues} btnSelectedValue={btnSelectedValue}
                            handleProdSubCheckChange={handleProdSubCheckChange} errors={errors} productsDropDownData={productsDropDownData}
                            productId={productId} paymentRadioButtons={paymentRadioButtons} valOffOnRadioBtn={valOffOnRadioBtn}
                            tabList={tabList} subscriptionData={subscriptionData} checkBoxDisable={checkBoxDisable} handleSubscriptionBtnChange={handleSubscriptionBtnChange}
                            unlimitedStateDataList={unlimitedStateDataList} countryData={countryData} btnSelPacValue={btnSelPacValue}
                            priceDetails={priceDetails} btnSelectedNationalValue={btnSelectedNationalValue} hasMadeSelection={hasMadeSelection} setHasMadeSelection={setHasMadeSelection}
                            newState={newState} pymtEntities={pymtEntities} subscriptionApi={subscriptionApi} 
                            payDet = {payDet} selectedPaymentId={selectedPaymentId} setSelectedPaymentId={setSelectedPaymentId}/>
                            
                    </Grid>
                    <Grid item xs={12} className='button-alignment'>
                        <Button variant="text" onClick={() => handleCancel(false)} className='cancel-btn'>Cancel</Button>
                        <Button variant="contained" disabled={checkBoxDisable} type="button" onClick={handleSubsPreSubmit} className='success-btn'>Save </Button>
                    </Grid>
                    {maxState && <CommonForAll open={maxState} heading={maxStateHeading} btnText={"OK"} showText={MaxStateText} className={"box-popup-single-state"} close={() => setMaxState(false)} />}
                </Sheet>
            </Modal>
        </Fragment>
    );
}
import { Fragment, memo } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Breadcrumbs } from "@mui/material";
import InvoiceModal from "../../modals/InvoiceModal";
import moment from 'moment';
import { CONFIG } from "../../../utils/config";
import { Link, useLocation } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


const BillingHistory = (props) => {
  const location = useLocation();
  const invoiceData = location.state.billingdetail;
  const loader = location.state.load;
  return (
    <Fragment>
      {loader && <div className="loader-wrap"><div className="loading"></div> : </div>}
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="breadcrumb-list">
        <Link color="inherit" to={"/subscription"}>Invoice</Link>
        <Typography color="text.primary">Billing History</Typography>
      </Breadcrumbs>
      <Box sx={{ width: '100%', typography: 'body1' }} className="subscriptions-invoice tab-design billing_history">

        <Typography>Billing History</Typography>
        <TableContainer component={Paper} className="common-table-design">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Billing Date</TableCell>
                <TableCell align="right">subscription</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="right">Billing Address</TableCell>
                <TableCell align="right">	Credit Card Number </TableCell>
                <TableCell align="right">Print Invoice</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                {invoiceData && invoiceData.orderDate
              ? moment( invoiceData.orderDate).format('DD-MMM-YYYY')
              : "-"}
                </TableCell>
                <TableCell align="right">  {invoiceData && invoiceData.planName ?invoiceData.planName : "-"}</TableCell>
                <TableCell align="right">
                  {invoiceData && invoiceData.amount
                    ? `${CONFIG.Currency_Symbol}${invoiceData.amount.toFixed(2)}`
                    : "-"}
                </TableCell>              
                <TableCell align="right"> {invoiceData && invoiceData.billingAddress ?invoiceData.billingAddress : "-"} </TableCell>
                <TableCell align="right">{invoiceData && invoiceData.cardNumber ?invoiceData.cardNumber : "-"} </TableCell>
                <TableCell align="right">
                  <InvoiceModal title={"Print Invoice"}  invoiceDetails={invoiceData} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Fragment>
  );
};

export default memo(BillingHistory);
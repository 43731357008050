export const CONFIG = {
    Explorer_url:"https://www.stortrack.com/Signin.html",
    Optimize_url:"https://optimize.stortrack.com/",
    RVPark_url:"http://explorer.rvparkexplorer.aggregateintelligence.com/",
    Currency_Symbol: '$',
    SupportEmail: "support@stortrack.com",
    Application_Name:"Business Intelligence Dashboard",
    ForgotLink : "/forgotpassword",
    ContactNo : "1-800-969-7424",
    NASS_API_URL: 'https://explorer.stortrack.com/self-storage/api',
	EU_API_URL: 'https://explorer.aggregateintelligence.com/europe/api',
    NASS_URL: 'https://explorer.stortrack.com/self-storage',
    MIG_login_url : "/",
    Explorer_Marketing_url :"https://www.stortrack.com/products/explorer.html",
    NAP: "https://explorer.stortrack.com/vehicle-rv-boat-storage",
    Contact_url : "https://www.stortrack.com/products/explorer.html?showPopup=true",
    NAP_API_URL : "https://explorer.stortrack.com/vehicle-rv-boat-storage/api",
    Europe_Marketing_url :"https://www.stortrack.co.uk/products/explorer.html",
    Explorer_Free_url : "https://bi.aggregateintelligence.com/pricing/us-explorer/us-free-explorer",
    Europe_Free_url : "https://bi.aggregateintelligence.com/pricing/eu-self-storage-explorer/eu-free-explorer"
}
import { Button, Checkbox, Chip, FormControlLabel, FormGroup, Grid, IconButton, TextField, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';

const StateSelect = ({ handleChangeState, checked, handleDelete, selectedState, usersSearchedData, stateSearchedData,searchedText, handleChangeSearch, handleResetState, isPreSunbsCribedStateList, stateLoader, isUnlimitedUS }) => {


    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));


    return (
        <>
            {stateLoader && <div className="loader-wrap"><div className="loading"></div> : </div>}
            {!isUnlimitedUS &&

                <Grid container spacing={2} className="box-padding">
                    <Grid item xs={matches ? 9 : 12} order={matches ? 1 : 2} className="title-bg-inner-headtxt-small">

                        {usersSearchedData?.filter(isEX => isEX.isSubscribed).length > 0 && (
                            <div style={{ marginBottom: "10px" }}>
                                <span className="already-selected">Existing State : </span>
                                {usersSearchedData?.filter(isSub => isSub.isSubscribed).map((list, index) => (
                                    <Chip
                                        disabled
                                        key={list.id}
                                        sx={{ m: 0.2 }}
                                        label={list.name}
                                        className="slect-txt-markets"
                                    />
                                ))}
                            </div>
                        )}

                        {selectedState.length>0 &&" Selected States : "  }
                        {selectedState?.map((list, index) => (
                            <Chip
                                key={list.id}
                                sx={{ m: 0.2 }}
                                label={list.name}
                                className="slect-txt-markets"
                                onDelete={() => handleDelete(list.id)}
                            />
                        ))}

                    </Grid>
                    <Grid item xs={matches ? 3 : 12} order={matches ? 2 : 1}>
                        <TextField
                            name={"searchField"}
                            placeholder='Search'
                            type="text"
                            value={searchedText}
                            onChange={handleChangeSearch}
                            className="markets-search"
                            InputProps={{
                                startAdornment: (
                                    <IconButton
                                        aria-label="toggle password visibility"

                                        edge="end"
                                    ><SearchIcon />
                                    </IconButton>
                                )
                            }}
                        />

                    </Grid>
                </Grid>
            }
            <span>
                <Grid container spacing={0}>
                    <Grid item md={9} sm={9} xs={12} >
                        <span className='title-bg-inner-headtxt box-padding'>Select US states </span>
                        {!isUnlimitedUS &&
                            <span style={{ marginLeft: "10px" }}>
                                <Button onClick={handleResetState} disabled={!selectedState?.length > 0} className="search-reset">Reset</Button></span>
                        }
                    </Grid>
                    {!isUnlimitedUS &&
                        <Grid item md={3} sm={3} xs={12} >
                            <Typography className="sevenstats">
                                You have selected {(usersSearchedData?.filter(isEX => isEX.isSubscribed).length) +(selectedState.length)} of 7 States.</Typography>
                        </Grid>
                    }
                </Grid>
                <Grid container spacing={2} className="box-padding check-color ">
                    {isUnlimitedUS &&
                        <Typography className="usstates-text">Good news! Your national subscription covers all states, including the features of the state subscription. No need for a separate state subscription—you're already enjoying full coverage nationwide.
                            If you have any questions, our customer support is here to help. Thanks for choosing us!</Typography>
                    }
                    {stateSearchedData.length > 0 ?
                    stateSearchedData.map((list, index) => (
                        <Grid item xs={matches ? 3 : 6} key={index} className = {list.hasCountrySubscription ? "allcheckbox-disabled" : ""}>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={list.isSubscribed || checked.some(item => item.id === list.id)}

                                            onChange={(e) => handleChangeState(e, list.id, list.name)}
                                            disabled={list.hasCountrySubscription || isPreSunbsCribedStateList.includes(list.id) ? true : false}
                                        />
                                    }
                                    label={list.name}
                                    value={list.id}
                                    name="regState"
                                />
                            </FormGroup>
                        </Grid>
                    )) 
                    : !stateLoader && <p className="nodata">No Data Found</p>
                    }
                </Grid>
            </span>
        </>
    )
}

export default StateSelect;
import React from 'react'
import { useParams } from 'react-router-dom'
import CommonAccountDetails from '../../../commonFunctionalities/CommonAccountDetails';
import { usersType } from '../../../../utils/enums';

const AdminViewOrUpdate = (props) => {
    let { userId } = useParams();
    return (
        <CommonAccountDetails isFrom={props.from === usersType.user? usersType.user : "viewOrUpdate" }  userId={userId} />
    )
}

export default AdminViewOrUpdate
const currencyMappings = {
    '£': 'GBP',
    '€': 'EUR',
    '$': 'USD',
    // Add more mappings as needed
};

export const formattedAmount = (price, currency, code) => {
    // Set default values for currency and code if they are null
    currency = currency || '';
    code = code || '';

    // Normalize currency and code
    if (currencyMappings[currency.toLowerCase()]) {
        code = currencyMappings[currency.toLowerCase()];
    } else if (currencyMappings[code]) {
        code = currencyMappings[code];
    }

    return new Intl.NumberFormat('en-US', {
        style: code ? 'currency' : 'decimal',
        currency: code
    }).format(price);
}
import { Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import invalid from "../../../src/assets/images/invalid.png";
import { CONFIG } from '../utils/config';
import { handlingCatchErorr, handlingSuccessResponse } from '../utils/errorHandling';
import { api } from '../../services/api';

const InvalidAccess = (props) => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = React.useState(5);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (timeLeft === 0) {
      if(props.from === "invalid-product"){
        const url = CONFIG.MIG_login_url
        window.location.replace(url);
      }
      else {
      setLoading(false);
        api.post('Impersonation/logout')
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              let userInfo = res.data;
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          props. handleImperLogout(JSON.stringify(res.data));
  
          // Remove impersonationInfo after setting userInfo
          localStorage.removeItem('impersonationInfo');
  
          handlingSuccessResponse(res, "You are Logged Out Successfully");
          navigate("/admin");
              setLoading(false);
            }else {
              localStorage.clear();
              const url = CONFIG.Explorer_url
              window.location.replace(url);
            }
          } else {
            handlingCatchErorr(res, navigate);
            const url = CONFIG.Explorer_url
            window.location.replace(url);
          }
        })
        .catch((error) => {
          handlingCatchErorr(error, navigate);
          const url = CONFIG.Explorer_url
          window.location.replace(url);
          setLoading(false);
        });
      }
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {

      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);  /* eslint-disable-line */
  return (
    <div className="account-acivate payment-success" style={{ borderRadius: '10px' }}>
    {loading ? 
      (
        <Typography className='txt-session' data-testid='id'>
          <div className='invalid-access'>
            <p className='text-center' style={{fontWeight: '500', fontSize: '16px', lineHeight: '30px'}}>Please Wait... While we process your request 
            </p>
          </div>
        </Typography>
        ) :(
        <Typography className='txt-session' data-testid='id'>
        <div className='invalid-access'>
          {props.from !== "page-exp" &&  props.from !=="un-auth"? 
          <>
          <img src={invalid} alt='MIS' /> 
          <h4 className='text-center'>Access is denied</h4>
          <p className='text-center'> You are not authorized to view this page! You will be redirect to home page with in <span style={{color: '#f15d47', fontWeight: '500'}}>{timeLeft} seconds.</span></p>
       </>
       :
        <p className='text-center' style={{fontWeight: '500', fontSize: '16px', lineHeight: '30px'}}>  Your session has expired, we will redirect to the home page within <br/><span style={{color: '#f15d47'}}>{timeLeft} seconds.</span></p>}
          </div>
      </Typography>
      )
    }
    </div>
  )
}

export default InvalidAccess;
import { Button, Modal, ModalClose, Typography, Sheet } from '@mui/joy';
import { Fragment, useEffect, useRef, useState } from 'react';
import { FormControl, FormHelperText, Grid, TextField, InputLabel, MenuItem, Select } from '@mui/material';
import { api, nassapi } from '../../../../services/api';
import { handlingCatchErorr, handlingSuccessResponse } from '../../../utils/errorHandling';
import { useNavigate } from 'react-router-dom';
import { validationFunction } from '../../commonFunctionalities/AllValidation';
import { regionCode, searchTypeId } from '../../../utils/enums';

let textFieldVariables = { "Address Line 1": "billingAddress", "Address Line 2": "billingAddress2", "City": "billingCity", "Country": "billingCountry", "State/Province": "billingState", "Zip": "billingZipCode" };
let widthValues = { "billingAddress": 6, "billingAddress2": 6, "billingCountry": 6, "billingState": 6, "billingCity": 12, "billingZipCode": 12 };

let textFieldVariablesAdmin = {
    "Name of  Market":"marketname",
    "Address": "address1", "Address2": "address2",
    "City": "city", "Country": "country", "State": "statename",
    "Zipcode": "zipcode","Logntitude": "longitude", "Latitude": "latitude"
};
let widthValuesAdmin = {"marketname":12, "address1": 6, "address2": 6, "zipcode": 6, "city": 6, "statename": 6, "country": 6, "longitude": 6, "latitude": 6 };


export default function BillingDetailsEditModal(props) {
    let { productDetailsData,apiFrom, userStoreID, editData, handleLoader, loadMarket, countryData, stateLookUpApi, stateData, disable, userId } = props;
    const isFirstRender = useRef(true);
    const navigate = useNavigate();
    const userData = JSON.parse(localStorage.getItem("userInfo"));
    const [loading, setLoading] = useState(false);
    const [countryCode,setCountryCode]  = useState("");

    let initialFormData = {
        billingAddress: editData?.billingAddress ? editData?.billingAddress : '',
        billingAddress2: editData?.billingAddress2 ? editData?.billingAddress2 : '',
        billingCity: editData?.billingCity ? editData?.billingCity : '',
        billingZipCode: editData?.billingZipCode ? editData?.billingZipCode : ""
    };
    let initialCountryId = editData?.billingCountry ? editData?.billingCountry : '';
    let initialStateId = editData?.billingState ? editData?.billingState : '';
    let initialCountryIds = editData?.billingCountryCode? editData?.billingCountryCode : ''; 
    
    let filteredProductDeatils = productDetailsData?.filter(ft => ft.id === userStoreID)[0];
    let city = filteredProductDeatils?.searchtypeid === searchTypeId.city ?
        filteredProductDeatils?.city?.split(',')[0] : filteredProductDeatils?.city;

    let initialCountryIdAdmin = filteredProductDeatils?.countryid ? filteredProductDeatils?.countryid : '';
    let initialStateIdAdmin = filteredProductDeatils && filteredProductDeatils?.statename ? filteredProductDeatils?.statename : '';
    
    let textData ;

    if (editData.billingStateId === null && editData.billingState === null) {
        // Create a copy of textFieldVariables without the "State/Province" key
        textData = Object.fromEntries(
            Object.entries(textFieldVariables).filter(([key]) => key !== "State/Province")
        );
    } else {
        textData = textFieldVariables;
    }   

    if (editData.billingStateId === null && editData.billingState === null) {
        // Create a copy of textFieldVariables without the "State/Province" key
        widthValues["billingCountry"] = 12;
    } else {
        widthValues["billingCountry"] = 6;
    }   

    


    let country, statename, data, textVariable, widthVariable;


    if (props.from === "adminMarketEdit") {
        country = initialCountryIdAdmin;
        statename = initialStateIdAdmin;
        data = filteredProductDeatils;
        textVariable = textFieldVariablesAdmin;
        widthVariable = widthValuesAdmin;
    } else {
        country = initialCountryId;
        statename = initialStateId;
        data = initialFormData;
        textVariable = textData;
        widthVariable = widthValues;
    }
    const [formData, setFormData] = useState({ ...data, city: city });
    const [countryId, setCountryId] = useState(country);
    const [stateId, setStateId] = useState(statename);
    const [open, setOpen] = useState(false);
    const [isNextStep, setIsNextStep] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return
        }
        if (isNextStep) (props.from === "adminMarketEdit" ? handleAccountPostSubmit() : handleBillingPostSubmit());
    }, [errors])
    const handleAccountPostSubmit = () => {
        let areThereErrorMsgOrNot = Object.entries(errors)?.length !== 0 ? Object.entries(errors).every(([key, val]) => val === "" || val === undefined) : true;
        if (areThereErrorMsgOrNot) {
            const stateIds = stateData.filter(name => name.name === stateId);
            const isNewState = stateIds?.length > 0 ? stateIds[0].id : ""
            const citys =
                filteredProductDeatils?.searchtypeid === searchTypeId.city
                    ? `${formData?.city}, ${String(isNewState)}`
                    : formData?.city;
            setLoading(true)
            const userEditInfo = {
                "userId": filteredProductDeatils?.userId,
                "id": filteredProductDeatils?.id,
                "address1": formData?.address1,
                "address2": formData?.address2,
                "city": citys,
                "state": isNewState,
                "zipcode": formData?.zipcode,
                "latitude": formData?.latitude,
                "longitude": formData?.longitude,
                "countryid": countryId,
                "secureId": userData?.secureId,
                "marketname" :formData?.marketname,
                "searchtypeid": formData?.searchtypeid
            };
            apiFrom.post("/market/admin/update", userEditInfo)
                .then((res) => {
                    setLoading(false)
                    handleCancel(true);
                    handlingSuccessResponse(res, "Market  Updated Successfully");
                    loadMarket();
                })
                .catch((err) => {
                    handlingCatchErorr(err, navigate);
                    setLoading(false)
                });
        }
    };

    useEffect(() => {
        if (props.from === "adminMarketEdit" && open) {
            stateLookUpApi(countryId);
        }
    }, [open]);

    const handleChange = (e,data) => {
        if (isNextStep) setIsNextStep(!isNextStep);
        const { name, value } = e.target;

        if (name === (props.from === "adminMarketEdit" ? "country" : "billingCountry")) {
            const countryCodes = data && data?.filter(id=>id.id === value).map((val=>val))

            setCountryId(value)
            setStateId("")
            stateLookUpApi(value); 
             setCountryCode(countryCodes?.[0]?.code);

        }
        else if (name === (props.from === "adminMarketEdit" ? "statename" : "billingState")) {
            setStateId(value); 
            if(props.from !== "adminMarketEdit") setErrors({ ...errors, billingStateId: "" });
        }
        else setFormData((prevData) => ({ ...prevData, [name]: value }));
        let returnedInfo = validationFunction(name, value, props.from);
        errorMessageFunc(name, returnedInfo);
       
    };



    const errorMessageFunc = (errorName, errorMessage) => {
        setErrors((prevData) => ({ ...prevData, [errorName]: errorMessage }));
    }

    const handleCheckCancel = (e, reason) => {
        if (reason === 'closeClick') handleCancel(false);
    }

    const handleCancel = (isSuccess) => {
        setOpen(false);
        setErrors({});
        if (!isSuccess && props.from === "adminMarketEdit") {
            setFormData(filteredProductDeatils);
            setCountryId(initialCountryIdAdmin);
            setStateId(initialStateIdAdmin);
        } else if (!isSuccess) {
            setFormData(initialFormData);
            setCountryId(initialCountryId);
            setStateId(initialStateId);
            setCountryCode(initialCountryIds)
            stateLookUpApi(initialCountryId) 
        }
        setIsNextStep(false);
    };

    const handleBillingPostSubmit = () => {
        let checkError = {};

        console.log(checkError,"checkError")

if (editData.billingCountryCode !== regionCode.ca && editData.billingCountryCode !== regionCode.us) {
    checkError = Object.fromEntries(
        Object.entries(errors).filter(([key]) => key !== "billingState" && key !== "billingStateId")
    );
} else {
    checkError = errors; // No filtering needed if country code is "CA" or "US"
}

        let areThereErrorMsgOrNot = Object.entries(checkError)?.length !== 0 ? Object.entries(checkError).every(([key, val]) => val === "") ? true : false : true;
        if (areThereErrorMsgOrNot) {
            const stateIds = stateData.filter(name => name.name === stateId);

            handleLoader(true, false);
            const userEditInfo = {
                "id": userId,
                "billingAddress": formData?.billingAddress,
                "billingAddress2": formData?.billingAddress2,
                "billingCity": formData?.billingCity,
                "billingZipCode": formData?.billingZipCode,
                "billingStateId": stateId,
                "billingCountry": countryId,
                "billingState": stateIds?.length > 0 ? stateIds[0].id : editData.billingStateId,
                "login": editData?.email,
                "firstName": editData?.firstname,
                "lastName": editData?.lastname,
                "company": editData?.company,
                "phone": editData?.phone,
                "paymentCustomerId": editData?.paymentCustomerId,
                "paymentCustomerAddressId": editData?.paymentCustomerAddressId,
                "billingCountryCode": countryCode ? countryCode : editData?.billingCountryCode?.toString(),
                "couponCode": editData?.couponCode,
                "uphone": editData?.uphone,
                "isreceiveemailalerts": editData?.isreceiveemailalerts,
            };
            api.put("account", userEditInfo)
                .then((res) => {
                    handleLoader(true, true);
                    handleCancel(true);
                    handlingSuccessResponse(res, "Billing Details Updated Successfully");
                })
                .catch((err) => {
                    handleCancel(false);
                    handlingCatchErorr(err, navigate);
                    handleLoader(false, false);
                });
        }
    };

    const handleBillingPreSubmit = () => {
        const stateIds = stateData.filter(name => name.name === stateId);

        let allFileds = { ...formData, "billingState": stateId, "billingCountry": countryId , "billingStateId": stateIds?.length > 0 ? stateIds[0].id : editData.billingStateId };
        setIsNextStep(true);
        Object.entries(allFileds).map(([key, val]) => {
            let returnedErrors = validationFunction(key, val)
            errorMessageFunc(key, returnedErrors);
            return '';
        });
    }
    const handleAccountPreSubmit = () => {
        let allFileds = { ...formData, "statename": stateId, "country": countryId };
        setIsNextStep(true);
        Object.entries(allFileds).map(([key, val]) => {
            let returnedErrors = validationFunction(key, val,props.from)
            errorMessageFunc(key, returnedErrors);
            return '';
        })
        // let isEmptyField = false;
        // if (!allFields.statename) {
        //     errorMessageFunc("statename", "State is required");
        //     isEmptyField = true;
        // }
        // if (!allFields.zipcode) {
        //     errorMessageFunc("zipcode", "Zipcode is required");
        //     isEmptyField = true;
        // }    
        // if (isEmptyField) return;
        // Object.entries(allFields).forEach(([key, val]) => {
        //     if (key !== "state" && key !== "zipcode") {
        //         let returnedErrors = validationFunction(key, val, props.from);
        //         errorMessageFunc(key, returnedErrors);
        //     }
        // });
    };
    return (
        <Fragment>
            {loading && <div className="loader-wrap"><div className="loading"></div></div>}
            <Button className='edit-account' onClick={() => setOpen(true)}>
                Edit
            </Button>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={handleCheckCancel}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                className="custom-model-popup"
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.body',
                        }}
                    />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        {props.from === "adminMarketEdit" ? "Edit Store" : "Billing Details"}
                    </Typography>
                    <Typography id="modal-desc" textColor="text.tertiary" component="div">
                        <form className='custom-form'>
                            <Grid container spacing={3}>
                                {Object.entries(textVariable).map(([key, val]) => (
                                    <Grid item key={key} xs={widthVariable[val]}>
                                        {val === (props.from === "adminMarketEdit" ? "country" : "billingCountry") ?
                                            <FormControl required sx={{ minWidth: 240 }} className='custom-fields'>
                                                <InputLabel id="demo-simple-select-required-label">{key}</InputLabel>
                                                <Select
                                                    name={val}
                                                    labelId="demo-simple-select-required-label"
                                                    id="demo-simple-select-required"
                                                    value={countryId}
                                                    label={key}
                                                    onChange={(e) => handleChange(e, countryData)} // Pass countryData to the handleChange function
                                                  
                                                    required
                                                    className='prd-text-fld'
                                                >
                                                    <MenuItem value="">Select Country</MenuItem>
                                                    {countryData && countryData?.length > 0 && countryData.map((ft, index) =>
                                                        <MenuItem key={index} value={ft.id}>{ft.name}</MenuItem>
                                                    )}
                                                </Select>
                                                <FormHelperText className='Mui-error'>{errors[val]}</FormHelperText>
                                            </FormControl  >
                                            :
                                            val === (props.from === "adminMarketEdit" ? "statename" : "billingState") ?
                                                <FormControl required ={props.from !== "adminMarketEdit" && (editData.billingCountryCode === regionCode.ca || editData.billingCountryCode === regionCode.us)} sx={{ minWidth: 240 }} className='custom-fields'>
                                                    <InputLabel id="demo-simple-select-required-label">{key}</InputLabel>
                                                    <Select
                                                        name={val}
                                                        labelId="demo-simple-select-required-label"
                                                        id="demo-simple-select-required"
                                                        value={stateId}
                                                        label={key}
                                                        onChange={handleChange}
                                                        disabled={disable}
                                                        required
                                                        className='prd-text-fld test'
                                                    >
                                                        <MenuItem value="">Select State</MenuItem>
                                                        {stateData && stateData?.length > 0 && stateData.map((ft, index) =>
                                                            <MenuItem key={index} value={ft.name}>{ft.name}</MenuItem>
                                                        )}
                                                    </Select>
                                                    {editData.billingCountryCode === regionCode.ca || editData.billingCountryCode === regionCode.us ?
                                                    <FormHelperText className='Mui-error'>{errors[val] || errors?.billingStateId}</FormHelperText> : ""}
                                                </FormControl  >
                                                :
                                                <TextField
                                                    name={val}
                                                    label={key}
                                                    type={val === "email" ? "email" : val === "latitude" || val === "longitude" ? "number" : "text"}
                                                    value={
                                                        val === 'city' &&
                                                            filteredProductDeatils?.searchtypeid === searchTypeId.city
                                                            ? formData && formData['city'] && formData['city'].split(',')[0]
                                                            : formData && formData[val]
                                                    }
                                                    onChange={handleChange}
                                                    fullWidth
                                                    error={!!errors[val]}
                                                    helperText={errors[val]}
                                                    required={props.from === "adminMarketEdit" ? 
                                                    val !== "address1" && val !== "zipcode" && val !== "address2"
                                                     : val !== "billingAddress2"}
                                                    className='custom-fields'
                                                />

                                        }
                                    </Grid>
                                )
                                )}
                                <Grid item xs={12} className='button-alignment'>
                                    <Button variant="text" onClick={() => handleCancel(false)} className='cancel-btn'>Cancel</Button>
                                    <Button variant="contained" type="button"
                                        onClick={props.from === "adminMarketEdit" ? handleAccountPreSubmit : handleBillingPreSubmit}
                                        className='success-btn'>Save </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Typography>
                </Sheet>
            </Modal>
        </Fragment>
    );
}
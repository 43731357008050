import * as React from "react";
import Box from '@mui/material/Box';
import { Outlet } from "react-router-dom";
import { Paper } from "@mui/material";
import AvailableTitle from "../Pricing/AvailableTitle"
function AvailableDataLayout() {
    return (
        <div className="mig-pricing new-pricing for-iframe">
            <div className="four-columns">
                <AvailableTitle />
                <Box sx={{ flexGrow: 1 }}>
                    <Paper elevation={3} >
                        <Outlet />
                    </Paper>
                </Box>
            </div>
        </div>
    );
}

export default AvailableDataLayout;
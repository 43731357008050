import React, { useState } from "react";
import { Card, Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CommonForAll from "../Home/modals/commonModals/CommonForAll";



const National = ({ handleSelectNational, activeIndex, errors, loading, subscriptionDetails, isPremium, subKey,isNational }) => {
    const [warn, setWarn] = useState(false);
    const [currentSub, setCurrentSub] = useState("")
    const handleWarningPopup = (premium, isSub,val) => {
        if (!premium && isSub && isNational?.length >0 ) {
            setWarn(!warn)
             const currentSubsription = val.subCategoryList.filter(is=>is.isSubscribed)
            setCurrentSub(currentSubsription[0].subscriptionPriceName.replace('Unlimited ', '')); // Removes 'Unlimited ' from the string
        }

    }
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const subParam = searchParams.get('sub');

    let subText = '';
    if (warn) {
        subText = `As a ${currentSub} subscriber, you can now enjoy even more value by upgrading to our unlimited package, which includes the both regions at a potentially lower cost. Consider this opportunity to maximize your benefits while saving on expenses.
        `;
    }
    return (
        <>
            {loading && <div className="loader-wrap"><div className="loading"></div></div>}
            <Grid container spacing={2} className="box-padding">
                {subscriptionDetails &&
                    subscriptionDetails.subscriptions.length !== 0 &&
                    subscriptionDetails.subscriptions
                        .map((details, subscriptionIndex) => (
                            details.subCategoryList
                                ?.filter((val, priceIndex, self) =>
                                    val.subscriptionPriceName !== "" && val.showToUser &&
                                    self.findIndex((v) => v.subscriptionPriceName === val.subscriptionPriceName) === priceIndex
                                )
      .map((val, priceIndex) => {
                                
                                let boxColor = (val.isSubscribed && !activeIndex && activeIndex !== 0 && val.subscriptionkey === subKey ?
                                    "active national-box select-outline-box " : val.isSubscribed ? " national-box select-outline-box" : "")
                                let boxSecondColor = activeIndex === priceIndex ? "active national-box" : "national-box "

                                let isPremiumBox = isPremium > 0 && !val.isSubscribed || 
                                !val.isSubscribed && subParam && (val.subscriptionkey !== subKey) 
                                   ? "hide-box" :
                                   isPremium > 0 && !val.isSubscribed || 
                                val.isSubscribed && subParam && (val.subscriptionkey !== subKey) || 
                                val.isSubscribed && subParam && (val.subscriptionkey !== subKey) ? "Freeze-box" 
                                : "";
                                
                                return (
                                    <Grid key={priceIndex} item xs={12} md={3} xl={3}>
                                        <Card
                                            // disabled={val.isSubscribed}
                                            onClick={() => {
                                                if ((isNational&& subParam !== null)  ) {
                                                    if (val.isSubscribed && (val.subscriptionkey === subKey && !val.isSubscribed )) {
                                                      handleSelectNational(
                                                        priceIndex,
                                                        val.subscriptionPriceName,
                                                        val,
                                                        null,
                                                        details.subscriptionTypeId
                                                      );
                                                      handleWarningPopup(val.isPremium, !val.isSubscribed, details);
                                                    }
                                                  } else {
                                                    if (activeIndex !== priceIndex && !(isPremium > 0)) {
                                                      handleSelectNational(
                                                        priceIndex,
                                                        val.subscriptionPriceName,
                                                        val,
                                                        null,
                                                        details.subscriptionTypeId
                                                      );
                                                      handleWarningPopup(val.isPremium, !val.isSubscribed, details);
                                                    }
                                                  }
                                                  
                                            }}
                                            className={boxColor + " " + boxSecondColor + "" + isPremiumBox}
 >


                                            {activeIndex === priceIndex && !val.isSubscribed &&
                                                <div className="box-tick"><CheckCircleOutlineIcon /> </div>
                                            }
                                            {val.isSubscribed &&
                                                <div className="selected-tick">  <CheckCircleOutlineIcon />
                                                    <span className="tick-text"> Already Subscribed </span>
                                                </div>
                                            }

                                            <Typography variant="subtitle1" gutterBottom>
                                                {val.subscriptionPriceName}
                                            </Typography>

                                        </Card>
                                    </Grid>
                                )
                            })
                        ))
                }
            </Grid>

            {Object.entries(errors).map(([key, value], index) => {
                if (key === 'national') {
                    return (
                        <div key={index} className="box-padding national-threebox">
                            {`${value}`}
                        </div>
                    );
                }
                return null; // If you want to skip other errors
            })}


            {warn && (
                <CommonForAll
                    open={warn}
                    btnText={"Ok"}
                    showText={subText}
                    className={"box-popup-single-state"}
                    close={() => setWarn(false)}
                />
            )}



        </>
    )

}


export default National;

import React, { useState } from 'react';
import AppRouter from './router/AppRouter';
import { Alert, Snackbar } from '@mui/material';
import TimeoutModal from './components/Home/modals/TimeoutModal';

const App = () => {
  const [isSnakBarOpen, setIsSnakBarOpen] = useState(false);
  const [snakBarMsg, setSnakBarMsg] = useState('');
  const [snaclClr, setSnaclClr] = useState('');

  let handleSnackbar = (value) => {
    if (value && window.globalThis.REACT_SNACK_OPEN) {
      let message = window.globalThis.REACT_SNACK_MESSAGE;
      let color = window.globalThis.REACT_SNACK_COLOR;
      setIsSnakBarOpen(value);
      setSnakBarMsg(value && message ? message : '');
      setSnaclClr(color)
    }
    else {
      window.globalThis.REACT_SNACK_OPEN = value;
      window.globalThis.REACT_SNACK_MESSAGE = '';
      setIsSnakBarOpen(value);
      setSnakBarMsg('');
      setSnaclClr("")
    }
  }
  return (
    <div className="app">
      <div id="enable-error-snack"  onClick={() => handleSnackbar(true)}></div>
      <AppRouter />
      <TimeoutModal />
      
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnakBarOpen}
        id={'snackClr'}
        className="snackbar bg-color-primary custom-snackbar snack-error"
        autoHideDuration={3000}
        onClose={() => handleSnackbar(false)}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
       
        // variant="success"
        // message={<span id="message-id">{snakBarMsg}</span>}
        // action={[
        //   <IconButton
        //     key="close"
        //     aria-label="Close"
        //     color="inherit"
        //     onClick={() => handleSnackbar(false)}
        //   >
        //     <Close />
        //   </IconButton>
        // ]}
      >
         <Alert onClose={() => handleSnackbar(false)} severity={snaclClr} sx={{ width: '100%' }}>
         {snakBarMsg}
        </Alert>
        </Snackbar>
    </div>
  );
};

export default App;

import {api} from "../../services/api";

export const handlingCatchErorr = (props, history) => {
    let userData = JSON.parse(localStorage.getItem("userInfo"));
    let isAuthenticated = userData !== null && (userData?.secureId !== null && userData?.secureId !== undefined) && (userData?.token !== null && userData?.token !== undefined) ? true : false;

    let message = props.response?.data?.Message;

    if (props?.response?.status === 401) {
        localStorage.removeItem('userInfo');
        localStorage.clear();
        api.defaults.headers.common["Authorization"] = '';
        const from = { pathname: "/UnauthorizedAccess" };
        history(from);
    }
    else if (props?.response?.status === 400) {
        if (message === "Authentication failed") {
            const from = { pathname: "/UnauthorizedAccess" };
            history(from);
        }
        else if (message === "expired") {
            errorMsg("Your activation key has may expired. Please Provide your email address")
            const from = { pathname: "/forgotpassword" };
            history(from);
        } else if (props.response?.data === "Invalid product details.") {
            errorMsg(props.response?.data)
            const from = {
                pathname: isAuthenticated ? "/invalid-product-access" : "/invalid-access"
            };
            history(from);
        }
        else if (message !== "") {
            errorMsg(props.response?.data)
            
        }
    }

    else {
        // Handle the default case
        errorMsg(message, props.response?.data, props?.response?.statusText,)
      

    }
}



export const errorMsg = (message, promessage, propsmessage) => {

    window.globalThis.REACT_SNACK_OPEN = true;
    window.globalThis.REACT_SNACK_MESSAGE = message ? message : (promessage ? promessage : propsmessage && propsmessage);
    window.globalThis.REACT_SNACK_COLOR = "error"; 
    document.getElementById('enable-error-snack').click();

}

export const handlingSuccessResponse = (props, message,info) => {
    if (props.status === 200) {
        window.globalThis.REACT_SNACK_OPEN = true;
        window.globalThis.REACT_SNACK_MESSAGE = message && message
        window.globalThis.REACT_SNACK_COLOR = info === "information" ? "info" : "success";
        document.getElementById('enable-error-snack').click();
    }
}




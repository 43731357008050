import { useTabContext } from "@mui/lab";
import { Box } from '@mui/material';

export const CommonTabPanel = ({ children, value }) => {
    const { value: contextValue } = useTabContext() || {};
    return (
        <Box sx={{ display: value === contextValue ? 'block' : 'none' }} key={value}>
            {children}
        </Box>
    );
};

import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LogoImg from "../../../assets/images/suite_logo.png";
import moment from "moment";
import { formattedAmount } from "../commonFunctionalities/commonFunction";
import { CONFIG } from "../../utils/config";
import { paymentMode } from "../../utils/enums";


const ComponentToPrint = React.forwardRef((props, ref) => {
  const {invoiceDetails} = props;
  return (
    <div ref={ref}>
      <Box className="print-invoice">
        <img src={LogoImg} alt="logo" className="invoice-logo" />
        <Grid container spacing={2}>
          <Grid item xs={8} className="invoice-address">
            <p>26400 Lahser Rd. Suite 210</p>
            <p>Southfield, MI 48033</p>
            <p>T 020 3048 3123</p>
          </Grid>
          <Grid item xs={4} className="invoice-date">
            <h3>Invoice</h3>
            <p>{invoiceDetails?.orderDate ? moment(invoiceDetails.orderDate).format('DD-MMM-YYYY') : "-" }</p>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="invoice-bill-to">
              <h3>Bill to:</h3>
              <p>{invoiceDetails?.billingAddress ? invoiceDetails.billingAddress : "-"}</p>
             
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <div className="invoice-desc">
              <h3>Description:</h3>
              <p>{invoiceDetails?.packageName ? invoiceDetails.packageName : "-"}</p>
              <h3 style={{ marginTop: '10px' }}>Type of Payment:</h3>
              <p>{invoiceDetails?.paymentMode ? invoiceDetails.paymentMode : "-"}</p>
            </div>
          </Grid>
          <Grid item xs={4} style={{ paddingLeft: '0px' }}>
            <div className="invoice-total">
              <div className="alignment-total">
                <h3>Total:</h3>
                <p>{ !invoiceDetails?.amount && invoiceDetails.paymentMode !== paymentMode.offline ? "-"   
                : invoiceDetails.paymentMode === paymentMode.offline ? 
                <p>Contact Us</p>
                : 
                formattedAmount(invoiceDetails.amount/100,invoiceDetails.currency,invoiceDetails.currencyCode)}</p>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    {/* printer-component */}
      <Box className="print-invoice print-invoice-printer">
        <img src={LogoImg} alt="logo" className="invoice-logo" />
        <Grid container spacing={2}>
          <Grid item xs={8} className="invoice-address">
            <p>26400 Lahser Rd. Suite 210</p>
            <p>Southfield, MI 48033</p>
            <p>T 020 3048 3123</p>
          </Grid>
          <Grid item xs={4} className="invoice-date">
            <h3>Invoice</h3>
            <p>{invoiceDetails?.orderDate ? moment(invoiceDetails.orderDate).format('DD-MMM-YYYY') : "-" }</p>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="invoice-bill-to">
              <h3>Bill to:</h3>
              <p>{invoiceDetails?.billingAddress ? invoiceDetails.billingAddress : "-"}</p>
             
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={invoiceDetails.paymentMode === paymentMode.offline ? 12 : 8}>
            <div className="invoice-desc">
              <h3>Description:</h3>
              <p>{invoiceDetails?.packageName ? invoiceDetails.packageName : "-"}</p>
              <h3 style={{ marginTop: '10px' }}>Type of Payment:</h3>
              <p>{invoiceDetails?.paymentMode ? invoiceDetails.paymentMode : "-"}</p>
            </div>
          </Grid>
          {invoiceDetails.paymentMode === paymentMode.offline ? "" :  
          <Grid item xs={4} style={{ paddingLeft: '0px' }}>
            <div className="invoice-total">
              <div className="alignment-total">
                <h3>Total:</h3>
                <p>{ !invoiceDetails?.amount && invoiceDetails.paymentMode !== paymentMode.offline ? "-"   
                : invoiceDetails.paymentMode === paymentMode.offline ? 
                <p>Contact Us</p>
                : 
                formattedAmount(invoiceDetails.amount/100,invoiceDetails.currency,invoiceDetails.currencyCode)}</p>
              </div>
            </div>
          </Grid>
}
        </Grid>
      </Box>
      <footer className="print-invoice-footer">        
        <div className="email_phone">
            <a href="https://aggregateintelligence.com/" target="_blank" >www.aggregateintelligence.com</a><span className="seprator">|</span>
            <a href={`tel:${CONFIG.ContactNo}`}>{CONFIG.ContactNo}</a><span className="seprator">|</span>
            <a href={`mailto:${CONFIG.SupportEmail}`} target="_blank">{CONFIG.SupportEmail} </a>
        </div>
        <div className="text-center">
            <img src="https://www.stortrack.com/images/ai_logo.png" alt="logo"/>
        </div>

    </footer>
    </div>
  );
});




export default ComponentToPrint;

import { Button, Modal, ModalClose, Typography, Sheet } from '@mui/joy';
import { Fragment, useState } from 'react';
import {api} from '../../../../services/api';
import { handlingCatchErorr, handlingSuccessResponse } from '../../../utils/errorHandling';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { CONFIG } from '../../../utils/config';
export default function AdminLoginAsModal(props) {
    let { userId } = props;
    const [open, setOpen] = useState(false);
    const [impersonation, setImpersonation] = useState(null);
    const [loader, setLoader] = useState(false);
    const handleCheckCancel = (e, reason) => {
        if (reason === 'closeClick') handleCancel(false);
    }
    const navigate = useNavigate();
    const handleCancel = () => {
        setOpen(false);
    };
    const startImpersonation = () => {
      setLoader(true);
      api.post('Impersonation', { userId: userId })
        .then((res) => {
            localStorage.setItem("impersonationInfo", JSON.stringify(res.data)); 
            setOpen(false);
            setImpersonation(true);
            const impersonationInfo = JSON.parse(localStorage.getItem("impersonationInfo"));
            const firstName = impersonationInfo.firstname;
            const lastName = impersonationInfo.lastname;
            const fullName = `${firstName} ${lastName}`
            handlingSuccessResponse(res, `You are now logged in as ${fullName}`, "information");
            updateUserInfoForImpersonation();
            navigate(CONFIG.MIG_login_url)
            window.history.pushState(null, null, window.location.pathname);
            window.onpopstate = function () {
                window.history.pushState(null, null, window.location.pathname);
            };
            setLoader(false);
        })
        .catch((err) => {
            handlingCatchErorr(err, navigate);
            setLoader(false);
        });
    }; 
    const updateUserInfoForImpersonation = () => {
        let impersonationInfo = JSON.parse(localStorage.getItem('impersonationInfo'));
        if (impersonationInfo) {
          localStorage.setItem('userInfo', JSON.stringify(impersonationInfo));
        }
      };           
    return (
        <Fragment>
            <Button className="login-as-btn" onClick={() => setOpen(true)}> {impersonation ? 'Logout' : 'Login As'}</Button>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={handleCheckCancel}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                className="login-as-popup"
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.body',
                        }}
                    />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Login as User
                    </Typography>
                    <Typography id="modal-desc" textColor="text.tertiary">
                        This feature allows administrators to log in as a user to troubleshoot issues and view account details. When needed, click "Login as" on a user's profile to temporarily access their dashboard, settings, and data. Admin permissions are retained, and actions are logged for security. Exercise caution, obtain user consent, and notify them of troubleshooting steps. Use the "Log Out" button to return to the admin session. Use this feature judiciously to respect user privacy. For common issues and assistance, contact the support team.
                    </Typography>
                    {impersonation? (<p>User Already LoggedIn</p>) : 
                        (<Button className='start-btn' sx={{marginTop: "20px"}} disabled={loader} onClick={startImpersonation}>{loader ? <CircularProgress size={24} color="inherit" sx={{ marginLeft: '8px' }} /> : ""} Start Login As</Button>)  }
                </Sheet>
            </Modal>
        </Fragment>
    );
}
import { Modal, ModalClose, Typography, Sheet } from '@mui/joy';
import { Fragment } from 'react';
import HubspotForm from 'react-hubspot-form';
import { CONFIG } from '../../utils/config';

export default function ContactUs(props) {
  let { open, title, handleCancel,isFrom } = props;

  const handleCheckCancel = (e, reason) => {
    if (reason === 'closeClick') handleCancel(e);
  }

  return (
    <Fragment>
      {(title === "Contact" || title === "Contact Us") ?
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={open}
          onClose={handleCheckCancel}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          className="custom-model-popup contact-us-popup"
        >
          <Sheet
            variant="outlined"
            sx={{
              maxWidth: 500,
              borderRadius: 'md',
              p: 3,
              boxShadow: 'lg',
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: 'calc(-1/4 * var(--IconButton-size))',
                right: 'calc(-1/4 * var(--IconButton-size))',
                boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                borderRadius: '50%',
                bgcolor: 'background.body',
              }}
            />
            <Typography
              component="h2"
              id="modal-title"
              variant="h4"
              textColor="inherit"
              fontWeight="lg"
              mb={1}
              style={{ marginBottom: '10px' }}
            >
              {title}
            </Typography>
            <Typography id="modal-desc" textColor="text.tertiary">
              <p style={{ marginTop: '0px' }}>
                Submit questions or concerns about your account
                or subscription to the support team.
              </p>
              <HubspotForm
                region="na1"  // specifies the region (data center) associated with your HubSpot account. 
                portalId="4297816"  //hubspot account unique portal id 
                formId="a83a7e57-db1d-41d2-8d9e-141c5c4586f6"   // // unique form  id  of hubspot form
              />
            </Typography>

          </Sheet>
        </Modal>
        :
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={open}
          onClose={handleCheckCancel}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          className="custom-model-popup request-quote-popup"
        >
          <Sheet
            variant="outlined"
            sx={{
              maxWidth: 500,
              borderRadius: 'md',
              p: 3,
              boxShadow: 'lg',
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: 'calc(-1/4 * var(--IconButton-size))',
                right: 'calc(-1/4 * var(--IconButton-size))',
                boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                borderRadius: '50%',
                bgcolor: 'background.body',
              }}
            />
            <Typography
              component="h2"
              id="modal-title"
              variant="h4"
              textColor="inherit"
              fontWeight="lg"
              mb={1}              
            >
              {/* {title} */}
              Request a quote for an<br/>Unlimited Explorer Subscription
              <p>Fill out the form below to request a quote or <a href= {isFrom === "us" ? CONFIG.Explorer_Free_url : 
                (isFrom === "europe" || isFrom === "uk") ? CONFIG.Europe_Free_url : ""}> Sign Up for FREE</a> to explore our subscription options now!</p>
            </Typography>
            <div id="modal-desc" textColor="text.tertiary">

              <HubspotForm
                region="na1"
                portalId="4297816"
                formId="ed218ffe-6d9b-4b1d-b2de-1366cfaf1a3b"
              />
            </div>

          </Sheet>
        </Modal>
      }
    </Fragment>
  );
}
import { CONFIG } from "../../utils/config";
import { Types, length, minimumLength,ultStaOrCou } from "../../utils/enums";

export const validationFunction = (name, val, data) => {
    let userData = JSON.parse(localStorage.getItem("userInfo"));
    let isAuthenticated = (userData?.secureId !== null && userData?.secureId !== undefined) && (userData?.token !== null && userData?.token !== undefined) ? true : false;
   
    let value = typeof (val) !== "string" ? val : val.trim();
    if (name === "fName" || name === "thridStepfName") {
        if (value.length === 0) return 'First Name is required';
        else if (value.length < minimumLength.minLength) return `First Name must be between ${minimumLength.minLength} and  ${length.name} characters. `;
        else if (value.length > length.name) return `First Name cannot exceed  ${length.name} characters. `;
        else if (!(/^[A-Za-z0-9\s.'_-]+$/).test(value)) return 'Invalid character';
        else return '';
    }

    if (name === "lName" || name === "thridSteplName") {
        if (value.length === 0) return 'Last Name is required';
        else if (value.length < minimumLength.minLength) return `Last Name must be between ${minimumLength.minLength} and  ${length.name} characters. `;
        else if (value.length > length.name) return `Last Name cannot exceed  ${length.name} characters. `;
        else if (!(/^[A-Za-z0-9\s.'_-]+$/).test(value)) return 'Invalid character';
        else return '';
    }
    if(name === "email" && data === true && value.length !== 0){
        return `Email Already linked to a ${CONFIG.Application_Name}. Please use a different email address`;    
    }

    if (name === "email" || name === "userName" || name === "thridStepemail") {
        if (value.length === 0) return 'Email is required';
        else if (!/^[a-zA-Z0-9]+(?:[._%+-][a-zA-Z0-9]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,4}$/.test(value)) return 'Invalid email address';
        else if (value.length > length.email) return `UserName cannot exceed ${length.email} characters `;
        else return '';
    }
    if ((name === "address1" || name === "zipcode" ||  name ==="statename" ) && data === "adminMarketEdit"){
     return ""
    } else if( name ==="address1"){
        if (value.length === 0) return 'Address Line 1 is required';
        else if (value.length > length.name) return `The max length ${length.name} characters is reached`;
        else return '';
    } else if (name === "zipcode"){
        if (value.length === 0) return 'Zip/Postal Code is required';
        else if (value.length > length.zipCode) return `The max length ${length.zipCode} characters is reached`;
        else return '';
    }

    if (name === "billingAddress" || name === "thridStepbillingAddress") {
        if (value.length === 0) return 'Address Line 1 is required';
        else if (value.length > length.name) return `The max length ${length.name} characters is reached`;
        else return '';
    }

    if (name === "billingCity" || name === "thridStepbillingCity") {
        if (value.length === 0) return 'City is required';
        else if (value.length > length.name) return `The max length ${length.name} characters is reached`;
        else return '';
    }

    if (name === "billingZipCode" || name === "thridStepbillingZipCode") {
        if (value.length === 0) return 'Zip/Postal Code is required';
        else if (value.length > length.zipCode) return `The max length ${length.zipCode} characters is reached`;
        else return '';
    }

    if (name === "billingCountry" || name === "thridStepbillingCountry" || name === "country") {
        if (value.length === 0) return 'Country is required';
        else if (value.length > length.name) return `The max length ${length.name} characters is reached`;
        else return '';
    }

    if (name === "billingState" || name === "thridStepbillingState") {
        if (value.length === 0) return 'State is required';
        else return '';
    }

    if(name === "billingStateId"){
        if (value === null) return 'State is required';
        else return '';
    }

    if (name === "productsCheckBoxes") {
        if (value.length === 0) return 'Atleast one product needs to be selected';
        else return '';
    }

    
    if (name === "units") {
        if (!/^(0|[1-9]\d{0,2})$/.test(value) || value === null)  return 'Market credits is Invalid';
        else if (value?.length === 0) return 'Market credits is required';
        else if (value?.length > length.creditCount) return `The max length ${length.creditCount} characters is reached`;

        else return '';
    }

    if (name === "Password" || name === "OldPassword" || name === "thridSteppass") {
        if (value.length === 0) return 'Password is required';
        else if (value.length > length.password) return `The max length ${length.password} characters is reached`;
        else return '';
    }
    

    if (name === "thridStepCardName") {
        if (value.length === 0) return 'Card Name is required';
        else if (!(/^[A-Za-z/\s.'-]+$/).test(value)) return 'Invalid character';
        else if (value.length > length.name) return `The max length ${length.name} characters is reached`;
        else return '';
    }

    if (name.endsWith("subscriptionId")) {
        if (value.length === 0) return 'Subscription Id is required';
        else if (value.length > length.subsLength) return `The max length ${length.subsLength} characters is reached`;
        else return '';
    }

    if (name.endsWith("checkSubscriptionBtn")) {
        if (value.length === 0) return 'Package details is required';
        else return '';
    }
    if (name.endsWith("SelPacDet")) {
        if (value.length === 0) return 'Package is required';
        else return '';
    }

    if (name.endsWith("customId")) {
        if (value.length === 0) return 'Credits/Amount is required';
        else return '';
    }

    if (name.endsWith(ultStaOrCou.ultCountry)) {
        if (value.length === 0) return 'Atleast one country needs to be selected';
        else return '';
    }

    if (name.endsWith(ultStaOrCou.ultState)) {
        if (value.length === 0) return 'Atleast one state needs to be selected';
        else return '';
    }

    if(name === "regState"){
        if (value.length === 0) return 'Atleast one state needs to be selected';  
        else return '';
    }

    if (name === "marketname") {
        if (value?.length === 0 || !value) return 'Store Name is required';
        else return '';
    }

    if (name === "address") {
        if (value.length === 0) return 'Address is required';
        else return '';
    }


     if (name === "city") {
        if (value?.length === 0) return 'City is required';
        else return '';
    }

    if (name === "state" && data !== "adminMarketEdit") {
        if (value?.length === 0) return 'State is required';
        else return '';
    }

    if (name === "isChecked" || name === "credits" || name === "isEnterpriseUserDropDown") return "";
    if (name === "isEnterpriceChecked") return "";
    if (name === "company" || name === "thridStepcompany") return "";
    if (name === "selectPayment") return "";
    if (name === "selectPackage") return "";
    if (name === "billingAddress2" || name === "thridStepbillingAddress2") return "";
    if (name === "btnChooseSubs") return "";
    
   
    if (name === "first") {
        if (value === false)
        return "Please accept the  term and condition";
        else return '';
    }

    if (name === "NewPassword") {
        let capsCount, smallCount, numberCount, symbolCount
        if (value.length === 0) return 'Password is required';
        else if (value.length < 6) return "Password must be minimum 6 characters. Include one UPPERCASE, lowercase, number and special character: @$! % * ? &";
        else if (value.length > length.password) return `The max length ${length.password} characters is reached`;
        else if (data.OldPassword === data.NewPassword) { return 'Your new password cannot be the same as your old password. Please choose a different password';
        }
        else {
            capsCount = (value.match(/[A-Z]/g) || []).length
            smallCount = (value.match(/[a-z]/g) || []).length
            numberCount = (value.match(/[0-9]/g) || []).length
            symbolCount = (value.match(/\W/g) || []).length
            if (capsCount < 1) {
                return "Must contain one UPPERCASE letter";
            }
            else if (smallCount < 1) {
                return "Must contain one lowercase letter";
            }
            else if (numberCount < 1) {
                return "Must contain one number";
            }
            else if (symbolCount < 1) {
                return "Must contain one special character: @$! % * ? &";
            }
        }
        return '';
    }

    if (name === "ConfirmPassword") {
        if (value.length === 0) return 'Password is required';
        else if (value.length > length.password) return `The max length ${length.password} characters is reached`;
        if (value !== data.NewPassword) return 'Passwords does not match';
        return '';
    }

    if (name === "payment" ) {
        if (value.length === 0) return 'At least one payment needs to be selected';
    
        else return '';
    }
    if (name === "national" ) {
        if (value.length === 0) return 'At least one country needs to be selected';
    
        else return '';
    }
    if (name === "latitude") {
        if (value.length === 0) return 'latitude is required';
    
        else return '';
    }
     if (name === "longitude" ){
     if (value.length === 0) return 'longitude is required';
    
        else return '';
    }

    if (name === "phone" || name === "registerphone" || name === "thridStepphone" || name === "stpOnePhone") {
        
        var string = value;
        var replacedString = value.replace(/-/g, "").replace("(", "").replace(")", "");
        var spaceRemovedStr = string.replace(/ /g, '');
        var numericRegex = /^([0-9]*)$/;
        var valid = numericRegex.exec(replacedString);
        let finalData = '';
        if (valid) {
            string = replacedString;
            var regex = replacedString.length === 10 ? /^([^\s]{3})([^\s]{3})+([^\s]{4})+$/g : replacedString.length === 11 ? /^([^\s]{3})([^\s]{3})+([^\s]{5})+$/g : replacedString.length === 12 ? /^([^\s]{3})([^\s]{3})+([^\s]{6})+$/g : replacedString.length === 13 ? /^([^\s]{3})([^\s]{3})+([^\s]{7})+$/g : replacedString.length === 14 ? /^([^\s]{3})([^\s]{3})+([^\s]{8})+$/g : replacedString.length === 15 ? /^([^\s]{3})([^\s]{3})+([^\s]{9})+$/g : /^([^\s]{3})([^\s]{3})+([^\s]{9})+$/g;
            var match = regex.exec(string);
            if (match) {
                match.shift();
                match[0] = "(" + match[0] + ")";
                string = match.join("-");
                if (replacedString.length >= 16) {
                    finalData = 'Invalid number';
                } else {
                    finalData = '';
                }
            } else {
                if (replacedString.length >= 16) {
                    finalData = 'Invalid number';
                } else {
                    finalData = '';
                }
            }

        } else if (spaceRemovedStr.length > 0) {
            finalData = 'Invalid number';
        }
        if (name === 'registerphone' && string === "") finalData = 'Phone is required';
        else if (string === "") finalData = '';
        return { 'phoneData': string, 'errMsg': finalData };
    }

    if (name === "payment" ) {
        if (value.length === 0) return 'At least one payment needs to be selected';
    
        else return '';
    }
    if (name === "national" ) {
        if (value.length === 0) return 'At least one country needs to be selected';
    
        else return '';
    }
}



import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { errorMsg, handlingCatchErorr, handlingSuccessResponse } from "../utils/errorHandling";
import { api } from "../../services/api";
import ContactUs from "../Home/modals/ContactUs";
import { CONFIG } from "../utils/config";

const PaymentSuccess = () => {
    const navigate = useNavigate();
    const search = useLocation().search;
    const session_id = new URLSearchParams(search).get('session_id');
    const entity  = new URLSearchParams(search).get('entity');
    const [authenticated, setAuthenticated] = useState();
    const [loading, setloading] = useState(false);
    const [isPayment, setisPayment] = useState([]);
    const [open, setOpen] = useState(false);
    const [isAccountActivated, setIsAccountActivated] = React.useState(0);


    const handleCancel = () => {
        setOpen(false);
    };

    const clickHandler = (event) => {
        event.preventDefault();
        if (!open) setOpen(true);
    };
    useEffect(() => {
        if (isAccountActivated === 0) {
            loadPaymentVerification();
        }
    }, []);

    const loadPaymentVerification = () => {
        setloading(true);
        api.post(`/Subscription/checkout/${session_id}/${entity}`)
            .then((res) => {
                if (res.status === 200) {
                    const redirectUrl = res.headers['redirecturl'];
                    if (redirectUrl) {
                        // Redirect to the new location
                        window.location.replace(redirectUrl);
                        handlingSuccessResponse(res, "Payment process is completed successfully");
                    } else {
                        setisPayment(res);
                        setloading(false);
                        setIsAccountActivated(1);
                    }
                }


            })
            .catch((error) => {
                if (error.response.status === 400) {
                    errorMsg(error.response.data);
                    setIsAccountActivated(2);

                } else {
                    handlingCatchErorr(error, navigate);
                }

                setloading(false);

            })
    }

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem("userInfo"));
        let isAuthenticated = userData !== null && (userData?.secureId !== null && userData?.secureId !== undefined) && (userData?.token !== null && userData?.token !== undefined) ? true : false;
        setAuthenticated(isAuthenticated)

    }, [])

    return (

        <>

            {isAccountActivated === 1 ?
                <div className={"account-acivate payment-success payment-msg" + (loading ? " payment-loading" : "")}>
                    <Typography variant="h4" gutterBottom className="text-center">
                    Thank you for your payment!
                    </Typography>
                    <p className="payment-desc">
                        Your purchase has been confirmed. An email will be sent to you with your purchase details. 
                    </p>
                    <Typography className="have-trouble">
                        If you have any questions regarding your account, please reach out to our team at<a href={`mailto:${CONFIG.SupportEmail}`}>{CONFIG.SupportEmail}</a>. We're ready to assist you!.
                    </Typography>
                    {
                        authenticated ?
                            <Link to={CONFIG.MIG_login_url} className="black-btn">Continue</Link>
                            :
                            <Link to={CONFIG.Explorer_url} className="black-btn">Log in to your Account</Link>
                    }
                    {/* <p className="payment-desc mb-0">Thanks once again for choosing {CONFIG.Application_Name}. We can't wait to serve you again in the future.
                    </p> */}
                </div >

                :
                isAccountActivated === 2 ?
                    <div className={"account-acivate payment-success payment-msg" + (loading ? " payment-loading" : "")}>
                        <Typography variant="h4" gutterBottom className="text-center">
                            Payment Failed!
                        </Typography>
                        <Typography className="have-trouble">
                            If you encounter any issues or have questions,<br />Please reach out to our <a href={`mailto:${CONFIG.SupportEmail}`}> {CONFIG.SupportEmail}</a> for assistance or<a onClick={clickHandler}>Contact Us</a>.
                        </Typography>


                        {
                            authenticated ?
                                <Link to={CONFIG.MIG_login_url} className="black-btn">Continue</Link>
                                :
                                <Link to={CONFIG.Explorer_url} className="black-btn">Continue </Link>
                        }
                    </div>
                    :
                    <div className={"account-acivate payment-success" + (loading ? " payment-loading" : "")}>

                        <Typography variant="h4" gutterBottom className="text-center">
                            Please Do Not Reload or Close This Page
                        </Typography>
                        <p className="payment-desc">
                            Thank you for your payment! We are currently finalizing the configuration of your account. To ensure that the process is completed successfully, please refrain from reloading or closing this page until you see the confirmation message.
                        </p>
                        <Typography variant="h5" gutterBottom>
                            Why is this important?
                        </Typography>
                        <p className="payment-desc text-left">
                            1. Closing or reloading the page prematurely may interrupt the configuration process and delay the confirmation of your account.<br />
                            2. This page is being configured to provide you with up-to-date information about your transaction.
                        </p>
                        <Typography variant="h5" gutterBottom>
                            What to Expect:
                        </Typography>
                        <p className="payment-desc text-left">
                            1. A confirmation message will be displayed on this page once the configuration is complete.<br />
                            2. You will also receive an email confirmation with detailed information about your account.
                        </p>
                        <Typography variant="h4" gutterBottom className="text-center">
                            Need Assistance?
                        </Typography>

                        <Typography className="payment-desc">
                            If you encounter any issues or have questions,<br />
                            please contact us at <a href={`mailto:${CONFIG.SupportEmail}`}> {CONFIG.SupportEmail}</a> or <b style={{fontWeight: '500'}}>{CONFIG.ContactNo}</b>
                        </Typography>

                    </div>
            }
            <ContactUs title={"Contact"} open={open} handleCancel={handleCancel} />
        </>

    )
}

export default PaymentSuccess;
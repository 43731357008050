import axios from 'axios';
import { CONFIG } from '../components/utils/config';

const baseURL = '/api/'; // Replace with your API base URL
const baseNASSURL = CONFIG. NASS_API_URL; // Replace with your NASS API base URL
const baseNApURL = CONFIG.NAP_API_URL
const baseEUSSURL = CONFIG. EU_API_URL; // Replace with your NASS API base URL

export const api = axios.create({
  baseURL,
  // timeout: 100000,
});

api.interceptors.request.use(
  (config) => {
    const key = JSON.parse(localStorage.getItem("userInfo"));
    const apiKey = key?.token ?? ""; // Replace with your actual API token
    config.headers['Authorization'] = `Bearer ${apiKey}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const nassapi = axios.create({
  baseURL: baseNASSURL, // Use baseNASSURL as baseURL here
  // timeout: 100000,
});

nassapi.interceptors.request.use(
  (configs) => {
    const key = JSON.parse(localStorage.getItem("userInfo"));
    const apiKey = key?.token ?? ""; // Replace with your actual API token
    configs.headers['Authorization'] = `Bearer ${apiKey}`;
    return configs;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const eussapi = axios.create({
  baseURL: baseEUSSURL, // Use baseNASSURL as baseURL here
  // timeout: 100000,
});

eussapi.interceptors.request.use(
  (configs) => {
    const key = JSON.parse(localStorage.getItem("userInfo"));
    const apiKey = key?.token ?? ""; // Replace with your actual API token
    configs.headers['Authorization'] = `Bearer ${apiKey}`;
    return configs;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const napapi = axios.create({
  baseURL: baseNApURL, // Use baseNASSURL as baseURL here
  // timeout: 100000,
});

napapi.interceptors.request.use(
  (configs) => {
    const key = JSON.parse(localStorage.getItem("userInfo"));
    const apiKey = key?.token ?? ""; // Replace with your actual API token
    configs.headers['Authorization'] = `Bearer ${apiKey}`;
    return configs;
  },
  (error) => {
    return Promise.reject(error);
  }
);

import * as React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Header, Footer } from '../core';
import Box from '@mui/material/Box';
import { Container } from '@mui/joy';
import { api } from "../../services/api";
import { handlingCatchErorr, handlingSuccessResponse } from "../utils/errorHandling";
function HomeLayout(props) {
  const navigate = useNavigate();
  const stoptImpersonation = () => {
    api.post('Impersonation/logout')
      .then((res) => {
        if (res.status === 200) {
          let userInfo = res.data;
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          props. handleImperLogout(JSON.stringify(res.data));
  
          // Remove impersonationInfo after setting userInfo
          localStorage.removeItem('impersonationInfo');
  
          handlingSuccessResponse(res, "You are Logged Out Successfully");
          navigate("/admin");
        }
      })
      .catch((err) => {
        handlingCatchErorr(err, navigate);
      });
  };  
  return (
    <div>
      <Header stoptImpersonation={stoptImpersonation} />
      <Box component="main" className="body-main">
        <Container maxWidth="xl">
          <div>
            <Outlet />
          </div>
        </Container>
      </Box>
      <Footer />
    </div>
  );
}

export default HomeLayout;
import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LogoImg from "../../../assets/images/suite_logo.png";
import moment from "moment";
import { formattedAmount } from "../commonFunctionalities/commonFunction";
import { CONFIG } from "../../utils/config";
import { getCurrencyDetail, paymentMode } from "../../utils/enums";


const UserBillingPrintComponent = React.forwardRef((props, ref) => {
  const {invoiceDetails, modeOfPayment, packagename} = props;
  return (
    <div ref={ref}>
      <Box className="print-invoice">
        <img src={LogoImg} alt="logo" className="invoice-logo" />
        <Grid container spacing={2}>
          <Grid item xs={8} className="invoice-address">
            <p>26400 Lahser Rd. Suite 210</p>
            <p>Southfield, MI 48033</p>
            <p>T 020 3048 3123</p>
          </Grid>
          <Grid item xs={4} className="invoice-date">
            <h3>Invoice</h3>
            <p>{invoiceDetails?.created ? moment(invoiceDetails.created).format('DD-MMM-YYYY') : "-" }</p>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="invoice-bill-to">
              <h3>Bill to:</h3>
              <p>{invoiceDetails?.billingAddress ? `${invoiceDetails.billingAddress.streetAddress}, ${invoiceDetails.billingAddress.city}, ${invoiceDetails.billingAddress.state}, ${invoiceDetails.billingAddress.country}, ${invoiceDetails.billingAddress.postalCode} ` : "-"}</p>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <div className="invoice-desc">
              <h3>Description:</h3>
              <p>{packagename ? packagename : "-"}</p>
              <h3 style={{ marginTop: '10px' }}>Type of Payment:</h3>
              <p>{modeOfPayment ? modeOfPayment : "-"}</p>
            </div>
          </Grid>
          <Grid item xs={4} style={{ paddingLeft: '0px' }}>
            <div className="invoice-total">
              <div className="alignment-total">
                <h3>Total:</h3>
                <p>{!invoiceDetails?.amount
                  ? "-"
                  : formattedAmount(invoiceDetails.amount / 100, getCurrencyDetail.currency, getCurrencyDetail.currencyCode)
                }</p>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    {/* printer-component */}
      <Box className="print-invoice print-invoice-printer">
        <img src={LogoImg} alt="logo" className="invoice-logo" />
        <Grid container spacing={2}>
          <Grid item xs={8} className="invoice-address">
            <p>26400 Lahser Rd. Suite 210</p>
            <p>Southfield, MI 48033</p>
            <p>T 020 3048 3123</p>
          </Grid>
          <Grid item xs={4} className="invoice-date">
            <h3>Invoice</h3>
            <p>{invoiceDetails?.created ? moment(invoiceDetails.created).format('DD-MMM-YYYY') : "-" }</p>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="invoice-bill-to">
              <h3>Bill to:</h3>
              <p>{invoiceDetails?.billingAddress ? `${invoiceDetails.billingAddress.streetAddress}, ${invoiceDetails.billingAddress.city}, ${invoiceDetails.billingAddress.state}, ${invoiceDetails.billingAddress.country}, ${invoiceDetails.billingAddress.postalCode} ` : "-"}</p>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={modeOfPayment === modeOfPayment === "offline" ? 12 : 8}>
            <div className="invoice-desc">
              <h3>Description:</h3>
              <p>{packagename ? packagename : "-"}</p>
              <h3 style={{ marginTop: '10px' }}>Type of Payment:</h3>
              <p>{modeOfPayment ? modeOfPayment : "-"}</p>
            </div>
          </Grid>
          {modeOfPayment === modeOfPayment === "offline" ? "" :  
          <Grid item xs={4} style={{ paddingLeft: '0px' }}>
            <div className="invoice-total">
              <div className="alignment-total">
                <h3>Total:</h3>
                <p>{!invoiceDetails?.amount
                  ? "-"
                  : formattedAmount(invoiceDetails.amount / 100, getCurrencyDetail.currency, getCurrencyDetail.currencyCode)
                }</p>
              </div>
            </div>
          </Grid>
}
        </Grid>
      </Box>
      <footer className="print-invoice-footer">        
        <div className="email_phone">
            <a href="https://aggregateintelligence.com/" target="_blank" >www.aggregateintelligence.com</a><span className="seprator">|</span>
            <a href={`tel:${CONFIG.ContactNo}`}>{CONFIG.ContactNo}</a><span className="seprator">|</span>
            <a href={`mailto:${CONFIG.SupportEmail}`} target="_blank">{CONFIG.SupportEmail} </a>
        </div>
        <div className="text-center">
            <img src="https://www.stortrack.com/images/ai_logo.png" alt="logo"/>
        </div>

    </footer>
    </div>
  );
});




export default UserBillingPrintComponent;

import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button, Modal, ModalClose, Sheet } from "@mui/joy";
import PrintComponent from './PrintComponent ';

const InvoiceModal = (props) => {
  const [open, setOpen] = useState(false);
  const [print, setPrint] = useState(false);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleCheckCancel = (e, reason) => {
    if (reason === 'closeClick') handleCancel(false);
  }

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button className="button-as-link" onClick={() => setOpen(true)}>
        {props.title}
      </Button>
      <Modal
        open={open}
        onClose={handleCheckCancel}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
        className="invoice-popup"
      >
        <Sheet
          variant="outlined"
          sx={{
            width: "100%",
            maxWidth: 700,
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: 'calc(-1/4 * var(--IconButton-size))',
              right: 'calc(-1/4 * var(--IconButton-size))',
              boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
              borderRadius: '50%',
              bgcolor: 'background.body',
            }}
          />
          <PrintComponent ref= {componentRef} invoiceDetails={props.invoiceDetails}/>
          <Button onClick={()=>{
            handlePrint()
            setPrint(true)
          }
        } 
          className='success-btn'>Print Invoice</Button>
        </Sheet>
      </Modal>
    </div>
  );
};

export default InvoiceModal


import { Table, TableBody, TableCell, TableRow, TableContainer, Paper, TablePagination } from '@mui/material';
import { Fragment, memo, useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AdminDeleteUserModal from '../../modals/adminModules/AdminDeleteUserModal';
import AdminLoginAsModal from '../../modals/adminModules/AdminLoginAsModal';
import EnhancedTableHead, { getSorting, handleAlphabets, stableSort } from '../../commonFunctionalities/CommonMuiSortingFunc';

const UserManagement = (props) => {
    let { loader,loaderTwo, usersData, users,state, handleRequestSortPreCall, initialUserManagementLoad, handleLoader, page, setPage } = props;
    let textWithAlphabets = ['firstname', 'lastname', 'email', 'company'];
    let disableTableRowsNames = ['loginAs', 'viewOrUpdate', 'deleteUser'];
    const [rowsPerPage, setRowsPerPage] = useState(25);
    useEffect(() => {
        handleAlphabets(textWithAlphabets);
    }, [])

    let headRows = [
        { id: 'firstname', label: 'First Name', numeric: false },
        { id: 'lastname', label: 'Last Name', numeric: false },
        { id: 'email', label: 'Email', numeric: false },
        { id: 'phone', label: 'Phone', numeric: true },
        { id: 'company', label: 'Company', numeric: true },
        { id: 'createdDate', label: 'Created', numeric: false },
        { id: 'lastLogin', label: 'Last Login', numeric: false },
        { id: 'subscriptions', label: 'no.Subscriptions', numeric: false },
        { id: 'viewOrUpdate', label: 'View/Update', numeric: false },
        { id: 'loginAs', label: 'Login As', numeric: false },
        { id: 'deleteUser', label: 'Delete', numeric: false },
    ];
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    usersData = usersData.slice().sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
   
    return (
        <Fragment>
                  {(loader || loaderTwo || (!loader && users.length === 0)) && <div className="loader-wrap"><div className="loading"></div> : </div>}
            <TableContainer component={Paper} className="common-table-design user-management-table fixed-header-table">
                <Table>
                    <EnhancedTableHead
                        order={state.order}
                        orderBy={state.orderBy}
                        onRequestSort={handleRequestSortPreCall}
                        disableTableRowsNames={disableTableRowsNames}
                        headRows={headRows}
                    />
                    <TableBody>
                        {usersData && usersData.length > 0 ?
                            stableSort(
                                usersData,
                                getSorting(state.order, state.orderBy)
                                )
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((strdata) =>
                                <TableRow key={strdata.userId}>
                                    <TableCell>{strdata.firstname ? strdata.firstname :"-"}</TableCell>
                                    <TableCell>{strdata.lastname ? strdata.lastname :"-"}</TableCell>
                                    <TableCell>{strdata.email ? strdata.email :"-"}</TableCell>
                                    <TableCell>{strdata.phone ? strdata.phone :"-"}</TableCell>
                                    <TableCell>{strdata.company ? strdata.company :"-"}</TableCell>
                                    <TableCell>{strdata.createdDate ? moment(strdata.createdDate).format('DD-MMM-YYYY') :"-"}</TableCell>
                                    <TableCell>{strdata.lastLogin ? moment(strdata.lastLogin).format('DD-MMM-YYYY') :"-"}</TableCell>
                                    <TableCell>{strdata.subscriptions ? strdata.subscriptions :"-"}</TableCell>
                                    <TableCell><Link to={`/updateusers/${strdata.userId}`} className='view-update-btn'>View/Update</Link></TableCell>
                                    <TableCell><AdminLoginAsModal editData={strdata} userId={strdata.userId} /></TableCell>
                                    <TableCell><AdminDeleteUserModal userId={strdata.userId} initialUserManagementLoad={initialUserManagementLoad} handleLoader={handleLoader} /></TableCell>
                                </TableRow>
                            ) :
                            ((!loader && !loaderTwo &&  !users.length === 0 )  &&
                                <TableRow>
                                    <TableCell colSpan={12} className="no-data">{'No Data Found'}</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {(usersData.length !== 0 )  &&
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={usersData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                />
            }
        </Fragment>
    )
}

export default memo(UserManagement);
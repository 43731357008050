import { Box, Button, Card, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Types, currencyCode, productNames } from "../utils/enums";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Basic from "../../assets/images/basic-white.png";
import Free from "../../assets/images/free-white.png";
import State from "../../assets/images/states-white.png";
import National from "../../assets/images/national-white.png";
import Reports from "../../assets/images/market-white.png";
import CommonForAll from "../Home/modals/commonModals/CommonForAll";
import AvailableData from "../Pricing/AvailableData";
import AvailableTitle from "../Pricing/AvailableTitle"
import Europe from "../../assets/images/europe-white.png";
import AvailableTitleEurope from "../Pricing/AvailableTitleEurope";
import EuropeData from "../Pricing/EuropeData";


const SubscriptionType = (props) => {
    const { marketDetail, summaryDetails, des, isPremium, IsUsSubscription, isAuthenticated, openPricePopup, entityLength, closePricePopup, selectedPymtEntity } = props;
    const [openData, setOPenData] = useState(false)
    const subscriptionDetails = marketDetail;
    const desc = des;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const subParam = searchParams.get('sub');
    const [warn, setWarn] = useState(false)
    const activeColor = summaryDetails;
    const selectedImage = [{
        id: 0,
        imageName: Free,
        subName: Types.free
    },
    {
        id: 1,
        imageName: Basic,
        subName: Types.basic
    },
    {
        id: 2,
        imageName: State,
        subName: Types.state
    },
    {
        id: 3,
        imageName: subscriptionDetails && subscriptionDetails?.productName === productNames.EUProductName ? Europe : National,
        subName: Types.national
    },
    {
        id: 4,
        imageName: Reports,
        subName: Types.reports
    },
    ]
    // const activeSub = subscriptionDetails?.subscriptions?.every(val=> !val.selected) && !activeColor ? 
    // "Unlimited National" : summaryDetails;

    const activeSub = summaryDetails;
    let subText = '';
    if (warn) {
        subText = `Already you have  Unlimited Us Subscription.`;
    }
    const handleStateClick = (isUs, subType) => {
        if (!isPremium > 0 && subType === Types.state && isUs) {
            setWarn(!warn)
        }

    }
    // const dynamicToValue =  window.location.pathname + window.location.search; 
    return (
        <>
            {props.load && <div className="loader-wrap"><div className="loading"></div></div>}
            <Grid container spacing={2} className="box-padding" >
                {subscriptionDetails && subscriptionDetails?.subscriptions?.length !== 0 &&
                    subscriptionDetails?.subscriptions?.filter(val => isAuthenticated ? val.showUpgrade : val.showSignUp && val.showToUser).sort((a, b) => a.sortOrder - b.sortOrder)?.map((details, index) => {
                        let boxColor = (details.selected && details.subscriptionkey !== subParam && subParam ?
                            "select-outline-box Freeze-box"
                            : details.selected ? "select-outline-box" :
                                !details.selected && details.subscriptionkey !== subParam && subParam || isPremium > 0 ? "select-outline-box  hide-box" : "");
                        let boxSecondColor = (details.subscriptionType === activeSub)

                            ? "select-color subscription-box-four"
                            : "subscription-box-four";
                        let blink = !activeSub ? "flicker-box" : ""
                        let ActiveImage = selectedImage
                            .filter(val => val.subName === details.subscriptionType)
                            .map((img, id) => img.imageName)
                        return (
                            <>
                                <Grid key={index} item xs={12} md={3} xl={3} className="box-clik">

                                    <Box
                                        disabled={subParam === null
                                            ? details.prices?.every(price => price.selected === true)
                                            : !details.selected || details.subscriptionkey !== subParam}
                                        className={boxColor + " " + boxSecondColor + " " + blink
                                        }

                                        onClick={() => {
                                            if (subParam === null) {
                                                handleStateClick(IsUsSubscription, details.subscriptionType)

                                                if (!details.prices?.every(price => price.selected === true) && !isPremium > 0) {
                                                    props.handleLoadDes(details.subscriptionType, details.subscriptionkey, null, details.subscriptionTypeId, details.packagename);

                                                }
                                            } else {
                                                if (details.subscriptionkey === subParam && details.selected === true) {
                                                    props.handleLoadDes
                                                        (details.subscriptionType, details.subscriptionkey, null, details.subscriptionTypeId, details.packagename);
                                                }
                                            }
                                        }}

                                    >
                                        {(details.selected && details.subscriptionType !== Types.reports) && <div className="selected-tick"> <CheckCircleOutlineIcon /> <span className="tick-text"> Already Subscribed </span></div>}
                                        {details.prices?.every(price => price.selected === true) && (
                                            <div className="selected-tick"> <CheckCircleOutlineIcon /> <span className="tick-text"> Already Subscribed </span></div>
                                        )}
                                        {(details.subscriptionType === activeSub && !details.selected
                                            || details.subscriptionType === Types.reports) &&
                                            <div className="box-tick"><CheckCircleOutlineIcon /> </div>
                                        }

                                        <Card className="boxbg-remove" >
                                            <img
                                                src={
                                                    details.subscriptionType === activeSub
                                                        ? ActiveImage// Access the first item in the mapped array
                                                        : (`/${details?.imageName}`)
                                                }
                                            />

                                            <Typography variant="subtitle1" gutterBottom className="box-inner-text" >
                                                {details.packagename}
                                            </Typography>


                                        </Card>
                                    </Box>
                                </Grid>

                            </>
                        )

                    })
                }
                <p className="box-desc">
                    {desc.description !== "" && desc.name !== "" &&
                        desc.description
                    }
                    {(entityLength.length <= 1 || summaryDetails === Types.free) && desc.description !== "" && desc.name !== "" && (
                        <>
                            <br />
                            <button className="clickhere " onClick={() => setOPenData(true)}>Click here</button> to compare subscription features.
                        </>

                    )}
                </p>
            </Grid>
            {warn && (
                <CommonForAll
                    open={warn}
                    btnText={"Ok"}
                    showText={subText}
                    className={"box-popup-single-state"}
                    close={() => setWarn(false)}
                />
            )}
            {(openData && entityLength.length <= 1) && <CommonForAll open={openData} showComponent={
                <div className="mig-pricing single-popup-design">
                    <AvailableTitle></AvailableTitle>
                    <AvailableData
                        from={"singel-checkout"}
                    /></div>}
                className={"box-popup-single-available"}
                close={() => {
                    setOPenData(false)
                }} />}  {/*open availabledata */}
            {(openData && entityLength.length > 1 && summaryDetails === Types.free) && <CommonForAll open={openData} showComponent={
                <div className="mig-pricing single-popup-design">
                    <AvailableTitleEurope />
                    <EuropeData
                        from={"singel-checkout"}
                        currency={currencyCode.Euro}
                    />
                </div>}
                className={"box-popup-single-available"}
                close={() => {
                    setOPenData(false)
                }} />}
        </>
    )
}
export default SubscriptionType
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Modal, ModalClose, Typography, Sheet } from '@mui/joy';
import { Box, Checkbox, Grid, TextField } from '@mui/material';
import { handlingCatchErorr } from '../../utils/errorHandling';
import {api} from '../../../services/api';
import { useNavigate } from 'react-router-dom';
import PasswordConfirmation from './PasswordConfirmation';
import { validationFunction } from '../commonFunctionalities/AllValidation';
import CommonPasswordStrengthMeter from '../commonFunctionalities/CommonPasswordStrengthMeter';

const initialPassData = { OldPassword: '', NewPassword: '', ConfirmPassword: '', };
let textFieldVariables = { "Old Password": "OldPassword", "New Password": "NewPassword", "Confirm Password": "ConfirmPassword" };
export default function ChangePasswordModal(props) {
    let { handleLoader } = props;
    const [open, setOpen] = useState(false);
    const [passData, setPassData] = useState({ ...initialPassData });
    const [showPasswords, setShowPasswords] = useState(false);
    const [isconfirm, setIsconfirm] = useState(false);
    const isFirstRender = useRef(true);
    const [passworderror, setPassworderror] = useState({});
    const [isSubmitOrNot, setIsSubmitOrNot] = useState(false);
    const [isStrength, setStrength] = useState(null);

    const navigate = useNavigate();

    const dataHandler = async (childData) => {
        setStrength(childData);
    }

    // Handle input field change
    const handleChange = (e) => {
        if (isSubmitOrNot) setIsSubmitOrNot(!isSubmitOrNot);
        const { name, value } = e.target;
        setPassData((prevData) => {
            const updatedData = { ...prevData, [name]: value };
            const returnedErrors = validationFunction(name, value, updatedData);
            errorMessageFunc(name, returnedErrors);
            handlePasswordValidation(name, value, updatedData);
            handlePasswordOldValidation(name, value, updatedData)


            return updatedData;
        });
    };
    
    const handlePasswordOldValidation = (name, value, updatedData) => {
      
    
        if (name === "OldPassword" && updatedData.NewPassword.length > 0) {
            if (value === updatedData.NewPassword) {
                errorMessageFunc("NewPassword", "Your new password cannot be the same as your old password. Please choose a different password");
            } else {
                errorMessageFunc("NewPassword", "");
            }  
        }    
    };
    

    const handlePasswordValidation = (name, value, updatedData) =>{
       
        if (name === "NewPassword" && updatedData.ConfirmPassword.length>0) {
            if (value !== updatedData.ConfirmPassword  ) {
                errorMessageFunc("ConfirmPassword", "Passwords does not match");
            } else{
                errorMessageFunc("ConfirmPassword", "");
  
            } 
            
            
        }  
           
    }

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        if (isSubmitOrNot) loadUpdatePasswordPostSubmit();
    }, [passworderror]);

    const errorMessageFunc = (errorName, errorMessage) => {
        setPassworderror((prevData) => ({ ...prevData, [errorName]: errorMessage }));
    }

    const loadUpdatePasswordPreSubmit = (e) => {
        setIsSubmitOrNot(true);
        e.preventDefault();
        Object.entries(passData).forEach(([key, val]) => {
            const returnedErrors = validationFunction(key, val, passData);
            errorMessageFunc(key, returnedErrors);
            handlePasswordValidation(key, val, passData)
            handlePasswordOldValidation(key, val, passData)
            
        });
    }

    const loadUpdatePasswordPostSubmit = () => {
        let { OldPassword, ConfirmPassword } = passData;
        let areThereErrorMsgOrNot = Object.entries(passworderror).length !== 0 ? Object.entries(passworderror).every(([key, val]) => val === "") : true;
        if (areThereErrorMsgOrNot) {
            const passInfo = { OldPassword: OldPassword, NewPassword: ConfirmPassword, id:null };
            handleLoader(true, false);
            api.post("account/changepassword", passInfo)
                .then((res) => {
                    if (res.status === 200) {
                        handleLoader(false, false);
                        handleCancel();
                        setIsconfirm(true)
                        localStorage.removeItem("userInfo");
                        localStorage.clear();
                    }
                })
                .catch((err) => {
                    handleLoader(false, false);
                    if (err.response?.status === 400) {
                        const newErrors = {};
                        newErrors.OldPassword = err.response.data;
                        setPassworderror(newErrors)
                    }
                    handlingCatchErorr(err, navigate);
                });
        }
    }



    const handleCheckCancel = (e, reason) => {
        if (reason === 'closeClick') handleCancel(false);
    }

    const handleCancel = () => {
        setOpen(false);
        setPassData(initialPassData);
        setPassworderror({});
        setShowPasswords(false);
        setIsSubmitOrNot(false)
    };

    return (
        <Fragment>
            <Button className="update-pwd-link" onClick={() => setOpen(true)}>
                Update Password
            </Button>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={handleCheckCancel}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                className="custom-model-popup change-pwd-popup"
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.body',
                        }}
                    />
                    <Typography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
                        Update Password
                    </Typography>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': '',
                        }}
                        noValidate
                        autoComplete="off"
                        className='custom-form'
                    >
                        <Grid container spacing={3}>
                            {Object.entries(textFieldVariables).map(([key, val]) => (
                                <Grid key={key} item xs={12}>
                                    <TextField
                                        name={val}
                                        value={passData[val]}
                                        onChange={handleChange}
                                        fullWidth
                                        label={key}
                                        variant="outlined"
                                        type={!showPasswords ? 'password' : 'text'}
                                        error={!!passworderror[val]}
                                        helperText={passworderror[val]}
                                        className={`custom-fields ${key === "New Password" ? "new-password-field" : ""} ${passData.NewPassword.length !== 0 && passData.NewPassword.length < 20 ? "new-password-error" : ""}`}
                                        onInput={handleChange}
                                        onPaste={(event) => {
                                            if (val === "NewPassword" || val === "ConfirmPassword") {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                    {key === "New Password" && (
                                        <div className="pwd-strength-meter">
                                            <CommonPasswordStrengthMeter password={passData.NewPassword} actions={dataHandler} />
                                        </div>
                                    )}
                                </Grid>
                            ))}
                            <Grid item xs={12} className='receive-alert'>
                                <Checkbox name="isChecked" checked={showPasswords} onChange={(e) => setShowPasswords(e.target.checked)} inputProps={{ 'aria-label': 'controlled' }} />Show Passwords
                            </Grid>
                            <Grid item xs={12} className='button-alignment'>
                                <Button onClick={handleCancel} className='cancel-btn'>Cancel</Button>
                                <Button onClick={loadUpdatePasswordPreSubmit} type="submit" className='success-btn'>Submit</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Sheet>
            </Modal>
            {isconfirm && <PasswordConfirmation open={isconfirm} />}
        </Fragment>
    );
}
